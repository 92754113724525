import React, { useEffect } from 'react'
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import './CommentForm.scss'
import { Controller } from "react-hook-form";
import { NavLink } from 'react-router-dom';

export const CommentForm = ({
  control,
  onSubmit,
  errors,
  handleSubmit
}) => {

  const {
		userData
	} = useSelector(state => state.user)

  return (
    <div className='CommentFormWrapper'>
      <form className="commentForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="userCommentImage">
          <NavLink to={`/profile?user=${userData._id}`}>
            <img
              src={userData?.profilePicture ? userData.profilePicture : '/profiletemp.jpg'}
              loading="lazy"
            />
          </NavLink>
        </div>
        <Controller
          name="comment"
          control={control}
          defaultValue={""}
          render={({field}) => <TextField
            {...field}
            multiline={true}
            className="textArea"
            label="Comment"
            variant="outlined"
            placeholder={"comment"}
            error={!!errors.comment}
            size="small"
            type="comment"
            sx={{
              width: '100%',
              fontSize: '12px'
            }}
            helperText={errors?.comment ? errors?.comment?.message : ''}
          />
        }/>
        <IconButton
        color="primary"
        aria-label="add an alarm"
        type="submit">
          <SendIcon />
        </IconButton>
      </form>
    </div>
  )
}

export default CommentForm;