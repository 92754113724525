//import EmotionOptions from '.components/EmotionOptions/EmotionOptions';//cut it and paste to the component
import React, {useState} from 'react';
import { emotions } from "../Hooks/Constants";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import './EmotionOptions.scss'
const EmotionOptions = ({
    showEmotion,
    setShowEmotion,
    setSelectedEmotion,
}) => {

	return <div className="emotionListSelection">
    {
    showEmotion ?
    <div className="emotionsListWrapper">
        <span
        className="close"
        onClick={()=>setShowEmotion(false)}
        ><CloseIcon sx={{ fontSize: 12 }}/></span>
        <ul className="emotions-list" >
            {emotions.map((emotion) => (
                <li key={emotion._id} >
                    <Tooltip title={emotion.name}>
                        <IconButton
                        size="small"
                        onClick={()=>{setSelectedEmotion(emotion._id)}}>
                            {emotion.emoji}
                        </IconButton>
                    </Tooltip>
                </li>
            ))}
        </ul>
    </div>
    :
    <Tooltip title={"Select Emotion"}>
        <span
        style={{
            fontSize: "19px",
            width: "60px",
            cursor: "pointer"
        }}
        onClick={()=>{
            setShowEmotion(show=>!show)
            setSelectedEmotion(-1)
        }}
        >
            {emotions.slice(0,3).map((emotion) => (
                <span key={emotion._id} className="emotionOption">{emotion.emoji}</span>	
            ))}
        </span>
    </Tooltip>
    }
    </div> 
}

export default EmotionOptions;