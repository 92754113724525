//import TodoActions from '.components/TodoActions/TodoActions';//cut it and paste to the component
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { useDispatch, useSelector } from 'react-redux';
import ReflectionForm from './ReflectionForm/ReflectionForm';
import Tooltip from '@mui/material/Tooltip';
import {
    actionPauseTodo,
    actionResumeTodo,
    actionStartTodo,
    actionSetTodoPopupMessage
} from '../../app/Reducers/todo/todoSlice';

const TodoActions = ({
    todo,
    iconFontSize="inherit"
}) => {

    const dispatch = useDispatch();
    const {
        userData
    } = useSelector(state => state.user);

    const [reflectionFormOpen, setReflectionFormOpen] = useState(false)
    
    const onStartEvent = () => {
        if(todo.assignedTo === userData._id || todo.assignedTo?._id === userData._id){
            dispatch(actionStartTodo(todo))
        } else {
            dispatch(actionSetTodoPopupMessage(
                true,
                "error", 
                "Unable to start todo. You can only start todo assigned to you."
            ))
        }
    }
    const onEndEvent = () => {
        if(todo.assignedTo === userData._id || todo.assignedTo?._id === userData._id){
            setReflectionFormOpen(true)
        } else {
            dispatch(actionSetTodoPopupMessage(
                true,
                "error", 
                "Unable to end todo. You can only end todo assigned to you."
            ))
        }
    }
    const onPauseEvent = () => {
        console.log(todo)
        console.log(userData)
        if(todo.assignedTo === userData._id || todo.assignedTo?._id === userData._id){
            dispatch(actionPauseTodo(todo._id))
        } else {
            dispatch(actionSetTodoPopupMessage(
                true,
                "error", 
                "Unable to pause todo. You can only pause todo assigned to you."
            ))
        }
    }
    const onResumeEvent = () => {
        if(todo.assignedTo === userData._id || todo.assignedTo?._id === userData._id){
            dispatch(actionResumeTodo(todo))
        } else {
            dispatch(actionSetTodoPopupMessage(
                true,
                "error", 
                "Unable to resume todo. You can only resume todo assigned to you."
            ))
        }
    }

    return <div className="actions-container">
        {
            todo?.status === 1 ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Tooltip title={"Pause"}>
                        <IconButton
                        onClick={()=>onPauseEvent()}
                        aria-label="pause"
                        size="medium"
                        sx={{padding: "2px"}}>
                            <PauseCircleOutlineIcon sx={{fontSize: iconFontSize}} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"End"}>
                        <IconButton
                        onClick={()=>onEndEvent()}
                        aria-label="end"
                        size="medium"
                        sx={{padding: "2px"}}>
                            <StopCircleIcon sx={{fontSize: iconFontSize}} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            ) :
            todo?.status === 0 ? (
                <Tooltip title={"Start"}>
                    <IconButton
                    onClick={()=>onStartEvent()}
                    aria-label="start"
                    size="medium"
                    sx={{padding: "2px"}}>
                        <PlayCircleOutlineIcon
                        sx={{ color: "#00AB55", fontSize: iconFontSize }} />
                    </IconButton>
                </Tooltip>
            ) :
            todo?.status === 2 ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Tooltip title={"Start"}>
                        <IconButton
                        onClick={()=>onResumeEvent()}
                        aria-label="resume"
                        size="medium"
                        sx={{padding: "2px"}}>
                            <PlayCircleOutlineIcon
                            sx={{ color: "#00AB55",fontSize: iconFontSize }}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"End"}>
                        <IconButton
                        onClick={()=>onEndEvent()}
                        aria-label="delete"
                        size="medium"
                        sx={{padding: "2px"}}>
                            <StopCircleIcon
                            sx={{fontSize: iconFontSize}} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            ) : ""
        }
        <ReflectionForm
            todo={todo}
            reflectionFormOpen={reflectionFormOpen}
            setReflectionFormOpen={setReflectionFormOpen}
        />
    </div>
}

export default TodoActions;