import React, { useEffect, useState} from 'react'
import Axios from "axios"
import './Payment.scss'
import { getHeaders } from '../../app/helpers'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from "@stripe/react-stripe-js"
import Checkout from './Checkout'

const Payment = ({
  amount
}) => {
  const URI = "https://wingenio.world"
  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState("")

  const getStripeConfig = async () => {

    const headers = getHeaders();
	  if(headers === null) return;

    const res = await Axios.get( 
      `${URI}/payment/stripe-config`,
      headers
    )

    setStripePromise(loadStripe(res.data))
    
  }

  const createPaymentIntent = async () => {

    const headers = getHeaders();
	  if(headers === null) return;

    const res = await Axios.post(
      `${URI}/payment/create-payment-intent`,
      { amount },
      headers
    )
    const { clientSecret } = res.data
    setClientSecret(clientSecret)
    
  }

  useEffect(() => {
    if(!stripePromise)
      createPaymentIntent()
	},[stripePromise])

	useEffect(() => {
    getStripeConfig()
	},[])

	return <div className='PaymentContainer'>
    {clientSecret && stripePromise && (
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <Checkout />   
      </Elements>)
    }
	</div>
}

export default Payment;