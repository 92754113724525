import React, { useEffect } from "react";
import ReactDom from "react-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
	actionToggleUpdateGroupFormOpen,
	actionUpdateGroup,
} from "../../app/Reducers/group/groupSlice";

const style = {
	position: "absolute",
	top: "40px",
	left: "50%",
	transform: "translate(-50%, 0)",
	width: "calc(100% - 80px)",
	maxWidth: "700px",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 2,
	height: "calc(100vh - 80px)",
	overflow: "auto"
};

const schema = yup.object().shape({
	name: yup.string().required("Provide valid name"),
});


const UpdateGroupForm = ({}) => {
	const dispatch = useDispatch();
	const {
    updateGroupFormOpen,
		selectedGroup,
  } = useSelector((state) => state.group);

	// form setup
	const {
		control,
		handleSubmit,
		reset,
    setValue,
		formState: { errors, isSubmitSuccessful },
	} = useForm({
		defaultValues: {
			name: "",
			description: "",
		},
		resolver: yupResolver(schema),
	});

	const onClose = () => {
		dispatch(actionToggleUpdateGroupFormOpen(false));
		reset({
			name: "",
			description: "",
		});
	};

	const onSubmit = ({
		name,
		description,
		classLink,
		role
	}) => {
		dispatch(
			actionUpdateGroup(selectedGroup._id,{
				name,
				description,
				classLink,
				role
			})
		);
	};

  useEffect(()=>{
    if(selectedGroup){
      setValue("name",selectedGroup?.name)
      setValue("description",selectedGroup?.description)
    }
  },[selectedGroup])

	return ReactDom.createPortal(
		<>
			<div className="GroupFormContainer">
				<Modal
					keepMounted
					open={updateGroupFormOpen}
					onClose={() => onClose()}
					aria-labelledby="modal-Group"
					aria-describedby="keep-mounted-modal-description"
				>
					<Box sx={style} spacing={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="flex-start"
							spacing={2}
							style={{ marginBottom: 10 }}
						>
							<Typography id="modal-Group" variant="h6" component="h2">
								Update group
							</Typography>

							<CloseIcon
								className="close-form-trigger"
								onClick={() => onClose()}
							/>
						</Stack>
						<form className="form-container" onSubmit={handleSubmit(onSubmit)}>
							<Stack direction="column" alignItems="center" spacing={2}>
								<RHFTextField
									control={control}
									name="name"
									label="name"
									placeholder="name"
									error={!!errors.name}
									helperText={errors?.name ? errors?.name?.message : ""}
									type="text"
								/>

								<RHFTextField
									control={control}
									name="description"
									label="description"
									placeholder="description"
									error={!!errors.description}
									helperText={
										errors?.description ? errors?.description?.message : ""
									}
									type="text"
								/>

								<Stack spacing={1} direction="row" justifyContent="center">
									<Button type="submit" variant="contained">
										Update
									</Button>
									<Button
										component={Link}
										to="/"
										type="button"
										variant="contained"
									>
										Cancel
									</Button>
								</Stack>
							</Stack>
						</form>
					</Box>
				</Modal>
			</div>
		</>,
		document.getElementById("update-group-form-wrapper-portal")
	);
};

export default UpdateGroupForm;
// Put this on the home page: <div id="create-group-form-wrapper-portal"></div>