import { configureStore } from '@reduxjs/toolkit';
import todoReducer from './Reducers/todo/todoSlice';
import userReducer from './Reducers/user/userSlice';
import noteReducer from './Reducers/note/noteSlice';
import dashboardReducer from './Reducers/dashboard/dashboardSlice';
import planningReducer from './Reducers/planning/planningSlice';
import chatsReducer from './Reducers/chats/chatsSlice';
import botReducer from './Reducers/bot/botSlice';
import momentReducer from './Reducers/moment/momentSlice';
import layoutReducer from './Reducers/layout/layoutSlice';
import postReducer from './Reducers/post/postSlice';
import goalReducer from './Reducers/goal/goalSlice';
import groupReducer from './Reducers/group/groupSlice';
import motivationReducer from './Reducers/motivations/motivationSlice';
import tradingPortfolioInfoReducer from './Reducers/tradingPortfolioInfo/tradingPortfolioInfoSlice';
import classroomReducer from './Reducers/classroom/classroomSlice';

export const store = configureStore({
  reducer: {
    todo: todoReducer,
    user: userReducer,
    note: noteReducer,
    dashboard: dashboardReducer,
    planning: planningReducer,
    chats: chatsReducer,
    bot: botReducer,
    moment: momentReducer,
    post: postReducer,
    goal: goalReducer,
    layout: layoutReducer,
    group: groupReducer,
    motivation: motivationReducer,
    tradingPortfolioInfo: tradingPortfolioInfoReducer,
    classroom: classroomReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
