//import Notes from '.components/Notes/Notes';//cut it and paste to the component
import React, {useState, useEffect} from 'react';
import NoteEditor from './NoteEditor';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CustomHTMLParser from "../Hooks/CustomHTMLParser";
import JSONToReact from "../Hooks/JSONToReact";
// import TitleIcon from '@mui/icons-material/Title';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import DeleteNote from './DeleteNote';
import NotesMoreOptions from './NotesMoreOptions';
import ColorSelection from '../ColorSelection/ColorSelection';
import { useDispatch } from 'react-redux';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import NoteIcon from '@mui/icons-material/Note';
import Tooltip from '@mui/material/Tooltip';
import {
    // actionUpdateNotes,
    actionChangeColor,
    actionGetTodosInNotes
} from '../../app/Reducers/note/noteSlice';
import {
    actionSetTodoInSideNav
} from '../../app/Reducers/todo/todoSlice';


const Notes = ({
    note,
    updateNote,
    deleteNote,
    enableCopyText,
    enableAddTodo,
    enableAddGoal
}) => {
   
    const [noteContent, setNoteContent] = useState(note.content);
    const [showNoteEditor, setShowNoteEditor] = useState(false);
    const [jsonNote, setJsonNote] = useState(JSON.parse(CustomHTMLParser(note.content)));
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
    const [showTodosInNotes, setShowTodosInNotes] = useState(false)
    
    const dispatch = useDispatch()

    // const createTodo = todoName => {
    //     dispatch(actionAddTodoInList({
    //         name: todoName,
    //         description: todoName
    //     }))
    // };

    const onSaveNote = updatedNote => {
        const rawJsonData = CustomHTMLParser(updatedNote);
        setNoteContent(updatedNote); // set the note data
        setShowNoteEditor(false); // close the editor
        setJsonNote(JSON.parse(rawJsonData));
        updateNote({
            ...note,
            content: updatedNote
        })
    }

    const onChangeColor = colorData => {
        dispatch(actionChangeColor(note._id, colorData))
    }

    useEffect(()=>{
        setNoteContent(note.content)
        setJsonNote(JSON.parse(CustomHTMLParser(note.content)));
    },[note])

    return (
    <div className='NotesContainer'>
        {/* {notes} */}
        <div className="note-wrapper">

            <div
            className="note-header"
            style={{
                backgroundColor: note?.colorData?.color ? note.colorData.color : "#f4f6f8"
            }}
            >
                <h2 style={{color: note?.colorData?.textColor ? note.colorData.textColor : "#252423" }} >{note?.title}</h2>
                <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                style={{marginRight: -10}}>
                    <Tooltip title={"Edit Note"}>
                        <IconButton
                        sx={{color: note?.colorData?.textColor ? note.colorData.textColor : "#252423" }}
                        className={"edit-note-trigger"}
                        onClick={()=>setShowNoteEditor(show=>!show)}
                        aria-label="edit"
                        size="small">
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    
                    <Tooltip title={showTodosInNotes ? "Show Note" : "Show Todos in Note"}>
                        <IconButton
                        sx={{color: note?.colorData?.textColor ? note.colorData.textColor : "#252423" }}
                        className={"edit-note-trigger"}
                        onClick={()=>{
                            setShowTodosInNotes(show=>!show)
                            if(!showTodosInNotes){
                                dispatch(actionGetTodosInNotes(note?._id))
                            }
                        }}
                        aria-label="edit"
                        size="small">
                            {showTodosInNotes ? <NoteIcon fontSize="inherit" /> : <TaskAltIcon fontSize="inherit" />}
                        </IconButton>
                    </Tooltip>

                    <ColorSelection
                    onChangeColor={onChangeColor} />

                    <NotesMoreOptions
                    note={note}
                    setShowNoteEditor={setShowNoteEditor}
                    />
                </Stack>
                
            </div>

            {showTodosInNotes ? 
            <div className="note-content">

                {
                    note?.todosInNotes?.length ? 
                    note.isLoading ? 
                    <div className="loading-wrapper">
                        <div className="loader"></div>
                    </div>
                    : 
                    <div className='todosInNotesList'>
                        <h3 className='todoInNoteHeading'>Note Todo List</h3>
                        {note.todosInNotes.map(
                            todo => <p 
                                    key={todo._id}
                                    className='todoInNote'
                                    onClick={()=>dispatch(actionSetTodoInSideNav(todo._id))}>
                                        {todo.name}
                                    </p>
                        )}
                    </div>
                    :
                    <p>No created Todos</p>
                }
            </div>
            :
            <div className="note-content">
                {noteContent.length ?   <JSONToReact
                                        htmlJson={jsonNote}
                                        note={note}
                                        noteId={note._id}
                                        enableCopyText={enableCopyText}
                                        enableAddTodo={enableAddTodo}
                                        enableAddGoal={enableAddGoal}
                                        /> : ""}
            </div>
            }

        </div>
        
        {showNoteEditor ? (
            <NoteEditor
            notes={noteContent}
            onSaveNote={onSaveNote}
            setShowNoteEditor={setShowNoteEditor}/>
        ) : ""}

        
        {/* {updateNote && <EditNote note={note}/>} */}

        {deleteNote && <DeleteNote
            note={note}
            deleteConfirmationOpen={deleteConfirmationOpen}
            setDeleteConfirmationOpen={setDeleteConfirmationOpen}
            deleteNote={deleteNote}
        />}
    </div>
    )
}

export default Notes;