//import Registration from '.components/Registration/Registration';//cut it and paste to the component
import React from 'react';
import './Registration.scss';
import { useSelector, useDispatch } from 'react-redux';
import VerifyEmail from '../VerifyEmail/VerifyEmail';
import RegistrationForm from './RegistrationForm';
import PopupNotifications from '../PopupNotifications/PopupNotifications';
import {
	actionHideUserPopupMessage
} from '../../app/Reducers/user/userSlice'

const Registration = ({
}) => {

	const {
		popupType,
		popupOpen,
		popupMessage,
		isUserRequestLoading,
		verifyEmail
	} = useSelector(state => state.user);

	const dispatch = useDispatch();

	const handlePopupClose = () => {
		dispatch(actionHideUserPopupMessage());
	}

	return (
		<div className='RegistrationContainer form-wrapper'>
			{
			verifyEmail ?
			<VerifyEmail/> :
			<RegistrationForm/>
			}
			{
				isUserRequestLoading ? 
				<div className="loading-wrapper">
				<div className="loader"></div>
				</div> : ""
			}
			<PopupNotifications
			open={popupOpen} 
			handleClose={handlePopupClose}
			severity={popupType}>
				{popupMessage}
			</PopupNotifications>
		</div>
	)
}

export default Registration;