import React, {useState, useCallback, useEffect} from 'react'
import { NavLink } from "react-router-dom";
import './NotificationItem.scss';
import { useDispatch } from 'react-redux';
import { actionSetTodoInSideNav } from '../../app/Reducers/todo/todoSlice';
import CommentIcon from '@mui/icons-material/Comment';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { ReactComponent as LogoIcon } from '../../Assets/ic_logo.svg';
import {
  actionTogglePostDetails,
  actionGetPostDetails
} from '../../app/Reducers/post/postSlice';

export const NotificationItem = ({
  data
}) => {

  const dispatch = useDispatch();

  const getPostNameToDisplay = (postDescription) => {
    return postDescription.length > 10 ? `${postDescription.substring(0,10)}...` : postDescription
  }

  const getPostData = () => (
    <div className="nameAndDateContainer">
      <p className="notificationHeadingName">
        <NavLink
        to={`/profile?user=${data.triggeredBy._id}`}
        className='semiBold'>{data.triggeredBy.firstName} {data.triggeredBy.lastName}</NavLink> commented on your <span
                                                                                                                  className='bold-with-cursor'
                                                                                                                  onClick={
                                                                                                                    ()=>dispatch(actionTogglePostDetails({
                                                                                                                      open: true,
                                                                                                                      post: data.post,
                                                                                                                      postId: data.post._id
                                                                                                                    }))
                                                                                                                  }>Post - {getPostNameToDisplay(data.post.postDescription)}</span>
      </p>
      <p className='notificationCommentWrapper'>
        <FormatQuoteIcon sx={{ fontSize: 28 }} />
        <span className="commentStyleWrapper">
          {data.postComment.comment}
        </span>
      </p>
    </div>
  )

  const getTodoData = () => (
    <div className="nameAndDateContainer">
      <p className="notificationHeadingName">
        <NavLink to={`/profile?user=${data.triggeredBy._id}`} className='semiBold'>{data.triggeredBy.firstName} {data.triggeredBy.lastName}</NavLink> commented on your <span className='bold-with-cursor' onClick={() => dispatch(actionSetTodoInSideNav(data.todo._id))}>Todo - {getPostNameToDisplay(data.todo.name)}</span>
      </p>
      <p className='notificationCommentWrapper'>
        <FormatQuoteIcon sx={{ fontSize: 28 }} />
        <span className="commentStyleWrapper">
          {data.momentComment.moment}
        </span>
      </p>
    </div>
  )

  const getSystemData = () => (
    <div className="nameAndDateContainer">
      {
      data.todo ? 
        <p className="notificationHeadingName">
          <span className='semiBold'>Hungry2Learn</span> message on your <span className='bold-with-cursor' onClick={() => dispatch(actionSetTodoInSideNav(data.todo._id))}>Todo - {getPostNameToDisplay(data.todo.name)}</span>
        </p> :
      data.post ?
        <p className="notificationHeadingName">
          <span className='semiBold'>Hungry2Learn</span> message on your <span
                                                                          className='bold-with-cursor'
                                                                          onClick={
                                                                            ()=>dispatch(actionTogglePostDetails({
                                                                              open: true,
                                                                              post: data.post,
                                                                              postId: data.post._id
                                                                            }))
                                                                          }>Post - {getPostNameToDisplay(data.post.postDescription)}</span>
        </p>
      :
        <p className="notificationHeadingName">
          <span className='semiBold'>Hungry2Learn</span>
        </p>
      }
      <p className='notificationCommentWrapper'>
        <FormatQuoteIcon sx={{ fontSize: 28 }} />
        <span className="commentStyleWrapper">
          {data.systemMessage}
        </span>
      </p>
    </div>
  )

  return (
    <div className={`NotificationItemContainer`}>
      <div className="notificationHeader">
        {
          /* This means that this is system generated notification */
          data.type == 2 ? <> 
            <div className={`userPostImage ${data.triggeredBy?.profilePicture ? "" : "noPicture"}`}>
              <a
              href="#"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100%",
                padding: "8px"
              }}><LogoIcon/></a>
              <span className="notifIcon comment">
                <WhatshotIcon sx={{ fontSize: 12, color: "#fff" }}/>
              </span>
            </div>
            {getSystemData()}
          </> : <>
            <div className={`userPostImage ${data.triggeredBy?.profilePicture ? "" : "noPicture"}`}>
              <NavLink to={`/profile?user=${data.triggeredBy._id}`}>
                <img src={data.triggeredBy.profilePicture ? data.triggeredBy.profilePicture : '/profiletemp.jpg'} loading="lazy"/>
              </NavLink>
              <span className="notifIcon comment">
                <WhatshotIcon sx={{ fontSize: 12, color: "#fff" }}/>
              </span>
            </div>
            {
            data?.post ? getPostData() :
            data?.todo ? getTodoData() :
            ""
            }
          </>
        }
        
      </div>
    </div>
  )
}

export default NotificationItem;