//import GroupCard from '.components/GroupCard/GroupCard';//cut it and paste to the component
import React from 'react';
import './GroupCard.scss';
import { NavLink } from "react-router-dom";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useDispatch } from 'react-redux';
import { actionRequestToJoinClassroom } from '../../app/Reducers/classroom/classroomSlice';
import Button from '@mui/material/Button';

const GroupCard = ({
  classData,
  joinFunction,
  joinText = "Join",
}) => {
  const dispatch = useDispatch();

	return <div className='GroupCardContainer'>
      <div
      className="classTimelinePicture"
      style={{
        backgroundImage: `url(${classData.classroomTimelineImage})`
      }}
      >
        <div className="classProfilePicture">
            <img src={classData?.classroomImage ? classData.classroomImage : ""} alt="" />
        </div>
        <NavLink className="nav-link" to={`/group/${classData._id}`}>.</NavLink>
      </div>
    <div className="classInfoContainer">
      <NavLink className="nav-link" to={`/group/${classData._id}`}>
        <h2 className='classRoomTitle'>{classData.name}</h2>
      </NavLink>
      <p className='classRoomDescription'>{classData.description}</p>
      <p className='classRoomDescription'>Created by: {classData.createdBy.firstName}</p>
      {
      joinFunction &&
      <Button
      style={{ width: "100%" }}
      variant="contained"
      onClick={()=>joinFunction(classData._id)}
      startIcon={<GroupAddIcon />}>{joinText}</Button>
      }
    </div>
	</div>
}

export default GroupCard;