//import CreateTodo from '.components/CreateTodo/CreateTodo';//cut it and paste to the component
import React, { useEffect,useState } from 'react';
// import './CreateTodo.scss';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CategoryIcon from '@mui/icons-material/Category';
import { useDispatch, useSelector } from 'react-redux';
import Axios from "axios";
import {
	actionAppendTodoData,
	actionToggleCreateTodoFormOpen,
	actionToggleCreateTodoCategoryFormOpen
} from '../../app/Reducers/todo/todoSlice';
// import {
// 	actionUpsertGoal
// } from '../../app/Reducers/goal/goalSlice';
import RHFSelect from "../ReactHookFormElements/RHFSelect";
import RHFCheckbox from "../ReactHookFormElements/RHFCheckbox";
import { TodoTypes } from "../Hooks/Constants";
import MenuItem from '@mui/material/MenuItem';
import { getHeaders } from '../../app/helpers';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;https://wingenio.world

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 2,
};

const schema = yup.object().shape({
	todoName: yup.string().required(),
	todoDescription: yup.string().required(),
	todoType: yup.number().required(),
	todoEstimate: yup.number().required(),
	isThrillingTask: yup.boolean(),
});

const CreateTodo = ({
	// goalName=""
}) => {

	const dispatch = useDispatch();
	const { 
		// userGoalNoteList,
		userData
	} = useSelector(state => state.user)
	const {
		selectedTodo,
		createTodoFormOpen,
		todoCategoryList
	} = useSelector(state => state.todo)
	
	// const { goals } = useSelector(state => state.goal)
	// const [selectedGoal, setSelectedGoal] = useState(goalName)
	// const [allGoals, setAllGoals] = useState([])

	// const isInGoalList = (noteGoal) => {
	// 	for(const goal of goals){
	// 		// console.log(goal)
	// 		if(noteGoal.name === goal.name) return true
	// 	}
	// 	return false
	// }

	const allCategories = [
		{_id: 1, name: "testing 1"},
		{_id: 2, name: "testing 2"}
	]

	// form setup
	const {
		control,
		watch,
		handleSubmit,
		reset,
		setValue,
		formState: {errors,isSubmitSuccessful}
	} = useForm({ 
		defaultValues: {
			todoName: "",
			todoDescription: "",
			todoEstimate: 1,
			todoType: 0,
			// todoGoal: goalName,
			isThrillingTask: false
		},
		resolver: yupResolver(schema)
	});
	
	const onClose = () => {
		dispatch(actionToggleCreateTodoFormOpen(false))
		reset({
			todoName: "",
			todoDescription: "",
			todoEstimate: 1,
			todoType: 0,
		});
	}

	// const addGoalData = (todoData, selectedGoalStr) => {
	// 	const selectedGoal = allGoals.find(goal => goal.name === selectedGoalStr)
	// 	if(selectedGoal.status){ // means the selected goal is in the saved goal
	// 		todoData = { ...todoData, goalId: selectedGoal._id }
	// 		// change the goal state here
	// 	}
	// 	else{
	// 		todoData = { ...todoData, goalName: goalName ? goalName : selectedGoalStr }
	// 		// change the goal state here
	// 	}
	// 	return todoData
	// }

	const saveTodoData = async (todo) => {
		const headers = getHeaders();
    if(headers === null) return
    
    const userId = userData ? userData._id : null;
		console.log(todo)
    try{
			/**/
			const res = await Axios.post(
					`${URI}/todos/create`,
					{
							...todo,
							userId
					},
					headers
			)
			// get the goal Id and update the goal
			// set the selected goal as onGoing goal
			const todoData = res.data
			// update the todo List
			dispatch(actionAppendTodoData({todo:todoData}))
			// update the goal list
			// if(todoData.goal){
			// 	// (todoData.goal)
			// 	dispatch(actionUpsertGoal(todoData.goal))
			// }
			reset({
				todoName: "",
				todoDescription: "",
				todoEstimate: 1,
				todoType: 0,
				isThrillingTask: false
			})
			/**/
    }
    catch(err){
      console.log(err)
    }
	}

	// on form submit
	const onSubmit = ({
		todoName,
		todoDescription,
		todoEstimate,
		todoType,
		todoGoal="",
		isThrillingTask=false,
		category=""
	}) => {

		let todoData = {
			name: todoName,
			description: todoDescription,
			estimate: todoEstimate,
			type: todoType,
			isThrillingTask,
			linkLevel: selectedTodo?.linkLevel ? selectedTodo.linkLevel : 0
		}

		if(category != ""){
			todoData = { ...todoData, category }
		}

		if(selectedTodo){
			todoData = {
				...todoData,
				linkedTodoId: selectedTodo._id,
				// isThrillingTask: selectedTodo.isThrillingTask
			}
		}
		
		console.log(todoData)
		/**/
		// let selectedGoalName = "";

		// if(todoGoal.length){ // means you selected a goal in the form
		// 	todoData = addGoalData(todoData, todoGoal)
		// }
		// else if(goalName.length){
		// 	todoData = addGoalData(todoData, goalName)
		// }
		// console.log(todoData)
		
		// dispatch(actionAddTodoInList(todoData))
		saveTodoData(todoData)
		/**/		
	}

	// reset the form after successfull submit of form data
	useEffect(() => {
		if (isSubmitSuccessful) {
			dispatch(actionToggleCreateTodoFormOpen(false))
		};
	}, [isSubmitSuccessful, reset]);

	// useEffect(()=>{
	// 	// remove the duplicate goals in the noteGoals and startedGoals
	// 	setAllGoals([
	// 		...goals,
	// 		...userGoalNoteList.filter(noteGoal=>!isInGoalList(noteGoal))
	// 	])
	// },[userGoalNoteList, goals])

	useEffect(()=>{
		setValue("isThrillingTask", selectedTodo ? selectedTodo.isThrillingTask : false)
	},[selectedTodo])

	// const selectedGoalWatcher = watch("todoGoal");

	return <div className='CreateTodoContainer'>
		<Modal
			keepMounted
			open={createTodoFormOpen}
			onClose={()=>onClose()}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description"
		>
			<Box sx={style}>
				<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={2}
				style={{marginBottom:10}}>

					<Typography id="keep-mounted-modal-title" variant="h6" component="h2">
						{`Create Todo`}
					</Typography>

					<CloseIcon 
					className="close-form-trigger"
					onClick={()=>onClose()}/>

				</Stack>
				<form className="todo-create-form" onSubmit={handleSubmit(onSubmit)}>
					<Stack spacing={2}>
						{/* {selectedGoalWatcher || goalName.length ? 
							<p><span style={{fontWeight:700}}>Goal:</span> {selectedGoalWatcher?.length ? selectedGoalWatcher : selectedGoal.length ? selectedGoal : goalName}</p> : ""
						} */}
						<Controller
						name="todoName"
						control={control}
						defaultValue={""}
						render={({field}) => <TextField
							{...field}
							size="small"
							className="textArea"
							label="Todo Name"
							variant="outlined"
							placeholder={"Todo Name"}
							error={!!errors.todoName}
							type="text"
							multiline
							helperText={errors?.todoName ? errors?.todoName?.message : ''}
						/>
						}/>
						
						<Controller
							name="todoDescription"
							control={control}
							defaultValue={""}
							render={({field}) => <TextField
								{...field}
								size="small"
								className="textArea"
								label="Todo Description"
								variant="outlined"
								placeholder={"Todo Description"}
								error={!!errors.todoDescription}
								type="text"
								multiline
								helperText={errors?.todoDescription ? errors?.todoDescription?.message : ''}
							/>
						}/>

						<Controller
							name="todoEstimate"
							control={control}
							defaultValue={1}
							render={({field}) => <TextField
								{...field}
								size="small"
								className="textArea"
								label="Todo Time Estimate (Hours)"
								variant="outlined"
								placeholder={"Todo Estimate"}
								error={!!errors.todoEstimate}
								type="text"
								multiline
								helperText={errors?.todoEstimate ? errors?.todoEstimate?.message : ''}
							/>
						}/>

						<div className="todoSelectionArea">
							{todoCategoryList.length ?
							<RHFSelect
							id="todo-category-selection"
							name="category"
							className={"todo-type-selection"}
							label="Category"
							control={control}
							defaultValue={""}
							variant="outlined"
							margin="normal"
							style={{
								flexGrow: 1
							}}>
								<MenuItem value={""}>Select Category</MenuItem>
								{todoCategoryList.map(
									todoCategory=>(
									<MenuItem
									key={todoCategory._id}
									value={todoCategory._id}>{todoCategory.name}</MenuItem>)
								)}
							</RHFSelect> : ""}

							<Tooltip title={"Add Category"}>
								<Button
								variant="outlined"
								startIcon={<CategoryIcon style={{fontSize: "12px"}}/>}
								style={{
									fontSize: "10px"
								}}
								onClick={()=>dispatch(actionToggleCreateTodoCategoryFormOpen(true, true, "createTodoForm"))}>
									<AddIcon/>
								</Button>
							</Tooltip>
						</div>
						{/* {goalName.length ? "" :
						<RHFSelect
						id="todo-goal-selection"
						name="todoGoal"
						className={"todo-type-selection"}
						label="Goal"
						control={control}
						defaultValue={goalName}
						variant="outlined"
						margin="normal">
							<MenuItem value={""}>Select Goal</MenuItem>
							{allGoals.map(goal=>(
							<MenuItem
							key={goal.name}
							value={goal.name}>{goal.name}</MenuItem>
							))}
						</RHFSelect>} */}

						<RHFSelect
						id="todo-type-selection"
						name="todoType"
						className={"todo-type-selection"}
						label="Todo Type"
						control={control}
						defaultValue={0}
						variant="outlined"
						margin="normal">
							{TodoTypes.map(type=><MenuItem key={type.value} value={type.value}>{type.text}</MenuItem>)}
						</RHFSelect>
						
						{selectedTodo ?
						<TextField
							disabled
							id="linked-todo"
							label="Linked todo"
							defaultValue={selectedTodo.name}
							// variant="filled"
						/> : ""}
						
						<RHFCheckbox
							control={control}
							name="isThrillingTask"
							label="Is Thrilling Task"
							className="thrillingTaskSelection"
							sx={{ '&.thrillingTaskSelection': { marginTop: "6px", marginLeft: "-10px" } }}
						/>
						{/* MuiFormControl-root
						MuiFormControl-marginNormal
						todo-type-selection
						css-ufpu9p-MuiFormControl-root

						MuiFormControlLabel-root
						MuiFormControlLabel-labelPlacementEnd
						css-j204z7-MuiFormControlLabel-root */}

						<Stack
						spacing={1}
						direction="row"
						justifyContent="flex-end">
							<Button type="submit" variant="outlined">Save</Button>
							<Button onClick={()=>onClose()} type="button" variant="outlined">Cancel</Button>
						</Stack>

					</Stack>
				</form>
			</Box>
		</Modal>
	</div>
}

export default CreateTodo;