//import NotesMoreOptions from '.components/NotesMoreOptions/NotesMoreOptions';//cut it and paste to the component
import React, { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CategoryIcon from '@mui/icons-material/Category';
import {v1 as uuid} from "uuid";
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
// import {
// 	// actionDeleteTodo,
// 	actionSetTodoPopupMessage
// } from '../../app/Reducers/todo/todoSlice';
import {
	actionDeleteNote,
	actionOpenChangeTodoCategory,
	actionToggleChangeNoteTitleForm
} from '../../app/Reducers/note/noteSlice';


// import EditTodo from "./EditTodo";
// import DeleteTodo from "./DeleteTodo"

const NotesMoreOptions = ({
	note,
	setShowNoteEditor,
}) => {
	const dispatch = useDispatch();
	const {
		userData
	} = useSelector(state => state.user);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const menuOptions = [
		{
			id: uuid(),
			name: "Edit Note",
			icon: <EditIcon fontSize="small"/>,
			callback: () => {
				if(note.owner === userData._id){
					setShowNoteEditor(true)
				} else {
					// dispatch(actionSetTodoPopupMessage(
					//	 true,
					//	 "error", 
					//	 "Unable to edit note. You can only edit assigned to you."
					// ))
				}
			}
		},
		{
			id: uuid(),
			name: "Change Title",
			icon: <EditIcon fontSize="small"/>,
			callback: () => {
				if(note.owner === userData._id){
					dispatch(actionToggleChangeNoteTitleForm(true,note))
					// updateNote(true)
				} else {
					// dispatch(actionSetTodoPopupMessage(
					//	 true,
					//	 "error", 
					//	 "Unable to edit note. You can only edit assigned to you."
					// ))
				}
			}
		},
		{
			id: uuid(),
			name: "Add to Category",
			icon: <CategoryIcon fontSize="small"/>,
			callback: () => {
				dispatch(actionOpenChangeTodoCategory(true, note))
				// if(note.assignedTo?._id === userData._id || note.assignedTo === userData._id){
				// 	// handleDelete()
				// }
				// else{
				// 	// dispatch(actionSetTodoPopupMessage(
				// 	//	 true,
				// 	//	 "error", 
				// 	//	 "Unable to delete note. You can only delete assigned to you."
				// 	// ))
				// }
			}
		},
		{
			id: uuid(),
			name: "Delete",
			icon: <DeleteIcon fontSize="small"/>,
			callback: () => {
				if(note.owner === userData._id){
					// handleDelete()
					dispatch(actionDeleteNote(note._id))
				}
				else{
					// dispatch(actionSetTodoPopupMessage(
					// 	 true,
					// 	 "error", 
					// 	 "Unable to delete note. You can only delete assigned to you."
					// ))
				}
			}
		}
	];

	const getMenuItems = () => {
		return menuOptions.map(option=>(
			<MenuItem
			key={option.id}
			onClick={()=>{
				option.callback()
				setAnchorEl(null)
			}}>
				<ListItemIcon style={{minWidth: 32}}>
					{option.icon}
				</ListItemIcon>
				<ListItemText>{option.name}</ListItemText>
			</MenuItem>
		))
	}
	
	return <div className='NotesMoreOptionsContainer'>
		{/* <EditTodo/> */}
		<Tooltip title={"More Options"}>
			<IconButton
			id="basic-button"
			aria-controls={open ? 'basic-menu' : undefined}
			aria-haspopup="true"
			aria-expanded={open ? 'true' : undefined}
			size="small"
			onClick={handleClick}>
				<MoreHorizIcon sx={{fontSize:20}}/>
			</IconButton>
		</Tooltip>
		<Menu
			id="basic-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
			'aria-labelledby': 'basic-button',
			}}
		>
			{getMenuItems()}
		</Menu>
	</div>
}

export default NotesMoreOptions;