import React, {useCallback} from 'react';
import TextField from '@mui/material/TextField';
import debounce from "lodash.debounce";
import { useDispatch } from 'react-redux';

const KeywordSearch = ({
    searchInputRef,
    reducerFunctionCallback,
    label="Search",
    triggerTextLengthGreaterThan = 2
}) => {

    const dispatch = useDispatch()
    const page = 1;

    const handler = useCallback(debounce((textSearch)=>{
        if(textSearch.length > triggerTextLengthGreaterThan){
            dispatch( reducerFunctionCallback( textSearch, page ) )
        } else if(textSearch.length === 0){
            // get the previouse set of todos
        }
    }, 500), []);

    const onChange = (event) => {
        handler(event.target.value);
    };

    return <TextField
            id="search-input"
            label={label}
            variant="outlined"
            size="small"            
            sx={{width: "100%", maxWidth: "250px"}}
            inputRef={searchInputRef}
            onChange={onChange}/>

}

export default KeywordSearch;