import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from "react-router-dom";
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actionAcceptToGroup } from "../../app/Reducers/group/groupSlice";

const UserSelectionItem = ({
  user,
  onUserAccept = null,
  onUserDecline = null,
  onUserRemove = null,
  isAdmin = false
}) => {

  const dispatch = useDispatch();
  
  return <div className='UserSelectionItemContainer'>
    <div className="notificationHeadingImage">
      {/* <img src={user} alt="" /> */}
      {user.firstName.charAt(0)}{user.lastName.charAt(0)}
    </div>
    <div className="listItemDetailsContainer">
      <Tooltip title={"View Details"}>
        <NavLink className="itemName" to={`/profile?user=${user._id}`}>{user.firstName} {user.lastName}</NavLink>
      </Tooltip>
      <p className="email">{user.email}</p>
    </div>
    <div className="actions-wrapper">
      {
      onUserAccept ?
        <Button
        variant="contained"
        size="small"
        style={{
          marginRight: "8px"
        }}
        onClick={()=>onUserAccept(user._id)}>Accept</Button> : ""
      }

      {
      onUserDecline ?
        <Button
        variant="contained"
        size="small"
        onClick={()=>onUserDecline(user._id)}>Decline</Button> : ""
      }
      
      {
      onUserRemove ?
        <Button
        variant="contained"
        size="small"
        onClick={()=>onUserRemove(user._id)}>Remove</Button> : ""
      }
      
      {
      isAdmin &&
        <Button
        variant="contained"
        size="small"
        disabled>Admin</Button>
      }


    </div>
  </div>
};

export default UserSelectionItem;
// Put this on the home page: <div id="create-group-form-wrapper-portal"></div>
