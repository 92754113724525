//import Profile from '.components/Profile/Profile';//cut it and paste to the component
import React, { useEffect, useState, memo } from 'react';
import './Profile.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
	actionGetUserProfile,
	actionChangeProfilePicture,
	actionChangeProfileTimelinePicture,
	actionSetUserSystemRole
} from '../../app/Reducers/user/userSlice';
import {
	actionGetPosts
} from '../../app/Reducers/post/postSlice';
import TodoList from "../../components/Todo/TodoList";
import ViewTimelinePicture from './ViewTimelinePicture';
import { NavLink, useSearchParams } from 'react-router-dom';
import { ReactComponent as FollowIcon } from '../../Assets/ic_follow.svg';
import Button from '@mui/material/Button';
import { actionTogglePostDetails } from '../../app/Reducers/post/postSlice';
import UserDisabledOrNotExist from '../../components/404/UserDisabledOrNotExist';
import PostInfiniteScroll from '../../components/PostInfiniteScroll/PostInfiniteScroll';
import ViewProfilePicture from './ViewProfilePicture';
import { 
	notACoachNorAdmin,
	notATeacherNorAdmin,
	enableCoachAssignRole,
	enableTeacherAssignRole,
	enableAssignAnyRole,
	systemRoles,
	getSystemRole,
	isCoachOrTeacher
} from '../../components/Hooks/Constants';

const joinButtonStyle = {
	position: "absolute",
	right: "20px",
	bottom: "20px",
	width: "200px"
}

const Profile = ({

}) => {

	const [parentControlShow, setParentControlShow] = useState(false)
	const [params, setParams] = useSearchParams({ user: "" })
	const dispatch = useDispatch();
	const id = params.get("user")

	const {
		selectedUserData,
		userData,
		isProfileLoading,
		userDoesNotExist
	} = useSelector(state => state.user)

	const {
		posts,
		page,
		isPostsLoading,
		hasNextPage,
		nextPage
	} = useSelector(state => state.post)

	const closeTodoList = () => { setParentControlShow(false) }
	const loadMoreCallback = pageNum => dispatch(actionGetPosts(id ? id : userData._id, pageNum))

	const onProfilePictureChange = (data, profilePicture) => {
		dispatch(
			actionChangeProfilePicture(
				{
					userId: id,
					...data
				},
				profilePicture
			))
	}

	const onTimelinePictureChange = (data, profilePicture) => {
		dispatch(
			actionChangeProfileTimelinePicture(
				{
					userId: id,
					...data
				},
				profilePicture
			)
		)
	}

	useEffect(()=>{
		dispatch(actionGetUserProfile(id))
		dispatch(actionGetPosts(id, page))
	},[id])

	return <div className='ProfileContainer'>
		{isProfileLoading ? <>
			<div className="loading-wrapper">
				<div className="loader"></div>
			</div>
		</> : ""}

		{
		!userDoesNotExist ? <>
			{/* Means that there is user data */}
			<div className="todo-list-wrapper">
				<TodoList
					defaultShow={false}
					parentControlShow={parentControlShow}
					callBack={closeTodoList}
				/>
			</div>
			<div className="timeline-wrapper">
				<div
				className="timeline-img-container">
					<ViewTimelinePicture
						src={selectedUserData.timelinePicture ? selectedUserData.timelinePicture : '/timelinetemp.jpg'}
						editable={true}
						onTimelinePictureChange={onTimelinePictureChange}
						associatedPostId={selectedUserData.timelinePicturePostLink}
					/>
					{
					selectedUserData._id == userData._id ? "" :
					<Button
					style={joinButtonStyle}
					variant="contained"
					startIcon={<FollowIcon />}>Follow</Button>
					}
				</div>
			</div>
			<div className="page-and-image-and-name-wrapper pp-wrap-inner">
				<div className="page-image-container">
					<ViewProfilePicture
						src={selectedUserData.profilePicture ? selectedUserData.profilePicture : '/profiletemp.jpg'}
						editable={true}
						onProfilePictureChange={onProfilePictureChange}
						associatedPostId={selectedUserData.profilePicturePostLink}
					/>
				</div>
				<div className="name-and-description">
					<div className="student-name-and-role">
						<h1 className='page-name'>{selectedUserData.firstName} {selectedUserData.lastName}</h1>
						<p className='role'>{getSystemRole(selectedUserData.systemRole)}</p>
					</div>
					<ul className="follow-actions">
						<li><NavLink to="/followers">{selectedUserData.followers.length} Followers</NavLink></li>
						<li><NavLink to="/followers">{selectedUserData.following.length} Following</NavLink></li>
						{/* Senior Web Developer In Wingenio */}
					</ul>
				</div>
				
				<div className="buttonActions">
					{
						notACoachNorAdmin(selectedUserData.systemRole) ? 
							enableCoachAssignRole(userData.systemRole) || enableAssignAnyRole(userData.systemRole) ? 
								<Button
								style={{ width: "200px" }}
								variant="contained"
								onClick={()=>dispatch(actionSetUserSystemRole(selectedUserData._id, systemRoles.coach))}					
								// startIcon={}
								>Assign as Coach</Button> 
							: ""
						:""
					}
					{/* {
						notATeacherNorAdmin(selectedUserData.systemRole) ? 
							enableTeacherAssignRole(userData.systemRole) || enableAssignAnyRole(userData.systemRole) ? 
								<Button
								style={{ width: "200px" }}
								variant="contained"
								onClick={()=>dispatch(actionSetUserSystemRole(selectedUserData._id, systemRoles.teacher))}					
								// startIcon={}
								>Assign as Teacher</Button> 
							: ""
						:""
					} */}
					{
						isCoachOrTeacher(selectedUserData.systemRole) ?
							<Button
								style={{ width: "200px" }}
								variant="contained"
								onClick={()=>dispatch(actionSetUserSystemRole(selectedUserData._id, systemRoles.student))}					
								// startIcon={}
								>Demote to Student</Button> 
							: ""					
					}
		
					
				</div>
			</div>
		</> : <UserDisabledOrNotExist/>
		}
		<div className="optionsContainerAndinfo pp-wrap-inner">
			<PostInfiniteScroll 
				posts={posts}
				page={page}
				nextPage={nextPage}
				hasNextPage={hasNextPage}
				loading={isPostsLoading}
				enablePostLayoutChange={true}
				loadMoreCallback={loadMoreCallback}
			/>
		</div>

		
	</div>
}

export default memo(Profile);