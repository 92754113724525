import React, { useState } from "react";
import ReactDom from 'react-dom';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
// import { createTodoCategoryFormOpen } from "../../app/Reducers/todo/todoSlice";
import Stack from "@mui/material/Stack";
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  actionToggleCreateTodoCategoryFormOpen
} from "../../app/Reducers/todo/todoSlice";
import Typography from '@mui/material/Typography';
import ColorSelection from "../ColorSelection/ColorSelection";
import { actionAddTodoCategory } from "../../app/Reducers/todo/todoSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  zIndex: 99
};

const schema = yup.object().shape({
  name: yup.string().required("Provide valid name")
});

const TodoCategoryForm = ({}) => {
  
  const dispatch = useDispatch();
  const [colorData, setColorData] = useState(
    { name: "Default", color: "#f4f6f8", textColor: "#f4f6f8" }
  )

  const {
    createTodoCategoryFormOpen
  } = useSelector(state => state.todo); 

  // form setup
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ name, description }) => {
    dispatch(actionAddTodoCategory({
      name,
      description,
      bgColor: colorData.color,
      textColor: colorData.textColor
    }));
  };

  const onChangeColor = colorData => {
    setColorData(colorData)
  }

  return ReactDom.createPortal(<>
    <div className="TodoCategoryFormContainer form-wrapper">
      <Modal
        keepMounted
        open={createTodoCategoryFormOpen}
        onClose={() => dispatch(actionToggleCreateTodoCategoryFormOpen(false))}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} spacing={2}>
          <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
              {`Create Todo Category`}
            </Typography>
            <Stack direction="column" alignItems="center" spacing={2}>

              <RHFTextField
                control={control}
                name="name"
                label="name"
                placeholder="name"
                error={!!errors.name}
                helperText={errors?.name ? errors?.name?.message : ""}
                size="small"
              />

              <RHFTextField
                control={control}
                name="description"
                label="description"
                placeholder="description"
                error={!!errors.description}
                helperText={errors?.description ? errors?.description?.message : ""}
                size="small"
              />

              <div className="color-container">
                <p>Note color: </p>
                <span
                  style={{
                    backgroundColor: colorData.color,
                    height: "20px",
                    width: "20px",
                    borderRadius: "20px",
                    fontSize: "0",
                    marginRight: "10px",
                    border: "1px solid #ccc"
                  }}
                >.</span>
                {colorData.name}
                <div className="selection-wrapper">
                  <ColorSelection onChangeColor={onChangeColor} />
                </div>
              </div>

              {/* <RHFTextField
                control={control}
                name="colorData"
                label="colorData"
                placeholder="colorData"
                error={!!errors.colorData}
                helperText={errors?.colorData ? errors?.colorData?.message : ""}
                size="small"
              /> */}

              <Stack spacing={1} direction="row" justifyContent="center">
                <Button type="submit" variant="contained">
                  Create
                </Button>
                <Button
                type="button"
                variant="contained"
                onClick={() => dispatch(actionToggleCreateTodoCategoryFormOpen(false))}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Modal>
    </div>
  </>,document.getElementById("create-todo-category-wrapper-portal"))
};

export default TodoCategoryForm;
