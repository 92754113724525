import React from 'react';
import './PlanningTaskTable.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
    actionAddTaskInPlanTaskList,
} from '../../app/Reducers/planning/planningSlice';
import PlanningTaskRow from './PlanningTaskRow';

const PlanningTaskTable = ({
    setTotalEstimatedTime
}) => {
    const {
        planTaskList
    } = useSelector(state => state.planning);
    
    const dispatch = useDispatch();

    return <section className="task-list-wrapper planning-input-wrapper">
        <h2 className="section-heading-2">Task List</h2>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">Task Name</TableCell>
                    <TableCell sx={{
                        width: 125
                    }} align="center">Estimate (hrs)</TableCell>
                    <TableCell sx={{
                        width: 100
                    }} align="center">Priority</TableCell>
                    <TableCell sx={{
                        width: 50,
                        borderBottom: "1px solid rgba(224, 224, 224, 1)"
                    }} align="center">Remove</TableCell>
                    
                </TableRow>
                </TableHead>
                <TableBody>
                {planTaskList.length ? 
                
                    planTaskList.map((note,i) => (
                        <PlanningTaskRow
                        key={note.taskKey}
                        setTotalEstimatedTime={setTotalEstimatedTime}
                        note={note}
                        index={i}/>
                    ))
                :
                    <TableRow
                    className="estimate-row-data"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                        colSpan={3}
                        align="left"
                        sx={{color: "red"}}
                        >Selected Note has no content</TableCell>
                    </TableRow>
                }
                </TableBody>
            </Table>
        </TableContainer>

        <Button
            variant="contained"
            className="add-task-button"
            onClick={() => dispatch(actionAddTaskInPlanTaskList())}
        >Add Task</Button>
    </section>
}

export default PlanningTaskTable;