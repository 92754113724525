//import Group from '.components/Group/Group';//cut it and paste to the component
import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
import './Group.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
	actionGetGroupDetails,
	actionRequestToJoinGroup,
	actionToggleGroupUsersJoinRequestListOpen,
	actionToggleGroupJoinRequestListOpen,
	actionAcceptToGroup,
	actionDeclineToGroup,
	actionChangeGroupProfilePicture,
	actionChangeGroupTimelinePicture,
	actionToggleUpdateGroupFormOpen,
	actionToggleGroupUsersMembersListOpen,
	actionRemoveToGroup
} from '../../app/Reducers/group/groupSlice';
import {
	actionSetDefaultSelectedGroup,
	actionRemoveDefaultSelectedGroup,
	actionsShowUserSelection
} from '../../app/Reducers/user/userSlice';
import {
	actionGetGroupPosts
} from '../../app/Reducers/post/postSlice';
import { useParams } from 'react-router-dom';
import ViewTimelinePicture from '../Profile/ViewTimelinePicture';
import ViewProfilePicture from '../Profile/ViewProfilePicture';
import Button from '@mui/material/Button';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import UsersListSelectionActions from '../../components/Group/UsersListSelectionActions';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Tooltip from '@mui/material/Tooltip';
import { systemRoles } from '../../components/Hooks/Constants';
import UserSearchAndSelectionModal from '../../components/UserSearchAndSelectionModal/UserSearchAndSelectionModal';
import PostInfiniteScroll from '../../components/PostInfiniteScroll/PostInfiniteScroll';
import UpdateGroupForm from '../../components/UpdateGroupForm/UpdateGroupForm';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {v1 as uuid} from "uuid";
import DeleteIcon from '@mui/icons-material/Delete';

const Group = ({

}) => {

	const [showUploadForm, setShowUploadForm] = useState(false)
	const dispatch = useDispatch();
	const { id } = useParams();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClose = () => setAnchorEl(null);

	const {
		isGroupLoading,
		selectedGroup,
    groupJoinRequests,
    groupJoinRequestListOpen,
		groupMembersListOpen,
		groupMembers,
		actionDeleteGroup
	} = useSelector(state => state.group)

	const {
		userData
	} = useSelector(state => state.user);

	const {
		groupPosts,
		groupnextPage,
		isPostsLoading,
		grouphasNextPage,
		grouppage
	} = useSelector(state => state.post)

	const loadMoreCallback = pageNum => dispatch(actionGetGroupPosts(id, pageNum))
	const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

	const onProfilePictureChange = (data, profilePicture) => {
		dispatch(
			actionChangeGroupProfilePicture(
				{
					groupId: id,
					...data
				},
				profilePicture
			))
	}

	const onTimelinePictureChange = (data, profilePicture) => {
		dispatch(
			actionChangeGroupTimelinePicture(
				{
					groupId: id,
					...data
				},
				profilePicture
			)
		)
	}

	const isMember = () => {
		if(selectedGroup?.admin.indexOf(userData._id) > -1)
			return true
		if(selectedGroup?.members)
			return selectedGroup.members.indexOf(userData._id) > -1
		return false
	}

	const getPageOptions = () => {
		const classroomOptions = []

		if(
			userData.systemRole == systemRoles.superAdmin ||
			userData.systemRole == systemRoles.admin
		){
			classroomOptions.push({
				id: uuid(),
				name: "Add member",
				style: { width: "200px" },
				icon: <GroupAddIcon />,
				callback: () => dispatch(actionsShowUserSelection(true))
			})
		}

		if(!isMember()){
			classroomOptions.push({
				id: uuid(),
				name: "Join",
				icon: <GroupAddIcon />,
				callback: () => dispatch(actionRequestToJoinGroup(id))
			})
		}
		if(isMember()){
			classroomOptions.push({
				id: uuid(),
				name: "Leave Group",
				icon: <ExitToAppIcon />,
				callback: () => console.log("Create Leave classroom functionality")
			})
		}

		if(userData._id === selectedGroup.createdBy._id){
			classroomOptions.push({
				id: uuid(),
				name: "Delete Group",
				style: { color: "#d32f2f" },
				icon: <DeleteIcon />,
				callback: () => dispatch(actionDeleteGroup(selectedGroup))
			})
		}

		return classroomOptions.length ? classroomOptions.map(option=>(
			<MenuItem
			key={option.id}
			onClick={()=>{
					option.callback()
					setAnchorEl(null)
			}}>
					<ListItemIcon style={{minWidth: 32}}>
							{option.icon}
					</ListItemIcon>
					<ListItemText>{option.name}</ListItemText>
			</MenuItem>
		)) : ""
	}

	useEffect(()=>{
		if(id){
			dispatch(actionGetGroupDetails(id))
			dispatch(actionGetGroupPosts(id, grouppage))
		}
	},[id])

	if(!selectedGroup) return ""

	return <div className='GroupContainer'>
		{isGroupLoading ? <>
			<div className="loading-wrapper">
				<div className="loader"></div>
			</div>
		</> : ""}	
		<div className="timeline-wrapper">
			<div
			className="timeline-img-container">
				<ViewTimelinePicture
					src={selectedGroup?.groupTimelineImage ? selectedGroup.groupTimelineImage : '/grouptimelinetemp.jpg'}
					editable={true}
					onTimelinePictureChange={onTimelinePictureChange}
					associatedPostId={selectedGroup.groupTimelinePicturePostLink}
				/>
			</div>
		</div>
		<div className="page-and-image-and-name-wrapper pp-wrap-inner">

			<div className="page-image-container">
				<ViewProfilePicture
					src={selectedGroup?.groupImage ? selectedGroup.groupImage : '/grouptemp.jpg'}
					editable={true}
					onProfilePictureChange={onProfilePictureChange}
					associatedPostId={selectedGroup.groupProfilePicturePostLink}
				/>
			</div>

			<div className="name-and-description">
				<h1 className='page-name'>{selectedGroup ? selectedGroup.name : ""}</h1>
				{selectedGroup?.description ? <div className="page-description">{selectedGroup.description}</div> : ""}
				{
					userData.defaultSelectedGroup ? 
						userData.defaultSelectedGroup == selectedGroup._id ?
							<Tooltip title={"Remove as default group"}>
								<span
								className='defaultSelectedGroup selected'
								onClick={
									()=>dispatch(actionRemoveDefaultSelectedGroup(selectedGroup._id))
								}><StarIcon sx={{
									width: "16px",
									height: "16px"
								}}/> Default group</span>
							</Tooltip> :
							<Tooltip title={"Set as default group"}>
								<span
								className='defaultSelectedGroup'
								onClick={
									()=>dispatch(actionSetDefaultSelectedGroup(selectedGroup._id))
								}><StarBorderIcon color="primary"/> Set as default selected group</span>
							</Tooltip>						
						:
						<Tooltip title={"Set as default group"}>
							<span
							className='defaultSelectedGroup'
							onClick={
								()=>dispatch(actionSetDefaultSelectedGroup(selectedGroup._id))
							}><StarBorderIcon/> Set as default selected group</span>
						</Tooltip>
				}
				<p
					className='info clickable'
					onClick={()=>{
						dispatch(actionToggleGroupUsersMembersListOpen(true, selectedGroup._id))
					}}
				>{selectedGroup?.members?.length} Member{selectedGroup?.members?.length > 1 ? "s" : ""}</p>
				{
				selectedGroup.admin == userData._id &&
				<p
					className='info clickable'
					onClick={()=>{
						
						dispatch(actionToggleGroupUsersJoinRequestListOpen(true, selectedGroup._id))
					}}
				>{selectedGroup?.joinRequests?.length} Join Request{selectedGroup?.joinRequests?.length > 1 ? "s" : ""}</p>
				}
				{
						selectedGroup
						?
							isMember() ?
								<p className='info'><GroupIcon /> Joined</p> :
								selectedGroup.joinRequests.indexOf(userData._id) > -1 ?
									<p className='info'><GroupAddIcon /> Join Requested</p>
									:
									<p
									className='info'
									onClick={()=>{dispatch(actionRequestToJoinGroup(selectedGroup._id, userData._id))}}
									><GroupAddIcon /> Join Group</p>										
						: ""
					}

			</div>
			
			<div className="buttonActions">
					
					{
					userData.systemRole == systemRoles.superAdmin ||
					userData.systemRole == systemRoles.admin ?
						<Button
						style={{ width: "200px" }}
						variant="contained"
						onClick={()=>dispatch(actionsShowUserSelection(true))}					
						startIcon={<GroupAddIcon />}>Add member</Button>
						:
						""
					}
					<Tooltip title="More Options">
						<Button
						variant="contained"
						onClick={handleMoreClick}
						sx={{
							width: "38px",
							minHeight: "36px",
							minWidth: "auto",
							padding: "0",
						}}
						><ExpandMoreIcon /></Button>
					</Tooltip>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						MenuListProps={{
						'aria-labelledby': 'basic-button',
						}}
						
					>
							{getPageOptions()}
					</Menu>
			</div>
		</div>
		<div className="optionsContainerAndinfo pp-wrap-inner">
			<div className="postPageInfoArea">
				<div className="postPageInfoAreaSection">
					<div
					className="wrapHeading"
					style={{
						marginBottom: "16px",
						alignItems: "center"
					}}>
						<h2 className="nav-section-header">Group information</h2>
						{
						selectedGroup.admin == userData._id &&
						<Tooltip title="Update Group">
							<IconButton
							sx={{ marginLeft: "10", padding: "2px"}}
							aria-label="Update Group"
							size="small"
							onClick={()=>dispatch(actionToggleUpdateGroupFormOpen(true))}>
								<EditIcon sx={{ fontSize: "14px", color:"#00AB55"}}/>
							</IconButton>
						</Tooltip>
						}
					</div>

					<dl>
						<dt className='infoLabelGroup'>Created By: </dt>
						<dd>{selectedGroup.createdBy.firstName} {selectedGroup.createdBy.lastName}</dd>
					</dl>

				</div>
			</div>
			<div className="postList">
				<PostInfiniteScroll 
					posts={groupPosts}
					page={groupnextPage}
					nextPage={grouphasNextPage}
					hasNextPage={grouphasNextPage}
					loading={isPostsLoading}
					loadMoreCallback={loadMoreCallback}
					enablePostLayoutChange={true}
					group={selectedGroup}
					enableCreatePost={isMember()}
				/>
			</div>
		</div>
		<UsersListSelectionActions
			userList=			{ groupJoinRequests }
			open=					{ groupJoinRequestListOpen }
			title=				{ "Group Join Requests" }
			onClose=			{ () => dispatch(actionToggleGroupJoinRequestListOpen(false)) }
			onUserAccept=	{ userId => dispatch(actionAcceptToGroup(selectedGroup._id, userId)) }
			onUserDecline={ userId => dispatch(actionDeclineToGroup(selectedGroup._id, userId)) }
		/>
		{
		selectedGroup.admin == userData._id ?
		<UsersListSelectionActions
			userList=			{ groupMembers }
			open=					{ groupMembersListOpen }
			title=				{ "Group Members" }
			onClose=			{ () => dispatch(actionToggleGroupUsersMembersListOpen(false)) }
			onUserRemove=	{ userId => dispatch(actionRemoveToGroup(selectedGroup._id, userId)) }
			exceptedUsersFromRemoval = {selectedGroup.admin}
		/> :
		<UsersListSelectionActions
			userList=			{ groupMembers }
			open=					{ groupMembersListOpen }
			title=				{ "Group Members" }
			onClose=			{ () => dispatch(actionToggleGroupUsersMembersListOpen(false)) }
			// onUserRemove=	{ userId => dispatch(actionRemoveToGroup(selectedGroup._id, userId)) }
			exceptedUsersFromRemoval = {selectedGroup.admin}
		/>
		}

		
		{/* 
		groupMembers */}
		<UserSearchAndSelectionModal
			showAddToGroupButton={true}
			selectedGroup={selectedGroup}/>
		{/* <p onClick={()=>dispatch(actionGetUserGroup(selectedUserData._id))}>testing here</p> */}
		<UpdateGroupForm />
	</div>
}

export default memo(Group);