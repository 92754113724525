//import TodoViewFilter from '.components/TodoViewFilter/TodoViewFilter';//cut it and paste to the component
import React, {useState,useEffect} from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import CategoryIcon from '@mui/icons-material/Category';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
    actionGetTodoList,
    actionToggleCreateTodoCategoryFormOpen,
    actionGetTodoCategories
} from '../../app/Reducers/todo/todoSlice';

import TodoCategoryForm from '../TodoCategory/TodoCategoryForm';


const TodoViewFilter = ({

}) => {

    const dispatch = useDispatch()
    const {selectedUserData} = useSelector(state => state.user);
    const {todoCategoryList} = useSelector(state => state.todo);

    const [categoryFilter, setCategoryFilter] = useState([
        // { label:"Done",         checked: false, value: 3 },
    ])

    const [selectedTasksFilter, setSelectedTasksFilter] = useState([
        { label:"Done",         checked: false, value: 3 },
        { label:"On going",     checked: true,  value: 1 },
        { label:"Paused",       checked: true,  value: 2 },
        { label:"Not started",  checked: true,  value: 0 }
    ])
    
    const dateFilters = [
        {value:"all",       label:"All"     },
        {value:"daily",     label:"Daily"   },
        {value:"weekly",    label:"Weekly"  },
        {value:"monthly",   label:"Monthly" }
    ]

    const [dateFilter, setDateFilter] = React.useState('all');

    const handleChange = (optionIndex, value) => {
        setSelectedTasksFilter([...selectedTasksFilter].map(e => {
            return {
                ...e,
                checked: e.value === value ? !e.checked : e.checked
            }
        }))
    }
    
    const handleDateChange = e => setDateFilter(e.target.value);

    useEffect(() => {
        const todoStatusArray = selectedTasksFilter.filter(e=>e.checked).map(e=>e.value)
        dispatch(
            actionGetTodoList(
                selectedUserData._id,
                {
                    todoStatusArray,
                    dateFilter,
                    page:1
                }
            )
        )
    }, [selectedTasksFilter,dateFilter,selectedUserData?._id]);

    useEffect(() => {
        setCategoryFilter(todoCategoryList.map(todoCategory=>{
            return {
                label: todoCategory.name,
                checked: false,
                value: todoCategory._id
            }
        }))
    }, [todoCategoryList]);

    useEffect(()=>{
        dispatch(actionGetTodoCategories())
    },[])

    return (
        <>
        <FormGroup className="tasks-filter-wrapper">
            <h3 className="filter-by-heading">Filter by Category</h3>
            {categoryFilter.map(({label,checked,value}) => {
                return <FormControlLabel 
                key={value}
                control={
                    <Checkbox
                    size="small"
                    value={value}
                    checked={checked}
                    // onChange={() => handleChange(optionIndex,value)}
                    />
                }
                label={label} />
            })}
            <Button
            variant="outlined"
            startIcon={<CategoryIcon style={{fontSize: "12px"}}/>}
            style={{
                fontSize: "10px",
                marginTop: "12px",
                marginBottom: "10px",
            }}
            onClick={()=>dispatch(actionToggleCreateTodoCategoryFormOpen(true))}
            >Create Category</Button>
        </FormGroup>

        <FormGroup className="tasks-filter-wrapper">
            <h3 className="filter-by-heading">Filter by status</h3>
            {selectedTasksFilter.map(({label,checked,value}, optionIndex) => (
                <FormControlLabel 
                key={optionIndex}
                control={
                    <Checkbox
                    size="small"
                    value={value}
                    checked={checked}
                    onChange={() => handleChange(optionIndex,value)}/>
                }
                label={label} />
            ))}
        </FormGroup>

        <div className="tasks-filter-wrapper">
            <FormControl >
                <h3 className="filter-by-heading">Filter by date range</h3>
                <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={dateFilter}
                    onChange={handleDateChange}
                >
                    {dateFilters.map(({value,label})=><FormControlLabel
                                                        key={label}
                                                        value={value}
                                                        control={<Radio />}
                                                        label={label} />)}
                </RadioGroup>
            </FormControl>
        </div>

        <TodoCategoryForm />

        </>
    )
}

export default TodoViewFilter;