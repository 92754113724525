//import ChangeTodoCategoryForm from '.components/ChangeTodoCategoryForm/ChangeTodoCategoryForm';//cut it and paste to the component
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RHFSelect from "../ReactHookFormElements/RHFSelect";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
	actionUpdateNotes,
	actionOpenChangeTodoCategory
} from '../../app/Reducers/note/noteSlice';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 2,
};

const schema = yup.object().shape({
  category: yup.string().required('Please Select Category'),
});


const ChangeTodoCategoryForm = ({
}) => {

	const dispatch = useDispatch();
	const {
		// todoCategoryList,
		selectedNote,
		openChangeTodoCategoryForm
	} = useSelector(state => state.note);
	const {
		todoCategoryList
	} = useSelector(state => state.todo);

	// form setup
	const {
		control,
		handleSubmit,
		reset,
		formState: {errors,isSubmitSuccessful} } = useForm({ 
		resolver: yupResolver(schema)
	});

	// reset the form after successfull submit of form data
	useEffect(() => {
		if (isSubmitSuccessful) {
			// actionOpenChangeTodoCategory(false)
		};
	}, [isSubmitSuccessful, reset]);

	// on form submit
	const onSubmit = ({category}) => {
		console.log("gumagena ")
		dispatch(actionUpdateNotes({
			_id: selectedNote._id,
			category
		}))
	}


	return <div className='ChangeTodoCategoryFormContainer'>
		<Modal
			keepMounted
			open={openChangeTodoCategoryForm}
			onClose={()=>dispatch(actionOpenChangeTodoCategory(false))}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description"
		>
			<Box sx={style} spacing={2}>
				<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={2}
				style={{marginBottom:10}}>

					<Typography id="keep-mounted-modal-title" variant="h6" component="h2">
						{`Change Note Category`}
					</Typography>

					<CloseIcon 
					className="close-form-trigger"
					onClick={()=>dispatch(actionOpenChangeTodoCategory(false))}/>

				</Stack>
				<form className="todo-edit-form" onSubmit={handleSubmit(onSubmit)}>
					<Stack spacing={2}>

					{todoCategoryList.length ?
						<RHFSelect
							name="category"
							label="Category"
							control={control}
							defaultValue=""
							error={errors.category}
							errors={errors}
						>
							<MenuItem value={""}>Select Category</MenuItem>
							{todoCategoryList.length ? todoCategoryList.map(
								noteCategory => (
									<MenuItem key={noteCategory._id} value={noteCategory._id}>
										{noteCategory.name}
									</MenuItem>
								)
							) : ""}
						</RHFSelect>
					:
					<p>No Category List Available</p>}
					  <Stack
					  spacing={1}
					  direction="row"
					  justifyContent="flex-end">
						  <Button type="submit" variant="outlined">Save</Button>
						  <Button onClick={()=>dispatch(actionOpenChangeTodoCategory(false))} type="button" variant="outlined">Cancel</Button>
					  </Stack>

					</Stack>
				</form>
			</Box>
		</Modal>
	</div>
}

export default ChangeTodoCategoryForm;