import React, {
	useEffect,
	memo,
	useRef
} from 'react';
import './UserSettings.scss';
import {
	actionsShowUserSelection,
	actionGetSystemRoleAssigneeList
} from '../../app/Reducers/user/userSlice';
import RoleForm from '../../components/Role/RoleForm';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import UserCard from '../../components/UserCard/UserCard';
import UserSearchAndSelectionModal from '../../components/UserSearchAndSelectionModal/UserSearchAndSelectionModal';
import { systemRoles } from '../../components/Hooks/Constants';

const buttonStyle = {
	margin: "10px auto 5px",
	display: "block",
	width: "160px",
	fontWeight: 600,
	letterSpacing: "0.25px"
}

const UserSettings = ({
}) => {
	// const searchInputRef = useRef(null)
	const dispatch = useDispatch()
	const {
		// roleList,
		superAdmins,
		admins,
		moderators,
		userData
	} = useSelector((state) => state.user);
		
	useEffect(()=>{
		dispatch(actionGetSystemRoleAssigneeList())
	},[])

	return (
    <div className={`UserSettingsContainer`}>
      <h2 className="section-title-01">User Settings</h2>
			{
			userData.systemRole == systemRoles.superAdmin ?
			<div className="followLayoutContainer">
				<h3 className="section-title-04">Super Admin <span style={{fontSize: "10px", fontWeight: 300, textTransform: "none"}}>(Default Role)</span></h3>
				<div className="suggestedPeopleList">
					{superAdmins.length ? (
						superAdmins.map((user) => <UserCard key={user._id} user={user} showFollowButton={true} />)
					) : (
						<p style={{marginBottom: "12px"}}>No Super admins to show</p>
					)}
				</div>
				<Button
				variant="contained"
				onClick={()=>dispatch(actionsShowUserSelection(true, 10))}
				size={"small"}
				sx={buttonStyle}
				disableElevation={true}
				>Add User</Button>
			</div> : ""
			}
			{
			userData.systemRole == systemRoles.superAdmin ||
			userData.systemRole == systemRoles.admin ?
			<>
			<div className="followLayoutContainer">
				<h3 className="section-title-04">Admins <span style={{fontSize: "10px", fontWeight: 300, textTransform: "none"}}>(Default Role)</span></h3>
				<div className="suggestedPeopleList">
					{admins.length ? (
						admins.map((user) => <UserCard key={user._id} user={user} showFollowButton={true} />)
					) : (
						<p style={{marginBottom: "12px"}}>No admins to show</p>
					)}
				</div>
				
				<Button
				variant="contained"
				onClick={()=>dispatch(actionsShowUserSelection(true, 1))}
				size={"small"}
				sx={buttonStyle}
				disableElevation={true}
				>Add User</Button>
			</div>
			<div className="followLayoutContainer">
        <h3 className="section-title-04">Moderators <span style={{fontSize: "10px", fontWeight: 300, textTransform: "none"}}>(Default Role)</span></h3>
        <div className="suggestedPeopleList">
          {moderators.length ? (
            moderators.map((user) => <UserCard key={user._id} user={user} showFollowButton={true} />)
          ) : (
            <p style={{marginBottom: "12px"}}>No moderators to show</p>
          )}
        </div>
				
				<Button
				variant="contained"
				onClick={()=>dispatch(actionsShowUserSelection(true, 2))}
				size={"small"}
				sx={buttonStyle}
				disableElevation={true}
				>Add User</Button>
      </div>  
			</> : ""
			}
    

      {/* <div className="followLayoutContainer">
        <h3 className="section-title-04">Roles</h3>
				{roleList.length ? (
					roleList.map(role => <p key={role._id}>{role.name}</p>)
				) : (
					<p style={{marginBottom: "12px"}}>No Roles to show</p>
				)}
      </div>
      <p>Delete a role that is not default role</p>
      <p>Set User Role Function</p> */}

      {/*
			<p></p>
				Super Admin - has access to all the functions of the webapp
				Admin - has access to most all functions of the webapp
				Moderator - has limited access to some of the functions in the web app

				Super Admin
				can set user as an admin and super admin

				Admin and super admin
				can view the following:
				1) Task calendar of a selected user
				2) Dashboard of a selected user
				3) Can view the Management task
			*/}
			<UserSearchAndSelectionModal showRoleAssignmentButton={true}/>
      <RoleForm />
    </div>
  );
		
				
}

export default memo(UserSettings);