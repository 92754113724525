import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller } from "react-hook-form";

const RHFCheckbox = ({
  control,
  name,
  label,
  defaultValue=false,
  sx={},
  size='small',
  className=""
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) =>(
        <FormControlLabel
          className={className}
          sx={sx}
          control={
            <Checkbox
            {...field}
            checked={field.value}
            size={size}
            />}
          label={label}
        />
      )}
    />
  );
};
export default RHFCheckbox;