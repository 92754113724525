import { ReactComponent as ChatIcon } from '../../Assets/ic_chat.svg';
import { ReactComponent as TargetIcon } from '../../Assets/ic_target.svg';
import { ReactComponent as QuestionIcon } from '../../Assets/ic_question.svg';
import { ReactComponent as ConceptIcon } from '../../Assets/ic_concept.svg';
import { ReactComponent as InsightIcon } from '../../Assets/ic_insight.svg';
import { ReactComponent as ResultIcon } from '../../Assets/ic_result.svg';
import { ReactComponent as KeyQuestionIcon } from '../../Assets/ic_keyquestion.svg';
import { ReactComponent as KeyConceptIcon } from '../../Assets/ic_keyconcept.svg';
import { ReactComponent as KeyInsightIcon } from '../../Assets/ic_keyinsight.svg';
import { ReactComponent as KeyResultIcon } from '../../Assets/ic_keyresult.svg';
import moment from 'moment';

export const TodoStatus = {
	notStrated: 0,
	started: 1,
	paused: 2,
	done: 3
}

export const getTodoStatus = statusCode => {
	switch(statusCode){
		case 0 : return {
			color: "#637381",
			background: "rgba(99, 115, 129, 0.16)",
			text: "Not Started"
		}
		case 1 : return {
			color: "#00ab55",
			background: "rgba(0, 171, 85, 0.08)",
			text: "On Going"
		}
		case 2 : return {
			color: "#b78103",
			background: "rgba(255, 193, 7, 0.16)",
			text: "Paused"
		}
		case 3 : return {
			color: "#1890FF",
			background: "rgba(24, 144, 255, 0.08)",
			text: "Done"
		}
	}
	return {
		color: "#637381",
		background: "rgba(99, 115, 129, 0.16)",
		text: "Not Started"
	}
}

const GoalStatus = {
	started: 1,
	reached: 2,
	deleted: 3,
	paused: 4,
	notStrated: 5,
}

export const getTodoStatusStyle = statusCode => {
	switch(statusCode){
		case TodoStatus.notStrated : return {
			color: "#637381",
			borderColor: "#637381",
			background: "rgba(99, 115, 129, 0.16)",
		}
		case TodoStatus.started : return {
			color: "#00ab55",
			borderColor: "#00ab55",
			background: "rgba(0, 171, 85, 0.08)",
		}
		case TodoStatus.paused : return {
			color: "#b78103",
			borderColor: "#b78103",
			background: "rgba(255, 193, 7, 0.16)",
		}
		case TodoStatus.done : return {
			color: "#1890FF",
			borderColor: "#1890FF",
			background: "rgba(24, 144, 255, 0.08)",
		}
	}
	return {
		color: "#637381",
		borderColor: "#637381",
		background: "rgba(99, 115, 129, 0.16)",
	}
}

export const getGoalStatusStyle = statusCode => {
	switch(statusCode){
		case GoalStatus.started : return {
			color: "#00ab55",
			borderColor: "#00ab55",
			background: "rgba(0, 171, 85, 0.08)",
		}
		case GoalStatus.reached : return {
			color: "#1890FF",
			borderColor: "#1890FF",
			background: "rgba(24, 144, 255, 0.08)",
		}
		case GoalStatus.paused : return {
			color: "#b78103",
			borderColor: "#b78103",
			background: "rgba(255, 193, 7, 0.16)",
		}
		case GoalStatus.notStrated : return {
			color: "#637381",
			borderColor: "#637381",
			background: "rgba(99, 115, 129, 0.16)",
		}
	}
	return {
		color: "#637381",
		borderColor: "#637381",
		backgroundColor: "rgba(99, 115, 129, 0.16)",
	}
}

export const getGoalStatusName = statusCode => {
	switch(statusCode){
		case 0 : return "Not Started"
		case 1 : return "Started"
		case 2 : return "Reached"
	}
	return "Not Started"
}

export const MomentType = {
	comment: 1,
	insight: 2,
	keyInsight: 5,
	question: 3,
	reflection: 4,
	concept: 6,
	keyConcept: 7,
	result: 8,
	keyResult: 9,
	objective: 10,
	keyQuestion: 11,
}

export const getMomentType = momentCode => {
	switch(momentCode){
		case 1 : return "Comment"
		case 2 : return "Insight"
		case 3 : return "Question"
		case 4 : return "Reflection"
		case 5 : return "Key Insight"
		case 6 : return "Concept"
		case 7 : return "Key Concept"
		case 8 : return "Result"
		case 9 : return "Key Result"
		case 10 : return "Objective"
		case 11 : return "Key Question"
	}
}

export const TodoTypes = [
	{value: 0, text: "Working"},
	{value: 1, text: "Learning"},
	{value: 2, text: "Learning and working"},
	{value: 3, text: "Administration"} 
]

export const getTodoType = todoType => {
	switch(todoType){
		case 0 : return "Working"
		case 1 : return "Learning"
		case 2 : return "Learning and working"
		case 3 : return "Administration"
	}
}

export const getDateAndTimeRange = (start, end) => {
	const dateStart = moment(start).format('LL');
	const dateEnd = moment(end).format('LL');
	const timeStart = moment(start).format('LT');
	const timeEnd = moment(end).format('LT');
	if(dateStart.toString() == dateEnd.toString())
		return <>
			<p className='dateAndTimeClassroom'>{dateStart}</p>
			<p className='dateAndTimeClassroom'>{timeStart} - {timeEnd}</p>
		</>
	else
		return <>
			<p className='dateAndTimeClassroom'>{moment(start).format('LLL')}</p>
			<p className='dateAndTimeClassroom'>{moment(end).format('LLL')}</p>
		</>
}

export const formatDateInfo = dateData => {
	const targetDate = new Date(dateData);
	const currentDate = new Date();
	const timeDifference = currentDate - targetDate;

	const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
	const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

	if(timeDifference < oneDayInMilliseconds){
		return moment(dateData).startOf('hour').fromNow()      
	}
	else if(timeDifference < oneWeekInMilliseconds){
		return moment(dateData).startOf('day').fromNow()
	}
	else{
		return moment(dateData).format('D MMM YYYY')
	}	
}

export const formatText = textWithLinks => {
    
	// Regular expression to find URLs in the text
	const urlRegex = /(https?:\/\/[^\s]+)/g;

	// Use the split method to separate the text by URLs
	const parts = textWithLinks.split(urlRegex);

	// Create a new array with text and anchor elements
	const renderedText = parts.map((part, index) => {
		if (part.match(urlRegex)) {
			// If the part is a URL, render it as an anchor tag
			return (
				<a key={index} href={part} target="_blank" rel="noopener noreferrer">
					{part}
				</a>
			);
		} else {
			// If the part is not a URL, render it as plain text
			return <span key={index}>{part}</span>;
		}
	});

	return renderedText
}

export const stopWords = ["ourselves", "hers", "between", "yourself", "but", "again", "there", "about", "once", "during", "out", "very", "having", "with", "they", "own", "an", "be", "some", "for", "do", "its", "yours", "such", "into", "of", "most", "itself", "other", "off", "is", "s", "am", "or", "who", "as", "from", "him", "each", "the", "themselves", "until", "below", "are", "we", "these", "your", "his", "through", "don", "nor", "me", "were", "her", "more", "himself", "this", "down", "should", "our", "their", "while", "above", "both", "up", "to", "ours", "had", "she", "all", "no", "when", "at", "any", "before", "them", "same", "and", "been", "have", "in", "will", "on", "does", "yourselves", "then", "that", "because", "what", "over", "why", "so", "can", "did", "not", "now", "under", "he", "you", "herself", "has", "just", "where", "too", "only", "myself", "which", "those", "i", "after", "few", "whom", "t", "being", "if", "theirs", "my", "against", "a", "by", "doing", "it", "how", "further", "was", "here", "than"];

export const emotions = [
	{_id: 1, emoji: "😌", name:"Calm", emotion:"happy"},
	{_id: 2, emoji: "😋", name:"Mindful Eating", emotion:"happy"},
	{_id: 3, emoji: "😊", name:"Filled With Gratitude", emotion:"happy"},
	{_id: 4, emoji: "🙂", name:"Happy", emotion:"happy"},
	{_id: 5, emoji: "😆", name:"Laughing", emotion:"happy"},
	{_id: 6, emoji: "🤣", name:"Rolling on the Floor Laughing", emotion:"happy"},
	{_id: 7, emoji: "🥰", name:"Loved", emotion:"happy"},
	{_id: 8, emoji: "🥳", name:"Partying", emotion:"happy"},
	{_id: 9, emoji: "😑", name:"Pissed off", emotion:"sad"},
	{_id: 10, emoji: "😒", name:"Disappointed", emotion:"sad"},
	{_id: 11, emoji: "😔", name:"Sad", emotion:"sad"},
	{_id: 12, emoji: "😪", name:"Sleepy", emotion:"sad"},
	{_id: 13, emoji: "🤒", name:"Sick", emotion:"sad"},
	{_id: 14, emoji: "😢", name:"Teary Eyes", emotion:"sad"},
	{_id: 15, emoji: "😭", name:"Crying", emotion:"sad"},
	{_id: 16, emoji: "😠", name:"Angry", emotion:"sad"}
];

export const emojis = [
	{emoji: "😀", name:"Grinning Face"},
	{emoji: "😃", name:"Grinning Face with Big Eyes"},
	{emoji: "😄", name:"Grinning Face with Smiling Eyes"},
	{emoji: "😁", name:"Beaming Face with Smiling Eyes"},
	{emoji: "😆", name:"Grinning Squinting Face"},
	{emoji: "😅", name:"Grinning Face with Sweat"},
	{emoji: "🤣", name:"Rolling on the Floor Laughing"},
	{emoji: "😂", name:"Face with Tears of Joy"},
	{emoji: "🙂", name:"Slightly Smiling Face"},
	{emoji: "🙃", name:"Upside-Down Face"},
	{emoji: "😉", name:"Winking Face"},
	{emoji: "😊", name:"Smiling Face with Smiling Eyes"},
	{emoji: "😇", name:"Smiling Face with Halo"},
	{emoji: "🥰", name:"Smiling Face with Hearts"},
	{emoji: "😍", name:"Smiling Face with Heart-Eyes"},
	{emoji: "🤩", name:"Star-Struck"},
	{emoji: "😘", name:"Face Blowing a Kiss"},
	{emoji: "😗", name:"Kissing Face"},
	{emoji: "😚", name:"Kissing Face with Closed Eyes"},
	{emoji: "😙", name:"Kissing Face with Smiling Eyes"},
	{emoji: "😋", name:"Face Savoring Food"},
	{emoji: "😛", name:"Face with Tongue"},
	{emoji: "😜", name:"Winking Face with Tongue"},
	{emoji: "🤪", name:"Zany Face"},
	{emoji: "😝", name:"Squinting Face with Tongue"},
	{emoji: "🤑", name:"Money-Mouth Face"},
	{emoji: "🤗", name:"Smiling Face with Open Hands"},
	{emoji: "🤭", name:"Face with Hand Over Mouth"},
	{emoji: "🤫", name:"Shushing Face"},
	{emoji: "🤔", name:"Thinking Face"},
	{emoji: "🤐", name:"Zipper-Mouth Face"},
	{emoji: "🤨", name:"Face with Raised Eyebrow"},
	{emoji: "😐", name:"Neutral Face"},
	{emoji: "😑", name:"Expressionless Face"},
	{emoji: "😶", name:"Face Without Mouth"},
	{emoji: "😏", name:"Smirking Face"},
	{emoji: "😒", name:"Unamused Face"},
	{emoji: "🙄", name:"Face with Rolling Eyes"},
	{emoji: "😬", name:"Grimacing Face"},
	{emoji: "🤥", name:"Lying Face"},
	{emoji: "😌", name:"Relieved Face"},
	{emoji: "😔", name:"Pensive Face"},
	{emoji: "😪", name:"Sleepy Face"},
	{emoji: "🤤", name:"Drooling Face"},
	{emoji: "😴", name:"Sleeping Face"},
	{emoji: "😷", name:"Face with Medical Mask"},
	{emoji: "🤒", name:"Face with Thermometer"},
	{emoji: "🤕", name:"Face with Head-Bandage"},
	{emoji: "🤢", name:"Nauseated Face"},
	{emoji: "🤮", name:"Face Vomiting"},
	{emoji: "🤧", name:"Sneezing Face"},
	{emoji: "🥵", name:"Hot Face"},
	{emoji: "🥶", name:"Cold Face"},
	{emoji: "🥴", name:"Woozy Face"},
	{emoji: "😵", name:"Face with Crossed-Out Eyes"},
	{emoji: "🤯", name:"Exploding Head"},
	{emoji: "🤠", name:"Cowboy Hat Face"},
	{emoji: "🥳", name:"Partying Face"},
	{emoji: "😎", name:"Smiling Face with Sunglasses"},
	{emoji: "🤓", name:"Nerd Face"},
	{emoji: "🧐", name:"Face with Monocle"},
	{emoji: "😕", name:"Confused Face"},
	{emoji: "😟", name:"Worried Face"},
	{emoji: "🙁", name:"Slightly Frowning Face"},
	{emoji: "😮", name:"Face with Open Mouth"},
	{emoji: "😯", name:"Hushed Face"},
	{emoji: "😲", name:"Astonished Face"},
	{emoji: "😳", name:"Flushed Face"},
	{emoji: "🥺", name:"Pleading Face"},
	{emoji: "😦", name:"Frowning Face with Open Mouth"},
	{emoji: "😧", name:"Anguished Face"},
	{emoji: "😨", name:"Fearful Face"},
	{emoji: "😰", name:"Anxious Face with Sweat"},
	{emoji: "😥", name:"Sad but Relieved Face"},
	{emoji: "😢", name:"Crying Face"},
	{emoji: "😭", name:"Loudly Crying Face"},
	{emoji: "😱", name:"Face Screaming in Fear"},
	{emoji: "😖", name:"Confounded Face"},
	{emoji: "😣", name:"Persevering Face"},
	{emoji: "😞", name:"Disappointed Face"},
	{emoji: "😓", name:"Downcast Face with Sweat"},
	{emoji: "😩", name:"Weary Face"},
	{emoji: "😫", name:"Tired Face"},
	{emoji: "🥱", name:"Yawning Face"},
	{emoji: "😤", name:"Face with Steam From Nose"},
	{emoji: "😡", name:"Enraged Face"},
	{emoji: "😠", name:"Angry Face"}
]

export const postReactions = [
	{_id: 1, emoji: "👍", name: "Like", color: '#00ab55'},
	{_id: 2, emoji: "❤️", name: "Love", color: '#ff6961'},
	{_id: 3, emoji: "👏", name: "Clap", color: '#b78103'},
	{_id: 4, emoji: "🙏", name: "Pray", color: '1890FF'},
	{_id: 5, emoji: "👎", name: "Dislike", color: '#ff694f'},
	{_id: 6, emoji: "😡", name: "Angry", color: '#ff694f'},
]

export const GoalConstants = {
	started: 1,
	reached: 2,
	deleted: 3,
	paused: 4,
	notStarted: 5,
}

export const colorList = [
	{ name: "Default", 					color: "#f4f6f8", textColor: "#252423" },
	{ name: "Pastel Green", 		color: "#77DD77", textColor: "#252423" },
	{ name: "Pastel Brown", 		color: "#836953", textColor: "#fff" },
	{ name: "Baby Blue", 				color: "#89cff0", textColor: "#252423" },
	{ name: "Pastel Turquoise", color: "#99c5c4", textColor: "#252423" },
	{ name: "Blue Green", 			color: "#9adedb", textColor: "#252423" },
	{ name: "Persian Pastel",		color: "#aa9499", textColor: "#252423" },
	{ name: "Magic Mint",				color: "#aaf0d1", textColor: "#252423" },
	{ name: "Light Green",			color: "#b2fba5", textColor: "#252423" },
	{ name: "Pastel Purple",		color: "#b39eb5", textColor: "#252423" },
	{ name: "Pastel Lilac",			color: "#bdb0d0", textColor: "#252423" },
	{ name: "Pastel Pea",				color: "#bee7a5", textColor: "#252423" },
	{ name: "Light Lime",				color: "#befd73", textColor: "#252423" },
	{ name: "Light Periwinkle", color: "#c1c6fc", textColor: "#252423" },
	{ name: "Pale Mauve",				color: "#c6a4a4", textColor: "#252423" },
	{ name: "Pastel Violet",		color: "#cb99c9", textColor: "#252423" },
	{ name: "Pastel Red",				color: "#ff6961", textColor: "#fff" },
	{ name: "Pastel Orange",		color: "#ff694f", textColor: "#fff" },
	{ name: "American Pink",		color: "#ff9899", textColor: "#252423" },
	{ name: "Baby Pink",				color: "#ffb7ce", textColor: "#252423" },
	{ name: "Baby Purple",			color: "#ca9bf7", textColor: "#252423" },
];

export const momentOptions = [
	{
		type: MomentType.objective,
		title: "Objective",
		icon: <TargetIcon style={{width:"100%",height:"auto"}}/>
	},
	{
		type: MomentType.comment,
		title: "Comment",
		icon: <ChatIcon/>
	},
	{
		type: MomentType.question,
		title: "Question",
		icon: <QuestionIcon style={{width:"100%",height:"auto"}}/>
	},
	{
		type: MomentType.keyQuestion,
		title: "Key Question",
		icon: <KeyQuestionIcon style={{width:"100%",height:"auto"}}/>
	},
	{
		type: MomentType.insight,
		title: "Insight",
		icon: <InsightIcon style={{width:"100%",height:"auto"}}/>
	},
	{
		type: MomentType.keyInsight,
		title: "Key Insight",
		icon: <KeyInsightIcon style={{width:"100%",height:"auto"}}/>
	},
	{
		type: MomentType.concept,
		title: "Concept",
		icon: <ConceptIcon style={{width:"100%",height:"auto"}}/>
	},
	{
		type: MomentType.keyConcept,
		title: "Key Concept",
		icon: <KeyConceptIcon style={{width:"100%",height:"auto"}}/>
	},
	{
		type: MomentType.result,
		title: "Result",
		icon: <ResultIcon style={{width:"100%",height:"auto"}}/>
	},
	{
		type: MomentType.keyResult,
		title: "Key Result",
		icon: <KeyResultIcon style={{width:"100%",height:"auto"}}/>
	}
]

export const systemRoles = {
	student: 0,
	superAdmin: 10,
	admin: 1,
	moderator: 2,
	coach: 3,
	teacher: 4
}



export const getSystemRole = (roleCode) => {
  switch (roleCode) {
		case systemRoles.student:
			return "Student"
    case systemRoles.superAdmin:
      return "Super Admin";
    case systemRoles.admin:
      return "Admin";
    case systemRoles.moderator:
      return "Moderator";
    case systemRoles.coach:
      return "Coach";
    case systemRoles.teacher:
      return "Teacher";
		default: 
			return "Student"
  }
};

export const notACoachNorAdmin		= role => role !== systemRoles.coach || role === systemRoles.superAdmin || role === systemRoles.admin
export const notATeacherNorAdmin	= role => role !== systemRoles.coach || role === systemRoles.superAdmin || role === systemRoles.admin

export const isCoachOrTeacherOrAdmin = role => role === systemRoles.coach || role === systemRoles.teacher || role === systemRoles.superAdmin || role === systemRoles.admin
export const isCoachOrTeacher = role => role === systemRoles.coach || role === systemRoles.teacher
export const isAdmin = role => role === systemRoles.superAdmin || role === systemRoles.admin

export const enableCoachAssignRole = role => role === systemRoles.coach
export const enableTeacherAssignRole = role => role === systemRoles.teacher
export const enableAssignAnyRole = role => role === systemRoles.superAdmin


export const NotificationType = {
  commentedOn: 1,
  systemMotivation: 2
}