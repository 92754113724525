//import MomentsView from '.components/MomentsView/MomentsView';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import './MomentsView.scss';
import {
    actionGetMoments,
    actionAppendMomentSubjects
} from '../../app/Reducers/moment/momentSlice';
import { useSelector, useDispatch } from 'react-redux';
import MomentList from '../../components/Moments/MomentList';
import TodoSideNav from '../../components/Todo/TodoSideNav';
import Pagination from '@mui/material/Pagination';

const MomentsView = ({

}) => {

    const [momentToEdit, setMomentToEdit] = useState(null);
    const [asideTodoOpen, setAsideTodoOpen] = useState(true)
    const {
        totalPages,
        page,
    } = useSelector(state => state.moment);

    const dispatch = useDispatch()

    const {
        momentsList
    } = useSelector(state => state.moment);
    const {
        userData
    } = useSelector(state => state.user); 

    const handlePaginationChange = (e,pageNum) => {
        dispatch(actionGetMoments(userData._id,{
            page:pageNum
        }))
    }

    useEffect(()=>{
        dispatch(actionGetMoments(userData._id))
    },[])

    return <div className='MomentsViewContainer'>
        <div className="">
            <div className="page-header-container">
                <h2 onClick={()=>dispatch(actionGetMoments(userData._id))} className="section-title-01">My moments</h2>
            </div>
            <div className="moments-list">
                <MomentList
                momentList={momentsList}
                setMomentToEdit={setMomentToEdit}
                isMomentTextClickable={true}
                title={"MOMENTS"}/>

                {totalPages > 1 ?
                <Pagination
                className="custom-style-pagination"
                count={totalPages}
                size="small"
                variant="outlined"
                color="primary"
                shape="rounded"
                siblingCount={0}
                sx={{paddingTop: "13px"}}
                page={page}
                onChange={handlePaginationChange}/> : ""}
            </div>
        </div>
        <TodoSideNav
            fixed={true}
        />
    </div>
}

export default memo(MomentsView);