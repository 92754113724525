import React, {useState, useCallback, useEffect} from 'react'
import './PostCards.scss';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {v1 as uuid} from "uuid";
import ReportIcon from '@mui/icons-material/Report';
// import ReactionPopup from '../ReactionPopup/ReactionPopup';
import PostHeader from './PostHeader';
import {
  emotions,
  postReactions,
  formatText,
  systemRoles
} from "../Hooks/Constants";
import { useDispatch, useSelector } from 'react-redux';
// import ImageActions from '../ImageActions/ImageActions';
import {
  actionDeletePost,
  actionToggleEditPostForm,
  actionTogglePostDetails
} from '../../app/Reducers/post/postSlice';
import '../ReactionPopup/ReactionPopup.scss'
import CommentList from '../Comment/CommentList';
import Slider from '@mui/material/Slider';
import debounce from "lodash.debounce";
import Axios from 'axios';
import { getHeaders } from '../../app/helpers';
import EditIcon from '@mui/icons-material/Edit';
import PostInputs from '../PostUpload/PostInputs';
const URI = "https://wingenio.world";

export const PostCards = ({
  postData,
  minified = false,
  showImage = true
}) => {

  const [post, setPost] = useState(postData)
  const [showMoreText, setShowMoreText] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [commentCount, setCommentCount] = useState(post.totalCommentCount || 0)

  const {
    userData
  } = useSelector(state=>state.user)

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const ownerMenuOptions = [
    {
      id: uuid(),
      name: "Edit",
      icon: <EditIcon fontSize="small"/>,
      callback: () => {
        dispatch(actionToggleEditPostForm(true, postData))
      }
    },
    {
      id: uuid(),
      name: "Delete",
      icon: <DeleteIcon fontSize="small"/>,
      callback: () => {
        dispatch(actionDeletePost(post._id))
      }
    }
  ];

  const unwantedMenuOptions = [
    {
      id: uuid(),
      name: "Other options is ongoing",
      icon: <ReportIcon fontSize="small"/>,
      callback: () => {
        console.log("Report post")
      }
    },
    // {
    //   id: uuid(),
    //   name: "Report post",
    //   icon: <ReportIcon fontSize="small"/>,
    //   callback: () => {
    //     console.log("Report post")
    //   }
    // },
    // {
    //   id: uuid(),
    //   name: "I don't want to see this",
    //   icon: <DeleteIcon fontSize="small"/>,
    //   callback: () => {
    //     console.log("create a function for this")
    //   }
    // }
  ];

  const followOptions = [
    {
      id: uuid(),
      name: "follow",
      icon: <ReportIcon fontSize="small"/>,
      callback: () => {
        console.log("Report post")
      }
    },
  ]

  const getMenuItems = () => {

    let options = [...unwantedMenuOptions];

    if(userData._id == post.createdBy._id){
      options = [...options, ...ownerMenuOptions]
    }


    return options.length ? options.map(option=>(
        <MenuItem
        key={option.id}
        onClick={()=>{
            option.callback()
            setAnchorEl(null)
        }}>
            <ListItemIcon style={{minWidth: 32}}>
                {option.icon}
            </ListItemIcon>
            <ListItemText>{option.name}</ListItemText>
        </MenuItem>
    )) : ""
  }

  const getEmotion = id => {
    if(id)
      for (const e of emotions) {
        if(e._id === id) 
          return e
      }
    return null
  }

  const getUserReaction = () => {
    if(post.reactions){
      for(let i = 0; i < post.reactions.length; i++){
        if(post.reactions[i].user == userData._id){
          for(let j = 0; j < postReactions.length; j++){
            if(postReactions[j]._id == post.reactions[i].type){
              return postReactions[j];
            }
          }
        }
      }
    }
    return null
  }
  
  const getReactionsData = () => {
    const dictionary = {}

    for(const reaction of post.reactions){
      if (dictionary[reaction.type]) {
        dictionary[reaction.type]++;
      } else {
        dictionary[reaction.type] = 1;
      }
    }
    
    const postReactionsData = Object.entries(dictionary)
              .map(entry=>{
                return {
                  ...postReactions.find(reaction => reaction._id == Number(entry[0])),
                  value: entry[1]
                }
              })
              .sort((a, b) => b.value - a.value)
              .slice(0, 3);

    return <div className="postReactionsWrapper">
      {
        postReactionsData.length ? 
        postReactionsData.map(e=>(
          <span key={e._id} className='postReactions'>{e.emoji}</span>
        ))
        :
        <span className='postReactions'>{postReactions[0].emoji}</span>
      }
      {post.reactions ? `${post.reactions.length}` : ""}
    </div>
  }

  const updateCommentCount = count => {
    setCommentCount(count)
  }

  const setPostPoints = async (postId, val) => {
    const headers = getHeaders();
    if(headers === null) return
    try{
      const res = await Axios.put(
          `${URI}/posts/set-points`,
          {
            postId,
            points: val
          },
          headers
      )
      console.log(res.data)
      setPost({
        ...post,
        points: res.data.points
      })
    }
    catch(err){
      console.log(err)
    }
  }

  const handlePointChange = useCallback(
    debounce(
      (postId, val) => {
        setPostPoints(postId, val)
        // console.log(postId, val)
        // dispatch(actionSetTodoPoints(postId, val))
      }, 500
    ), []
  );

  const reactOnPost = async data => {
    const headers = getHeaders();
    if(headers === null) return
    const {
      postId,
      reaction
    } = data;
    
    try{
      const res = await Axios.put(
        `${URI}/posts/react-on-post`,
        {
          postId,
          reaction
        },
        headers
      )
      setPost(post=>{
        return {
          ...post,
          reactions: res.data?.reactions,
          reactionsCount: res.data?.reactionsCount ? res.data.reactionsCount : 0
        }
      })
    } catch(err){
      console.log(err)
    }
  }

  const e = getEmotion(post?.emotion)
  const emotion = e ? `Is Feeling ${e.name} ${e.emoji}` : null;
  const userReaction = getUserReaction();
  const points = post?.points ? post.points : 0

  useEffect(()=>{},[post])
  useEffect(()=>{
    setPost(prevData => {
      return {
        ...prevData,
        postDescription: postData.postDescription,
        postImage: postData.postImage,
        emotion: postData.emotion,
      }
    })
  },[postData])
  // console.log(post.postDescription)
  return (
    <div className={`PostCardsContainer ${minified ? "minified" : ""}`}>
      <div className="cardHeader">
        <PostHeader feeling={emotion} post={post} todo={post?.todo ? post.todo : null}/>
        <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMoreClick}>
            <MoreHorizIcon/>
        </IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            {getMenuItems()}
        </Menu>
      </div>

      {
      post?.postDescription && !minified ? 
      <div className="cardContentWrapper">
        <p className="cardContent">{ formatText(post.postDescription) }</p>
      </div> : ""
      }
      {/* If the post has image, display it in this way */}
      {
        showImage ?
          post.postImage ? 
            <div className="cardImageWrapper">
              <img
              onClick={()=>dispatch(actionTogglePostDetails({
                open: true,
                post
              }))}
              src={post.postImage}
              loading="lazy"
              alt={post.postDescription}/>
              {/* <ImageActions src={post.postImage} editable={false} post={post}/> */}
              { post?.postDescription && minified ? 
              <div className={`postTextContentWrapper ${showMoreText ? "active" : ""}`}>
                <p className={`cardContent ${showMoreText ? "showMorePostText" : ""}`}>{ formatText(post.postDescription) }</p>
                <span className="showMore" onClick={()=>setShowMoreText(show=>!show)}>{showMoreText ? "Less..." : "More..."}</span>
              </div> : "" }
            </div>
          : 
            minified ?
            <div className="cardContentWrapper">
              <p className="cardContent">{ formatText(post.postDescription) }</p>
            </div> : ""
        : ""
      }

      {
        userData.systemRole == systemRoles.superAdmin ||
        userData.systemRole == systemRoles.admin ?
        <div 
        className="postPointsInputWrapper"
        style={post?.postImage ? {marginTop: minified ? "0" : "10px"} : {}}>
          Points: {points}
          <div className="postPointsSliderWrapper">
            <Slider
              key={`slider-${post._id}-${0}`}
              defaultValue={points}
              size="small"
              valueLabelDisplay="auto"
              onChange={(e, val) => handlePointChange(post._id, val)}
              step={1}
              marks
              min={0}
              max={10}
            />
          </div>
        </div> : "" 
      }

      {
      minified ? "" : 
      <>
        <div className="cardReactionsInfo">
          {getReactionsData()}
          <div className="commentAndShare">
            <p>{commentCount} Comment{commentCount > 1 ? "s":""}</p>
          </div>
        </div>
        <ul className="cardReactionsActions">
          <li className='ReactionPopupContainer'>
            <div className={`reactionPopup`}>
              {
                postReactions.map(e=>(
                  <span
                  key={e._id}
                  className='postReactionsClickable'
                  onClick={()=>reactOnPost({
                    reaction: userReaction ? 
                                userReaction._id == e._id ?
                                  0 :
                                  e._id :
                              e._id,
                    postId: post._id
                  })}>{e.emoji}</span>
                ))
              }
            </div>
            {
              userReaction ? 
              <span 
              style={{color: userReaction.color}}
              onClick={()=>reactOnPost({
                reaction: 0,
                postId: post._id
              })}>
                {userReaction.emoji} {userReaction.name}
              </span>
              :
              <span
              onClick={()=>reactOnPost({
                reaction: 1,
                postId: post._id
              })}
              >
                Like
              </span>
            }
          </li>
          <li onClick={()=>{}}>Comments</li>
          <li onClick={()=>{}}>Pin</li>
        </ul>
        <CommentList
        initialCommentArray = {post.comments || []}
        totalCommentCount = {commentCount || 0}
        postData={post}
        updateCommentCount = {updateCommentCount}/>
      </>
      }
    </div>
  )
}

export default PostCards;