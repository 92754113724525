//import GroupSelection from '.components/GroupSelection/GroupSelection';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo,useCallback} from 'react';
import './GroupSelection.scss';
import { useSelector,useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { ReactComponent as TeamIcon } from '../../Assets/ic_team.svg';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
	// actionGetTeamMembers
} from '../../app/Reducers/group/groupSlice';

const GroupSelection = ({
	// createdGroupList=[],
	onSelectGroup
}) => {
	const {
		createdGroupList
	} = useSelector(state => state.group)

	const dispatch = useDispatch();

	return <div className='GroupSelectionContainer'>
		<div className="owner-detail">
			<div className="asignee-info">
			<FormControl
			fullWidth
			size="small">
				<InputLabel id="selected-group-label">Select Group</InputLabel>
				<Select
				labelId="selected-group-label"
				id="selected-group"
				value={""}
				label="Select Group"
				onChange={onSelectGroup}
				style={{width:"250px"}}
				>
				{createdGroupList.map(group => 
					<MenuItem key={group._id} value={group._id}>
					<ListItemIcon sx={{minWidth: "32px"}}>
						<TeamIcon/>
					</ListItemIcon>
					{group.name}
					</MenuItem>
				)}
				</Select>
			</FormControl>
			</div>
		</div>
	</div>
}

export default GroupSelection;