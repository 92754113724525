//import TeamView from '.components/TeamView/TeamView';//cut it and paste to the component
import React from 'react';
import './TeamView.scss';

const TeamView = ({

}) => {
    return <div className='TeamViewContainer'>
        Under construction
    </div>
}

export default TeamView;