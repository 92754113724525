import React from 'react'
import { ReactComponent as StarIcon } from '../../Assets/ic_star.svg';
import { useSelector } from 'react-redux';
import ThrillingTaskItem from './ThrillingTaskItem';
import "./ThrillingTasks.scss"

export const ThrillingTasks = ({}) => {
	
	const {
		thrillingTasks
	} = useSelector(state => state.todo)	

	return (
	<div className="ThrillingTasksWrapper">
		<div className="goalTitleContainer">
			<h3 className='thrillingTaskTitle'><StarIcon/>Thrilling Tasks</h3>
		</div>
		<div className="ThrillingTasks">
      {thrillingTasks.map(task=>{
        return <ThrillingTaskItem key={task._id} todo={task}/>
      })}
		</div>
	</div>
	)
}

export default ThrillingTasks;




