import React, {useCallback} from 'react';
import {v1 as uuid} from "uuid";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as StarIcon } from '../../Assets/ic_star.svg';
// import {
//     actionCreateGoal
// } from '../../app/Reducers/goal/goalSlice';
import {
    actionAddTodoInList,
    actionCreateThrillingTask
} from '../../app/Reducers/todo/todoSlice';
import { useDispatch } from 'react-redux';

const JSONToReact = ({
    htmlJson,
    note,
    enableCopyText=false,
    enableAddTodo=false,
    enableAddGoal=false
}) => {

    const dispatch = useDispatch();
    const getTextData = elementData => elementData.reduce((a,c)=>a+(c.children ? c.children[0].textContent : c.textContent),"")

    const parseChildren = children => {
        return children.map(child=>parseHtmlJson(child));
    }

    const onCopyText = useCallback(text => {
        navigator.clipboard.writeText(text).then(function() {
            console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    },[])
    
    // const onAddGoal = useCallback(text => {
    //     dispatch(actionCreateGoal({name: text}))
    // },[])

    const onCreateThrillingTask = useCallback(text => {
        let todoData = {
            name: text, 
            description: text,
            noteId: note._id
        }
        if(note.category){
            todoData = { ...todoData, category: note.category }
        }

        dispatch(actionCreateThrillingTask(todoData))
    },[])

    const createTodo = useCallback(todoName => {
        let todoData = {
            name: todoName,
            description: todoName,
            noteId: note._id
        }
        if(note.category){
            todoData = { ...todoData, category: note.category }
        }
        dispatch(actionAddTodoInList(todoData))
    });

    const getActionButtons = elementChildren => {
        return  elementChildren[0]?.type === 'TextElem' &&
                elementChildren[0]?.textContent?.replace((/\s|\r\n|\n|\r/gm),"").length ? <>
                    
                    <span className="content-action-wrapper">
                        {/* {enableAddGoal ? <Tooltip title="Add To Goals">
                            <IconButton
                            size="small"
                            onClick={()=>onAddGoal(getTextData(elementChildren))}>
                                <StarIcon/>
                            </IconButton>
                        </Tooltip> : ""} */}

                        {enableAddGoal ? <Tooltip title="Create thrilling task">
                            <IconButton
                            size="small"
                            onClick={()=>onCreateThrillingTask(getTextData(elementChildren))}>
                                <StarIcon/>
                            </IconButton>
                        </Tooltip> : ""}

                        {enableAddTodo ? <Tooltip title="Create Todo">
                            <IconButton
                            size="small"
                            onClick={()=>createTodo(getTextData(elementChildren))}>
                                <AddCircleIcon sx={{fontSize: 18,padding: 0}}/>
                            </IconButton>
                        </Tooltip> : ""}

                        {enableCopyText ? <Tooltip title="Copy Text">
                            <IconButton
                            size="small"
                            onClick={()=>onCopyText(getTextData(elementChildren))}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip> : ""}
                    </span>
                </> : ""
    }

    const wrapDataInElement = (tagName,elementChildren) => {
        const uID = uuid();
        switch(tagName){
            case "P" :      return  <p key={uID}>
                                        {parseChildren(elementChildren)}
                                        {getActionButtons(elementChildren)}
                                    </p>
            case "LI" :     return  <li key={uID}>
                                        {parseChildren(elementChildren)}
                                        {getActionButtons(elementChildren)}
                                    </li>                        
            case "STRONG" : return <strong key={uID}> {parseChildren(elementChildren)}    </strong>
            case "A" :      return <a href={"/"} key={uID}>      {parseChildren(elementChildren)}    </a>
            case "UL" :     return <ul key={uID}>     {parseChildren(elementChildren)}    </ul>
            case "OL" :     return <ol key={uID}>     {parseChildren(elementChildren)}    </ol>
            case "BODY" :   return <div key={uID}>    {parseChildren(elementChildren)}    </div>
            case "DIV" :    return <div key={uID}>    {parseChildren(elementChildren)}    </div>
            case "SPAN" :   return <span key={uID}>   {parseChildren(elementChildren)}    </span>
            case "H1" :     return <h1 key={uID}>     {parseChildren(elementChildren)}    </h1>
            case "H2" :     return <h2 key={uID}>     {parseChildren(elementChildren)}    </h2>
            case "H3" :     return <h3 key={uID}>     {parseChildren(elementChildren)}    </h3>
            default:        return <div key={uID}>    {parseChildren(elementChildren)}    </div>
        }
    }

    const parseHtmlJson = childElement => {
        if(childElement.type === "Elem"){
            return wrapDataInElement(childElement.tagName,childElement.children)
        }
        else if(childElement.type === "TextElem"){
            return childElement.textContent
        }
    }

    return <div className='JSONToReactContainer'>
        {parseHtmlJson(htmlJson)}
    </div>
}

export default JSONToReact;