//import ColorSelection from '.components/ColorSelection/ColorSelection';//cut it and paste to the component
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as ColorSelectionIcon } from '../../Assets/ic_color_selection.svg';
import Tooltip from '@mui/material/Tooltip';
import { colorList } from '../Hooks/Constants';
const ITEM_HEIGHT = 48;

const ColorSelection = ({
    onChangeColor
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div className='ColorSelectionContainer'>
        <Tooltip title={"Set Note Color"}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className="color-selection"
            >
                <ColorSelectionIcon />
            </IconButton>
        </Tooltip>
        <Menu
            id="long-menu"
            MenuListProps={{
            'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            size="small"
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                },
            }}
        >
            {colorList.map(c => {
                return (
                <MenuItem key={c.color} onClick={()=>{
                    onChangeColor(c)
                    setAnchorEl(null)
                }}>
                    <span
                        style={{
                            backgroundColor: c.color,
                            height: "20px",
                            width: "20px",
                            borderRadius: "20px",
                            fontSize: "0",
                            marginRight: "10px",
                            border: "1px solid #ccc"
                        }}
                    >.</span>
                    {c.name}
                </MenuItem>
                )
            })}
        </Menu>
    </div>
}

export default ColorSelection;




