//import MyClasses from '.components/MyClasses/MyClasses';//cut it and paste to the component
import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
import './MyClasses.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
} from '../../app/Reducers/classroom/classroomSlice';
import Button from '@mui/material/Button';
import ClassroomCard from '../../components/ClassroomCard/ClassroomCard';
import {
	actionToggleCreateClassroomFormOpen,
	actionGetClassroomListCreatedByUser
} from '../../app/Reducers/classroom/classroomSlice';
import { ReactComponent as AddClassIcon } from '../../Assets/ic_add_class.svg';
import CreateClassroomForm from '../../components/CreateClassroomForm/CreateClassroomForm';
import { useSearchParams } from 'react-router-dom';
import UsersListSelectionActions  from '../../components/Group/UsersListSelectionActions';

const MyClasses = ({

}) => {

	const [showUploadForm, setShowUploadForm] = useState(false)
	const dispatch = useDispatch();
	const [params, setParams] = useSearchParams({ user: "" })
	const id = params.get("user")

	const {
		isLoading,
		selectedClassroom,
		userCreatedClasses
	} = useSelector(state => state.classroom)

	const {
		userData
	} = useSelector(state => state.user);

	const {
		MyClassesPosts,
		MyClassesnextPage,
		isPostsLoading,
		MyClasseshasNextPage,
		MyClassespage
	} = useSelector(state => state.post)

	const {
    postGridView
  } = useSelector(state => state.layout)


	const observer = useRef()

	const lastPostRef = useCallback(node => {
    if (isPostsLoading) return
    if (observer.current) observer.current.disconnect()
		// this function will listen if the last element is intersected
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && MyClasseshasNextPage) {
					// dispatch(actionGetMyClassesPosts(id, MyClassespage+1))
      }
    })
    if (node) observer.current.observe(node)
  }, [MyClassesnextPage])

	useEffect(()=>{
		dispatch(actionGetClassroomListCreatedByUser(id))
	},[id])

	if(!selectedClassroom) return ""

	return <div className='MyClassesContainer pageWrapper'>
		{isLoading ? <>
			<div className="loading-wrapper">
				<div className="loader"></div>
			</div>
		</> : ""}	
    <div className="page-header-container">
      <h2 className="section-title-01">My Teaching Classes</h2>
      <Button
      style={{
        marginTop: "8px",
        width: "200px"
      }}
      variant="contained"
      onClick={()=>dispatch(actionToggleCreateClassroomFormOpen(true))}					
      startIcon={<AddClassIcon />}>Create Class</Button>
    </div>
		<div className="optionsContainerAndinfo">
			<div className="postList">
				<div className="classList">
					{userCreatedClasses.map(classData => <ClassroomCard key={classData._id} classData={classData}/>)}
					{/* {Array.from(Array(10).keys()).map(x=><ClassroomCard key={x}/>)} */}
				</div>
			</div>
		</div>
		
    <CreateClassroomForm />
		<UsersListSelectionActions />
	</div>
}

export default memo(MyClasses);