import React, { useEffect, useState} from 'react'
import './Transactions.scss'
import { useDispatch, useSelector } from "react-redux";
import { actionGetUserTransactions } from '../../app/Reducers/user/userSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import TransactionItem from './TransactionItem';

const Transactions = ({
  
}) => {
  const dispatch = useDispatch()
  const {
    userData,
    userTransactions
  } = useSelector(state => state.user)
  const [queryParameters] = useSearchParams()
  
  useEffect(()=>{
    if(userData?._id){
      dispatch(actionGetUserTransactions(userData._id))
    }
  }, [userData])
  
	return <div className='TransactionsContainer pageWrapper'>
    <div className="page-header-container">
      <h2 className="section-title-01">Your transactions</h2>
    </div>
    <ul className="transactionsList">
      {userTransactions.map(
        transaction => (
          <li className="transactionItem" key={transaction._id}>
            <TransactionItem transaction={transaction} />
          </li>
        )
      )}
    </ul>

	</div>
}

export default Transactions;