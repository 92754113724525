//import momentItem from '.components/momentItem/momentItem';//cut it and paste to the component
import React, {memo} from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import { getMomentType } from "../Hooks/Constants"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import {
	actionSetTodoInSideNav
} from '../../app/Reducers/todo/todoSlice';
import { emotions } from "../Hooks/Constants";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import { ReactComponent as UserIcon } from '../../Assets/ic_user.svg';
import { formatText } from '../Hooks/Constants';

const MomentItem = ({
	momentData,
	setAnchorEl,
	setSelectedMoment,
	open,
	isMomentTextClickable,
}) => {

	const dispatch = useDispatch();
	const momentMoreOption = (event,momentData) => {
		setAnchorEl(event.currentTarget)
		setSelectedMoment(momentData)
	};

	const {
		userData
	} = useSelector(state => state.user)

	return (
	<TimelineItem key={moment._id}>
		<TimelineSeparator>
			<TimelineDot 
			sx={{
				backgroundColor: "#00AB55"
			}}/>
			<TimelineConnector />
		</TimelineSeparator>
		<TimelineContent sx={{paddingRight:0}}>
			<Paper elevation={3}>
				<div className="momentItem">
					<span className="moment-type">{getMomentType(momentData.momentType)}</span>
					{
					momentData?.owner ?
					<p className="asignee-info">
						<span className="icon">{momentData.owner.profilePicture ? <img src={momentData.owner.profilePicture} alt="Profile" /> :<UserIcon/>}</span>
						<span className="value">{ momentData.owner.firstName } {momentData.owner.lastName }</span>
					</p> : ""
					}
					
					{ momentData.image ? <img src={momentData.image} alt="img" style={{marginBottom: 10}} loading="lazy"/> : "" }

					{
					isMomentTextClickable ? 
					<p
					className="momentItem-desc clickable"
					onClick={()=>dispatch(actionSetTodoInSideNav(momentData.todo))}>{momentData.moment}</p>
					:
					<p className="momentItem-desc">{formatText(momentData.moment)}</p>
					}
					
					{momentData.emotion ? 
					<p className="feeling-emotion">
						{emotions[momentData.emotion].emoji} - {emotions[momentData.emotion].name}
						{momentData.energy ?	<span>
													<FlashOnIcon
													sx={{
														fontSize: "20px",
														marginBottom: "-6px",
														color: "#fbaf02",
													}}/>
													<span style={{color: "#fbaf02"}}>
														{momentData.energy}
													</span>
												</span> : ""}
					</p>
					: ""}
					{
					momentData?.owner?._id === userData._id ?
					<IconButton
					className="moment-more-option-trigger"
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={event => momentMoreOption(event,momentData)}>
						<MoreHorizIcon/>
					</IconButton> : ''
					}
					<div className="date-wrapper">
						<p className="date">{moment(momentData.createdAt).format('L')}</p>
						<p className="time">{moment(momentData.createdAt).format('LT')}</p>
					</div>
				</div>
			</Paper>
		</TimelineContent>
	</TimelineItem>
	)
}

export default memo(MomentItem);