//import PlanList from '.components/PlanList/PlanList';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import './PlanList.scss';
import { useSelector,useDispatch } from 'react-redux';
import {
    actionGetPlanningDetails,
    actionDeletePlan
} from '../../app/Reducers/planning/planningSlice';
import DeleteIcon from '@mui/icons-material/Delete';

const PlanList = ({

}) => {

    const dispatch = useDispatch();

    const {
        selectedUserPlanList
    } = useSelector(state => state.planning);

    return <div className='PlanListContainer'>
        <ul className="plan-list">
            {selectedUserPlanList.map(plan=>(
                <li key={plan._id}>
                    <p
                    className="plan-title"
                    onClick={()=>dispatch(actionGetPlanningDetails(plan._id))}>
                        {plan.title}
                    </p>

                    <span
                    className="delete-plan"
                    onClick={()=>dispatch(actionDeletePlan(plan._id))}>
                        <DeleteIcon/>
                    </span>
                </li>
            ))}
        </ul>
    </div>
}

export default PlanList;