//import GroupList from '.components/GroupList/GroupList';//cut it and paste to the component
import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
import './GroupList.scss';
import ClassroomCard from '../../components/ClassroomCard/ClassroomCard';
import {
  actionGetSuggestedGroups,
  actionSearchGroup,
  actionToggleFindGroupSection,
  actionGetJoinedGroup,
  actionRequestToJoinGroup,
} from '../../app/Reducers/group/groupSlice';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as AddClassIcon } from '../../Assets/ic_add_class.svg';
import { useSearchParams } from 'react-router-dom';
import KeywordSearch from '../../components/KeywordSearch/KeywordSearch';
import GroupListSection from '../../components/GroupListSection/GroupListSection';

const GroupList = ({

}) => {
  const [params, setParams] = useSearchParams({ user: "" })
  const dispatch = useDispatch();
  const {
		isLoading,
    showFindGroupSection,
    
    // suggested classroom
    suggestedGroups,
    suggestedGroupsHasNextPage,
    suggestedGroupsNextPage,

    // group search
    searchedGroupList,

    // joined classroom
    joinedGroups,
    joinedGroupsHasNextPage,
    joinedGroupsNextPage,
	} = useSelector(state => state.group)

  const id = params.get("user")
	const searchInputRef = useRef(null)

	useEffect(()=>{
    dispatch(actionGetSuggestedGroups(1))
    dispatch(actionGetJoinedGroup(1))
	},[])

	// if(!selectedClassroom) return ""

	return <div className='GroupListContainer'>

		{isLoading ? <>
			<div className="loading-wrapper">
				<div className="loader"></div>
			</div>
		</> : ""}	
    <div className="page-header-container">
      <h2 className="section-title-01">My Groups</h2>

      <Button
      style={{
        marginTop: "8px",
        width: "200px"
      }}
      variant={"contained"}
      onClick={()=>{
        dispatch(actionToggleFindGroupSection(!showFindGroupSection))
      }}
      startIcon={<AddClassIcon />}>{showFindGroupSection ? "Hide Find groups" : "Find groups"}</Button>
    </div>
    {
    showFindGroupSection ?
    <div className="followLayoutContainer">
      <div className="wrapHeading" style={{marginBottom: "20px"}}>
        <h3 className='section-title-04'>Search Classroom Results</h3>
        <KeywordSearch
        searchInputRef = { searchInputRef }
        reducerFunctionCallback = { actionSearchGroup }
        label="Search"
        triggerTextLengthGreaterThan = {1}
        />
      </div>
      <div className="suggestedPeopleList">
        {searchedGroupList.map(classData => <ClassroomCard key={classData._id} classData={classData}/>)}
      </div>
    </div> : ""
    }

    <GroupListSection
      title=        {"Joined Groups"}
      classList=    {joinedGroups}
      hasNextPage=  {joinedGroupsHasNextPage}
      nextPage=     {joinedGroupsNextPage}
      loadClassFunction = {
        page => dispatch(actionGetJoinedGroup(page))
      }

    />

    <GroupListSection
      title=        {"Groups you might be interested"}
      joinText=     {"Join Group"}
      classList=    {suggestedGroups}
      hasNextPage=  {suggestedGroupsHasNextPage}
      nextPage=     {suggestedGroupsNextPage}
      loadClassFunction = {
        page => dispatch(actionGetSuggestedGroups(page))
      }
      joinFunction={
        groupId => dispatch(actionRequestToJoinGroup(groupId))
      }
    />
    
	</div>
}

export default GroupList;