//import PlanListSelection from '.components/PlanListSelection/PlanListSelection';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import './PlanListSelection.scss';
import { useSelector,useDispatch } from 'react-redux';
import EventNoteIcon from '@mui/icons-material/EventNote';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { ReactComponent as UserIcon } from '../../Assets/ic_user.svg';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
    actionGetSelectedUserPlanList
} from '../../app/Reducers/planning/planningSlice';


const PlanListSelection = ({
    onSelectPlan
}) => {

    const dispatch = useDispatch();

    const {
        selectedUserPlanList,
        selectedPlan
    } = useSelector(state => state.planning);

    useEffect(()=>{
        dispatch(actionGetSelectedUserPlanList())
    },[])

    return <div className='PlanListSelectionContainer'>

        <FormControl
        fullWidth
        size="small">
            <InputLabel id="selected-plan-label">Select plan</InputLabel>
            <Select
            labelId="selected-plan-label"
            id="selected-plan"
            value={selectedPlan ? selectedPlan._id : ""}
            label="Select plan"
            onChange={e=>onSelectPlan(selectedUserPlanList.find(pl => pl._id === e.target.value))}
            >
            {selectedUserPlanList.map(plan => 
                <MenuItem key={plan._id} value={plan._id}>
                <ListItemIcon sx={{minWidth: "32px"}}>
                    <EventNoteIcon/>
                </ListItemIcon>
                {plan.title}
                </MenuItem>
            )}
            </Select>
        </FormControl>
        
    </div>
}

export default PlanListSelection;