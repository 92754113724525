import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetUsersWithEmergentIndex } from '../../app/Reducers/user/userSlice';
import UserCard from '../../components/UserCard/UserCard';
import TodosWithPointsPortal from '../../components/Portals/TodosWithPointsPortal';
import PostsWithPointsPortal from '../../components/Portals/PostsWithPointsPortal';

const UsersListWithEmergentIndex = ({

}) => {
	const dispatch = useDispatch();
  const {
    usersWithEmergentIndex
  } = useSelector(state => state.user);

  useEffect(()=>{
    dispatch(actionGetUsersWithEmergentIndex())
  },[])
  
  return <div className={`UsersListWithEmergentIndexContainer`}>
    {
      usersWithEmergentIndex?.length ?
      usersWithEmergentIndex.map(user=><UserCard key={user._id} user={user} showFollowButton={true}/>) :
      <p>No users to show</p>
    }
    <PostsWithPointsPortal />
    <TodosWithPointsPortal />
  </div>

}

export default memo(UsersListWithEmergentIndex);