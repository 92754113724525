//import TodoCategoryList from '.components/TodoCategoryList/TodoCategoryList';//cut it and paste to the component
import React, {useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import './TodoCategory.scss';
import {v1 as uuid} from "uuid";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CreateTodoCategoryForm from './CreateTodoCategoryForm'
import EditTodoCategoryForm from './EditTodoCategoryForm'
import {
  actionGetTodoCategory,
	// actionDeleteTodoCategory
} from '../../app/Reducers/note/noteSlice';
import {
  // actionGetTodoCategory,
	actionDeleteTodoCategory
} from '../../app/Reducers/todo/todoSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
	actionToggleNotesFilter
} from '../../app/Reducers/layout/layoutSlice';

const TodoCategoryList = ({

}) => {
		const {noteCategoryList} = useSelector(state => state.note);
		
    const {todoCategoryList} = useSelector(state => state.todo);
		const dispatch = useDispatch();
		const {
			showNoteFilter
		} = useSelector(state => state.layout);
		const [showCreateCategoryForm, setShowCreateCategoryForm] = useState(false)
		const [showEditCategoryForm, setShowEditCategoryForm] = useState(false)
		const [selectedTodoCategory, setSelectedTodoCategory] = useState(null)
		// const {
		//		 popupType,
		//		 popupMessage,
		//		 popupOpen
		// } = useSelector(state => state.note);
		
		useEffect(()=>{
			dispatch(actionGetTodoCategory())
		},[])

		return <div className={`TodoCategoryListContainer ${showNoteFilter ? 'open' : ''}`}>
			
			<div className="noteCategoryListHeading">
				<h3 className='filter-by-heading'>Note Categories</h3>
				<Tooltip title="Add Note Category">
					<IconButton
					sx={{ marginLeft: "10", padding: "5px"}}
					aria-label="add"
					onClick={()=>dispatch(actionToggleNotesFilter(false))}>
						<CloseIcon sx={{fontSize: 18}}/>
					</IconButton>
				</Tooltip>
			</div>
			
			<FormGroup className="tasks-filter-wrapper">
				{todoCategoryList.length ? todoCategoryList.map(todoCategory => {
					return (
					<div key={todoCategory._id} className='filter-checkbox-wrapper'>

						{/* <FormControlLabel 
						key={uuid()}
						control={<Checkbox size="small"/>}
						label={todoCategory.name}
						sx={{marginRight: 0}} /> */}
						<p style={{fontSize: "12px"}}>{todoCategory.name}</p>

						<div className="actions-wrapper">

							<Tooltip title="Edit">
								<IconButton
								sx={{ marginLeft: "2px", padding: "5px", width: "24px", height: "24px"}}
								onClick={()=>{
									setSelectedTodoCategory(todoCategory)
									setShowEditCategoryForm(true)
								}}>
									<EditIcon sx={{fontSize: 16}} />
								</IconButton>
							</Tooltip>

							<Tooltip title="Delete">
								<IconButton
								sx={{ marginLeft: "2px", padding: "5px", width: "24px", height: "24px"}}
								onClick={()=>dispatch(actionDeleteTodoCategory(todoCategory))}>
									<DeleteIcon sx={{fontSize: 16}} />
								</IconButton>
							</Tooltip>

						</div>

					</div>
					)
				}) : <p>No Note Category Available</p>}
			</FormGroup>

			<Button
			sx={{width:"100%"}}
			type="button"
			variant="contained"
			onClick={()=>setShowCreateCategoryForm(open => !open)}
			>Add note category</Button>
			
			{/* <Stack spacing={1} direction="row" justifyContent="center">
				<Button
				type="button"
				variant="contained"
				size='small'
				sx={{
					fontSize: 14,
				}}
				>
					Select All
				</Button>
				<Button type="button" variant="contained">
					Clear
				</Button>
			</Stack> */}
			
			<CreateTodoCategoryForm 
			showCreateCategoryForm = {showCreateCategoryForm}
			setShowCreateCategoryForm = {setShowCreateCategoryForm}
			/>

			<EditTodoCategoryForm 
			selectedTodoCategory = {selectedTodoCategory}
			setSelectedTodoCategory = {setSelectedTodoCategory}
			showEditCategoryForm = {showEditCategoryForm}
			setShowEditCategoryForm = {setShowEditCategoryForm}
			/>

		</div>
}

export default TodoCategoryList;