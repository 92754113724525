//import Counter from '.components/Counter/Counter';//cut it and paste to the component
import React, {useState,useEffect,useCallback} from 'react';

const Counter = ({
    ongoing,
    initialTimeRendered,// in seconds
    estimateInSec,
    callback,
    progress
}) => {

    const [countInSec, setCountInSec] = useState(initialTimeRendered)
    // const [progress, setProgress] = useState(Math.round(countInSec/estimateInSec*100))

    const secToTimeFormat = useCallback(
        sec => {
            var hours   = Math.floor(sec / 3600);
            var minutes = Math.floor((sec - (hours * 3600)) / 60);
            var seconds = Math.floor(sec - (hours * 3600) - (minutes * 60));
        
            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            return hours+':'+minutes+':'+seconds;
        },[]
    )

    useEffect(() => {
        let interval = null;
        if(ongoing){
            interval = setInterval(() => {
                setCountInSec(c => c+1)
                const percentage = Math.round(countInSec/estimateInSec*100);
                if(percentage <= 100 && percentage > progress){
                    callback(percentage)
                }
            }, 1000);
        }
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [ongoing,progress,countInSec])

    useEffect(() => {
        callback(progress)
    }, [progress]);

    useEffect(() => {
        setCountInSec(initialTimeRendered)
    }, [initialTimeRendered])

    return <>
        <div className="time-rendered">
            <p>Rendered: <span style={{color: countInSec/estimateInSec > 1 ? "#d32f2f" : ""}}> {(countInSec/3600).toFixed(2)} hours</span></p>
            <span style={{color: progress < 100 ? "#01ab56" : "#d32f2f"}}>({progress.toFixed(0)}%)</span>
        </div>
        {ongoing ? <p>Current time: {secToTimeFormat(countInSec)}</p> : ""}
        {
            countInSec/estimateInSec > 1 ? 
            <p style={{color: "#d32f2f"}}>
                Overtime: {((countInSec-estimateInSec)/3600).toFixed(2)} hours
            </p> : ""
        }
    </>
}

export default Counter;