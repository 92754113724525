export const getHeaders = () => {
    const token = localStorage.getItem('wstoken');
    return token ? {
        headers: {
            "auth-token": token
        }
    } : null
}

export const mergeAndRemoveDuplicatesById = (arr1, arr2) => {
    const idSet = new Set();
    const mergedAndUniqueArray = [];

    arr1.forEach(item => {
        if (!idSet.has(item._id)) {
            idSet.add(item._id);
            mergedAndUniqueArray.push(item);
        }
    });

    arr2.forEach(item => {
        if (!idSet.has(item._id)) {
            idSet.add(item._id);
            mergedAndUniqueArray.push(item);
        }
    });

    return mergedAndUniqueArray;
}

export const mergeArrayKeysById = (arr1, arr2) => {
    
    const mergedData = {};
    
    // Merge data from array1
    for (const item of arr1) {
        const id = item._id;
        mergedData[id] = { ...mergedData[id], ...item };
    }
    
    // Merge data from array2
    for (const item of arr2) {
        const id = item._id;
        mergedData[id] = { ...mergedData[id], ...item };
    }
    
    // Extract the merged data as an array
    return Object.values(mergedData);
}

export const openAIFormatText = (inputText) => {
    // Bold Text
    inputText = inputText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    inputText = inputText.replace(/__(.*?)__/g, '<strong>$1</strong>');

    // Italic Text
    inputText = inputText.replace(/\*(.*?)\*/g, '<em>$1</em>');
    inputText = inputText.replace(/_(.*?)_/g, '<em>$1</em>');

    // Underlined Text
    inputText = inputText.replace(/<u>(.*?)<\/u>/g, '<u>$1</u>');

    // Hyperlinks
    inputText = inputText.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');

    // Unordered Lists
    inputText = inputText.replace(/^\s*-\s(.+)$/gm, '<li>$1</li>');
    if (inputText.match('<li>')) {
        inputText = '<ul>\n' + inputText + '\n</ul>';
    }

    // Ordered Lists
    inputText = inputText.replace(/^\s*\d+\.\s(.+)$/gm, '<li>$1</li>');
    if (inputText.match('<li>')) {
        inputText = <ol>\n' + inputText + '\n</ol>;
    }

    return inputText;
}