import React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange } from '@mui/material/colors';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import HomeIcon from '@mui/icons-material/Home';
import { NavLink } from "react-router-dom";
import "./UserDisabledOrNotExistWrapper.scss"


const UserDisabledOrNotExist = ({

}) => {
    return <div className='UserDisabledOrNotExistWrapper'>
      <div className="UserDisabledOrNotExistContent">
        <div className="iconContainer">
          <PersonOffIcon sx={{ fontSize: 96 }} />
        </div>
        <h1>Account does not exist or disabled</h1>
        <p>When this happens, it's usually because the owner or hungry2learn disables this account.</p>
        <NavLink className="nav-link" to={`/home`}>
          <span className="icon-container"><HomeIcon/></span>
          <span className="menu-nav-name">Go back to global feeds</span>
        </NavLink>
      </div>
    </div>
}

export default UserDisabledOrNotExist;
