//import MomentDashboardData from '.components/MomentDashboardData/MomentDashboardData';//cut it and paste to the component
import React,{ useState, useEffect, useMemo } from 'react';
import IdeaDataComparison from '../IdeaDataComparison/IdeaDataComparison';
import { useSelector } from 'react-redux';
import LinkedTasks from '../../../components/LinkedTasks/LinkedTasks';

const MomentDashboardData = ({

}) => {
	const {
		challengeMoments,
		conceptMoments,
		keyConceptMoments,
		resultMoments,
		keyResultMoments,
		objectiveMoments,
		keyQuestionMoments,
		tasksInDateRange,
	} = useSelector(state => state.dashboard);

	const [graphSize,setgraphSize] = useState(window.innerWidth < 500 ? 300 : 400);
	const [graphHeight,setgraphHeight] = useState(window.innerWidth < 500 ? 300 : 350);
	
	const {
		linkedTasks
	} = useSelector(state => state.todo);

	useEffect(() => {
		const handleResize = () => {
			setgraphSize(window.innerWidth < 500 ? 300 : 400)
			setgraphHeight(window.innerWidth < 500 ? 300 : 350)
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const graphs = useMemo(()=>{
		return <div className="data-graph-container">
			<IdeaDataComparison
				// title={"Tasks Types Comparison"}
				dataSet={
					{
						set1: [
							{x: 'Thirilling Tasks', y: [...tasksInDateRange].filter(task => task.isThrillingTask).length},
							{x: 'Tasks', y: [...tasksInDateRange].filter(task => !task.isThrillingTask).length},
							{x: 'Linked tasks', y: [...tasksInDateRange].filter(task => task.linkedTodos.length).length}
						],
					}
				}
				height={graphHeight}
				width={graphSize}
			/>

			<IdeaDataComparison
				dataSet={
					{
						set1: [{x: 'Objectives', y: objectiveMoments.length}, {x: 'Results', y: resultMoments.length}],
						set2: [ {x: 'Results', y: keyResultMoments.length}] // this is the key results
					}
				}
				height={graphHeight}
				width={300}
			/>

			<IdeaDataComparison
				// title={"Ideas Types Comparison"}
				dataSet={
					{
						set1: [{x: 'Questions', y: challengeMoments.length}, {x: 'Concepts', y: conceptMoments.length}],
						set2: [{x: 'Questions', y: keyQuestionMoments.length}, {x: 'Concepts', y: keyConceptMoments.length}]  // this is the key questions and concept
					}
				}
				height={graphHeight}
				width={300}
			/>
		</div>
	},[graphSize,tasksInDateRange]);

	// const momentsTable = useMemo(()=><TaskMomentsTable/>,[])

	return (
	  <div className="idea-content-wrapper">
			{/* <h2 className="sectionTitle">Detailed Data</h2>
			{graphs}
			<h2 className="sectionTitle">Linked Tasks</h2>
			{linkedTasks.map(task => {
				return <div key={task._id} className={`LinkedTasksContainer`}>
					<LinkedTasks task={task}/>
				</div>
			})} */}
			
	  </div>
	)
}

export default MomentDashboardData;
