//import ManageAccounts from '.components/ManageAccounts/ManageAccounts';//cut it and paste to the component
import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
// import './ManageAccounts.scss';
import { useSelector, useDispatch } from 'react-redux';
import UserCard from '../../components/UserCard/UserCard';
import { actionGetDisabledAccounts } from '../../app/Reducers/user/userSlice';

const ManageAccounts = ({

}) => {

	const dispatch = useDispatch();

	const {
		disabledAccounts,
	} = useSelector(state => state.user);

	useEffect(()=>{
		dispatch(actionGetDisabledAccounts({page: 1}))
	},[])

	const follow = userId => {
		console.log("follow "+userId)
	}

	return <div className='FollowersContainer'>
		<h1 className='section-title-01'>Manage Accounts</h1>

		<div className="followLayoutContainer">
			<h3 className='section-title-04'>Disabled Accounts</h3>
			<div className="suggestedPeopleList">
				{
				disabledAccounts.length ?
				disabledAccounts.map(user=><UserCard key={user._id} user={user} showFollowButton={true}/>) :
				<p>No disabled accounts to show</p>
				}
			</div>
		</div>

		{/* <div className="followLayoutContainer">
			<h3 className='section-title-04'>Following</h3>
			<div className="suggestedPeopleList">
				{
				following.length ?
				following.map(user=><UserCard key={user._id} user={user} showFollowButton={true}/>) :
				<p>No following to show</p>
				}
			</div>
		</div>

		<div className="followLayoutContainer">
			<h3 className='section-title-04'>People you may know</h3>
			<div className="suggestedPeopleList">
				{suggestedUsers.map(user=><UserCard key={user._id} user={user} showFollowButton={true}/>)}
			</div>
		</div> */}
	</div>
}

export default memo(ManageAccounts);