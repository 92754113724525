//import DeleteNote from '.components/DeleteNote/DeleteNote';//cut it and paste to the component
import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};


const DeleteNote = ({
    note,
    deleteConfirmationOpen,
    setDeleteConfirmationOpen,
    deleteNote
}) => {
    return <div className='DeleteNoteContainer'>
        <Modal
            keepMounted
            open={deleteConfirmationOpen}
            onClose={()=>setDeleteConfirmationOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
                <Stack spacing={2}>
                    
                    <Typography
                    id="keep-mounted-modal-title"
                    variant="h6"
                    component="h2"
                    style={{marginBottom:10}}>
                        {`Delete Note "${note.title}"?`}
                    </Typography>

                    <Stack
                    spacing={1}
                    direction="row"
                    justifyContent="flex-end">
                        <Button variant="outlined" onClick={()=>deleteNote(note._id)}>Yes</Button>
                        <Button variant="outlined" onClick={()=>setDeleteConfirmationOpen(false)}>Cancel</Button>
                    </Stack>

                </Stack>
            </Box>
        </Modal>
    </div>
}

export default DeleteNote;