//import TrainingRowData from '.components/TrainingRowData/TrainingRowData';//cut it and paste to the component
import React, {useState,useMemo} from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Rating from '@mui/material/Rating';
import {v1 as uuid} from "uuid";
import { useDispatch, useSelector } from 'react-redux';
import {
    actionUpdateTrainingData,
    actionCreateTrainingData,
    actionRemoveTrainingDataForm,
    actionDeleteTrainingData,
    actionGetTrainingDataReviews
} from '../../app/Reducers/bot/botSlice';
import AvatarWithName from '../../components/AvatarWithName/AvatarWithName'
import {
} from '../../app/Reducers/bot/botSlice';
import ConfirmationDialogRaw from '../../components/ConfirmationDialogRaw/ConfirmationDialogRaw'

const TrainingRowData = ({
    trainingData,
    // removeTrainingData
}) => {

    const dispatch = useDispatch();

    const [inquiry, setInquiry] = useState(trainingData.inquiry)
    const [answer, setAnswer] = useState(trainingData.answer)
    const [intent, setIntent] = useState(trainingData.intent)
    const [subjects, setSubjects] = useState(trainingData.subjects)
    const [links, setLinks] = useState(trainingData.links)
    const [suggestionPriority, setSuggestionPriority] = useState(
        trainingData.suggestionPriority ? trainingData.suggestionPriority : "subject"
    )

    const [showSubjectField, setShowSubjectField] = useState(false)
    const [subjectField, setSubjectField] = useState("")

    const [showSourceLinkField, setShowSourceLinkField] = useState(false)
    const [sourceLinkField, setSourceLinkField] = useState("")

    const onInquiryChange = e => setInquiry(e.target.value)
    const onAnswerChange = e => setAnswer(e.target.value)
    const onIntentChange = e => setIntent(e.target.value)
    const onSubjectFieldChange = e => setSubjectField(e.target.value)
    const onSourceLinkFieldChange = e => setSourceLinkField(e.target.value)
    
    const addLink = () => {
        setLinks([...links,sourceLinkField])
        setSourceLinkField("")
        setShowSourceLinkField(false)
    }
    const removeLink = i => setLinks(links.filter((e,index) => index !== i))

    const addSubject = () => {

        setSubjects([...subjects,subjectField])
        setSubjectField("")
        setShowSubjectField(false)

    }
    const removeSubject = i => setSubjects(subjects.filter((e,index) => index !== i))

    const updateTrainingData = () => {
        
        const trainingDataUpdate = {
            _id: trainingData._id,
            inquiry: inquiry,
            answer: answer,
            suggestionPriority: suggestionPriority,
            intent: intent,
            subjects: subjects,
            links: links
        }
        console.log(trainingDataUpdate)

        dispatch(actionUpdateTrainingData(trainingDataUpdate))
    }

    const saveTrainingData = () => {
        
        const trainingDataCreated = {
            tempId: trainingData.tempId,
            inquiry: inquiry,
            answer: answer,
            suggestionPriority: suggestionPriority,
            intent: intent,
            subjects: subjects,
            links: links
        }

        dispatch(actionCreateTrainingData(trainingDataCreated))
    }

    const [open, setOpen] = React.useState(false);

    const deleteTrainingData = () => {
        dispatch(actionDeleteTrainingData(trainingData._id))
    }

    const getTrainingDataRating = useMemo(()=>{
        if(trainingData?.ratings?.length)
            return  Math.round(trainingData.ratings
                    .map(r=>r.rating)
                    .filter(r=>r > 0)
                    .reduce((acc,v,i,a)=>(acc+v/a.length),0)*10)/10
        return 0
    },[trainingData])

    return (
        <TableRow
        className={`training-table`}
        sx={{ 
            '&:last-child td, &:last-child th': { border: 0 },
            backgroundColor: trainingData.isFormInput ? "#edf8f2" : "transparent"
        }}>
            <TableCell sx={{verticalAlign:"top"}} align="left">

                <AvatarWithName 
                    name={`${trainingData.filedBy.firstName} ${trainingData.filedBy.lastName}`}
                    imgSrc={""}
                />
                <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Your inquiry"
                    defaultValue={inquiry}
                    style={{ width: 200 }}
                    onChange={onInquiryChange}
                />

            </TableCell>
            <TableCell sx={{verticalAlign:"top"}} align="center">

                <div className="answer-wrapper">

                    <AvatarWithName 
                        name={`${trainingData.filedBy.firstName} ${trainingData.filedBy.lastName}`}
                        imgSrc={""}
                    />
                    <TextareaAutosize
                        aria-label="empty textarea"
                        placeholder="Write your answer here"
                        defaultValue={answer}
                        style={{ width: 200 }}
                        onChange={onAnswerChange}
                    />

                    <FormControl
                    className="suggestion-priority-selection">
                        <FormLabel
                        sx={{ 
                            fontSize: 14,
                            textAlign: "left"
                        }}
                        >
                            Suggestion priority
                        </FormLabel>
                        <RadioGroup
                        className="radio-button-group"
                        aria-labelledby="demo-radio-buttons-group-label"
                        
                        name="radio-buttons-group"
                        onChange={e=>{
                            console.log(e.target.value)
                            setSuggestionPriority(e.target.value)
                        }}>

                            <FormControlLabel
                            value="subject"
                            control={
                                <Radio
                                checked={suggestionPriority === "subject"}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 }}}/>
                            }
                            label="Subject" />

                            <FormControlLabel
                            value="link"
                            control={
                                <Radio
                                checked={suggestionPriority === "link"}
                                sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>
                            }
                            label="Link" />

                        </RadioGroup>
                    </FormControl>
                    {trainingData?.ratings?.length ? <>
                        <div
                        className="rating-container"
                        onClick={()=>dispatch(actionGetTrainingDataReviews(trainingData._id))}>
                            <p className="rating-info">Rating ( {getTrainingDataRating}/5 )</p> 
                            <Rating
                            name="simple-controlled"
                            size="small"
                            value={getTrainingDataRating}
                            readOnly/>  
                        </div>
                    </> : ""}
                </div>

            </TableCell>
            <TableCell sx={{verticalAlign:"top"}} align="center">

                <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Write intent here"
                    defaultValue={intent}
                    style={{ width: 200 }}
                    onChange={onIntentChange}
                />

            </TableCell>
            <TableCell sx={{verticalAlign:"top"}} align="center">
                {subjects.length ?
                <ul className="subject-list">
                    {subjects.map( (subject,i) => {
                        return (
                            <li key={uuid()} className="eclair subject-tag">
                                {subject}
                                <span 
                                className="remove-trigger"
                                onClick={()=>removeSubject(i)}>
                                    <DeleteIcon fontSize="inherit" />
                                </span>
                            </li>
                        )
                    })}
                </ul>
                : ""}

                {showSubjectField ? "" :    <Button
                                            size="small"
                                            variant="contained"
                                            onClick={()=>setShowSubjectField(true)}
                                            >Add Subject</Button>
                }

                {showSubjectField ? <div className="subject-form-wrapper">

                    <TextField
                    size="small"
                    label="Subject"
                    variant="outlined" 
                    value={subjectField}
                    onChange={onSubjectFieldChange}/>

                    <div className="action-wrapper">
                        <Button
                        size="small"
                        variant="contained"
                        onClick={()=>addSubject()}>Add</Button>
                        <Button
                        size="small"
                        variant="contained"
                        onClick={()=>setShowSubjectField(false)}>Cancel</Button>
                    </div>

                </div> : ""}

            </TableCell>
            <TableCell sx={{verticalAlign:"top"}} align="center">

                {links.length ? 
                <ul className="source-link-list">
                    {links.map((link,i)=>{
                        return (
                            <li key={uuid()} className="eclair">
                                <a
                                href={link}
                                target="_blank">
                                    {link}
                                </a>
                                <span
                                className="remove-trigger"
                                onClick={()=>removeLink(i)}>
                                    <DeleteIcon fontSize="inherit" />
                                </span>
                            </li>
                        )
                    })}
                </ul>
                : ""}

                {showSourceLinkField ? "" :    <Button
                                            size="small"
                                            variant="contained"
                                            onClick={()=>setShowSourceLinkField(true)}
                                            >Add Link</Button>
                }

                {showSourceLinkField ? <div className="subject-form-wrapper">

                    <TextField
                    size="small"
                    label="Source Link"
                    variant="outlined" 
                    value={sourceLinkField}
                    onChange={onSourceLinkFieldChange}/>

                    <div className="action-wrapper">
                        <Button
                        size="small"
                        variant="contained"
                        onClick={()=>addLink()}>Add</Button>
                        <Button
                        size="small"
                        variant="contained"
                        onClick={()=>setShowSourceLinkField(false)}>Cancel</Button>
                    </div>

                </div> : ""}

            </TableCell>
            <TableCell sx={{verticalAlign:"top"}} align="center">
                {
                trainingData.isFormInput ? 
                    <div className="action-button-container">
                        <Button
                        size="small"
                        variant="contained"
                        onClick={
                            () => saveTrainingData()
                        }>Save</Button>

                        <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={
                            () => dispatch(actionRemoveTrainingDataForm(trainingData._id))
                        }>Cancel</Button>
                      
                    </div>
                    :
                    <div className="action-button-container">
                        <Button
                        size="small"
                        variant="contained"
                        onClick={() => updateTrainingData()}>Update</Button>
                        <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() => 
                        
                            setOpen(true)
                        
                        }>Remove</Button>
                        <ConfirmationDialogRaw
                            onClose={()=>setOpen(false)}
                            open={open}
                            callBack={deleteTrainingData}
                            dialog={"Delete training data?"}
                            description={inquiry}
                        />
                    </div>
                }
                
            </TableCell>
        </TableRow>
    )
}

export default TrainingRowData;