//import UserCardChip from '.components/UserCardChip/UserCardChip';//cut it and paste to the component
import React from 'react';
import { NavLink } from "react-router-dom";
import "./UserCardChip.scss"

const UserCardChip = ({
  user,
  userOverAllPoints = 0
}) => {

	return <div className='userCardChip'>
      <div className="userCardChipImgContainer">
        <NavLink to={`/profile?user=${user._id}`}>
          <img src={user.profilePicture ? user.profilePicture : '/profiletemp.jpg'} alt="" loading="lazy"/>
        </NavLink>
      </div>
      <div className="nameAndDateContainer">
        <p className='name'>
          <NavLink to={`/profile?user=${user._id}`}><span className='semiBold'>{user.firstName} {user.lastName}</span></NavLink>
        </p>
        {/* <p className="date" style={{fontSize: "14px"}}>Total points: <span className='semiBold'>{userOverAllPoints}</span></p> */}
      </div>
  </div>
}

export default UserCardChip;