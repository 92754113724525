import React, { useEffect, useState } from 'react';
import './Classroom.scss';
import { useSelector, useDispatch } from 'react-redux';
import ViewTimelinePicture from '../Profile/ViewTimelinePicture';
import ViewProfilePicture from '../Profile/ViewProfilePicture';
import Button from '@mui/material/Button';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { systemRoles } from '../../components/Hooks/Constants';
import PostInfiniteScroll from '../../components/PostInfiniteScroll/PostInfiniteScroll';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import {v1 as uuid} from "uuid";
import {
	actionsShowUserSelection
} from '../../app/Reducers/user/userSlice';
import {
	actionGetClassroomPosts
} from '../../app/Reducers/post/postSlice';
import {
	actionSetSelectedClass,
	actionRequestToJoinClassroom,
	actionToggleClassroomUsersJoinRequestListOpen,
	actionToggleClassroomUsersMembersListOpen,
	actionAcceptToClassroom,
	actionDeclineToClassroom,
	actionRemoveToClassroom,
	actionToggleCreateSeminarFormOpen,
	actionToggleUpdateClassroomFormOpen,
	actionToggleSeminarAttendeesOpen,
	actionChangeClassroomProfilePicture,
	actionChangeClassroomTimelinePicture,
	actionDeleteClassroom,
	actionCancelRequestToJoinClassroom,
} from '../../app/Reducers/classroom/classroomSlice';
import UsersListSelectionActions from '../../components/Group/UsersListSelectionActions';
import CreateSeminarForm from '../../components/Seminar/CreateSeminarForm';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SeminarItem from '../../components/Seminar/SeminarItem';
import JoinSeminarForm from '../../components/Seminar/JoinSeminarForm';
import UpdateSeminarForm from '../../components/Seminar/UpdateSeminarForm';
import UpdateClassroomForm from '../../components/UpdateClassroomForm/UpdateClassroomForm';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AllSeminars from '../../components/Seminar/AllSeminars';

const Classroom = ({

}) => {
	const [params, setParams] = useSearchParams({ user: "" })
  const [tabIndex, setTabIndex] = React.useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const dispatch = useDispatch();
  const id = params.get("class")
	const handleClose = () => setAnchorEl(null);

	const {
		isLoading,
		selectedClassroom,
		classroomJoinRequests,
		classroomUsersJoinRequestListOpen,
		classroomMembers,
		classroomMembersListOpen,
		seminarAttendees,
		showSeminarAttendees,
	} = useSelector(state => state.classroom)

	const {
		userData
	} = useSelector(state => state.user);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

	const {
		isPostsLoading,
		classroomPosts,
		classroomhasNextPage,
		classroomnextPage,
		classroompage,
	} = useSelector(state => state.post)

	const onProfilePictureChange = (data, profilePicture) => {
		dispatch(
			actionChangeClassroomProfilePicture(
				{
					classroomId: id,
					...data
				},
				profilePicture
			))
	}

	const onTimelinePictureChange = (data, profilePicture) => {
		dispatch(
			actionChangeClassroomTimelinePicture(
				{
					classroomId: id,
					...data
				},
				profilePicture
			)
		)
	}

	const loadMoreCallback = pageNum => dispatch(actionGetClassroomPosts(id ? id : userData._id, pageNum))

	const isMember = () => {
		if(selectedClassroom?.admin == userData._id)
			return true
		if(selectedClassroom?.members)
			return selectedClassroom.members.indexOf(userData._id) > -1
		return false
	}

	
	const joinRequestSent = () => {
		if(selectedClassroom?.joinRequests)
			return selectedClassroom.joinRequests.indexOf(userData._id) > -1
		return false
	}

	const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

	const getPageOptions = () => {
		const classroomOptions = []

		if(
			userData.systemRole == systemRoles.superAdmin ||
			userData.systemRole == systemRoles.admin
		){
			classroomOptions.push({
				id: uuid(),
				name: "Add member",
				style: { width: "200px" },
				icon: <GroupAddIcon />,
				callback: () => dispatch(actionsShowUserSelection(true))
			})
		}

		if(!isMember()){

			if(joinRequestSent()){
				classroomOptions.push({
					id: uuid(),
					name: "Cancel Join Request",
					icon: <GroupAddIcon />,
					callback: () => dispatch(actionCancelRequestToJoinClassroom(id))
				})
			}
			else{
				classroomOptions.push({
					id: uuid(),
					name: "Join",
					icon: <GroupAddIcon />,
					callback: () => dispatch(actionRequestToJoinClassroom(id))
				})
			}
		}
		if(isMember()){
			classroomOptions.push({
				id: uuid(),
				name: "Leave Classroom",
				icon: <ExitToAppIcon />,
				callback: () => console.log("Create Leave classroom functionality")
			})
		}

		if(userData._id === selectedClassroom.createdBy){
			classroomOptions.push({
				id: uuid(),
				name: "Delete Classroom",
				style: { color: "#d32f2f" },
				icon: <DeleteIcon />,
				callback: () => dispatch(actionDeleteClassroom(selectedClassroom))
			})
		}

		return classroomOptions.length ? classroomOptions.map(option=>(
			<MenuItem
			key={option.id}
			onClick={()=>{
					option.callback()
					setAnchorEl(null)
			}}>
					<ListItemIcon style={{minWidth: 32}}>
							{option.icon}
					</ListItemIcon>
					<ListItemText>{option.name}</ListItemText>
			</MenuItem>
		)) : ""
	}

	useEffect(()=>{
		dispatch(actionSetSelectedClass(id))
		dispatch(actionGetClassroomPosts(id, classroompage))
	},[id])

	if(!selectedClassroom) return ""

	return <div className='ClassroomContainer pb200'>
		{isLoading ? <>
			<div className="loading-wrapper">
				<div className="loader"></div>
			</div>
		</> : ""}	
		<div className="timeline-wrapper">
			<div
			className="timeline-img-container">
				<ViewTimelinePicture
					src={selectedClassroom?.classroomTimelineImage ? selectedClassroom.classroomTimelineImage : '/timelinetemp.jpg'}
					editable={true}
					onTimelinePictureChange={onTimelinePictureChange}
					associatedPostId={selectedClassroom.classroomTimelinePicturePostLink}
				/>
			</div>
		</div>
		<div
		className="page-and-image-and-name-wrapper pp-wrap-inner"
		style={{
			marginBottom: 0
		}}>
			<div className="page-image-container">
				<ViewProfilePicture
					src={selectedClassroom?.classroomImage ? selectedClassroom.classroomImage : '/classroomtimelinetemp.jpg'}
					editable={true}
					onProfilePictureChange={onProfilePictureChange}
					associatedPostId={selectedClassroom.classroomProfilePicturePostLink}
				/>
			</div>
			<div className="name-and-description">
				<h1 className='page-name'>{selectedClassroom.name}</h1>
				<div className="page-description">{selectedClassroom.description}</div>
				<p
					className='info clickable'
					onClick={()=>dispatch(actionToggleClassroomUsersMembersListOpen(true, selectedClassroom._id))}
				>{selectedClassroom?.members?.length} Student{selectedClassroom?.members?.length > 1 ? "s" : ""}</p>
				
				{
				selectedClassroom.admin == userData._id &&
				<p
					className='info clickable'
					onClick={()=>dispatch(actionToggleClassroomUsersJoinRequestListOpen(true, selectedClassroom._id))}
				>{selectedClassroom?.joinRequests?.length} Join Request{selectedClassroom?.joinRequests?.length > 1 ? "s" : ""}</p>
				}

				{
					isMember() ?
						<p className='info'><GroupIcon /> Joined</p>
						:
						joinRequestSent() ?
							<Tooltip title={"Cancel Join Request"}>
								<p className='info clickable' onClick={()=>dispatch(actionCancelRequestToJoinClassroom(id))}><GroupAddIcon /> Join Request sent</p>
							</Tooltip>
							:
							<Tooltip title={"Join Classroom"}>
								<p className='info clickable' onClick={()=>dispatch(actionRequestToJoinClassroom(id))}><GroupAddIcon /> Join</p> 
							</Tooltip>
				}
			</div>
			
			<div className="buttonActions">
				{
				userData.systemRole == systemRoles.superAdmin ||
        userData.systemRole == systemRoles.admin ||
				userData._id == selectedClassroom.createdBy
				?
					<Button
					style={{ width: "200px" }}
					variant="contained"
					onClick={()=>dispatch(actionsShowUserSelection(true))}					
					startIcon={<GroupAddIcon />}>Add member</Button>:""
				}
				<Tooltip title="More Options">
					<Button
					variant="contained"
					onClick={handleMoreClick}
					sx={{
						width: "38px",
						minHeight: "36px",
						minWidth: "auto",
						padding: "0",
					}}
					><ExpandMoreIcon /></Button>
				</Tooltip>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					MenuListProps={{
					'aria-labelledby': 'basic-button',
					}}
					
				>
						{getPageOptions()}
				</Menu>
			</div>
		</div>

		<div
		className="tabStyle"
		style={{
			marginBottom: "28px"
		}}>
			<div className="pp-wrap-inner">
				<Tabs
					value={tabIndex}
					onChange={handleTabChange}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
				>
					<Tab label="Posts" />
					<Tab label="Seminars" />
				</Tabs>
			</div>
    </div>

		{
		tabIndex == 0 ? 
			<div className="optionsContainerAndinfo pp-wrap-inner">
				<div className="postPageInfoArea">
					<div className="postPageInfoAreaSection">
						<div
						className="wrapHeading"
						style={{
							marginBottom: "16px",
							alignItems: "center"
						}}>
							<h2 className="nav-section-header">Class information</h2>
							{
							selectedClassroom.admin == userData._id &&
							<Tooltip title="Update Classroom">
								<IconButton
								sx={{ marginLeft: "10", padding: "2px"}}
								aria-label="Update Classroom"
								size="small"
								onClick={()=>dispatch(actionToggleUpdateClassroomFormOpen(true))}>
									<EditIcon sx={{ fontSize: "14px", color:"#00AB55"}}/>
								</IconButton>
							</Tooltip>
							}
						</div>

						{
						selectedClassroom.units ? 
						<dl>
							<dt>Units</dt>
							<dd>{selectedClassroom.units}</dd>
						</dl> : ""
						}
						{
						selectedClassroom.teacher ? 
						<dl>
							<dt className='infoLabelClassroom'>Teacher</dt>
							<dd>{selectedClassroom.teacher.firstName} {selectedClassroom.teacher.lastName}</dd>
						</dl> : ""
						}
						{
						selectedClassroom.coach ? 
						<dl>
							<dt className='infoLabelClassroom'>Coach</dt>
							<dd>{selectedClassroom.coach.firstName} {selectedClassroom.coach.lastName}</dd>
						</dl> : ""
						}
						{/* <dl>
							<dt className='infoLabelClassroom'>Schedule</dt>
							<dd className='dateAndTimeClassroom'>Mon 7pm - 9pm</dd>
						</dl> */}
					</div>
					<div className="seminarListWrapper">
						<div
						className="wrapHeading"
						style={{
							marginBottom: "16px",
							alignItems: "center"
						}}>
							<h2 className="nav-section-header">Upcomming Class Seminars</h2>
							{
							selectedClassroom.admin == userData._id &&
							<Tooltip title="Add Seminar">
								<IconButton
								sx={{
									marginLeft: "10", padding: "2px"}}
								aria-label="Add Seminar"
								size="small"
								onClick={()=>dispatch(actionToggleCreateSeminarFormOpen())}>
									<AddIcon sx={{
										width: "16px",
										height: "16px",
									}} fontSize="small"/>
								</IconButton>
							</Tooltip>
							}
						</div>
						{
							selectedClassroom?.seminars ? selectedClassroom.seminars.map(seminar => (
								<SeminarItem key={seminar._id} seminar={seminar} enableEdit={selectedClassroom.admin == userData._id}/>
							)) : ""
						}
					</div>
				</div>
				<div className="postList">
					<PostInfiniteScroll
						posts={classroomPosts}
						page={classroompage}
						nextPage={classroomnextPage}
						hasNextPage={classroomhasNextPage}
						loading={isPostsLoading}
						loadMoreCallback={loadMoreCallback}
						classroom={selectedClassroom}
						enableCreatePost={isMember()}
					/>
				</div>
			</div>
			:
			<div className="pp-wrap-inner">
				<AllSeminars classroomId={selectedClassroom._id}/>
			</div>
		}

		<UsersListSelectionActions
			userList={classroomJoinRequests}
			open={classroomUsersJoinRequestListOpen}
			title={"Classroom Join Requests"}
			onUserAccept=	{ userId => dispatch(actionAcceptToClassroom(selectedClassroom._id, userId)) }
			onUserDecline={ userId => dispatch(actionDeclineToClassroom(selectedClassroom._id, userId)) }
			onClose={() => dispatch(actionToggleClassroomUsersJoinRequestListOpen(false))}/>

		<UsersListSelectionActions
			userList={classroomMembers}
			open={classroomMembersListOpen}
			title={"Classroom Students"}
			onUserRemove=	{ userId => dispatch(actionRemoveToClassroom(selectedClassroom._id, userId)) }
			onClose={() => dispatch(actionToggleClassroomUsersMembersListOpen(false))}/>

		<UsersListSelectionActions
			userList={seminarAttendees}
			open={showSeminarAttendees}
			title={"Seminar Joiners"}
			// onUserRemove=	{ userId => dispatch(actionRemoveToClassroom(selectedClassroom._id, userId)) }
			onClose={() => dispatch(actionToggleSeminarAttendeesOpen(false))}/>
		
		<CreateSeminarForm/>
		<JoinSeminarForm/>
		<UpdateSeminarForm/>
		<UpdateClassroomForm/>

	</div>
}

export default Classroom;