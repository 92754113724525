import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';
import {v1 as uuid} from "uuid";

/* Just a test code... remove it afterwards */ 
const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const initialState = {
    trainingDatas: [],
    selectedTrainingData: null,
    trainingDataReviewsPanelOpen: false,
    popupOpen: false,
    popupType: "error",
    popupMessage: "Error",
};

export const botSlice = createSlice({
    name: 'bot',
    initialState,
    reducers: {
        setTrainingData: (state,action) => {
            state.trainingDatas = action.payload
        },
        updateTrainingData: (state,action) => {
            // console.log("Ito yun")
            // console.log(action.payload)
            // state.trainingDatas = [...state.trainingDatas].filter(
            //     e=>e._id !== action.payload
            // )
            state.popupOpen = true
            state.popupType = "success"
            state.popupMessage = "Training data Updated"
        },
        deleteTrainingData: (state,action) => {
            state.trainingDatas = [...state.trainingDatas].filter(
                e=>e._id !== action.payload
            )
            state.popupOpen = true
            state.popupType = "success"
            state.popupMessage = "Training data removed."
        },
        createTrainingDataForm: (state,action) => {
            const tempId = uuid();
            const initialTrainingFormData = {
                _id: tempId,
                tempId,
                inquiry:"",
                answer: "",
                suggestionPriority:"link",
                intent:"",
                subjects:[],
                links: [],
                moment:"",
                filedBy: action.payload,
                isFormInput: true,
            }
            state.trainingDatas = [initialTrainingFormData,...state.trainingDatas];
        },
        removeTrainingDataForm: (state,action) => {
            state.trainingDatas = [...state.trainingDatas].filter(trainingData => trainingData._id !== action.payload)
        },
        appendSavedTrainingData: (state,action) => {
            state.trainingDatas = [...state.trainingDatas].map( trainingData => {
                if(trainingData?.tempId === action.payload.tempId){
                    return {
                        ...action.payload,
                        filedBy: trainingData.filedBy,
                        answeredBy: trainingData.filedBy,
                        tempId: "",
                        isFormInput: false
                    }
                }
                return trainingData
            })
            state.popupOpen = true
            state.popupType = "success"
            state.popupMessage = "Training Created"
        },
        setSelectedTrainingData: (state,action) => {
            state.selectedTrainingData = action.payload
            state.trainingDataReviewsPanelOpen = true
        },
        closeTrainginDataReviewsPanel: (state,action) => {
            state.trainingDataReviewsPanelOpen = action.payload
        },
        setBotPopUpMessage: (state, action) => {
            const {
                popupOpen,
                popupType,
                popupMessage
            } = action.payload;

            state.popupOpen = popupOpen ? popupOpen : false;
            if(popupOpen){
                if(popupType) state.popupType = popupType
                if(popupMessage) state.popupMessage = popupMessage
            }
        },
    }
})

export const {
    setTrainingData,
    deleteTrainingData,
    createTrainingDataForm,
    removeTrainingDataForm,
    appendSavedTrainingData,
    setSelectedTrainingData,
    closeTrainginDataReviewsPanel,
    setBotPopUpMessage,
    updateTrainingData
} = botSlice.actions;

export const actionCloseTrainginDataReviewsPanel = (open=false) => dispatch => {
    dispatch(closeTrainginDataReviewsPanel(open))
}

export const actionGetTrainingDataReviews = trainingDataId => dispatch => {
    const headers = getHeaders();
    if(headers === null) return
    
    Axios.get(
        `${URI}/bot/get-training-data-reviews/${trainingDataId}`,
        headers
    ).then(res=>{
        dispatch(setSelectedTrainingData(res.data.trainingData))
    })
    
    
}

export const actionGetTrainingData = () => (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return
    
    Axios.get(
        `${URI}/bot/all-training-data`,
        headers
    ).then(res=>{
        dispatch(setTrainingData(res.data))
    })
}

export const actionCreateTrainingDataForm = () => (dispatch,getState) => {
    const {user} = getState();
    dispatch(createTrainingDataForm(user.userData))
}

export const actionRemoveTrainingDataForm = formId => (dispatch,getState) => {
    dispatch(removeTrainingDataForm(formId))
}

export const actionCreateTrainingData = trainingData => (dispatch) => {
    const headers = getHeaders();
    if(headers === null) return
    
    Axios.post(
        `${URI}/bot/create-training-data`,
        trainingData,
        headers
    ).then(res=>{
        dispatch(appendSavedTrainingData(res.data))
    })
}

export const actionUpdateTrainingData = trainingData => (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return
    
    Axios.put(
        `${URI}/bot/update-training-data`,
        trainingData,
        headers
    ).then(res=>{
        dispatch(updateTrainingData(res))
    })
}

export const actionDeleteTrainingData = trainingDataId => (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return

    Axios.delete(
        `${URI}/bot/${trainingDataId}`,
        headers
    ).then(res=>{
        dispatch(deleteTrainingData(trainingDataId))
    })
}

export const actionRateTrainingData = ratingData => (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return

    Axios.post(
        `${URI}/bot/rate-training-data`,
        ratingData,
        headers
    ).then(res=>{
        // dispatch(deleteTrainingData(trainingDataId))
    })
    
}

export const actionHideBotPopupMessage = () => dispatch => {
    dispatch(setBotPopUpMessage({popupOpen: false}))
}

export const actionTrainBot = () => dispatch => {
    Axios.get(
        `https://wingenio.world/bigbrotrain`
    ).then(res=>{
        dispatch(
            setBotPopUpMessage({
                popupOpen: true,
                popupType: "error",
                popupMessage: "This functionality is still on going"
            })
        )
    })
}

export default botSlice.reducer;