//import PlanningTaskRow from '.components/PlanningTaskRow/PlanningTaskRow';//cut it and paste to the component
import React, {useRef,useCallback} from 'react';
import Rating from '@mui/material/Rating';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import {
    actionSetPlanTaskList,
    actionRemoveTaskToPlanTaskList
} from '../../app/Reducers/planning/planningSlice';
import debounce from "lodash.debounce";

const PlanningTaskRow = ({
    setTotalEstimatedTime,
    note,
    index
}) => {

    const {
        planTaskList
    } = useSelector(state => state.planning);
    
    const dispatch = useDispatch();

    const searchInputRef = useRef(null)

    const handleTextChange = useCallback(debounce((textSearch)=>{
        console.log(textSearch)
        dispatch(
            actionSetPlanTaskList(
            planTaskList.map(n => n.id === note.id ? {
                                ...n,
                                taskName: textSearch
                            } : n )
            )
        );
    }, 500), []);

    const onChange = (event) => {
        handleTextChange(event.target.value);
    };

    const onTimeEstimateChange = useCallback((e)=>{

        const updatedTaskData = [...planTaskList].map(n => n.id === note.id ? {
                                    ...n,
                                    estimate: Number(e.target.value)
                                } : n );

        dispatch(actionSetPlanTaskList(updatedTaskData));
        const tTime = updatedTaskData.reduce((a,c)=>a+c.estimate,0);
        setTotalEstimatedTime(tTime)    
    },[planTaskList]);


    const handleRatingChange = useCallback((e) => {
        dispatch(
            actionSetPlanTaskList(
            planTaskList.map(n => n.id === note.id ? {
                                ...n,
                                priority: Number(e.target.value)
                            } : n )
        ));
    },[planTaskList])

    return <TableRow
    className="estimate-row-data"
    key={note.id}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell align="left">
            <TextField
            type="text"
            sx={{width:"100%"}}
            variant="outlined"
            size="small"
            label="Task Name"
            defaultValue={note.taskName}
            inputRef={searchInputRef}
            onChange={onChange}
            />    
            {/* {note.taskName} */}
        </TableCell>
        <TableCell align="center">
            <input
            type="number"
            className="estimate"
            value={note.estimate}
            onChange={e=>onTimeEstimateChange(e)}/>
        </TableCell>
        <TableCell align="center">
            <Rating
                name="simple-controlled"
                size="small"
                value={note.priority}
                onChange={e=>handleRatingChange(e)}
            />    
        </TableCell>
        <TableCell align="center">
            <Tooltip title="Remove">
                <IconButton size="small" onClick={()=>{dispatch(actionRemoveTaskToPlanTaskList(index))}}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </TableCell>
    </TableRow>
}

export default PlanningTaskRow;