//import Followers from '.components/Followers/Followers';//cut it and paste to the component
import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
import './Followers.scss';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {
	actionGetPeaopleYouMayKnow,
	actionGetFollowersAndFollowing,
} from '../../app/Reducers/user/userSlice';
import UserCard from '../../components/UserCard/UserCard';

const Followers = ({

}) => {

	const dispatch = useDispatch();

	const {
		following,
		followers,
		suggestedUsers
	} = useSelector(state => state.user);

	useEffect(()=>{
		dispatch(actionGetPeaopleYouMayKnow())
		dispatch(actionGetFollowersAndFollowing())
	},[])

	const follow = userId => {
		console.log("follow "+userId)
	}

	return <div className='FollowersContainer'>
		<h1 className='section-title-01'>My Connections</h1>

		<div className="followLayoutContainer">
			<h3 className='section-title-04'>Followers</h3>
			<div className="suggestedPeopleList">
				{
				followers.length ?
				followers.map(user=><UserCard key={user._id} user={user} showFollowButton={true}/>) :
				<p>No followers to show</p>
				}
			</div>
		</div>

		<div className="followLayoutContainer">
			<h3 className='section-title-04'>Following</h3>
			<div className="suggestedPeopleList">
				{
				following.length ?
				following.map(user=><UserCard key={user._id} user={user} showFollowButton={true}/>) :
				<p>No following to show</p>
				}
			</div>
		</div>

		<div className="followLayoutContainer">
			<h3 className='section-title-04'>People you may know</h3>
			<div className="suggestedPeopleList">
				{suggestedUsers.map(user=><UserCard key={user._id} user={user} showFollowButton={true}/>)}
			</div>
		</div>
	</div>
}

export default memo(Followers);