//import ChatBox from '.components/ChatBox/ChatBox';//cut it and paste to the component
import React, {useState,useEffect,useRef} from 'react';
import './ChatBox.scss';
import {v1 as uuid} from "uuid";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from 'react-redux';
import {
	actionSendMessageToBot,
	actionCloseBotChatBox
} from '../../app/Reducers/chats/chatsSlice';
import { ReactComponent as LogoIcon } from '../../Assets/ic_logo.svg';
import CloseIcon from '@mui/icons-material/Close';
import {
	actionAddTodoAndStart
} from '../../app/Reducers/todo/todoSlice'
import ConfirmationDialogRaw from '../../components/ConfirmationDialogRaw/ConfirmationDialogRaw';
import RioResponse from "./RioResponse";
import RateBotReply from "../RateBotReply/RateBotReply";


const schema = yup.object().shape({
	chat: yup.string()
});


const RioChatBox = ({
	chatData
}) => {

	const chatThreadRef = useRef()
	const dispatch = useDispatch()
	const [open, setOpen] = React.useState(false);
	const [youtubeData, setyoutubeData] = useState(null)
	const [linkData, setlinkData] = useState(null)
	const [dialogTitle, setDialogTitle] = useState("")
	const [dialogDescription, setDialogDescription] = useState("")
	const [dialogFor, setDialogFor] = useState("video")
	const [selectedDialogData, setSelectedDialogData] = useState()
	const [botFeedBackRatingsOpen,setBotFeedBackRatingsOpen] = useState(false)
	

	// form setup
	const { control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
		defaultValues: {
			message:""
		},
		resolver: yupResolver(schema)
	});

	const onSubmit = async ({message}) => {

		reset()
		dispatch(actionSendMessageToBot(message))
		
	}

	const createTodoOnWatch = () => {
		console.log(selectedDialogData)

		let todoData = {
            name: `Watch ${youtubeData.snippet.title}`,
            description: `
			${youtubeData.snippet.description}
			Link: https://www.youtube.com/watch?v=${youtubeData.id.videoId}
			`
        }

		const trainingData = selectedDialogData?.responseData?.trainingDataId;
		if(trainingData){
			const userInquiryToBot = selectedDialogData.userMessage;
			const botResponse = `${selectedDialogData.responseData.response.answer} https://www.youtube.com/watch?v=${youtubeData.id.videoId}`
			todoData = {
				...todoData,
				trainingData,
				userInquiryToBot,
				botResponse,
				linkFromThirdParty: `https://www.youtube.com/watch?v=${youtubeData.id.videoId}`
			}
		}

		console.log(todoData)

		// console.log(youtubeData)
		// setDialogFor("video")

		dispatch(actionAddTodoAndStart(todoData))

		window.open(`https://www.youtube.com/watch?v=${youtubeData.id.videoId}`)
	}

	const createTodoOnRead = () => {

		let todoData = {
            name: `Reading this link ${linkData}`,
            description: `${linkData}`
        }
		const trainingData = selectedDialogData?.responseData?.trainingDataId;

		if(trainingData){	
			const userInquiryToBot = selectedDialogData.userMessage;
			let botResponse = selectedDialogData.responseData.response.answer;

			if(dialogFor === "link"){
				botResponse = `${botResponse} ${linkData}`
			}
			
			todoData = {
				...todoData,
				trainingData,
				userInquiryToBot,
				botResponse
			}
		}
		console.log(todoData)

		dispatch(actionAddTodoAndStart(todoData))

		window.open(`${linkData}`)
	}

	// const openWatchDialogBox = (suggestion) => {
	// 	setOpen(true)
	// 	setyoutubeData(suggestion)
	// 	setDialogTitle("Create todo for watching this video?")
	// 	setDialogDescription("After watching this, make sure you end your todo in calendar and start your previous task.")
	// }

	// const getYoutubeMessage = customMessageYT => {
	// 	return <div key={customMessageYT.id.videoId} className="suggestion-container">
	// 		<img className="yt-suggestion-image"
	// 		src={customMessageYT.snippet.thumbnails.medium.url} alt={customMessageYT.snippet.title} />
	// 		<p className="yt-suggestion-title">{customMessageYT.snippet.title}</p>
	// 		<p className="yt-suggestion-description">{customMessageYT.snippet.description}</p>
	// 		{/* <a href={`https://www.youtube.com/watch?v=${customMessageYT.id.videoId}`} className="">{`https://www.youtube.com/watch?v=${suggestion.id.videoId}`}</a> */}
	// 		<Button
	// 		className="watch"
	// 		variant="contained"
	// 		type="button"
	// 		onClick={()=>openWatchDialogBox(customMessageYT)}>
	// 			Watch
	// 		</Button>
	// 	</div>
	// }

	const onChatboxClose = () => dispatch(actionCloseBotChatBox(chatData._id))

	useEffect(() => {
		console.log("Dumaan here")
		// if (chatThreadRef) {
		// 	chatThreadRef.current?.addEventListener('DOMNodeInserted', event => {
		// 		const { currentTarget: target } = event;
		// 		target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
		// 	});
		// }
		// if(chatData.conversation.length){
		// 	console.log("********************")
		// 	console.log(chatData.conversation.at(-1).withSelection)
		// 	// dispatch(actionRemoveOptionsInChatbox())
		// }
	},
	[chatData.conversation])

	return <div className='ChatBoxContainer'>
		
		<div className="chat-box-header">
			<div className="user-icon">
				<LogoIcon sx={{color: "#fff"}}/>
			</div>
			<p className="name">
				<a href="">Rio</a>
			</p>
			<span className="close" onClick={()=>onChatboxClose()}><CloseIcon/></span>
		</div>

		<div
		className="conversation-container"
		ref={chatThreadRef}>
			
			{chatData.conversation.map(dialog => {

				const { 
					_id,
					message,
					isSender,
					ratingsEnabled
				}  = dialog;

				return <div key={_id} className={`chat-dialog ${isSender ? "sender" : ""} ${ratingsEnabled ? "rating-enabled" : ""}`}>
					{
					isSender ? <>
						<p className="message">{message}</p>
					</> : <>
						<RioResponse
						dialog={dialog}
						// getYoutubeMessage={getYoutubeMessage}
						setDialogFor={setDialogFor}
						setlinkData={setlinkData}
						setOpen={setOpen}
						setDialogTitle={setDialogTitle}
						setDialogDescription={setDialogDescription}
						setSelectedDialogData={setSelectedDialogData}
						setBotFeedBackRatingsOpen = {setBotFeedBackRatingsOpen}
						setyoutubeData={setyoutubeData}/>
					</>
					}
				</div>
			})}

		</div>

		<div className="chatbox-form-container">
			<form className="chatbox-form-container-wrapper" onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="message"
					control={control}
					defaultValue={""}
					render={({field}) => <TextField
						{...field}
						className="textArea"
						label="Message"
						variant="outlined"
						placeholder={"Message"}
						error={!!errors.message}
						type="text"
						size="small"
						sx={{ width: '100%' }}
						helperText={errors?.message ? errors?.message?.message : ''}
					/>
				}/>
				<Button
				type="submit"
				variant="outlined">Send</Button>
			</form>
		</div>
		<RateBotReply
			botFeedBackRatingsOpen = {botFeedBackRatingsOpen}
			setBotFeedBackRatingsOpen = {setBotFeedBackRatingsOpen}
			selectedDialogData={selectedDialogData}
		/>


		<ConfirmationDialogRaw 
			onClose={()=>setOpen(false)}
			open={open}
			callBack={dialogFor === "video" ? createTodoOnWatch : createTodoOnRead}
			dialog={dialogTitle}
			description={dialogDescription}
		/>
	</div>
}

export default RioChatBox;