import React, {useState} from 'react';
import { getMomentType } from '../Hooks/Constants';

const MomentSet = ({
  title,
  moments,
  filterFunction,
  showSubjectTitleOnMobile = true
}) => {

	return <>
    {showSubjectTitleOnMobile ? <p className='cellHeading' style={{color: '#637381', fontSize: '0.85rem'}}>{title}</p> : ''}
    {moments ? <div className='cellMomentList'>
      {
        moments
        .filter(filterFunction)
        .map(moment => (<div key={moment._id} className="cellMomentData">
          <span className="moment-type">{getMomentType(moment.momentType)}</span>
          <p key={moment._id}>{moment.moment}</p>
        </div>
        ))
      }
    </div> : ""}
  </>
}

export default MomentSet;