import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { actionRequestForgotPassword } from "../../app/Reducers/user/userSlice";
import Stack from "@mui/material/Stack";
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { 
	actionAddTodoCategory
	// actionCreateTodoCategory
} from "../../app/Reducers/todo/todoSlice";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 2,
};

const schema = yup.object().shape({
	name: yup.string().required("Provide valid name"),
	description: yup.string().required("Provide valid description"),
	// owner: yup.string().required("Provide valid owner").owner(),
	// colorData: yup.string().required("Provide valid colorData").colorData(),
});

const CreateTodoCategoryForm = ({
	showCreateCategoryForm = false,
	setShowCreateCategoryForm
}) => {
	const dispatch = useDispatch();

	// form setup
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isSubmitSuccessful },
	} = useForm({
		defaultValues: {
			name: "",
			description: "",
			// owner: "",
			// colorData: "",
		},
		resolver: yupResolver(schema),
	});

	const onSubmit = ({ name, description }) => {
		dispatch(actionAddTodoCategory({
			name,
			description
		}));
		setShowCreateCategoryForm(false)
	};

	return (
		<div className="CreateTodoCategoryFormContainer form-wrapper">
			<Modal
				keepMounted
				open={showCreateCategoryForm}
				onClose={()=>setShowCreateCategoryForm(false)}
				aria-labelledby="keep-mounted-modal-title"
				aria-describedby="keep-mounted-modal-description"
			>
        <Box sx={style} spacing={2}>
					<form className="form-container" onSubmit={handleSubmit(onSubmit)}>

						<h2 className="title">Create Note Category</h2>

						<Stack direction="column" alignItems="center" spacing={2}>
							<RHFTextField
								control={control}
								name="name"
								label="name"
								placeholder="name"
								error={!!errors.name}
								helperText={errors?.name ? errors?.name?.message : ""}
							/>

							<RHFTextField
								control={control}
								name="description"
								label="description"
								placeholder="description"
								error={!!errors.description}
								helperText={errors?.description ? errors?.description?.message : ""}
							/>

							<Stack spacing={1} direction="row" justifyContent="center">
								<Button type="submit" variant="contained">
									Submit
								</Button>
								<Button
								onClick={()=>setShowCreateCategoryForm(false)}
								type="button"
								variant="contained">
									Cancel
								</Button>
							</Stack>
						</Stack>
					</form>
				</Box>
			</Modal>
		</div>
	);
};

export default CreateTodoCategoryForm;
