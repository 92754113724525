import React from "react";
import ReactDom from "react-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import UserSelectionItem from "./UserSelectionItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const UsersListSelectionActions = ({
  userList = [],
  open = false,
  title="Join Requests",
  onUserAccept,
  onUserDecline,
  onUserRemove,
  onClose,
  exceptedUsersFromRemoval=[]
}) => {
  return ReactDom.createPortal(<>
      <div className="GroupFormContainer">
        <Modal
          keepMounted
          open={open}
          onClose={() => onClose()}
          aria-labelledby="modal-Group"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            style={{marginBottom:10}}>
              <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                {title}
              </Typography>
              <CloseIcon className="close-form-trigger" onClick={()=>onClose()}/>
            </Stack>
            <div className="userListContainer">
              {
              userList.length ?
                userList.map(user => {
                  if(exceptedUsersFromRemoval.findIndex(userId => userId === user._id) === -1)
                    return <UserSelectionItem
                            key={user._id}
                            user={user}
                            onUserAccept={onUserAccept}
                            onUserDecline={onUserDecline}
                            onUserRemove={onUserRemove}
                            />
                  return <UserSelectionItem
                    key={user._id}
                    user={user}
                    isAdmin={true}
                  />
                })
                :
                <p>No join request yet.</p>
              }
            </div>
          </Box>
        </Modal>
      </div>
    </>,
    document.getElementById("group-users-list-selection-wrapper-portal")
  );
};

export default UsersListSelectionActions;
// Put this on the home page: <div id="create-group-form-wrapper-portal"></div>
