import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const initialState = {
	
	isPostsLoading: true,
	createPostOpen: false,
	postForTodo: null,
	postTotalPoints: 0,
	postWithPointsCount: 0,
	showPostModal: false,
	selectedDataFor: "post",
	associatedPost: null,
	

	// edit post
	editPostFormOpen: false,
	postToEdit: null,

	// report post functionality
	selectedPost: null,
	showReportPost: false,

	// list of users post pagination
	posts: [],
	hasNextPage: false,
	hasPrevPage: false,
	limit: 10,
	nextPage: null,
	page: 1,
	pagingCounter: 1,
	prevPage: null,
	totalDocs: 0,
	totalPages: 1,

	// list of users group post pagination
	classroomPosts: [],
	classroomhasNextPage: false,
	classroomhasPrevPage: false,
	classroomlimit: 10,
	classroomnextPage: null,
	classroompage: 1,
	classroompagingCounter: 1,
	classroomprevPage: null,
	classroomtotalDocs: 0,
	classroomtotalPages: 1,

	// list of users group post pagination
	groupPosts: [],
	grouphasNextPage: false,
	grouphasPrevPage: false,
	grouplimit: 10,
	groupnextPage: null,
	grouppage: 1,
	grouppagingCounter: 1,
	groupprevPage: null,
	grouptotalDocs: 0,
	grouptotalPages: 1,

	// list of users global post pagination
	globalPosts: [],
	globalhasNextPage: false,
	globalhasPrevPage: false,
	globallimit: 10,
	globalnextPage: null,
	globalpage: 1,
	globalpagingCounter: 1,
	globalprevPage: null,
	globaltotalDocs: 0,
	globaltotalPages: 1,

	// post sidenav data
	postInSideNav: null,
	isSideNavOpen: false,
	isPostListLoading: false,
	isPostDetailsLoading: false,

	// popup messages: 
	popupType: "error",
	popupOpen: false,
	popupMessage: "Error",

	// data to show in the admin dashboard
	showUserPostsWithPointsData: false,
	adminDashboardSelectedUserId: null,
	adminDashboardSelectedUser: null,
};

export const postSlice = createSlice({
	name: 'post',
	initialState,
	reducers: {
		setPostPointsWithCount: (state, action) => {
			if(action.payload.length){
				const {
					totalPoints = 0,
					count = 0
				} = action.payload[0]
				state.postTotalPoints = totalPoints;
				state.postWithPointsCount = count;
			}
		},
		addPost: (state, action) => {
			state.posts = [action.payload, ...state.posts]
			state.globalPosts = [action.payload, ...state.globalPosts]
		},
		setPosts: (state, action) => {
			state.groupPosts = [] // empty the group posts
			state.globalPosts = [] // empty the global posts
			if(action.payload.page == 1){
				state.posts = action.payload.docs
			}
			else{
				state.posts = [...state.posts, ...action.payload.docs]
				.filter(
					(element, index, self) => self
																	.findIndex(s => s._id === element._id) === index
				)
			}
			state.isPostsLoading = false
			state.hasNextPage = action.payload.hasNextPage
			state.hasPrevPage = action.payload.hasPrevPage
			state.limit = action.payload.limit
			state.nextPage = action.payload.nextPage
			state.page = action.payload.page
			state.pagingCounter = action.payload.pagingCounter
			state.prevPage = action.payload.prevPage
			state.totalDocs = action.payload.totalDocs
			state.totalPages = action.payload.totalPages
		},
		deleteProfilePagePost: (state, action) => {
			const postId = action.payload._id;
			state.posts = [...state.posts].filter(post => post._id !== postId);
		},
		deleteGroupPost: (state, action) => {
			const postId = action.payload._id;
			state.groupPosts = [...state.groupPosts].filter(post => post._id !== postId);
		},
		deleteGlobalPost: (state, action) => {
			const postId = action.payload._id;
			state.globalPosts = [...state.globalPosts].filter(post => post._id !== postId);
		},
		updateProfilePagePost: (state, action) => {
			const postId = action.payload._id;
			state.posts = [...state.posts].map(post => post._id == postId ? action.payload : post)
			state.editPostFormOpen = false
    	state.postToEdit = null
		},
		updateGroupPost: (state, action) => {
			const postId = action.payload._id;
			state.groupPosts = [...state.groupPosts].map(post => post._id == postId ? action.payload : post)
			state.editPostFormOpen = false
    	state.postToEdit = null
		},
		updateGlobalPost: (state, action) => {
			const postId = action.payload._id;
			state.globalPosts = [...state.globalPosts].map(post => post._id == postId ? action.payload : post)
			state.editPostFormOpen = false
    	state.postToEdit = null
		},
		setGroupPosts: (state, action) => {
			state.posts = [] // empty the personal posts
			state.globalPosts = [] // empty the global posts
			state.groupPosts = [...state.groupPosts, ...action.payload.docs]
			.filter(
				(element, index, self) => self
																.findIndex(s => s._id === element._id) === index
			)
			state.isPostsLoading = false
			state.grouphasNextPage = action.payload.hasNextPage
			state.grouphasPrevPage = action.payload.hasPrevPage
			state.grouplimit = action.payload.limit
			state.groupnextPage = action.payload.nextPage
			state.grouppage = action.payload.page
			state.grouppagingCounter = action.payload.pagingCounter
			state.groupprevPage = action.payload.prevPage
			state.grouptotalDocs = action.payload.totalDocs
			state.grouptotalPages = action.payload.totalPages
		},
		setGlobalPosts: (state, action) => {
			state.posts = [] // empty the personal posts
			state.groupPosts = [] // empty the group posts
			state.isPostsLoading = false
			state.globalPosts = [...state.globalPosts, ...action.payload.docs]
			.filter(
				(element, index, self) => self
																.findIndex(s => s._id === element._id) === index
			)
			state.globalhasNextPage = action.payload.hasNextPage
			state.globalhasPrevPage = action.payload.hasPrevPage
			state.globallimit = action.payload.limit
			state.globalnextPage = action.payload.nextPage
			state.globalpage = action.payload.page
			state.globalpagingCounter = action.payload.pagingCounter
			state.globalprevPage = action.payload.prevPage
			state.globaltotalDocs = action.payload.totalDocs
			state.globaltotalPages = action.payload.totalPages
		},
		setClassroomPosts: (state, action) => {
			state.groupPosts = [] // empty the group posts
			state.globalPosts = [] // empty the global posts
			state.posts = [] // empty the personal posts
			if(action.payload.page == 1){
				state.classroomPosts = action.payload.docs
			}
			else{
				state.classroomPosts = [...state.classroomPosts, ...action.payload.docs]
				.filter(
					(element, index, self) => self
																	.findIndex(s => s._id === element._id) === index
				)
			}
			state.isPostsLoading = false
			state.classroomhasNextPage = action.payload.hasNextPage
			state.classroomhasPrevPage = action.payload.hasPrevPage
			state.classroomlimit = action.payload.limit
			state.classroomnextPage = action.payload.nextPage
			state.classroompage = action.payload.page
			state.classroompagingCounter = action.payload.pagingCounter
			state.classroomprevPage = action.payload.prevPage
			state.classroomtotalDocs = action.payload.totalDocs
			state.classroomtotalPages = action.payload.totalPages
		},
		setPostRequestLoading: (state, action) => {
			state.isPostsLoading = action.payload
		},
		setPostPopUpMessage: (state, action) => {
			const {
					popupOpen,
					popupType,
					popupMessage
			} = action.payload;

			state.popupOpen = popupOpen ? popupOpen : false;

			if(popupOpen){
				if(popupType) state.popupType = popupType
				if(popupMessage) state.popupMessage = popupMessage
			}

			state.isPostsLoading = false
		},
		addLoadedComments: (state, action) => {
			const {
				commentDatas,
				postId
			} = action.payload;

			let comments = [...commentDatas.docs]
			const {
				hasNextPage,
				hasPrevPage,
				limit,
				nextPage,
				page,
				pagingCounter,
				prevPage,
				totalDocs,
				totalPages,
			} = commentDatas;

			state.posts = [...state.posts].map(post => {

				if(post._id === postId){
					post.comments = [...post.comments, ...comments]
					Object.assign(
						post,
						{
							commentLoadMore:{
								hasNextPage,
								hasPrevPage,
								limit,
								nextPage,
								page,
								pagingCounter,
								prevPage,
								totalDocs,
								totalPages,
							}
						}
					);
					
				}
				return post
			})
			state.isPostsLoading = false
		},
		addGroupPost: (state, action) => {
			state.groupPosts = [action.payload, ...state.groupPosts]
		},
		addClassroomPost: (state, action) => {
			state.classroomPosts = [action.payload, ...state.classroomPosts]
		},
		createPostOpen: (state, action) => {
			console.log(action.payload)
			state.createPostOpen = action.payload.open
			state.postForTodo = action.payload.postForTodo
		},
		toggleReportPostForm: (state, action) => {
			state.showReportPost = action.payload.open
			state.selectedPost = action.payload.postId
		},
		toggleEditPostForm: (state, action) => {
			state.editPostFormOpen = action.payload.open
			state.postToEdit = action.payload.postToEdit
		},
		toggleUserPostsWithPointsData: (state,action) => {
			state.showUserPostsWithPointsData = action.payload.open
			state.adminDashboardSelectedUserId = action.payload.user ? action.payload.user._id : null
			state.adminDashboardSelectedUser = action.payload.user ? action.payload.user : null
		},
		togglePostDetails: (state,action) => {
			const {
				open = false,
				post = null,
				selectedDataFor = "post"
			} = action.payload
			state.showPostModal = open;
			state.selectedPost = post;
			state.selectedDataFor = selectedDataFor;
		},
		setAssociatedPost: (state,action) => {
			state.associatedPost = action.payload
		},
		setPostInSideNav: (state, action) => {
			state.postInSideNav = action.payload
			state.isSideNavOpen = true
			state.isTodoListLoading = false
			state.isTodoDetailsLoading = false
		},
		openSideNav: (state,action) => {
			state.isSideNavOpen = action.payload
			if(action.payload === false){ // means closing the sidenav
				state.postInSideNav = null
			}
		},
	}
});

export const {
	addPost,
	setPosts,
	setGlobalPosts,
	setPostRequestLoading,
	setPostPopUpMessage,
	addLoadedComments,
	setGroupPosts,
	addGroupPost,
	addClassroomPost,
	deleteProfilePagePost,
	deleteGroupPost,
	deleteGlobalPost,
	updateProfilePagePost,
	updateGroupPost,
	updateGlobalPost,
	createPostOpen,
	toggleReportPostForm,
	setPostPointsWithCount,
	toggleEditPostForm,
	toggleUserPostsWithPointsData,
	togglePostDetails,
	setClassroomPosts,
	setAssociatedPost,
	setPostInSideNav,
	openSideNav
} = postSlice.actions;

const showErrorType = (err,dispatch) => {
	if (err.response) {
		dispatch(setPostPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: err.response.data
			}
		))
	} else if (err.request) {
		console.log(err.response)
		dispatch(setPostPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: 'Server error. No response received, request may have timed out, been blocked or server is down.'
			}
		))
	} else {
		dispatch(setPostRequestLoading(false))
		console.log('Error:', err.message);
	}
}

export const actionToggleUserPostsWithPointsData = (
	open = true,
	user = null
) => dispatch => {
	dispatch(toggleUserPostsWithPointsData({open, user}))
}

export const actionTogglePostDetails = ({
	open = false,
	post = null,
	postId = null,
	selectedDataFor = "post"
}) => async (dispatch) => {

	const headers = getHeaders();
	
	if(headers === null) return;

	if(open){
		if(postId){
			try{
				const res = await Axios.get( 
					`${URI}/posts/get-post-details/${postId}`,
					headers
				)
				console.log(res.data)
				dispatch(togglePostDetails({
					open,
					post: res.data ? res.data.docs[0] : null,
					selectedDataFor
				}))
			}
			catch(err){
				showErrorType(err, dispatch)
			}
		}
		else{
			dispatch(togglePostDetails({ open, post }))
		}
	}
	else{
		dispatch(togglePostDetails({ open, post }))
	}
	
}

export const actionLoadMoreComments = ({
	postId,
	page,
	limit
}) => async (dispatch, getState) => {

	const {user} = getState();
	const headers = getHeaders();
	
	if(headers === null) return;

	try{
		// dispatch(setPostRequestLoading(true))

		let query = `&postId=${postId}`;
		if(page)				{	query+=`&page=${page}` }
		if(limit)				{ query+=`&limit=${10} `}
		if(query.length){ query = query.substring(1); }
		
		const res = await Axios.get( 
				`${URI}/comments/load-more-comments?${query}`,
				headers
		)
		console.log(res)
		dispatch(addLoadedComments({
			commentDatas: res.data,
			userData: user.userData,
			postId
		}))
	}
	catch(err){
		showErrorType(err, dispatch)
	}

}

export const actionCreatePost = (data,file) => async (dispatch,getState) => {

	let headers = getHeaders();
	if(headers === null) return

	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	try{

		let formData = new FormData();

		formData.append('userId', data.userId);
		formData.append('postDescription', data.postDescription);
		formData.append('energy', data.energy);
		formData.append('emotion', data.emotion);
		formData.append('postImage', data.postImage);
		formData.append('startTimeToWritePosts', data.startTimeToWritePosts);
		if(data.group){
			formData.append('group', data.group);
		}
		if(data.classroom){
			formData.append('classroom', data.classroom);
		}
		if(data.todo){
			formData.append('todo', data.todo);
		}
		
		// check if there is image data to be send
		if(file){
			formData.append('image', file);
		}

		const res = await Axios.post(
			`${URI}/posts/create-post`,
			formData,
			headers
		);
		
		console.log(res.data)
		setTimeout(() => {
			if(data.classroom){ // add to group posts
				dispatch(addClassroomPost({
					...res.data,
					newPost:true
				}))
			}
			else if(data.group){ // add to group posts
				dispatch(addGroupPost({
					...res.data,
					newPost:true
				}))
			}
			else{ // add to individual posts
				dispatch(addPost({
					...res.data,
					newPost:true
				}))
			}
		}, 1000);
		
	} catch(err){
		console.log(err)
	}
}

export const actionGetPosts = (userId, page) => async (dispatch) => {
	const headers = getHeaders();
	if(headers === null) return
	// const {post} = getState();

	// dispatch(setTodoListLoading(true))
	try{
		dispatch(setPostRequestLoading(true))
		
		let cancel
		const params = `?page=${page}`
		const res = await Axios.get(
			`${URI}/posts/get-user-posts/${userId}${params}`,
			{
				...headers,
				cancelToken: new Axios.CancelToken(c => cancel = c)
			}
		)
		console.log(res.data)

		dispatch(setPosts(res.data))
	}
	catch(err){
		console.log(err)
		showErrorType(err, dispatch)
	}
}

export const actionGetGroupPosts = (groupId, page) => async (dispatch) => {
	const headers = getHeaders();
	if(headers === null) return

	// dispatch(setTodoListLoading(true))
	try{
		dispatch(setPostRequestLoading(true))
		
		let cancel
		const params = `?page=${page}`
		const res = await Axios.get(
				`${URI}/posts/get-group-posts/${groupId}${params}`,
				{
					...headers,
					cancelToken: new Axios.CancelToken(c => cancel = c)
				}
		)
		console.log(res.data)

		dispatch(setGroupPosts(res.data))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionGetClassroomPosts = (classroomId, page) => async (dispatch) => {
	const headers = getHeaders();
	if(headers === null) return
	
	try{
		dispatch(setPostRequestLoading(true))
		
		let cancel
		const params = `?page=${page}`
		const res = await Axios.get(
			`${URI}/posts/get-classroom-posts/${classroomId}${params}`,
			{
				...headers,
				cancelToken: new Axios.CancelToken(c => cancel = c)
			}
		)

		dispatch(setClassroomPosts(res.data))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionGetGlobalPosts = (page) => async (dispatch) => {
	const headers = getHeaders();
	if(headers === null) return

	try{
		dispatch(setPostRequestLoading(true))
		let cancel
		const params = `?page=${page}`
		const res = await Axios.get(
				`${URI}/posts/get-global-posts${params}`,
				{
					...headers,
					cancelToken: new Axios.CancelToken(c => cancel = c)
				}
		)
		dispatch(setGlobalPosts(res.data))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionShowReportPost = (open = false, postId = null) => async (dispatch,getState) => {
	dispatch(toggleReportPostForm({open,postId}))
}

export const actionDeletePost = postId => async (dispatch,getState) => {
	const {post} = getState();
	const headers = getHeaders();
    if(headers === null) return

	const res = await Axios.delete(
		`${URI}/posts/delete-post/${postId}`,
		headers
	)
	
	if(post.posts.length){
		dispatch(deleteProfilePagePost(res.data))
	}
	else if(post.groupPosts.length){
		dispatch(deleteGroupPost(res.data))
	}
	else if(post.globalPosts.length){
		dispatch(deleteGlobalPost(res.data))
	}
}

export const actionCreatePostOpen = (open, postForTodo=null) => async dispatch => {
	dispatch(createPostOpen({
		open,
		postForTodo
	}))
}

export const actionGetPostsOverAllScore = userId => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return
	const res = await Axios.get(
		`${URI}/posts/get-post-points-total/${userId}`,
		headers
	)
	dispatch(setPostPointsWithCount(res.data))
}

export const actionToggleEditPostForm = (
	open = true,
	postToEdit = null
) => async dispatch => {
	dispatch(toggleEditPostForm({
		open,
		postToEdit
	}))
}

export const actionSubmitEditPost = (data,file) => async (dispatch,getState) => {
	let headers = getHeaders();
	if(headers === null) return
	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}
	const {post} = getState();
	const {
		postDescription,
		energy,
		postId,
	} = data
	try{
		let formData = new FormData();
		if(postDescription) formData.append('postDescription', postDescription);
		if(energy) 					formData.append('energy', energy);
		if(postId) 					formData.append('postId', postId);
		if(file)						formData.append('image', file);
		
		const res = await Axios.put(
			`${URI}/posts/edit-post`,
			formData,
			headers
		);
		setTimeout(() => {
			if(post.posts.length){
				dispatch(updateProfilePagePost(res.data))
			}
			else if(post.groupPosts.length){
				dispatch(updateGroupPost(res.data))
			}
			else if(post.globalPosts.length){
				dispatch(updateGlobalPost(res.data))
			}
		}, 1000);
	} catch(err){
		console.log(err)
	}
}

export const actionGetAssociatedPost = (postId) => async (dispatch,getState) => {
	
	const headers = getHeaders();
	if(headers === null) return;

	try{
		if(postId){
			const res = await Axios.get( 
				`${URI}/posts/get-post-details/${postId}`,
				headers
			)
			console.log(res.data.docs[0])
			dispatch(setAssociatedPost(res.data.docs[0]))
		}
		else{
			dispatch(setAssociatedPost(null))
		}
	
	} catch(err){
		dispatch(setAssociatedPost(null))
		console.log(err)
	}
}

/**/

export const actionOpenPostSideNav = isOpen => dispatch => {
	dispatch(openSideNav(isOpen))
}

export const actionSetPostInSideNav = postId => async (dispatch, getState) => {

	const headers = getHeaders();
	if(headers === null) return
			
	// dispatch(setShowTodoDetailsPanelWithLoading())
	try{
		const res = await Axios.get(
			`${URI}/posts/get-post-details/${postId}`,
			headers
		)
		console.log(res.data)
		
		dispatch(setPostInSideNav(res.data ? res.data.docs[0] : null))
		
	} catch(err){
		showErrorType(err, dispatch)
	}

};
/**/

export default postSlice.reducer;