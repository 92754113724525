import React, {useEffect} from 'react';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {
  actionChangePassword,
  actionHideForgotPassword
} from '../../app/Reducers/user/userSlice';
import Stack from '@mui/material/Stack';
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  verificationCode:
    yup
    .string()
    .required('Verification code is required. Check your email.'),
  password:
    yup
    .string()
    .required('No password provided.') 
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmPassword:
    yup
    .string()
    .required("Confirm password required")
    .oneOf([yup.ref('password')], 'Passwords does not match'),
});

const ForgotPasswordForm = ({
  email,
  setEmail
}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const {
        popupMessage,
        invalidVerificationCode,
        redirect
    } = useSelector(state=>state.user)

    // form setup
    const { control, handleSubmit, reset, formState: {errors,isSubmitSuccessful},setError } = useForm({ 
        defaultValues: {
          password: "",
          confirmPassword: ""
        },
        resolver: yupResolver(schema)
    });

    const onSubmit = ({
      verificationCode,
      password
    }) => {
      dispatch(actionChangePassword({
        email,
        verificationCode,
        password
      }))
    }

    const onCancel = () => {
      dispatch(actionHideForgotPassword())
      setEmail("")
    }

    useEffect(()=>{
      if(invalidVerificationCode){
        setError('verificationCode', {
          type: 'custom',
          message: 'Wrong verification code!'
        });
      }
    },[invalidVerificationCode])

    useEffect(()=>{
      if(redirect){
        console.log("dumaan here")
        navigate("/")
      }
    },[redirect])

    return <div className='ForgotPasswordFormContainer form-wrapper'>
      <form className="moment-create-form form-container" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="title">Change Password</h1>
        <Stack 
        direction="column"
        alignItems="center"
        spacing={2}>
          <RHFTextField
            control={control}
            name="verificationCode"
            label="Verification Code"
            placeholder="Verification Code"
            error={!!errors.verificationCode}
            helperText={errors?.verificationCode ? errors?.verificationCode?.message : ''}
          />
          <RHFTextField
            control={control}
            name="password"
            label="Password"
            placeholder="Password"
            error={!!errors.password}
            helperText={errors?.password ? errors?.password?.message : ''}
            type="password"
          />
          <RHFTextField
            control={control}
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Confirm Password"
            error={!!errors.confirmPassword}
            helperText={errors?.confirmPassword ? errors?.confirmPassword?.message : ''}
            type="password"
          />
          <Stack
          spacing={1}
          direction="row"
          justifyContent="center">
            <Button type="submit" variant="contained">Change</Button>
            <Button
            onClick={onCancel}
            type="button"
            variant="contained">Cancel</Button>
          </Stack>
        </Stack>
      </form>
    </div>
}

export default ForgotPasswordForm;