import React, {useState,memo,useCallback} from 'react';
// import './Todo.scss';
import TodoMoreOptions from '../Todo/TodoMoreOptions';
import TodoActions from '../Todo/TodoActions'
import { useDispatch, useSelector } from 'react-redux';
import {
	actionSetTodoInSideNav
} from '../../app/Reducers/todo/todoSlice';
import {
	getTodoStatusStyle
} from "../Hooks/Constants";

const ThrillingTaskItem = ({
	todo
}) => {

	const dispatch = useDispatch();
	const {
		userData
	} = useSelector(state => state.user)

	return <div className='ThrillingTaskItemContainer goalsWrapper'>
	  <div className="goalItemWrapper">
			<p
			className="goal"
			style={{
				...getTodoStatusStyle(todo.status)
			}}
			onClick={() => {
				dispatch(actionSetTodoInSideNav(todo._id))
			}}>
				{todo.name}
			</p>
			{userData._id === todo.assignedTo ? <>
				<TodoActions todo={todo} iconFontSize={18}/> 
				<TodoMoreOptions todo={todo} iconFontSize={18}/>
			</> : ""}
	  </div>
	</div>

}

export default ThrillingTaskItem;

