import React, {useCallback, useMemo, useState} from 'react';
import { useDispatch } from 'react-redux';
import Todo from '../../components/Todo/Todo';
import UserCardChip from '../../components/UserCard/UserCardChip';
import {
  actionGetUserDashboardDetails,
} from '../../app/Reducers/dashboard/dashboardSlice';
import { Link } from 'react-router-dom';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import ProductivityAndEventContent from '../Dashboard/ProductivityAndEventContent';
import MomentSet from '../../components/Moments/MomentSet';
import { useEffect } from 'react';
import { ReactComponent as DashboardIcon } from '../../Assets/ic_dashboard.svg';
import FeedIcon from '@mui/icons-material/Feed';
// import PostList from '../../components/PostList/PostList';
import TaskTableAndPostTab from '../Dashboard/TaskTableAndPostTab/TaskTableAndPostTab';

const UserDataSectionInfo = ({
  userDashboardData
}) => {
	const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [showPosts, setShowPosts] = useState(false);

  const todoOwner = userDashboardData.userData;
  const todos = userDashboardData.todos;

  // const postList = useMemo(()=><PostList userId={userDashboardData.userData._id}/>,[userId])

  useEffect(()=>{

  },[userDashboardData]);

  const nextTodo = () => {
    const i = index === todos.length - 1 ? 0 : index + 1
    setIndex(i)
  }

  const prevTodo = () => {
    const i = index === 0 ? todos.length - 1 : index - 1
    setIndex(i)
  }
  
  const getMoreInformationOfUser = (userId) => {
    const endDate = new Date();
    const formattedEndDate = `endDate=${moment(endDate).format('YYYY-MM-DD')}`;
    dispatch(actionGetUserDashboardDetails(userId,formattedEndDate))
  }

  const getTodoDetails = (todo) => {
    const moments = todo?.moments ? todo.moments : []
    if(!todo) 
      return ""
    return (
      <div className="latestTodoWrapper">
        <div className="ownerAndTodoDetails">
          <div className="todoWrapper">
            <Todo todo={todo} showDate={true}/>
          </div>
        </div>
        <div className="UserDataSectionInfoMomentWrapper">
          <h3 className='section-title-04'>Moments</h3>
          <div className="momentSetWrapper objectiveMomentWrapper">
            <MomentSet
              title={"Objectives"}
              moments={moments}
              filterFunction={moment => moment.momentType == 6 || moment.momentType == 7}
              showSubjectTitleOnMobile={false}
            />
          </div>
          <div className="momentSetWrapper resultMomentWrapper">
            <MomentSet
              title={"Results"}
              moments={moments}
              filterFunction={moment => moment.momentType == 8 || moment.momentType == 9}
              showSubjectTitleOnMobile={false}
            />
          </div>
          <div className="momentSetWrapper questionMomentWrapper">
            <MomentSet
              title={"Questions"}
              moments={moments}
              filterFunction={moment => moment.momentType == 3 || moment.momentType == 11}
              showSubjectTitleOnMobile={false}
            />
          </div>
          <div className="momentSetWrapper conceptMomentWrapper">
            <MomentSet
              title={"Concepts"}
              moments={moments}
              filterFunction={moment => moment.momentType == 6 || moment.momentType == 7}
              showSubjectTitleOnMobile={false}
            />
          </div>
          <div className="momentSetWrapper insightMomentWrapper">
            <MomentSet
              title={"Insights"}
              moments={moments}
              filterFunction={moment => moment.momentType == 2 || moment.momentType == 5}
              showSubjectTitleOnMobile={false}
            />
          </div>
        </div>
      </div>
    )
  }

  return <>
    
    <div className="userDataHeader">
      <UserCardChip
      user={todoOwner}
      userOverAllPoints={userDashboardData.userData.totalPoints}
      />

      <div className="actionButtons">
        <Tooltip title={`${todoOwner.firstName}'s Dashboard`}>
          <IconButton component={Link} to={`/dashboard?user=${todoOwner._id}`} aria-label="dashboard-link">
            <DashboardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Show all posts of User"}>
          <IconButton onClick={()=>setShowPosts(show => !show)} aria-label="show-all-posts">
            <FeedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Show weekly productivity graph and other tasks"}>
          <IconButton onClick={()=>getMoreInformationOfUser(userDashboardData.userData._id)} aria-label="show-weekly-productivity">
            <QueryStatsIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>

    {userDashboardData?.dashboardData ?
    <div className='WeeklyGraphSliderContainer'>
      <ProductivityAndEventContent
      graphData={userDashboardData.dashboardData.weeklyProductivityAndEvent}
      height={200}/>
    </div> : ""}

    {getTodoDetails(todos[index])}
    {
      todos.length > 1 ? 
      <div className="actionButtons" style={{
        width: "100%",
        justifyContent: "space-between"
      }}>
          <Tooltip title={"Previous Task"}>
            <IconButton onClick={()=>prevTodo()} aria-label="previous-task">
              <KeyboardArrowLeftIcon />
              <p className='nextAndPrev'>Prev</p>
            </IconButton>
          </Tooltip>
          <Tooltip title={"Next Task"}>
            <IconButton onClick={()=>nextTodo()} aria-label="next-task">
              <p className='nextAndPrev'>Next</p>
              <KeyboardArrowRightIcon />
            </IconButton>
          </Tooltip>
      </div> : ""
    }
    { showPosts ? <TaskTableAndPostTab userId={userDashboardData.userData._id} /> : "" }
  </>
}

export default UserDataSectionInfo;
