import React from "react";
import ReactDom from "react-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
	actionToggleCreateMotivationMessageFormOpen,
	actionCreateMotivationMessage,
} from "../../app/Reducers/motivations/motivationSlice";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 2,
};

const schema = yup.object().shape({
	message: yup.string().required("Provide valid message")
});

const CreateMotivationMessageForm = ({}) => {
	const dispatch = useDispatch();
	const {
		createMotivationMessageFormOpen
	} = useSelector(state => state.motivation);

	// form setup
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isSubmitSuccessful },
	} = useForm({
		defaultValues: {
			message: "",
		},
		resolver: yupResolver(schema),
	});

	const onClose = () => {
		dispatch(actionToggleCreateMotivationMessageFormOpen(false));
		reset({
			message: ""
		});
	};

	const onSubmit = ({ message }) => {
		console.log("dumaan dito")
		console.log(message)
		dispatch(actionCreateMotivationMessage({message}));
	};
	return ReactDom.createPortal(
		<>
			<div className="MotivationMessageFormContainer">
				<Modal
					keepMounted
					open={createMotivationMessageFormOpen}
					onClose={() => onClose()}
					aria-labelledby="modal-MotivationMessage"
					aria-describedby="keep-mounted-modal-description"
				>
					<Box sx={style} spacing={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="flex-start"
							spacing={2}
							style={{ marginBottom: 10 }}
						>
							<Typography
								id="modal-MotivationMessage"
								variant="h6"
								component="h2"
							>
								Create MotivationMessage
							</Typography>

							<CloseIcon
								className="close-form-trigger"
								onClick={() => onClose()}
							/>
						</Stack>
						<form className="form-container" onSubmit={handleSubmit(onSubmit)}>
							<Stack direction="column" alignItems="center" spacing={2}>
								<RHFTextField
									control={control}
									name="message"
									label="message"
									placeholder="message"
									error={!!errors.message}
									helperText={errors?.message ? errors?.message?.message : ""}
									type="text"
								/>

								<Stack spacing={1} direction="row" justifyContent="center">
									<Button type="submit" variant="contained">
										save
									</Button>
									<Button
										component={Link}
										to="/"
										type="button"
										variant="contained"
									>
										Cancel
									</Button>
								</Stack>
							</Stack>
						</form>
					</Box>
				</Modal>
			</div>
		</>,
		document.getElementById("create-motivationMessage-form-wrapper-portal")
	);
};

export default CreateMotivationMessageForm;
