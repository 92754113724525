//import UpdateScheduleTime from '.components/UpdateScheduleTime/UpdateScheduleTime';//cut it and paste to the component
import React, {useState,useEffect} from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import {
    actionScheduleChangeTime,
    actionOpenSideNav,
    actionToggleEditScheduleForm
} from '../../app/Reducers/todo/todoSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

const UpdateScheduleTime = ({
}) => {

    const {
        scheduleToUpdate,
        openEditScheduleForm
    } = useSelector(state => state.todo);

    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState(null);

    const dispatch = useDispatch();

    useEffect(()=>{
        console.log(scheduleToUpdate)
        if(scheduleToUpdate){
            setStartTime(new Date(scheduleToUpdate.start));
            setEndTime(scheduleToUpdate.end ? new Date(scheduleToUpdate.end) : null);
        }
    },[scheduleToUpdate]);

    const changeTime = () => {
        /* 
        do the following validations:
        1) if there is end time data, the start time must be less than the endtime
        */
       
        if(startTime){
            dispatch(actionScheduleChangeTime(
                scheduleToUpdate?._id,
                scheduleToUpdate?.todoId,
                scheduleToUpdate.start,
                scheduleToUpdate.end,
                startTime.toISOString(),
                endTime ? endTime.toISOString() : null
            ))
        }
        
    }
    
    return <div className='UpdateScheduleTimeContainer'>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Modal
                keepMounted
                open={openEditScheduleForm}
                onClose={()=>actionOpenSideNav(false)}
                aria-labelledby="edit-time-modal"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={2}>

                        {scheduleToUpdate ? <>
                        <Typography
                        id="edit-time-modal"
                        variant="h6"
                        component="h2"
                        style={{marginBottom:10}}>
                            {`Edit time of "${scheduleToUpdate?.name}"`}
                        </Typography>
                        <Stack spacing={2}>
                            <DateTimePicker
                                label="Start time"
                                value={startTime}
                                onChange={timeValue=>setStartTime(timeValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            {endTime ? 
                                <DateTimePicker
                                    label="End time"
                                    value={endTime}
                                    onChange={timeValue=>setEndTime(timeValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                /> : ""
                            }
                        </Stack>
                        </> : ""}

                        <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end">
                            <Button
                            variant="outlined"
                            onClick={()=>{
                                changeTime()
                                // updateSchedule({
                                //     ...schedule,
                                //     start: startTime.toISOString(),
                                //     end: endTime.toISOString()
                                // })
                                // setUpdateScheduleModalOpen(false)
                            }}>Yes</Button>
                            <Button
                            variant="outlined"
                            onClick={()=> dispatch(actionToggleEditScheduleForm(false))}
                            >Cancel</Button>
                        </Stack>

                    </Stack>
                </Box>
            </Modal>
        </LocalizationProvider>
    </div>
}

export default UpdateScheduleTime;