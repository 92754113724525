import React, { useReducer, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PostCards from '../PostCards/PostCards';
import Axios from 'axios';
import { getHeaders } from '../../app/helpers';
import './PostList.scss';

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const initialState = {
  posts: [],
  hasNextPage: false,
  limit: 10,
  nextPage: null,
  page: 1,
  pagingCounter: 1,
  prevPage: null,
  totalDocs: 0,
  totalPages: 1,
}

const joinArraysOfSelectedUserWithoutDuplicate = (
  oldArray,
  incommingArray,
  selectedUserId
) => {
  const combinedObject = {};
  // Iterate through the first array and add objects to the combined object
  oldArray.forEach((item) => {
    if(item.createdBy._id == selectedUserId){
      const id = item._id; // Change this to the unique property of your objects
      combinedObject[id] = item;
    }
  });
  // Iterate through the second array and add objects to the combined object (checking for duplicates)
  incommingArray.forEach((item) => {
    if(item.createdBy._id == selectedUserId){
      const id = item._id; // Change this to the unique property of your objects
      if (!combinedObject[id]) {
        combinedObject[id] = item;
      }
    }
  });
  // Convert the combined object back into an array
  const combinedArray = Object.values(combinedObject);
  return combinedArray;
}

const reducer = (state, action) => {
  const { 
    type,
    payload
  } = action;

  switch (type){
    case "fetchMorePost" : {
      const {
        userId,
        docs,
        hasNextPage,
        limit,
        nextPage,
        page,
        pagingCounter,
        prevPage,
        totalDocs,
        totalPages,
      } = payload
      const posts = joinArraysOfSelectedUserWithoutDuplicate(
        [...state.posts],
        [...docs],
        userId
      )
      return {
        ...state,
        posts,
        hasNextPage,
        limit,
        nextPage,
        page,
        pagingCounter,
        prevPage,
        totalDocs,
        totalPages,
      };
      
    }
    default:
      return state;
  }
}

const PostList = ({
  userId
}) => {

  const {
    taskAndPostDashboardGridView
  } = useSelector(state => state.layout)

  const [state, dispatch] = useReducer(reducer, initialState)

  const getUserPostsWithPoints = async ({
    page
  }) => {
    const headers = getHeaders();
    if(headers === null) return
    
    const id = userId
    const limit = 10

    let query = ``;
    if(page)				{	query+=`&page=${page}` }
    if(limit)				{ query+=`&limit=${10} `}
    if(query.length){ query = "?"+query.substring(1); }

    try{
      const res = await Axios.get(
        `${URI}/posts/get-user-posts/${id}${query}`,
        headers
      )

      dispatch({
        type: "fetchMorePost",
        payload: {
          ...res.data,
          userId: id
        }
      })
    }
    catch(err){
      console.log(err)
    }
  }

  useEffect(()=>{
    getUserPostsWithPoints({ page: 1 })
  }, [userId])

	return <div className='GroupSelectionContainer'>
    <div className={`postListContainer ${taskAndPostDashboardGridView ? "gridView" : ""}`}>
      {state.posts.map(post =>  <PostCards
                                key={post._id}
                                minified={taskAndPostDashboardGridView}// default value is true
                                postData={post}/>)}
    </div>
    {state.hasNextPage ? 
      <p
      className='load-more-comments'
      sx={{ margin: 2 }}
      onClick={() => getUserPostsWithPoints({
        page: state.nextPage
      })}>Load more...</p>:""
    }
	</div>
}

export default PostList;