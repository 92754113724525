import React, {useState} from 'react';
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import MomentItem from './MomentItem';
import {
	actionSetTodoInSideNav,
	actionSetTodoPopupMessage,
	actionDeleteMomentInSideNav
} from '../../app/Reducers/todo/todoSlice';
import PopupNotifications from '../PopupNotifications/PopupNotifications';
import {
	actionHideMomentPopupMessage,
	actionMakeMomentPost
} from '../../app/Reducers/moment/momentSlice'
import './MomentList.scss';

const MomentList = ({
	momentList=[],
	setMomentToEdit,
	isMomentTextClickable=false,
	title
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedMoment,setSelectedMoment] = useState(null);
	const {
		userData
	} = useSelector(state => state.user);
	const {
		popupType,
		popupOpen,
		popupMessage,
		isMomentRequestLoading
	} = useSelector(state => state.moment);

	const open = Boolean(anchorEl);
	const dispatch = useDispatch();

	const deleteMoment = () => {
		console.log(`Delete this moment ${selectedMoment}`)
		console.log(selectedMoment)
		dispatch(actionDeleteMomentInSideNav(selectedMoment._id))
	}

	const handleClose = () => {
		setAnchorEl(null)
		setSelectedMoment(null)
		setMomentToEdit(null)
	};

	const menuOptions = [
		{
			id: 1,
			name: "Edit",
			icon: <EditIcon fontSize="small"/>,
			callback: () => {
				if(selectedMoment.owner._id === userData._id){
					setMomentToEdit(selectedMoment) // parent component function
				} else {
					dispatch(actionSetTodoPopupMessage(
						true,
						"error", 
						"Unable to edit moment. You can only edit moment assigned to you."
					))
				}
			}
		},
		{
			id: 2,
			name: "Delete",
			icon: <DeleteIcon fontSize="small"/>,
			callback: () => {
				if(selectedMoment.owner._id === userData._id){
					deleteMoment() // component function
				} else {
					dispatch(actionSetTodoPopupMessage(
						true,
						"error", 
						"Unable to delete moment. You can only delete moment assigned to you."
					))
				}
			}
		},
		{
			id: 3,
			name: "View Details",
			icon: <InfoIcon fontSize="small"/>,
			callback: () => {
				if(selectedMoment?.todo)
					dispatch(actionSetTodoInSideNav(selectedMoment.todo))
			} // component function
		},
		{
			id: 4,
			name: "Make moment Post",
			icon: <EditIcon fontSize="small"/>,
			callback: () => {
				if(selectedMoment.owner._id === userData._id){
					dispatch(actionMakeMomentPost(selectedMoment))
				} else {
					dispatch(actionSetTodoPopupMessage(
						true,
						"error", 
						"You can only post a moment that is yours."
					))
				}
			} // component function
		}
	];

	const getMenuItems = () => {
		return menuOptions.map(option=>(
			<MenuItem
			key={option.id}
			onClick={()=>{
				option.callback()
				setAnchorEl(null)
			}}>
				<ListItemIcon style={{minWidth: 32}}>
					{option.icon}
				</ListItemIcon>
				<ListItemText>{option.name}</ListItemText>
			</MenuItem>
		))
	}

	const handlePopupClose = () => {
		dispatch(actionHideMomentPopupMessage());
	}

	return <div className='MomentListContainer'>
		{title ? <h3 className="section-title-04">{title}</h3> : ""}
		
		<div className="moment-list-wrapper">
			<Timeline
			sx={{
				[`& .${timelineItemClasses.root}:before`]: {
				  flex: 0,
				  padding: 0,
				},
				padding: 0
			}}>
				{momentList?.map(momentData=>{
					return  <MomentItem
							key={momentData._id}
							momentData={momentData}
							setAnchorEl={setAnchorEl}
							setSelectedMoment={setSelectedMoment}
							open={open}
							isMomentTextClickable={isMomentTextClickable}/>
				})}
			</Timeline>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
				'aria-labelledby': 'basic-button',
				}}
			>
				{getMenuItems()}
			</Menu>
		</div>
		{
		  isMomentRequestLoading ? 
		  <div className="loading-wrapper">
			<div className="loader"></div>
		  </div> : ""
		}
		<PopupNotifications
		open={popupOpen} 
		handleClose={handlePopupClose}
		severity={popupType}>
			{popupMessage}
		</PopupNotifications>
	</div>
}

export default MomentList;