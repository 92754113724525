//import RateBotReply from '.components/RateBotReply/RateBotReply';//cut it and paste to the component
import React, {useRef,useState,useEffect,useCallback} from 'react';
// import './RateBotReply.scss';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as yup from "yup";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import {
    actionRateTrainingData
} from '../../app/Reducers/bot/botSlice';
import debounce from "lodash.debounce";
import Rating from '@mui/material/Rating';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};


const RateBotReply = ({
    botFeedBackRatingsOpen,
    setBotFeedBackRatingsOpen,
    selectedDialogData
}) => {

    const dispatch = useDispatch();
    const feedbackInputRef = useRef(null)
    const [botSuggestionRating, setBotSuggestionRating] = useState(0)
    const [botSuggestionFeedback, setBotSuggestionFeedback] = useState("")
    const handleFeedbackChange = useCallback(debounce((e) => {
        setBotSuggestionFeedback(e)
    }, 500),[])

    const onFeedbackChange = e => {
        handleFeedbackChange(e.target.value)
    }

    const handleRatingChange = useCallback((e) => {
        setBotSuggestionRating(Number(e.target.value))
    },[])

    // on form submit
    const onSubmit = (e) => {
        e.preventDefault()
        if(botSuggestionRating || botSuggestionFeedback.length){
            dispatch(actionRateTrainingData({
                userInquiryToBot: selectedDialogData.userMessage,
                botResponse: selectedDialogData.message,
                rating: botSuggestionRating,
                comment: botSuggestionFeedback,
                trainingData: selectedDialogData.responseData.trainingDataId
            }))
            setBotFeedBackRatingsOpen(false)
        }
    }
    return <div className='RateBotReplyContainer'>
        <Modal
            keepMounted
            open={botFeedBackRatingsOpen}
            onClose={()=>setBotFeedBackRatingsOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
                <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                style={{marginBottom:10}}>

                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        {`Bot Response Rating and Feedback`}
                    </Typography>

                    <CloseIcon 
                    className="close-form-trigger"
                    onClick={()=>setBotFeedBackRatingsOpen(false)}/>

                </Stack>
                <form className="todo-create-form" onSubmit={onSubmit}>
                    <Stack spacing={2}>

                        <div className="rio-rating-wrapper">
                            <span className="label">Bot Suggestion Rating:</span>
                            <Rating
                                name="simple-controlled"
                                size="small"
                                value={botSuggestionRating}
                                onChange={e=>handleRatingChange(e)}
                            />
                        </div>

                        <TextField
                        className="feedback"
                        label="Bot Suggestion Feedback"
                        variant="outlined"
                        inputRef={feedbackInputRef}
                        onChange={onFeedbackChange}/>

                        <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end">
                            <Button type="submit" variant="outlined">Save</Button>
                            <Button onClick={()=>setBotFeedBackRatingsOpen(false)} type="button" variant="outlined">Cancel</Button>
                        </Stack>

                    </Stack>
                </form>
            </Box>
        </Modal>
    </div>
}

export default RateBotReply;