//import UserCard from '.components/UserCard/UserCard';//cut it and paste to the component
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import {
	actionFollowUser,
  actionUnfollowUser,
  actionSetUserSystemRole
} from '../../app/Reducers/user/userSlice';
import {
  actionAddUserToGroup
} from '../../app/Reducers/group/groupSlice';
import { NavLink } from "react-router-dom";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Tooltip from '@mui/material/Tooltip';
import {
  actionToggleUserTodosWithPointsData
} from '../../app/Reducers/todo/todoSlice';
import {
  actionToggleUserPostsWithPointsData
} from '../../app/Reducers/post/postSlice';
import UserCardMoreOptions from './UserCardMoreOptions';
import "./UserCard.scss"

const UserCard = ({
  user,
  showFollowButton = false,
  showRoleAssignmentButton = false,
  showAddToGroupButton = false,
  selectedGroup = null
}) => {

  const {
		userData,
    activeRoleType
	} = useSelector(state => state.user);
	const dispatch = useDispatch();

  const isAlreadyFollowing = userData.following.indexOf(user._id) > -1

  const getAddMemberButton = () => {
    if(selectedGroup){
      if(selectedGroup.members.findIndex(memberId => memberId == user._id) > -1)
        return <Button
                variant="outlined"
                size={"small"}
                sx={{ width: "100%", fontWeight: 700, letterSpacing: "0.25px", cursor: "pointer" }}
                disableElevation={true}
              >Member</Button>
      else
        return <Button
                variant="contained"
                size={"small"}
                onClick={()=>dispatch(actionAddUserToGroup(selectedGroup._id, user._id))}
                startIcon={<GroupAddIcon />}
              >Add to Group</Button>
    }
    return ""
  }

  const getEmergentIndex = (user) => {
    const todosTotalPoints = user?.todosPoints?.todosTotalPoints || 0
    const todosWithPointsCount = user?.todosPoints?.todosWithPointsCount || 0
    const postsTotalPoints = user?.postsPoints?.postsTotalPoints || 0
    const postsWithPointsCount = user?.postsPoints?.postsWithPointsCount || 0
    return <>
    {/* <p>todosTotalPoints = {todosTotalPoints}</p> */}
    
    <div className="emergentIndexDetails">
      <Tooltip title={`Number of Todos with points`}>
        <p
        className='clickableDetail'
        onClick={()=>dispatch(actionToggleUserTodosWithPointsData(true, user))}
        >TT = {todosWithPointsCount},</p>  
      </Tooltip>
      <Tooltip title={`Number of Posts with points`}>
        <p
        className='clickableDetail'
        onClick={()=>dispatch(actionToggleUserPostsWithPointsData(true, user))}
        > TP = {postsWithPointsCount}</p>
      </Tooltip>
    </div>
    <Tooltip title={`Emergent Index`}>
      <p className='clickableDetail'>EI = {((todosTotalPoints+postsTotalPoints)/(todosWithPointsCount+postsWithPointsCount)).toFixed(1)}</p>
    </Tooltip>
    </>
  }

	return <div className='userCard'>
    
    <div className="profileCardImgContainer">
      <NavLink to={`/profile?user=${user._id}`}>
        <img src={user.profilePicture ? user.profilePicture : '/profiletemp.jpg'} alt="" loading="lazy"/>
      </NavLink>
    </div>
    <p className='suggestedPeopleName'><NavLink to={`/profile?user=${user._id}`}>{user.firstName} {user.lastName}</NavLink></p>
    { user?.todosPoints || user?.postsPoints ? 
    getEmergentIndex(user)
    : "" }

    {/* Show the assign role Button */}
    {
      showRoleAssignmentButton ?
      <Button
      variant="contained"
      onClick={()=>dispatch(actionSetUserSystemRole(user._id, activeRoleType))}
      size={"small"}
      sx={{ width: "100%", fontWeight: 700, letterSpacing: "0.25px"}}
      disableElevation={true}
      >Assign user</Button> : ""
    }
    
    {/* Show the follow Button */}
    {
    showFollowButton ?
      isAlreadyFollowing ?
        <Tooltip title={`Unfollow ${user.firstName}?`}>
          <Button
          variant="outlined"
          size={"small"}
          sx={{ width: "100%", fontWeight: 700, marginTop: "20px", letterSpacing: "0.25px", cursor: "pointer" }}
          disableElevation={true}
          onClick={()=>dispatch(actionUnfollowUser(user._id))}
          >Following</Button>
        </Tooltip>
        :
        <Tooltip title={`Follow ${user.firstName}`}>
          <Button
          variant="contained"
          onClick={()=>dispatch(actionFollowUser(user._id))}
          size={"small"}
          sx={{ width: "100%", fontWeight: 700, marginTop: "20px", letterSpacing: "0.25px"}}
          disableElevation={true}
          >Follow</Button>
        </Tooltip>
      :
    ""
    }

    {
    showAddToGroupButton && selectedGroup ?
    getAddMemberButton() : ""
    }

    <UserCardMoreOptions user={user}/>

  </div>
}

export default UserCard;