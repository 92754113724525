import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';

/* Just a test code... remove it afterwards */ 
const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;
/* Just a test code... remove it afterwards */

const initialState = {
    challenges: [],
    momentsList: [],
    momentSubjects: [],
    isMomentRequestLoading: false,

    // list of moment pagination
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 0,
    totalPages: 1,

    // list of todo parameters
    momentTypesArray: [4],
    dateFilter: "all",

    // popup messages: 
	popupType: "error",
	popupOpen: false,
	popupMessage: "",
};

export const momentSlice = createSlice({
    name: 'moment',
    initialState,
    reducers: {
        addMoment: (state,action) => {
            state.noteList = [...state.noteList, action.payload]
            state.isMomentRequestLoading = false
        },
        updateMomentsList: (state,action) => {
            state.momentsList = action.payload.docs
            state.hasNextPage = action.payload.hasNextPage
            state.hasPrevPage = action.payload.hasPrevPage
            state.limit = action.payload.limit
            state.nextPage = action.payload.nextPage
            state.page = action.payload.page
            state.pagingCounter = action.payload.pagingCounter
            state.prevPage = action.payload.prevPage
            state.totalDocs = action.payload.totalDocs
            state.totalPages = action.payload.totalPages
            state.momentTypesArray = action.payload.momentTypesArray
            state.dateFilter = action.payload.dateFilter
            state.isMomentRequestLoading = false
            if(action.payload.subjects.length){
                state.momentSubjects = action.payload.subjects
            }
        },
        setMomentSubjects: (state,action) => {
            state.momentSubjects = action.payload
            state.isMomentRequestLoading = false
        },
        appendMomentSubjects: (state,action) => {
            if(action?.payload?.label?.length)
            if(state.momentSubjects.findIndex(option => option.label === action.payload.label) === -1){
                state.momentSubjects = [...state.momentSubjects, action.payload.label]
            }
            state.isMomentRequestLoading = false
        },
        selectSubject: (state,action) => {
            state.momentSubjects = [...state.momentSubjects].map(option => {
                if(option.label === action.payload){
                    return {
                        ...option,
                        checked: !option.checked
                    }
                }
                return option
            });
            state.isMomentRequestLoading = false
        },
		setMomentPopUpMessage: (state, action) => {
			const {
                popupOpen,
                popupType,
                popupMessage
			} = action.payload;

			state.popupOpen = popupOpen ? popupOpen : false;

			if(popupOpen){
                if(popupType) state.popupType = popupType
                if(popupMessage) state.popupMessage = popupMessage
			}

			state.isMomentRequestLoading = false
		},
        setMomentRequestLoading: (state, action) => {
			state.isMomentRequestLoading = action.payload
		}
    }
});

export const {
    addMoment,
    updateMomentsList,
    setMomentSubjects,
    appendMomentSubjects,
    selectSubject,
    setMomentPopUpMessage,
    setMomentRequestLoading
} = momentSlice.actions;

const showErrorType = (err,dispatch) => {
	if (err.response) {
		dispatch(setMomentPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: err.response.data
			}
		))
	} else if (err.request) {
		console.log(err.response)
		dispatch(setMomentPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: 'Server error. No response received, request may have timed out, been blocked or server is down.'
			}
		))
	} else {
		dispatch(setMomentRequestLoading(false))
		console.log('Error:', err.message);
	}
}

export const actionMakeMomentPost = moment => async (dispatch) => {
    const headers = getHeaders();
    if(headers === null) return

    dispatch(setMomentRequestLoading(true));
    try{
        const res = await Axios.post(
            `${URI}/moments/make-moment-a-post`,
            {
                _id: moment._id
            },
            headers
        )
        dispatch(setMomentPopUpMessage({
            popupOpen: true,
            popupType: 'success',
            popupMessage: "Post added in your feeds. Go to profile page."
        }))
    }
    catch(err){
        console.log(err)
        showErrorType(err, dispatch)
    }
    
}

// export const actionAddMoment = moment => async (dispatch,getState) => {
//     const {user} = getState();
//     const userId = user.userData ? user.userData._id : null;

//     const headers = getHeaders();
//     if(headers === null) return

//     dispatch(setMomentRequestLoading(true))

//     try{
//         const res = await Axios.put(
//             `${URI}/moments/add-moment`,
//             {
//                 ...moment,
//                 userId
//             },
//             headers
//         )
//         setTimeout(()=>{
//             dispatch(addMoment(res.data))
//         },500)
//     }
//     catch(err){
//         console.log(err)
//         showErrorType(err, dispatch)
//     }
// }

export const actionGetChallenges = () => async (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return

    dispatch(setMomentRequestLoading(true))

    try{
        const res = await Axios.get(
            `${URI}/moments/get-challenges`,
            headers
        )
        dispatch(updateMomentsList(res.data))
    }
    catch(err){
        console.log(err)
        showErrorType(err, dispatch)
    }
}

export const actionGetEnergyMoment = () => async (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return

    dispatch(setMomentRequestLoading(true))

    try{
        const res = await Axios.get(
            `${URI}/moments/get-energy-moment`,
            headers
        )
        dispatch(updateMomentsList(res.data))
    }
    catch(err){
        console.log(err)
        showErrorType(err, dispatch)
    }
}

export const actionGetMoments = (
    userId,
    paramObj={}
) => async (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return
    const {moment} = getState();

    const momentTypesArray = paramObj?.momentTypesArray ? paramObj.momentTypesArray : moment.momentTypesArray
    const dateFilter = paramObj?.dateFilter ? paramObj.dateFilter : moment.dateFilter
    
    const subjects =    paramObj?.subjectFilterArray ?
                        paramObj.subjectFilterArray :
                        moment.momentSubjects;
    
    const filteredSubjects = [...subjects]
                            .filter(option => option.checked)
                            .map(option => option.label)

    const page = paramObj?.page ? paramObj.page : moment.page

    try{

        dispatch(setMomentRequestLoading(true))

        const res = await Axios.get(
            `${URI}/moments/get-user-moments/${userId}?subjectfilter=${filteredSubjects}&momentTypefilter=${momentTypesArray}&dateFilter=${dateFilter}&page=${page}&limit=${10}`,
            headers
        )
        dispatch(updateMomentsList({
            ...res.data,
            momentTypesArray,
            subjects,
            dateFilter
        }))
    }
    catch (err){
        console.log(err)
        showErrorType(err, dispatch)
    }
}

export const actionGetMomentSubjects = () => dispatch => {
    const headers = getHeaders();
    if(headers === null) return
    
    dispatch(setMomentRequestLoading(true))

    try{
        const res = Axios.get(
            `${URI}/moments/get-moment-subjects`,
            headers
        )
        if(res.data){
            dispatch(setMomentSubjects(res.data.map(subject=>{
                return {
                    label: subject,
                    checked: false
                }
            })))
        }
    }
    catch (err){
        console.log(err)
        showErrorType(err, dispatch)
    }
}

export const actionHideMomentPopupMessage = () => dispatch => {
	dispatch(setMomentPopUpMessage({popupOpen: false}))
}

export const actionAppendMomentSubjects = subject => dispatch => {
    dispatch(appendMomentSubjects(
        {
            label: subject,
            checked: false
        }
    ))
}

export default momentSlice.reducer;