import React,{useState, useEffect} from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Tab from "./Tab";
import "./App.css";
import Auth from '../pages/Auth/Auth';
import { useSelector, useDispatch } from 'react-redux';
import {
  actionAuthenticateToken
} from "../app/Reducers/user/userSlice"
import PostUploadModal from "./PostUpload/PostUploadModal";
import MomentsView from '../pages/MomentsView/MomentsView';
import Planning from '../pages/Planning/Planning';
import TaskView from '../pages/TaskView/TaskView';
import Dashboard from "../pages/Dashboard/Dashboard";
import TradingDashboard from "../pages/TradingDashboard/TradingDashboard";
import TeamView from "../pages/TeamView/TeamView";
import BigBroSuggestion from "../pages/BigBroSuggestion/BigBroSuggestion";
import BigBroTraining from "../pages/BigBroTraining/BigBroTraining";
import Profile from "../pages/Profile/Profile";
import Home from "../pages/Home/Home";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "../pages/TermsOfService/TermsOfService";
// import ChatArea from "../components/ChatArea/ChatArea";
import Login from '../components/Login/Login';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import Registration from '../components/Registration/Registration';
import EditTodo from "./Todo/EditTodo";
import GroupManagement from '../pages/GroupManagement/GroupManagement';
import Group from '../pages/Group/Group';
import MyClasses from '../pages/MyClasses/MyClasses';
import CreateGroupForm from "./Group/CreateGroupForm";
import UserSettings from "../pages/UserSettings/UserSettings";
import Followers from "../pages/Followers/Followers";
import GlobalDashboard from "../pages/GlobalDashboard/GlobalDashboard";
import EditPosts from "./PostUpload/EditPosts";
import ImageActions from "./ImageActions/ImageActions";
import MotivationalQoutes from "../pages/MotivationalQoutes/MotivationalQoutes";
import ManageAccounts from "../pages/ManageAccounts/ManageAccounts";
import ClassList from "../pages/ClassList/ClassList";
import Classroom from "../pages/Classroom/Classroom";
import Payment from "../pages/Payment/Payment";
import Completion from "../pages/Completion/Completion";
import Transactions from "../pages/Transactions/Transactions";
import PaymentCompleted from "../pages/Completion/PaymentCompleted";
import GroupList from "../pages/GroupList/GroupList";
// import ImageAction
/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {

  // const { theme, loading } = useTeamsFx();
  const {
    userData
  } = useSelector(state => state.user); 
  const dispatch = useDispatch()

  const {
    minimizeMenu
  } = useSelector(state => state.layout);

  useEffect(() => {
    const token = localStorage.getItem('wstoken');
    if(token) dispatch(actionAuthenticateToken())
  }, [])

  return (
    // <Provider theme={theme || teamsTheme}>
    <>
      {!userData ?
        // <PrivacyPolicy />
        <Routes>
          <Route path="/" >
            <Route index element={<Auth>
              <Login/>
            </Auth>} />
            <Route path="auth" element={<Auth />} >
              <Route path="forgot-password" element={<ForgotPassword/>}/>
              <Route path="login" element={<Login/>}/>
              <Route path="registration" element={<Registration/>}/>
            </Route>
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
          </Route>
        </Routes>
        :
        <div className={`
          ${minimizeMenu ? "minimizedMenu" : ""}
        `}>
          <Routes>
            <Route path="/" element={<Tab />} >
              <Route index element={<TaskView />} />
              <Route path="planning" element={<Planning />} />
              <Route path="my-moments" element={<MomentsView />} />
              <Route path="tasks" element={<TaskView />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="dashboard/:userId" element={<Dashboard />} />
              <Route path="teamview" element={<TeamView />} />
              <Route path="bigbrosuggestion" element={<BigBroSuggestion />} />
              <Route path="bigbrotraining" element={<BigBroTraining />} />
              <Route path="profile" element={<Profile />} />
              <Route path="home" element={<Home />} />
              <Route path="profile/:id" element={<Profile />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="manage-group" element={<GroupManagement />} />
              <Route path="group/:id" element={<Group />} />
              <Route path="user-settings" element={<UserSettings />} />
              <Route path="motivational-qoutes" element={<MotivationalQoutes />} />
              <Route path="followers" element={<Followers />} />
              <Route path="global-dashboard" element={<GlobalDashboard />} />
              <Route path="manage-accounts" element={<ManageAccounts />} />
              <Route path="trading" element={<TradingDashboard />} />
              <Route path="my-classes" element={<MyClasses />} />
              <Route path="classrooms" element={<ClassList />} />
              <Route path="classroom" element={<Classroom />} />
              <Route path="completion" element={<Completion />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="payment-completed" element={<PaymentCompleted />} />
              <Route path="group-list" element={<GroupList />} />
              
              {/* <Route path="terms-of-service" element={<TermsOfService />} /> */}
              <Route path="" element={<Navigate replace to="/tasks" />} />
            </Route>
          </Routes>
          {/* <ChatArea/> */}
        </div>
      }
      <EditTodo />
      <PostUploadModal />
      <CreateGroupForm />
      <EditPosts />
      <ImageActions />
    </>
    // </Provider>
  );
}
