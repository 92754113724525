//import TodoSideNav from '.components/TodoSideNav/TodoSideNav';//cut it and paste to the component
import React, {useState,useCallback} from 'react';
import ReactDom from 'react-dom';
import './TodoSideNav.scss';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import UpdateScheduleTime from './UpdateScheduleTime';
import MomentForm from '../Moments/MomentForm'
import MomentList from '../Moments/MomentList'
import { useSelector, useDispatch } from 'react-redux';
import {
    actionOpenSideNav,
    actionSetTodoInSideNav,
    actionGetMoreTodoMoments,
    actionGetTodoPosts
} from '../../app/Reducers/todo/todoSlice';
import {
    actionCreatePostOpen,
} from '../../app/Reducers/post/postSlice';
import TodoActions from './TodoActions'
import TodoHistoryData from './TodoHistoryData'
import { ReactComponent as UserIcon } from '../../Assets/ic_user.svg';
import TodoTags from './TodoTags/TodoTags';
import TodoMoreOptions from './TodoMoreOptions';
import SlideToggle from '../Navigation/SlideToggle'
import TimeProgress from '../TimeProgress/TimeProgress'
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';
import PostCards from '../PostCards/PostCards';

const TodoSideNav = ({
    fixed=false
}) => {

    const [momentToEdit, setMomentToEdit] = useState(null);
    const [partialDesc, setPartialDesc] = useState(true);
    const [expand, setExpand] = useState(false);
    const dispatch = useDispatch();
    
    const {
        todoInSideNav,
        scheduleInSideNav,
        isSideNavOpen,
        todoHistory,
        isTodoDetailsLoading,
        sideNavMoments,
        hasNextPageInTodoMoments,
        nextPageInTodoMoments,
        hasNextPageOfTodoPosts,
        todoPostCount,
        nextPageOfTodoPosts
    } = useSelector(state => state.todo);

    const {
        minimizeMenu
    } = useSelector(state => state.layout);

    const {
        userData
    } = useSelector(state => state.user);

    const getScheduleData = () => {
        
        const { name } = sideNavTodoData;

        return <div className="todo-info-section">
            
            <SlideToggle
            fullContentTrigger={true}
            headText={
                <h4 className="text-title">History</h4>
            }>
                <div className="history-list">
                    {todoHistory?.map(record => (
                        <TodoHistoryData key={record._id} record={{
                            ...record,
                            name,
                            todoId: todoInSideNav._id
                        }}/>
                    ))}
                </div>
            </SlideToggle>
            
            <UpdateScheduleTime />
        </div>
    }

    const getAsigneAndWatchers = () => {

        const { assignedTo: { firstName, lastName, profilePicture } } = sideNavTodoData;
        return  <p className="todo-detail asignee-info">
                    <span className="icon">{profilePicture ? <img src={profilePicture} alt="Profile" loading="lazy"/> :<UserIcon/>}</span>
                    <span className="label">Assignee: </span>
                    <span className="value">{ firstName } {lastName }</span>
                </p>
    }
    
    const sideNavTodoData = todoInSideNav ? todoInSideNav : scheduleInSideNav;
    // const statusData = getTodoStatus(sideNavTodoData?.status);

    const renderedTime = useCallback(lastStartDate => {

        const latestDate = new Date();
        const todoUpdatedDate = new Date(lastStartDate);

        const diffTime = Math.abs(latestDate - todoUpdatedDate);
        const diffSecs = diffTime / (1000);

        return Math.round(diffSecs)

    }, [])

    const totalTimeConsumed = useCallback(todoDetail => {
        if(todoDetail.status === 1){
            return todoDetail.timeConsumed + renderedTime(todoDetail.start);
        }
        return todoDetail.timeConsumed
    })

    const getShortDesc = desc => {
        if(desc.length > 28)
            return desc.substring(0,28) + "..."
        return desc.substring(0,28)
    }

    const getLinkedTodos = () => {
        
        return <div className="todo-info-section">
            
            <SlideToggle
            fullContentTrigger={true}
            headText={
                <h4 className="text-title">Linked Todos ({sideNavTodoData ? sideNavTodoData.linkedTodos.length : 0})</h4>
            }>
                <div className="linked-todo-list" sx={{paddngBottom: "10px"}}>
                    {sideNavTodoData?.linkedTodos?.map(linkedTodo => (
                        <p
                        className="todo-detail clickable"
                        onClick={()=>dispatch(actionSetTodoInSideNav(linkedTodo._id))}>
                            <span className="icon"><LinkIcon sx={{
                                width: "20px",
                                heigth: "20px"
                            }}/></span>
                            <span className="label">{linkedTodo.name}</span>
                        </p>
                    ))}
                </div>
            </SlideToggle>
            
            <UpdateScheduleTime />
        </div>
    }

    const getTodoPostsActions = () => {
        
        return <div className="todo-info-section">            
            <h4 className="text-title">Posts ({todoPostCount})</h4>
            <div className="tasks-post-actions">
                <Button
                variant="outlined"
                size="small"
                onClick={()=>{
                    dispatch(actionGetTodoPosts(sideNavTodoData._id))
                }}>Show Todo Posts</Button>
                <Button
                variant="outlined"
                size="small"
                onClick={()=>{
                    dispatch(actionCreatePostOpen(true,sideNavTodoData))
                }}>Create Todo Post</Button>
            </div>
        </div>
    }

    return ReactDom.createPortal(<>
    <div className={`SideNavContainer ${minimizeMenu ? "minimizedMenu" : ""} ${fixed ? "fixed" : ""} ${isSideNavOpen ? "open":""} ${expand ? "expand":""}`}>
       <div className="side-nav-wrapper">
            <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            style={{
                padding: "0 2px 19px",
                borderBottom: "1px solid #E7E7E7"
            }}>
                <h2 className="section-title-01">Todo Details</h2>
                
                {
                sideNavTodoData && userData._id === sideNavTodoData.assignedTo._id ?
                <TodoActions todo={sideNavTodoData}/> :
                ""
                }
                
                {
                expand ?
                <Tooltip title={"Shorten"}>
                    <IconButton
                    size="small"
                    sx={{marginLeft: "8px"}}
                    onClick={()=>setExpand(false)}>
                        <CloseFullscreenIcon style={{color: "#637381"}} fontSize="inherit" />
                    </IconButton>
                </Tooltip>:
                <Tooltip title={"Expand"}>
                    <IconButton
                    size="small"
                    sx={{marginLeft: "8px"}}
                    onClick={()=>setExpand(true)}>
                        <OpenInFullIcon style={{color: "#637381"}} fontSize="inherit" />
                    </IconButton>
                </Tooltip>
                }
                <CloseIcon 
                className="close-form-trigger"
                onClick={()=>dispatch(actionOpenSideNav(false))}/>
            </Stack>
            <div className="sidenavContentWrapper">
                {
                isTodoDetailsLoading ? 
                <div className="loading-wrapper">
                    <div className="loader"></div>
                </div> : ""
                }
                {sideNavTodoData ? <>
                    <div className="todo-info-wrapper">
                        <TodoTags todo={sideNavTodoData}/>
                        {userData._id === sideNavTodoData.assignedTo._id ? <>
                            <TodoMoreOptions todo={sideNavTodoData}/>
                        </> : ""}
                        <p className="todo-name">{sideNavTodoData?.name}</p>
                        {/* {sideNavTodoData?.description?.length ? <>
                            <p className={`todo-description ${partialDesc ? "one-line" : ""}`}>{sideNavTodoData.description}</p>
                            <p
                            className="todo-more-trigger"
                            onClick={()=>setPartialDesc(!partialDesc)}
                            >{partialDesc ? "More..." : "Less..."}</p>
                        </> : ""} */}

                        {sideNavTodoData?.description?.length ? <>
                            <p className={`todo-description ${partialDesc ? "one-line" : ""}`}>{ partialDesc ? getShortDesc(sideNavTodoData.description) : sideNavTodoData.description }</p>
                            {
                            sideNavTodoData.description.length > 28 ? 
                            <p
                            className="todo-more-trigger"
                            onClick={()=>setPartialDesc(!partialDesc)}>
                                {partialDesc ? "More..." : "Less..."}
                            </p> : ""
                            }
                        </> : ""}

                        {sideNavTodoData.category ? <>
                            <p className='categoryLabel'>Category: <span className='bold'>{sideNavTodoData.category.name}</span></p>
                        </> : ""}

                        {
                        sideNavTodoData?.noteId?.title ? <p className='noteReference'>Note Reference: {sideNavTodoData?.noteId?.owner?.firstName} {sideNavTodoData?.noteId?.owner?.lastName}'s note "{sideNavTodoData?.noteId?.title}"</p> : ""
                        }

                        {sideNavTodoData?.goal ? <p className="todo-goal"><span style={{fontWeight:700}}>Goal:</span> {sideNavTodoData.goal?.name}</p> : ""}
                        {getAsigneAndWatchers()}
                        <TimeProgress
                        todo={todoInSideNav}
                        />
                        
                    </div>

                    {sideNavTodoData?.linkedTodos ? getLinkedTodos() : ""}

                    <div className="history-and-moment-wrapper">
                        {getScheduleData()}
                    </div>
                    
                    <div className="todo-posts-wrapper">
                        {getTodoPostsActions()}
                        <div className="cardListWrapper">
                            {
                            sideNavTodoData?.posts?.length ?
                                sideNavTodoData.posts.filter(post=>post.hasOwnProperty("_id")).map(
                                    post => <PostCards key={post._id} postData={post}/>
                                ) : ""
                            }
                        </div>
                        {
                        hasNextPageOfTodoPosts ? 
                        <p
                        className='load-more-comments'
                        onClick={()=>dispatch(actionGetTodoPosts(sideNavTodoData._id, nextPageOfTodoPosts))}>
                            Load more...
                        </p> : "" 
                        }
                    </div>
                    
                    <div className="moments-wrapper">
                        <MomentList
                        momentList={sideNavMoments}
                        // momentList={sideNavTodoData ? sideNavTodoData.moments : []}
                        setMomentToEdit={setMomentToEdit}
                        title={"MOMENTS"}
                        />
                    </div>
                    {hasNextPageInTodoMoments ? <p
                                                className='load-more-comments'
                                                onClick={()=>{
                                                    dispatch(actionGetMoreTodoMoments(
                                                        sideNavTodoData?._id,
                                                        nextPageInTodoMoments
                                                    ))
                                                }}
                                                >Load More...</p> : ""}
                </> : ""}
            </div>
        </div>

        <div className="moment-form-wrapper">
            {scheduleInSideNav || sideNavTodoData ? <MomentForm
                        todoId={sideNavTodoData?._id}
                        momentToEdit={momentToEdit}
                        setMomentToEdit={setMomentToEdit}
                        // setMomentList={setMomentList}
                        /> : ""}
        </div>
    </div>
    
    </>,document.getElementById("todo-side-navigation-wrapper-portal"))
}

export default TodoSideNav;