//import MobileHeader from '.components/MobileHeader/MobileHeader';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import { ReactComponent as LogoIcon } from '../../Assets/ic_logo.svg';
import {
    actionToggleNavigation
} from '../../app/Reducers/layout/layoutSlice';
import { useSelector, useDispatch } from 'react-redux';

const MobileHeader = ({

}) => {

    const dispatch = useDispatch();
    const { isNavigationOpen } = useSelector(state => state.layout);

    return <div className='MobileHeaderContainer'>
        
        <div className="nav-logo-container">
            <div className="logo-wrapper">
                <LogoIcon/>
            </div>
            <p>Hungry2Learn</p>
        </div>

        <div
        className={`hamburger ${isNavigationOpen ? "is-active" : ""}`}
        id="hamburger-1"
        onClick={()=>dispatch(actionToggleNavigation(!isNavigationOpen))}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
        </div>
        

    </div>
}

export default MobileHeader;