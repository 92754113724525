import React, {useState, useRef, useEffect} from 'react'
import './ChangeProfilePicture.scss';
import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { emotions } from "../Hooks/Constants";
import {
	actionChangeProfilePicture
} from '../../app/Reducers/user/userSlice';
import { useForm } from "react-hook-form";
import PostInputs from '../PostUpload/PostInputs';
import PostHeader from '../PostCards/PostHeader';

const ChangeProfilePicture = ({
	closeModal,
	onProfilePictureChange
}) => {

	const dispatch = useDispatch()

	const [image, setImage] = useState("");
	const [scale, setScale] = useState(0);
	const [selectedEmotion, setSelectedEmotion] = useState(-1)
	
	// let editor;
	const fileInput = useRef(null);
	const editorRef = useRef(null);

	const {
		userData
	} = useSelector(state => state.user)

	const {
		setError, 
		control, 
		handleSubmit, 
		reset,
		formState: {errors,isSubmitSuccessful}
	} = useForm({ 
		defaultValues: {
		  postDescription: ""
		}
	});
		
	const handleFileChange = e => {
		const file = e.target.files[0];
		e.target.files[0].crossOrigin = 'anonymous';
		setImage(URL.createObjectURL(file));
	};
		
	const handleDrop = (dropped) => {
		setImage(dropped[0])
	}

	const onSubmit = async data => {
		console.log(
			{
				...data,
				emotion: selectedEmotion,
			}
		)

		if (editorRef) {
			const img = editorRef.current;
			img.crossOrigin = 'anonymous';
			const croppedPicture = img.getImage().toDataURL(); // add 'image/jpeg' in the parameter to make it jpg
			const profilePicture = await fetch(croppedPicture).then(r => r.blob());
			onProfilePictureChange({
				...data,
				emotion: selectedEmotion,
			},profilePicture)
			closeModal()
		}
	}

	const emotion = selectedEmotion > -1 ? emotions[selectedEmotion-1]._id ? `Is Feeling ${emotions[selectedEmotion-1].name} ${emotions[selectedEmotion-1].emoji}` : null : null;
		
	return (
		<form
		className='ChangeProfilePicture'
		encType="multipart/form-data"
		onSubmit={handleSubmit(onSubmit)}>
		{/* <div className="ChangeProfilePicture"> */}
			<div className="pictureInputContainer">
				<div className="changeProfileInputWrapper">
					<div className="imageEditorContainer">
						<input
						type="file"
						ref={fileInput}
						accept="image/*"
						onChange={handleFileChange} />
						<p className='dragInstruction'>Or drag your image below</p>
						<Dropzone
							onDrop={handleDrop}
							noClick
							noKeyboard
							style={{ width: '250px', height: '250px' }}
						>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps()}>
									<AvatarEditor
									ref={editorRef}
									width={300}
									height={300}
									border={30}
									borderRadius={150}
									color={[0, 0, 0, 0.6]} // RGBA
									scale={1+(scale/100)}
									rotate={0}
									image={image} />
									<input {...getInputProps()} />
								</div>
							)}
						</Dropzone>
						<Slider
							size="small"
							defaultValue={0}
							aria-label="Small"
							valueLabelDisplay="auto"
							color="white"
							onChange={ (e, val) => setScale(val) } 
						/>
					</div>
				</div>
				<div className="postDescription">
					<div className="cardHeader">
						<PostHeader feeling={emotion} owner={userData}/>
					</div>
					<PostInputs
						control={control}
						errors={errors}
						// functions
						setSelectedEmotion={setSelectedEmotion}
					/>
				</div>
			</div>
			<div className="informationUpdate">
				<Button
				type="submit"
				variant="contained"
				color="primary"
				// onClick={onSubmit}
				style={{
					width: "100%",
					borderRadius: "8px",
					height: "48px",
					fontSize: "16px",
				}}>Update</Button>
			</div>
		{/* </div> */}
		</form>
	);
}
export default ChangeProfilePicture;