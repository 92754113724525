//import EditTodo from '.components/EditTodo/EditTodo';//cut it and paste to the component
import React, {useEffect} from 'react';
import ReactDom from 'react-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
    actionUpdateTodo,
    actionToggleCreateTodoCategoryFormOpen,
    actionToggleEditTodoFormOpen,
} from '../../app/Reducers/todo/todoSlice';
import RHFSelect from "../ReactHookFormElements/RHFSelect";
import { TodoTypes } from "../Hooks/Constants";
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import CategoryIcon from '@mui/icons-material/Category';
import RHFCheckbox from '../ReactHookFormElements/RHFCheckbox';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

const schema = yup.object().shape({
    todoName: yup.string().required(),
    todoDescription: yup.string().required(),
});

const EditTodo = ({
    // todo,
    // editTodoOpen,
    // setEditTodoOpen,
}) => {

    const dispatch = useDispatch();

    const {
		selectedTodo,
		editTodoFormOpen,
		todoCategoryList
	} = useSelector(state => state.todo)

    // form setup
    const { control, handleSubmit, reset, setValue, formState: {errors,isSubmitSuccessful} } = useForm({ 
        defaultValues: {
            todoName: selectedTodo?.name,
            todoDescription: selectedTodo?.description,
            todoEstimate: selectedTodo?.timeEstimate ? selectedTodo?.timeEstimate : 0,
            todoType: selectedTodo?.type ? selectedTodo?.type : 0
        },
        resolver: yupResolver(schema)
    });

    // on form submit
    const onSubmit = ({todoName,todoDescription,todoEstimate,todoType,isThrillingTask}) => {

        dispatch(actionUpdateTodo({
            _id: selectedTodo?._id,
            name: todoName,
            description: todoDescription,
            estimate: todoEstimate,
            type: todoType,
            isThrillingTask
        }))
        // setEditTodoOpen(false)

    }

    const onClose = () => {
        // dispatch(toggleEditTodoFormOpen(false)
		dispatch(actionToggleEditTodoFormOpen(false))
		reset({
			todoName: "",
			todoDescription: "",
			todoEstimate: 1,
			todoType: 0,
		});
	}

    useEffect(()=>{
        if(selectedTodo){
            setValue("todoName", selectedTodo?.name)
            setValue("todoDescription", selectedTodo?.description)
        }
    },[selectedTodo])
    
    return ReactDom.createPortal(<>
        <div className='EditTodoContainer'>
            <Modal
                keepMounted
                open={editTodoFormOpen}
                onClose={()=>onClose()}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style} spacing={2}>
                    <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                    style={{marginBottom:10}}>

                        <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                            {`Update Todo`}
                        </Typography>

                        <CloseIcon 
                        className="close-form-trigger"
                        onClick={()=>onClose()}/>

                    </Stack>
                    <form className="todo-edit-form" onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>

                            <Controller
                            name="todoName"
                            control={control}
                            defaultValue={""}
                            render={({field}) => <TextField
                                {...field}
                                className="textArea"
                                label="Todo Name"
                                variant="outlined"
                                // inputRef={input => input && setTimeout(() =>{
                                //     input.focus()
                                // },100)}
                                placeholder={"Todo Name"}
                                error={!!errors.todoName}
                                type="text"
                                multiline
                                helperText={errors?.todoName ? errors?.todoName?.message : ''}
                            />
                            }/>
                            
                            <Controller
                                name="todoDescription"
                                control={control}
                                defaultValue={""}
                                render={({field}) => <TextField
                                    {...field}
                                    className="textArea"
                                    label="Todo Description"
                                    variant="outlined"
                                    placeholder={"Todo Description"}
                                    error={!!errors.todoDescription}
                                    type="text"
                                    multiline
                                    helperText={errors?.todoDescription ? errors?.todoDescription?.message : ''}
                                />
                            }/>

                            <Controller
                                name="todoEstimate"
                                control={control}
                                defaultValue={1}
                                render={({field}) => <TextField
                                    {...field}
                                    className="textArea"
                                    label="Todo Time Estimate (Hours)"
                                    variant="outlined"
                                    placeholder={"Todo Estimate"}
                                    error={!!errors.todoEstimate}
                                    type="text"
                                    multiline
                                    helperText={errors?.todoEstimate ? errors?.todoEstimate?.message : ''}
                                />
                            }/>

                            <div className="todoSelectionArea">
                                {todoCategoryList.length ?
                                <RHFSelect
                                id="todo-category-selection"
                                name="category"
                                className={"todo-type-selection"}
                                label="Category"
                                control={control}
                                defaultValue={""}
                                variant="outlined"
                                margin="normal"
                                style={{
                                    flexGrow: 1
                                }}>
                                    <MenuItem value={""}>Select Category</MenuItem>
                                    

                                    {todoCategoryList.map(todoCategory=>(
                                    <MenuItem
                                    key={todoCategory._id}
                                    value={todoCategory._id}>{todoCategory.name}</MenuItem>
                                    ))}
                                </RHFSelect> : ""}

                                <Tooltip title={"Add Category"}>
                                    <Button
                                    variant="outlined"
                                    startIcon={<CategoryIcon style={{fontSize: "12px"}}/>}
                                    style={{
                                        fontSize: "10px"
                                    }}
                                    onClick={()=>dispatch(actionToggleCreateTodoCategoryFormOpen(true, true, "editTodoForm"))}>
                                        <AddIcon/>
                                    </Button>
                                </Tooltip>
                            </div>

                            <RHFSelect
                            id="todo-type-selection"
                            name="todoType"
                            className={"todo-type-selection"}
                            label="Todo Type"
                            control={control}
                            defaultValue={0}
                            variant="outlined"
                            margin="normal">
                                {TodoTypes.map(type=><MenuItem key={type.value} value={type.value}>{type.text}</MenuItem>)}
                            </RHFSelect>

                            <RHFCheckbox
                                control={control}
                                name="isThrillingTask"
                                label="Is Thrilling Task"
                                className="thrillingTaskSelection"
                                sx={{ '&.thrillingTaskSelection': { marginTop: "6px", marginLeft: "-10px" } }}
                            />

                            <Stack
                            spacing={1}
                            direction="row"
                            justifyContent="flex-end">
                                <Button type="submit" variant="outlined">Save</Button>
                                <Button onClick={()=>onClose()} type="button" variant="outlined">Cancel</Button>
                            </Stack>

                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    </>,document.getElementById("edit-todo-wrapper-portal"))
}

export default EditTodo;