//import MomentViewFilter from '.components/MomentViewFilter/MomentViewFilter';//cut it and paste to the component
import React, {useState,useEffect} from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';

import { useSelector, useDispatch } from 'react-redux';
import {
    actionGetMoments,
    actionGetMomentSubjects
} from '../../app/Reducers/moment/momentSlice';
import { momentOptions } from "../Hooks/Constants";

const MomentViewFilter = ({

}) => {

    const dispatch = useDispatch()
    const {userData} = useSelector(state => state.user);
    const {momentSubjects} = useSelector(state => state.moment);

    const [momentFilter, setMomentFilter] = useState(
			[...momentOptions.map(momentOption=>{
				return {
					label: momentOption.title,
					checked: false,
					value: momentOption.type,
					icon: momentOption.icon,
				}
			})]
		)

    const [subjectFilter, setSubjectFilter] = useState([])
    
    const dateFilters = [
        {value:"all",       label:"All"     },
        {value:"daily",     label:"Daily"   },
        {value:"weekly",    label:"Weekly"  },
        {value:"monthly",   label:"Monthly" }
    ]

    const [dateFilter, setDateFilter] = React.useState('all');

    const handleTypeChange = (optionIndex, value) => {
        setMomentFilter([...momentFilter].map(e => {
            return {
                ...e,
                checked: e.value === value ? !e.checked : e.checked
            }
        }))
    }

    const handleSubjectChange = (optionIndex, value) => {

        const subjectFilterUpdate = [...subjectFilter]
                                    .map((option) => {
                                        if(option.label === value){
                                            return {
                                                ...option,
                                                checked: !option.checked
                                            }
                                        }
                                        return option
                                    });

        const momentTypesArray =    momentFilter
                                    .filter(e=>e.checked)
                                    .map(e=>e.value)
        
        console.log(subjectFilterUpdate);

        dispatch(
            actionGetMoments(
                userData._id,
                {
                    momentTypesArray,
                    subjectFilterArray: subjectFilterUpdate,
                    dateFilter,
                    page:1
                }
            )
        )

    }

    const handleDateChange = e => setDateFilter(e.target.value);

    useEffect(() => {
        const momentTypesArray = momentFilter.filter(e=>e.checked).map(e=>e.value)
        dispatch(
            actionGetMoments(
                userData._id,
                {
                    momentTypesArray,
                    dateFilter,
                    page:1
                }
            )
        )
    }, [momentFilter,dateFilter]);

    useEffect(() => {
        setSubjectFilter(momentSubjects)
    },[momentSubjects])

    useEffect(() => {
        dispatch(actionGetMomentSubjects())
    },[])

    return (
        <>
        <FormGroup className="tasks-filter-wrapper">
            <h3 className="filter-by-heading">Filter by type</h3>
            {momentFilter.map(({label,checked,value,icon}, optionIndex) => {
							return (
                <FormControlLabel
								className={`checkboxColored ${checked ? "checked":""}`}
                key={optionIndex}
                label={<><span className='checkboxIconMoment'>{icon}</span> {label}</>}
                control={
                    <Checkbox
                    size="small"
                    value={value}
                    checked={checked}
										// icon={<span style={{width: "18px"}}>{icon}</span>}
										// checkedIcon={<span style={{width: "18px"}}>{icon}</span>}
                    onChange={() => handleTypeChange(optionIndex,value)}/>
                }/>
            )})}
        </FormGroup>

        <FormGroup className="tasks-filter-wrapper">
            <h3 className="filter-by-heading">Filter by subject</h3>
            {subjectFilter.map(({label,checked}, optionIndex) => (
                <FormControlLabel
                key={optionIndex}
                label={label} 
                control={
                    <Checkbox
                    size="small"
                    value={checked}
                    onChange={() => handleSubjectChange(optionIndex,label)}
                    />
                }/>
            ))}
        </FormGroup>

        <div className="tasks-filter-wrapper">
            <FormControl >
                <h3 className="filter-by-heading">Filter by date range</h3>
                <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={dateFilter}
                    onChange={handleDateChange}
                >
                    {dateFilters.map(({value,label})=><FormControlLabel
                                                        key={label}
                                                        value={value}
                                                        control={<Radio />}
                                                        label={label} />)}
                </RadioGroup>
            </FormControl>
        </div>
        </>
    )
}

export default MomentViewFilter;