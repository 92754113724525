//import MomentForm from '.components/MomentForm/MomentForm';//cut it and paste to the component
import React, {useEffect, useState, useRef} from 'react';
import './MomentForm.scss';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { MomentType, getMomentType } from "../Hooks/Constants"
import { useSelector,useDispatch } from 'react-redux';
import {
	actionAddMoment,
	actionEditMoment
} from '../../app/Reducers/todo/todoSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as SubmitIcon } from '../../Assets/ic_submit.svg';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { emotions, momentOptions } from "../Hooks/Constants";
import ImageIcon from '@mui/icons-material/Image';
import ImageDropZone from '../ImageDropZone/ImageDropZone';
import {v1 as uuid} from "uuid";

const schema = yup.object().shape({
	moment: yup.string().required()
});

const MomentForm = ({
	todoId,
	momentToEdit=null,
	setMomentToEdit,
	// setMomentList
}) => {

  const [imageToUpload, setImageToUpload] = useState(null)
	const [momentType, setMomentType] = useState(MomentType.comment);
	const [selectedEmotion, setSelectedEmotion] = useState(emotions[0])
	// const [showEmotion, setShowEmotion] = useState(false)
	const [energy, setEnergy] = useState(5)
	const [imagePreview, setImagePreview] = useState(null)
	const [showImageEditor, setShowImageEditor ] = useState(false)
	const [showSavedImage, setShowSavedImage] = useState(false)
	// const [showKeyMoment, setShowKeyMoment] = useState(false)
	
	// let editor;
	const fileInputRef = useRef(null);
	const editorRef = useRef(null);
	const parentRef = useRef(null);
	const textFieldRef = useRef(null);

	const dispatch = useDispatch()

	// form setup
	const { control, handleSubmit, reset, formState: {errors,isSubmitSuccessful}, setValue } = useForm({ 
		defaultValues: {
			moment: ""
		},
		resolver: yupResolver(schema)
	});

	const handleFileChange = e => {
		if(e){
			const file = e.target.files[0];
			e.target.files[0].crossOrigin = 'anonymous';
			setImagePreview(URL.createObjectURL(file));
			setImageToUpload(file)
		}
		else{
			setImagePreview(null);
			setImageToUpload(null)
		}
	};
		
	const handleDrop = (dropped) => {
		const file = dropped[0];
		file.crossOrigin = 'anonymous';
		setImagePreview(URL.createObjectURL(file));
		setImageToUpload(file)
	}

	const onSubmit = async (data) => {

		const {moment,description} = data;
		
		if(momentToEdit){
			let momentData = {
				momentId: momentToEdit._id,
				todoId,
				updatedMomentText: moment,
				momentType,
				energy
			}
			if(selectedEmotion?.key){
				momentData = {
					...momentData,
					emotion: selectedEmotion.key
				}
			}
			await dispatch(actionEditMoment(
				momentData,
				imageToUpload
			))
		}
		else{
			let momentData = {
				todoId,
				moment,
				momentType,
				energy
			}
			if(selectedEmotion?.key){
				momentData = {
					...momentData,
					emotion: selectedEmotion.key
				}
			}
			await dispatch(actionAddMoment(
				momentData,
				imageToUpload
			))
		}
		setMomentToEdit(null)
		setShowImageEditor(false)
		setImageToUpload(null)
		/**/
	}

	const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf('image') !== -1) {
        const blob = item.getAsFile();
        const imageURL = URL.createObjectURL(blob);
				console.log(imageURL)
        // You can now use imageURL to display the pasted image, e.g., set it as the background of a div
        // or render it using an <img> element.
        // For simplicity, we'll display it as a background image here.
        // const textField = textFieldRef.current;
				setShowImageEditor(true)
				setImageToUpload(blob)
				setImagePreview(imageURL);

        // textField.style.backgroundImage = `url(${imageURL})`;
      }
    }
  };

	// reset the form after successfull submit of form data
	useEffect(() => {
		if (isSubmitSuccessful) {
			reset({ moment: "" });
		};
	}, [isSubmitSuccessful, reset]);

	useEffect(()=>{
		if(momentToEdit){
			reset({ moment: momentToEdit ? momentToEdit.moment : "" });
			setMomentType(momentToEdit.momentType)
			setShowImageEditor(true)
			setShowSavedImage(true)
		}
	},[momentToEdit])

	// console.log(momentToEdit)
	return <div className='MomentFormContainer'>
		<form
		className="moment-create-form"
		onSubmit={handleSubmit(onSubmit)}
		>
			<Stack
			spacing={2}
			ref={parentRef}>

				<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
					<p className="moment-form-title">Type</p>
					<ul className="moment-choices">
						<li>
							<Tooltip title={"Add image"}>
								<IconButton
								// aria-label="delete"
								size="small"
								sx={{margin: 0}}
								onClick={()=>{
									if(showImageEditor){
										setImagePreview(null)
										setImageToUpload(null)
									}
									setShowImageEditor(show=>!show)
								}}>
									<ImageIcon style={{color: showImageEditor ? "#00AB55" : "#637381"}} fontSize="inherit" />
								</IconButton>
							</Tooltip>
						</li>
						{momentOptions.map(momentOption => (
							<li
							key={uuid()}
							className={`${momentType === momentOption.type ? "active" : ""}`}>
								<Tooltip title={momentOption.title}>
									<IconButton
									onClick={()=>setMomentType(momentOption.type)}// aria-label="delete"
									size="small">
										<span style={{width: "22px"}}>{momentOption.icon}</span>
									</IconButton>
								</Tooltip>
							</li>
						))}
					</ul>
				</Stack>

				{
				showImageEditor ?
					showSavedImage ? 
						<div className="savedImgWrapper">
							<Tooltip title={"Change Image"}>
								<IconButton
								className='savedImgButton'
								size='small'
								onClick={() => setShowSavedImage(false)}>
									<EditIcon style={{fontSize: 14, color: "#222"}}/>
								</IconButton>
							</Tooltip>
							<img src={momentToEdit?.image} alt="" loading="lazy"/>
						</div>:
						<div className="imageEditorWrapper">
							{
							momentToEdit ? 
								<IconButton
								className='cancelButton'
								size='small'
								onClick={() => {
									console.log("dumaan dito")
									setShowSavedImage(true)
								}}>
									<ArrowBackIcon style={{fontSize: 14}}/>
								</IconButton>
							:
								<IconButton
								className='colseButton'
								size='small'
								onClick={() => {
									console.log("dumaan dito")
									setImagePreview(null)
									setImageToUpload(null)
									setShowImageEditor(show=>!show)
								}}>
									<CloseIcon style={{fontSize: 14}}/>
								</IconButton>
							}

							<ImageDropZone
							fileInputRef={fileInputRef}
							handleFileChange={handleFileChange}
							handleDrop={handleDrop}
							editorRef={editorRef}
							image={imagePreview}
							editorWidth={388}
							maxWidth={388}
							yRatio={2/3}
							border={10}/>

						</div>: ''
				}

				<Controller
					name="moment"
					control={control}
					defaultValue={""}
					render={({field}) => <TextField
						{...field}
						ref={textFieldRef}
      			onPaste={handlePaste}
						className="textArea"
						label="Moment description"
						variant="outlined"
						placeholder={"Moment description"}
						error={!!errors.moment}
						type="text"
						multiline
						helperText={errors?.moment ? errors?.moment?.message : ''}
						sx = {{marginTop: "12px"}}
					/>
				}/>

				{/* <div className="mood-container">

					{
					selectedEmotion._id ? 
					<p className="feeling-emotion">Is Feeling - {selectedEmotion.name} {selectedEmotion.emoji}</p>
					: 
					<p className="feeling-emotion">What do you feel?</p>
					}
					
					<EmotionOptions
						showEmotion={showEmotion}
						setShowEmotion={setShowEmotion}
						setSelectedEmotion={setSelectedEmotion}
					/>
					
				</div> */}

				<div className="mood-container">

					{/* <div className="energy-input-container">
						<p className="moment-type-label">Energy</p>
						<Rating
						name="simple-controlled"
						size="small"
						value={energy}
						onChange={e=>{setEnergy(Number(e.target.value))}}
						icon={<FlashOnIcon fontSize="inherit" />}
						emptyIcon={<FlashOnIcon fontSize="inherit" />}/> 
					</div> */}
					
					<p className="moment-form-title">Moment <span style={{color:"#00AB55"}}>({getMomentType(momentType)})</span></p>

					{
					momentToEdit ? <>

						<Button 
						component={
							React.forwardRef((props, ref) => <button type="button" {...props} ref={ref} />)
						}
						onClick={()=>{
							setMomentToEdit(null)
							setShowImageEditor(false)
							setValue("moment","")
						}} 
						variant="contained" 
						endIcon={<CancelIcon />}
						sx={{height:"30px"}}>Cancel</Button>

						<Button
						type="submit"
						variant="contained"
						endIcon={<SubmitIcon/>}
						sx={{height:"30px"}}>Update</Button>

					</>:<>

						<Button
						type="submit"
						variant="contained"
						endIcon={<SubmitIcon/>}
						sx={{height:"30px"}}>Submit</Button>

					</>
					}
				</div>
			</Stack>
		</form>
	</div>
}

export default MomentForm;