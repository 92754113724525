//import VerifyEmail from '.components/VerifyEmail/VerifyEmail';//cut it and paste to the component
import React, {useEffect} from 'react';
// import './VerifyEmail.scss';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {
	actionVerifyEmail,
	actionSendVerificationEmailRequest,
	toggleVerifyEmail
} from '../../app/Reducers/user/userSlice';
import Stack from '@mui/material/Stack';
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
	verificationCode: yup.string().required("Provide Verification Code"),
});

const VerifyEmail = ({
}) => {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const {
		invalidVerificationCode
	} = useSelector(state => state.user)

	// form setup
	const { control, handleSubmit, reset, formState: {errors,isSubmitSuccessful},setError } = useForm({ 
		defaultValues: {
			verificationCode:""
		},
		resolver: yupResolver(schema)
	});

	const onSubmit = async ({verificationCode}) => {
		const res = await dispatch(actionVerifyEmail(verificationCode))
		if(res === "success")
			navigate("/")
	}

	useEffect(()=>{
		if(invalidVerificationCode){
			setError('verificationCode', {
				type: 'custom',
				message: 'Wrong verification code!'
			})
		}
	},[invalidVerificationCode])

	return <div className='VerifyEmailContainer form-wrapper'>
		<form className="moment-create-form form-container" onSubmit={handleSubmit(onSubmit)}>
			<h1 className="title">Verify Email</h1>
			<p style={{
				textAlign: "left",
				marginBottom: "30px"
			}}>Please write the verification code sent to your email to verify your account.</p>
			<Stack spacing={2}>
				<Stack 
				direction="column"
				alignItems="center"
				spacing={2}>
					<RHFTextField
						control={control}
						name="verificationCode"
						label="Verification Code"
						placeholder="Verification Code"
						error={!!errors.verificationCode}
						helperText={errors?.verificationCode ? errors?.verificationCode?.message : ''}
					/>
				</Stack>
				<Stack
				spacing={1}
				direction="row"
				justifyContent="center">
					<Button type="submit" variant="contained">Send</Button>
					<Button onClick={()=>dispatch(toggleVerifyEmail(false))} type="button" variant="contained">Cancel</Button>
				</Stack>
			</Stack>
			<p
			style={{
				color: '#00AB55',
				fontWeight: 600,
				marginTop: "12px",
				cursor: "pointer"
			}}>
				<span
				style={{
					cursor: "pointer"
				}}
				onClick={()=> dispatch(actionSendVerificationEmailRequest())}
				>Resend Code</span>
			</p>
		</form>
	</div>
}

export default VerifyEmail;