//import TodoList from '.components/TodoList/TodoList';//cut it and paste to the component
import React, {useState,useRef, useEffect} from 'react';
import './TodoList.scss';
import Todo from './Todo';//cut it and paste to the component
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CreateTodo from './CreateTodo';
import { ReactComponent as RefreshIcon } from '../../Assets/ic_refresh.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
	actionGetTodoList,
	actionHideTodoPopupMessage,
	actionToggleCreateTodoFormOpen,
	actionTodoSearch
} from '../../app/Reducers/todo/todoSlice';
import {
	actionGetPostsOverAllScore
} from '../../app/Reducers/post/postSlice';
import {
	actionToggleShowTodoList
} from '../../app/Reducers/layout/layoutSlice';
// import {
// 	actionGetUserEmergentIndex
// } from '../../app/Reducers/user/userSlice';
// import {
//	 actionGetGoals
// } from '../../app/Reducers/goal/goalSlice';
import Tooltip from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';
import KeywordSearch from '../KeywordSearch/KeywordSearch';
import Button from '@mui/material/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PopupNotifications from '../PopupNotifications/PopupNotifications';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AddTaskIcon from '@mui/icons-material/AddTask';
// import EmergentIndex from '../EmergentIndex/EmergentIndex';

const TodoList = ({
	// todoList,
	defaultShow = false,
	parentControlShow = false,
	callBack,
}) => {

	const dispatch = useDispatch()
			
	const {
		isTodoListLoading,
		totalPages,
		popupType,
		popupMessage,
		popupOpen,
		page,
		todoList,
		totalTodoPoints,
		todoWithPointsCount
	} = useSelector(state => state.todo);
			
	const {selectedUserData} = useSelector(state => state.user); 
	const { thirdPartyUserData } = useSelector(state => state.user);
	const [createNotesOpen, setCreateNotesOpen] = useState(false)
	const [showSearchBar, setShowSearchBar] = useState(false)
	const [searchWrapperHeight , setSearchWrapperHeight] = useState(0)
	const showSearchFieldRef = useRef(null)
	const searchInputRef = useRef(null)

	const {
		showTodoList
	} = useSelector(state => state.layout);

	const handlePopupClose = () => {
		dispatch(actionHideTodoPopupMessage());
		// setshowPopupNotification(true)
	}

	const handlePaginationChange = (e,pageNum) => {
		dispatch(actionGetTodoList(selectedUserData._id,{
			page:pageNum
		}))
	}
	useEffect(()=>{
	   dispatch(actionToggleShowTodoList(defaultShow))
		 dispatch(actionGetPostsOverAllScore(selectedUserData._id))
	},[])
	useEffect(()=>{},[todoList])
			
	return <div className={`todo-list-wrapper ${parentControlShow ? parentControlShow : showTodoList ? "" : "hide"}`}>
		<div className={`TodoListContainer`}>
			<Button
			className="todo-list-show-button-trigger"
			variant="contained"
			color="primary"
			onClick={()=>{
				parentControlShow ? callBack() : dispatch(actionToggleShowTodoList(!showTodoList))
			}}>
				<NavigateBeforeIcon fontSize="medium"/>	
			</Button>
			
			<div className={`todo-heading-container ${showSearchBar ? "active-search-bar" : ""}`} onClick={()=>console.log(thirdPartyUserData)}>
				<Stack
				direction="row"
				alignItems="center"
				style={{marginBottom: "4px"}}>
					<h2 className='section-heading-uppercase'>
						<TaskAltIcon fontSize="small"/> Todo List
					</h2>
					<Tooltip title="Refresh">
						<IconButton
						sx={{ marginLeft: "10", padding: "5px"}}
						aria-label="refresh"
						onClick={()=>dispatch(actionGetTodoList(selectedUserData._id))}>
							<span style={{width: 14}}>
								<RefreshIcon style={{width: "100%", height: "auto"}}/>
							</span>
						</IconButton>
					</Tooltip>

					<Tooltip title="Add Task">
						<IconButton
						sx={{ marginLeft: "10", padding: "5px"}}
						aria-label="add"
						onClick={()=>dispatch(actionToggleCreateTodoFormOpen(true))}>
							<AddTaskIcon sx={{fontSize: 18}}/>
						</IconButton>
					</Tooltip>
					
					<Tooltip title="Clear Filters">
						<IconButton
						sx={{ marginLeft: "10", padding: "5px"}}
						aria-label="sort"
						onClick={()=>dispatch(actionGetTodoList(
							selectedUserData._id,
							{
								todoStatusArray: [0,1,2],
								dateFilter: "all",
								goalFilter: "",
								page: 1,
								limit: 10
							}
						))}>
							<FilterAltOffIcon sx={{fontSize: 16}}/>
						</IconButton>
					</Tooltip>

					<Tooltip title="Search todo">
						<IconButton
						sx={{ marginLeft: "10", padding: "5px"}}
						aria-label="search"
						onClick={()=>{
							if(showSearchBar){
								setShowSearchBar(false)
								setSearchWrapperHeight(0);
							}
							else{
								setShowSearchBar(true)
								setSearchWrapperHeight(showSearchFieldRef.current.offsetHeight); 
								searchInputRef.current && searchInputRef.current.focus()
							}
						}}>
							<SearchIcon sx={{fontSize: 18}}/>
						</IconButton>
					</Tooltip>
				</Stack>
				
				<div
				className="hidden-content"
				style={{
					height: searchWrapperHeight,
					transition: "all 300ms ease",
					overflow: "hidden"
				}}>
					<div
					ref={showSearchFieldRef}
					style={{
						paddingTop: "6px",
						paddingBottom: "6px"
					}}>
						<KeywordSearch
						searchInputRef={searchInputRef}
						reducerFunctionCallback={actionTodoSearch}
						label={"Search todo"}/>
					</div>
				</div>
			</div>
			
			<CreateTodo
				createNotesOpen={createNotesOpen}
				setCreateNotesOpen={setCreateNotesOpen}
			/>

			<div className={`todo-list-container ${showSearchBar ? "is-search-open" : ""}`}>
				{
					isTodoListLoading ? 
					<div className="loading-wrapper">
						<div className="loader"></div>
					</div> : ""
				}
				{todoList?.length ? todoList.map(todo =><Todo
										key={todo._id}
										todo={todo}/>) :
					<p style={{padding: "10px 0", color:"#646464"}}>No Assigned Todo List</p>}
			</div>
			
			
			{totalPages > 1 ? <Pagination
						className="custom-style-pagination"
						count={totalPages}
						size="small"
						variant="outlined"
						color="primary"
						shape="rounded"
						siblingCount={0}
						sx={{paddingTop: "13px"}}
						page={page}
						onChange={handlePaginationChange}/> : ""}
		</div>
		<PopupNotifications
		open={popupOpen} 
		handleClose={handlePopupClose}
		severity={popupType}>
			{popupMessage}
		</PopupNotifications>
	</div>
}

export default TodoList;