//import TaskView from '.components/TaskView/TaskView';//cut it and paste to the component
import React, { useState, useRef, useEffect, memo } from 'react';
import './TaskView.scss';
import WingenioCalendar from "../../components/WingenioCalendar/WingenioCalendar";
import "../../components/GlobalStyles/GlobalStyles.scss";
import TodoList from "../../components/Todo/TodoList";
import NotesAside from '../../components/Notes/NotesAside';
import TodoSideNav from '../../components/Todo/TodoSideNav';
import { useSelector, useDispatch } from 'react-redux';
import PostSideNav from '../../components/PostSideNav/PostSideNav';
// import {
//     actionAddBreaktime,
// } from '../../app/Reducers/todo/todoSlice';
import {
    actionToggleNotes,
    actionToggleShowTodoList
} from '../../app/Reducers/layout/layoutSlice';
// import { ReactComponent as BreakIcon } from '../../Assets/ic_break.svg';
import { ReactComponent as NotesIcon } from '../../Assets/ic_notes.svg';

const TaskView = ({

}) => {

    const dispatch = useDispatch();
    const containerRef = useRef(null);

    const {
        showTodoList
    } = useSelector(state => state.layout);

    const handleResize = (entries) => {
        for (const entry of entries) {
          // Check the window width when the element resizes
          checkWindowWidth();
        }
    };
    
    const checkWindowWidth = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 1100) {
            dispatch(actionToggleShowTodoList(false))
        } else {
            dispatch(actionToggleShowTodoList(true))
        }
    };

    useEffect(()=>{
		const resizeObserver = new ResizeObserver(handleResize);
        const container = containerRef.current;

        if (container) {
            resizeObserver.observe(container);
        }
        // Check the initial window width
        checkWindowWidth();
		
        return () => {
            if (container) {
                resizeObserver.unobserve(container);
            }
        };
	},[])

    return <div
    className={`TaskViewContainer ${showTodoList ? "showTodoList":""}`}
    ref={containerRef}>
        
        <TodoList defaultShow={true}/>

        <div className="calendar-wrapper">
            <div className="page-header-container">
                <h2 className="section-title-01">Task Records</h2>
                <ul className="actions-container">
                    <li onClick={()=>dispatch(actionToggleNotes(true))}>
                        <span className="icon"><NotesIcon/></span>
                        <span className="text">Task Notes</span>
                    </li>
                </ul>
            </div>

            <WingenioCalendar />
            
        </div>

        <NotesAside
            fixed={true}
            enableAddTodo={true}
            enableAddGoal={true}
        />

        <TodoSideNav />
        <PostSideNav />

    </div>
}

export default memo(TaskView);