import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionGetAllClassSeminar
} from '../../app/Reducers/classroom/classroomSlice';
import SeminarItem from './SeminarItem';

const AllSeminars = ({
  classroomId
}) => {
  
  const dispatch = useDispatch();

  const {
		userData
	} = useSelector(state => state.user);

  const {
		allSeminars,
    selectedClassroom,
    allSeminarshasNextPage,
    allSeminarsnextPage
	} = useSelector(state => state.classroom)

  useEffect(()=>{
    dispatch(actionGetAllClassSeminar(classroomId, 1))
  },[])

	return (
    <div className='AllSeminarsContainer'>
      <div className="seminarListContainer">
        {
          allSeminars.map(seminar => (
            <SeminarItem
            key={seminar._id}
            seminar={seminar}
            enableEdit={selectedClassroom.admin == userData._id}/>
          ))
        }
      </div>
      {
        allSeminarshasNextPage ? 
        <p
        className='load-more-comments'
        onClick={()=>{
          dispatch(actionGetAllClassSeminar(classroomId, allSeminarsnextPage))
        }}>Load more...</p> : ""
      }
    </div>
	)
}

export default AllSeminars;