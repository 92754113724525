import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const initialState = {
	classroomList: [],
	createClassroomFormOpen: false,
	updateClassroomFormOpen: false,
	selectedClassroom: {},
	userCreatedClasses: [],
	classroomJoinRequests: [],
	classroomUsersJoinRequestListOpen: false,
	classroomMembers: [],
	classroomMembersListOpen: false,
	showSearchClassroomSection: false,
	showFindClassSection: false,
	selectedSeminar: {},
	paidSeminar: {},
	createSeminarFormOpen: false,
	joinSeminarFormOpen: false,
	updateSeminarFormOpen: false,
	seminarAttendees: [],
	showSeminarAttendees: false,
	showPaymentForm: false,
	// list of todo pagination
	classroomsSearchResults: [],
	classroomSearchhasNextPage: false,
	classroomSearchhasPrevPage: false,
	classroomSearchlimit: 10,
	classroomSearchnextPage: null,
	classroomSearchpage: 1,
	classroomSearchpagingCounter: 1,
	classroomSearchprevPage: null,
	classroomSearchtotalDocs: 0,
	classroomSearchtotalPages: 1,

	// suggested classroom
	suggestedClassrooms: [],
	suggestedClassroomsHasNextPage: false,
	suggestedClassroomsNextPage: 1,

	// joined classroom
	joinedClassrooms: [],
	joinedClassroomsHasNextPage: false,
	joinedClassroomsNextPage: 1,

	// All seminars
	allSeminars: [],
	allSeminarshasNextPage: false,
	allSeminarsnextPage: null,

	// popup messages:
	popupType: "error",
	popupOpen: false,
	popupMessage: "Error"
};


export const classroomSlice = createSlice({
	name: 'classroom',
	initialState,
	reducers: {
		setClassroomLoading: (state, action) => {
			state.isClassroomLoading = action.payload
		},
		createClassroom: (state, action) => {
			const {
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;

			state.classroomList = [action.payload, ...state.classroomList]
			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupType) state.popupType = popupType
			if (popupMessage) state.popupMessage = popupMessage
			state.isClassroomLoading = false
		},
		addClassroomDataOnList: (state, action) => {
			state.classroomList = [action.payload, ...state.classroomList]
			state.isClassroomLoading = false
		},
		setClassroomList: (state, action) => {
			state.classroomList = action.payload
			state.isClassroomLoading = false
		},
		update: (state, action) => {
			const {
				_id,
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;
			state.classroomList = [...state.classroomList].map(data => data._id === action.payload._id ? action.payload : data)
			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupType) state.popupType = popupType
			if (popupMessage) state.popupMessage = popupMessage
			state.isClassroomLoading = false
		},
		deleteData: (state, action) => {
			const {
				_id,
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;
			state.classroomList = [...state.classroomList].filter(data => data._id !== _id)

			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupType) state.popupType = popupType
			if (popupMessage) state.popupMessage = popupMessage
			state.isClassroomLoading = false
		},
		toggleCreateClassroomFormOpen: (state, action) => {
			state.createClassroomFormOpen = action.payload
		},
		setPopUpMessage: (state, action) => {
			const {
				popupOpen,
				popupType,
				popupMessage
			} = action.payload

			state.popupOpen = popupOpen ? popupOpen : false
			if (popupOpen) {
				if (popupType) state.popupType = popupType
				if (popupMessage) state.popupMessage = popupMessage
			}
			state.isClassroomLoading = false
			state.isClassroomLoading = false
		},
		deleteClassroom: (state, action) => {
			const classroomId = action.payload._id
			state.classroomList = [...state.classroomList].filter(classroom => classroom._id !== classroomId)
			window.location.href = `/my-classes?user=${action.payload.createdBy}`
		},
		setUserCreatedClass: (state, action) => {
			state.userCreatedClasses = action.payload
		},
		setSuggestedClassrooms: (state, action) => {
			if(action.payload.page == 1){
				state.suggestedClassrooms = action.payload.docs
			}
			else{
				state.suggestedClassrooms = [
					...state.suggestedClassrooms,
					...action.payload.docs
				]
			}
			state.suggestedClassroomsHasNextPage = action.payload.hasNextPage
			state.suggestedClassroomsNextPage = action.payload.nextPage
		},
		setJoinedClassrooms: (state, action) => {
			// Uncomment this function if you already figureout how to paginate the joined classrooms
			if(action.payload.page == 1){
				state.joinedClassrooms = action.payload.docs
			}
			else{
				state.joinedClassrooms = [
					...state.joinedClassrooms,
					...action.payload.docs
				]
			}
			state.joinedClassroomsHasNextPage = action.payload.hasNextPage
			state.joinedClassroomsNextPage = action.payload.nextPage
		},
		appendUserCreatedClass: (state, action) => {
			state.userCreatedClasses = [...state.userCreatedClasses, action.payload]
		},
		setSelectedClassroom: (state, action) => {
			state.selectedClassroom = action.payload
		},
		setClassroomJoinRequests: (state, action) => {
			state.classroomJoinRequests = action.payload
			state.classroomUsersJoinRequestListOpen = true
		},
		setClassroomMembers: (state, action) => {
			state.classroomMembers = action.payload
			state.classroomMembersListOpen = true
		},
		toggleClassroomUsersJoinRequestListOpen: (state, action) => {
			state.classroomUsersJoinRequestListOpen = action.payload
		},
		toggleClassroomMembersListOpen: (state, action) => {
			state.classroomMembersListOpen = action.payload
		},
		addToClassroomJoinRequests: (state, action) => {
			const { classroom } = action.payload
			state.selectedClassroom = {
				...state.selectedClassroom,
				joinRequests: classroom.joinRequests
			}
		},
		acceptToClassroomJoinRequests: (state, action) => {
			const {
				classroom,
				user
			} = action.payload

			
			console.log({
				classroom,
				user
			})
			state.selectedClassroom = {
				...state.selectedClassroom,
				members: classroom.members,
				joinRequests: classroom.joinRequests
			}
			state.classroomJoinRequests = [...state.classroomJoinRequests].filter(userData => userData._id != user._id)
		},
		removeToClassroomJoinRequests: (state, action) => {
			const {
				classroom,
				user
			} = action.payload
			console.log(action.payload)
			state.selectedClassroom = {
				...state.selectedClassroom,
				joinRequests: classroom.joinRequests
			}
			state.classroomJoinRequests = [...state.classroomJoinRequests].filter(userData => userData._id != user._id)
		},
		removeToClassroom: (state, action) => {
			const {
				classroom,
				user
			} = action.payload
			console.log(action.payload)
			state.selectedClassroom = {
				...state.selectedClassroom,
				members: classroom.members,
			}
			state.classroomMembers = [...state.classroomMembers].filter(userData => userData._id != user._id)
		},
		setClassroomsSearchResults: (state, action) => {
			const {
				docs,
				hasNextPage,
				hasPrevPage,
				limit,
				nextPage,
				page,
				pagingCounter,
				prevPage,
				totalDocs,
				totalPages
			} = action.payload

			state.classroomsSearchResults = docs
			state.classroomSearchhasNextPage = hasNextPage
			state.classroomSearchhasPrevPage = hasPrevPage
			state.classroomSearchlimit = limit
			state.classroomSearchnextPage = nextPage
			state.classroomSearchpage = page
			state.classroomSearchpagingCounter = pagingCounter
			state.classroomSearchprevPage = prevPage
			state.classroomSearchtotalDocs = totalDocs
			state.classroomSearchtotalPages = totalPages
		},
		toggleFindClassSection: (state, action) => {
			state.showFindClassSection = action.payload
		},
		toggleCreateSeminarForm: (state, action) => {
			state.createSeminarFormOpen = action.payload
		},
		appendClassroomSeminar: (state, action) => {
			const seminars = state.selectedClassroom.seminars
			state.selectedClassroom = {
				...state.selectedClassroom,
				seminars: [...seminars, action.payload]
			}
			state.createSeminarFormOpen = false
		},
		setSelectedSeminar: (state, action) => {
			state.selectedSeminar = action.payload.seminar
			state.joinSeminarFormOpen = action.payload.open
			if(!action.payload.open){
				state.showPaymentForm = false
			}
		},
		toggleUpdateSeminarForm: (state, action) => {
			state.selectedSeminar = action.payload.seminar
			state.updateSeminarFormOpen = action.payload.open
		},
		updateClassroomSeminar: (state, action) => {
			const seminars = state.selectedClassroom.seminars
			const {
				open,
				seminar
			} = action.payload
			
			console.log(seminar)

			state.updateSeminarFormOpen = open
			state.selectedClassroom = {
				...state.selectedClassroom,
				seminars: [...seminars].map(seminarData => {
					if(seminarData._id == seminar._id){
						return seminar // this is the updated seminar
					}
					return seminarData
				})
			}
			state.selectedSeminar = seminar
		},
		toggleUpdateClassroomFormOpen: (state, action) => {
			state.updateClassroomFormOpen = action.payload
		},
		updateSelectedClassroom: (state, action) => {
			state.selectedClassroom = {
				...state.selectedClassroom,
				name: 				action.payload.name,
				description: 	action.payload.description,
				classLink: 		action.payload.classLink,
				role: 				action.payload.role,
			}
			state.updateClassroomFormOpen = false
		},
		joinSeminar: (state, action) => {
			state.selectedClassroom = {
				...state.selectedClassroom,
				seminars: [...state.selectedClassroom.seminars]
									.map(seminar => {
										return (seminar._id === action.payload._id) ? action.payload : seminar
									})
			}
			state.joinSeminarFormOpen = false
			state.updateSeminarFormOpen = false
			state.paidSeminar = action.payload
			state.selectedSeminar = {}
		},
		toggleSeminarAttendees: (state, action) => {
			const {
				open,
				attendees
			} = action.payload
			state.seminarAttendees = attendees
			state.showSeminarAttendees = open
		},
		setClassroomProfilePicture: (state,action) => {
			state.selectedClassroom = {
				...state.selectedClassroom,
				classroomImage: action.payload.classroomImage
			}
			state.isClassroomLoading = false
		},
		setClassroomTimelinePicture: (state,action) => {
			state.selectedClassroom = {
				...state.selectedClassroom,
				classroomTimelineImage: action.payload.classroomTimelineImage
			}
			state.isClassroomLoading = false
		},
		setShowPaymentForm: (state,action) => {
			state.showPaymentForm = action.payload
		},
		setAllSeminars: (state,action) => {
			const {
				docs,
				hasNextPage,
				nextPage,
			} = action.payload
			
			state.allSeminars = [...state.allSeminars, ...docs]
													.filter(
														(seminar, index, self) => self
																										.findIndex(s => s._id === seminar._id) === index
													)

			state.allSeminarshasNextPage =	hasNextPage
			state.allSeminarsnextPage=			nextPage
		},		
	}
})


export const {
	setClassroomLoading,
	create,
	setClassroomList,
	update,
	deleteData,
	setPopUpMessage,
	toggleCreateClassroomFormOpen,
	deleteClassroom,
	addClassroomDataOnList,
	setUserCreatedClass,
	appendUserCreatedClass,
	setSelectedClassroom,
	toggleClassroomUsersJoinRequestListOpen,
	toggleClassroomMembersListOpen,
	setClassroomJoinRequests,
	addToClassroomJoinRequests,
	removeToClassroomJoinRequests,
	removeToClassroom,
	setClassroomMembers,
	acceptToClassroomJoinRequests,
	setSuggestedClassrooms,
	setClassroomsSearchResults,
	toggleFindClassSection,
	toggleCreateSeminarForm,
	appendClassroomSeminar,
	setSelectedSeminar,
	toggleUpdateSeminarForm,
	updateClassroomSeminar,
	toggleUpdateClassroomFormOpen,
	updateSelectedClassroom,
	joinSeminar,
	toggleSeminarAttendees,
	setClassroomProfilePicture,
	setClassroomTimelinePicture,
	setShowPaymentForm,
	setJoinedClassrooms,
	setAllSeminars,
} = classroomSlice.actions;

const showErrorType = (err, dispatch) => {
	if (err.response) {
		console.log(err.response.data)
		dispatch(setPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: err.response.data
			}
		))
	} else if (err.request) {
		console.log(err.response)
		dispatch(setPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: 'Server error. No response received, request may have timed out, been blocked or server is down.'
			}
		))
	} else {
		dispatch(setClassroomLoading(false))
		console.log('Error:', err.message);
	}
}

export const actionToggleFindClassSection = (open = true) => dispatch => {
	dispatch(toggleFindClassSection(open))
}

export const actionToggleUpdateClassroomFormOpen = (open = true) => dispatch => {
	dispatch(toggleUpdateClassroomFormOpen(open))
}

export const actionToggleCreateSeminarFormOpen = (open = true) => dispatch => {
	dispatch(toggleCreateSeminarForm(open))
}

export const actionToggleUpdateSeminarFormOpen = (
	open = true,
	seminar = null
) => dispatch => {
	dispatch(toggleUpdateSeminarForm({open, seminar}))
}

export const actionToggleJoinSeminarFormOpen = (
	open = false,
	seminar = null
) => dispatch => {
	if(seminar){
		localStorage.setItem('selectedSeminar', seminar._id);
	}
	dispatch(setSelectedSeminar({open, seminar}))
}

export const actionJoinSeminar = ({
	seminarId,
	stripePaymentRef,
	status
}) => async dispatch => {
	const headers = getHeaders();
	if (headers === null) return

	try {
		const res = await Axios.put(
			`${URI}/seminar/join-seminar/${seminarId}`,
			{
				stripePaymentRef,
				status
			},
			headers
		)
		
		dispatch(joinSeminar(res.data))
	}

	catch (err) {
		showErrorType(err, dispatch)
	}
	
}

export const actionCreateSeminar = (data) => async dispatch => {
	const headers = getHeaders();
	if (headers === null) return

	try {
		const res = await Axios.post(
			`${URI}/seminar/create-seminar`,
			data,
			headers
		)
		console.log(res.data)
		dispatch(appendClassroomSeminar(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionUpdateSeminar = (id, data) => async dispatch => {
	const headers = getHeaders();
	if (headers === null) return

	console.log(id, data)
	try {
		const res = await Axios.put(
			`${URI}/seminar/update-seminar/${id}`,
			data,
			headers
		)
		console.log(res)
		dispatch(updateClassroomSeminar({
			open: false,
			seminar: res.data
		}))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionToggleClassroomUsersJoinRequestListOpen = (open,classroomId) => async (dispatch, getState) => {
	// make a request here to get all the join request to the classroom
	if(open){
		const headers = getHeaders();
		if (headers === null) return
	
		try {
			const res = await Axios.get(
				`${URI}/classroom/classroom-join-requests/${classroomId}`,
				headers
			)
			console.log(res.data)
			dispatch(setClassroomJoinRequests(res.data))
		}
		catch (err) {
			showErrorType(err, dispatch)
		}
	}
	else{
		dispatch(toggleClassroomUsersJoinRequestListOpen(open));
	}
}

export const actionToggleClassroomUsersMembersListOpen = (open,classroomId) => async (dispatch, getState) => {
	if(open){
		const headers = getHeaders();
		if (headers === null) return
	
		try {
			const res = await Axios.get(
				`${URI}/classroom/classroom-members/${classroomId}`,
				headers
			)
			dispatch(setClassroomMembers(res.data))
		}
		catch (err) {
			showErrorType(err, dispatch)
		}
	}
	else{
		dispatch(toggleClassroomMembersListOpen(open));
	}
}

export const actionArchiveSeminar = (
	seminarId = null,
	archived = true
) => async (dispatch, getState) => {
	const headers = getHeaders();
	if (headers === null) return

	try {
		const res = await Axios.put(
			`${URI}/seminar/archive-seminar/${seminarId}`,
			{archived},
			headers
		)
		console.log(res)
		dispatch(updateClassroomSeminar({
			open: false,
			seminar: res.data
		}))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionChangeClassroomProfilePicture = (data,file) => async (dispatch,getState) => {
		
	let headers = getHeaders();
	if(headers === null) return

	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	try{
		let formData = new FormData();
		for (const [key, value] of Object.entries(data)) {
			formData.append(key, value);
		}

		if(file){ formData.append('image', file); }

		dispatch(setClassroomLoading())
		const res = await Axios.put(
			`${URI}/classroom/change-classroom-profile-picture`,
			formData,
			headers
		)

		setTimeout(() => {
			dispatch(setClassroomProfilePicture(res.data))   
		}, 1000);
	} catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionChangeClassroomTimelinePicture = (data,file) => async (dispatch,getState) => {
		
	let headers = getHeaders();
	if(headers === null) return

	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	try{
		let formData = new FormData();
		for (const [key, value] of Object.entries(data)) {
			formData.append(key, value);
		}
		if(file){ formData.append('image', file); }

		dispatch(setClassroomLoading())
		const res = await Axios.put(
			`${URI}/classroom/change-classroom-timeline-picture`,
			formData,
			headers
		)
		
		setTimeout(() => {
			dispatch(setClassroomTimelinePicture(res.data))  
		}, 1000);

	} catch(err){ 
		showErrorType(err, dispatch)
	}
}

export const actionToggleSeminarAttendeesOpen = (
	open = true,
	seminarId = null
) => async (dispatch, getState) => {
	if(open){
		const headers = getHeaders();
		if (headers === null) return
	
		try {
			const res = await Axios.get(
				`${URI}/seminar/seminar-joiners/${seminarId}`,
				headers
			)
			console.log(res.data)
			dispatch(toggleSeminarAttendees({
				attendees: res.data,
				open
			}))
		}
		catch (err) {
			showErrorType(err, dispatch)
		}
	}
	else{
		dispatch(toggleSeminarAttendees({
			attendees: [],
			open
		}))
	}
}


export const actionGetAllClassSeminar = (
	classroomId = null,
	page = 1
) => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	try {
		let cancel
		const params = `?page=${page}`
		const res = await Axios.get(
			`${URI}/seminar/classroom-seminars/${classroomId}${params}`,
			{
				...headers,
				cancelToken: new Axios.CancelToken(c => cancel = c)
			}
		)
		dispatch(setAllSeminars(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
	
}

export const actionToggleCreateClassroomFormOpen = open => async (dispatch, getState) => {
	dispatch(toggleCreateClassroomFormOpen(open))
}

export const actionCreateClassroom = data => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setClassroomLoading());
	
	try {
		const res = await Axios.post(
			`${URI}/classroom/create-classroom`,
			data,
			headers
		)
		dispatch(appendUserCreatedClass(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

export const actionUpdateClassroom = (classroomId, data) => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setClassroomLoading());
	
	try {
		const res = await Axios.put(
			`${URI}/classroom/update-classroom/${classroomId}`,
			data,
			headers
		)
		dispatch(updateSelectedClassroom(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

export const actionSetSelectedClass = classId  => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setClassroomLoading());

	try {
		const res = await Axios.get(
			`${URI}/classroom/classroom-details/${classId}`,
			headers
		)
		dispatch(setSelectedClassroom(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

export const actionRequestToJoinClassroom = classId  => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setClassroomLoading());
	
	try {
		const res = await Axios.put(
			`${URI}/classroom/request-to-join-classroom/${classId}`,
			{},
			headers
		)
		dispatch(addToClassroomJoinRequests(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

export const actionCancelRequestToJoinClassroom = classId  => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setClassroomLoading());
	
	try {
		const res = await Axios.put(
			`${URI}/classroom/cancel-request-to-join-classroom/${classId}`,
			{},
			headers
		)
		dispatch(removeToClassroomJoinRequests(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}


export const actionAcceptToClassroom = (classroomId, userId) => async dispatch => {

  const headers = getHeaders();
  if (headers === null) return

  try {
    const res = await Axios.put(
      `${URI}/classroom/accept-to-classroom`,
      { userId, classroomId },
      headers
    )
		// console.log(res.data)
    dispatch(acceptToClassroomJoinRequests(res.data))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }
}

export const actionDeclineToClassroom = (classroomId, userId) => async dispatch => {

  const headers = getHeaders();
  if (headers === null) return

  try {
    const res = await Axios.put(
      `${URI}/classroom/decline-to-classroom`,
      { userId, classroomId },
      headers
    )
		dispatch(removeToClassroomJoinRequests(res.data))
		// console.log(res.data)
    // dispatch(deleteClassroom(res.data))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }
}

export const actionRemoveToClassroom = (classroomId, userId) => async dispatch => {

  const headers = getHeaders();
  if (headers === null) return

  try {
    const res = await Axios.put(
      `${URI}/classroom/remove-to-classroom`,
      { userId, classroomId },
      headers
    )
		dispatch(removeToClassroom(res.data))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }
	
}

// export const actionUpdateClassroomName = data => async (dispatch) => {

// 	const headers = getHeaders();
// 	if (headers === null) return

// 	dispatch(setClassroomLoading());
// 	const {
// 		_id,
// 		name
// 	} = data;

// 	try {
// 		const res = await Axios.put(
// 			`${URI}/classroom/updateClassroomName`,
// 			{
// 				_id,
// 				name
// 			},
// 			headers
// 		)archive
// 		console.log(res)
// 	}
// 	catch (err) {
// 		showErrorType(err, dispatch)
// 	}
// }

// export const actionUpdateClassroomDescription = data => async (dispatch) => {

// 	const headers = getHeaders();
// 	if (headers === null) return

// 	dispatch(setClassroomLoading());
// 	const {
// 		_id,
// 		description
// 	} = data;

// 	try {
// 		const res = await Axios.put(
// 			`${URI}/classroom/updateClassroomDescription`,
// 			{
// 				_id,
// 				description
// 			},
// 			headers
// 		)
// 		console.log(res)
// 	}
// 	catch (err) {
// 		showErrorType(err, dispatch)
// 	}
// }

// export const actionUpdateClassroomClassroomImage = data => async (dispatch) => {

// 	const headers = getHeaders();
// 	if (headers === null) return

// 	dispatch(setClassroomLoading());
// 	const {
// 		_id,
// 		classroomImage
// 	} = data;

// 	try {
// 		const res = await Axios.put(
// 			`${URI}/classroom/updateClassroomClassroomImage`,
// 			{
// 				_id,
// 				classroomImage
// 			},
// 			headers
// 		)
// 		console.log(res)
// 	}
// 	catch (err) {
// 		showErrorType(err, dispatch)
// 	}
// }

// export const actionUpdateClassroomClassroomTimelineImage = data => async (dispatch) => {

// 	const headers = getHeaders();
// 	if (headers === null) return

// 	dispatch(setClassroomLoading());
// 	const {
// 		_id,
// 		classroomTimelineImage
// 	} = data;

// 	try {
// 		const res = await Axios.put(
// 			`${URI}/classroom/updateClassroomClassroomTimelineImage`,
// 			{
// 				_id,
// 				classroomTimelineImage
// 			},
// 			headers
// 		)
// 		console.log(res)
// 	}
// 	catch (err) {
// 		showErrorType(err, dispatch)
// 	}
// }

// export const actionUpdateClassroomClassroomImageSrcFolder = data => async (dispatch) => {

// 	const headers = getHeaders();
// 	if (headers === null) return

// 	dispatch(setClassroomLoading());
// 	const {
// 		_id,
// 		classroomImageSrcFolder
// 	} = data;

// 	try {
// 		const res = await Axios.put(
// 			`${URI}/classroom/updateClassroomClassroomImageSrcFolder`,
// 			{
// 				_id,
// 				classroomImageSrcFolder
// 			},
// 			headers
// 		)
// 		console.log(res)
// 	}
// 	catch (err) {
// 		showErrorType(err, dispatch)
// 	}
// }



// export const actionGetClassroomListInAdmin = data => async (dispatch) => {

// 	const headers = getHeaders();
// 	if (headers === null) return

// 	dispatch(setClassroomLoading());
// 	// const {
// 	// 	adminId
// 	// } = data;

// 	try {
// 		// const res = await Axios.get(
// 		// 	`${URI}/classroom/getClassroomListInAdmin?adminId=${admin}`,
// 		// 	headers
// 		// )
// 		// console.log(res)
// 	}
// 	catch (err) {
// 		showErrorType(err, dispatch)
// 	}
// }

export const actionGetClassroomListCreatedByUser = createdBy => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setClassroomLoading());

	try {
		const res = await Axios.get(
			`${URI}/classroom/get-user-created-classroom/${createdBy}`,
			headers
		)
		dispatch(setUserCreatedClass(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionSearchClassroom = (query="", page = 1, limit = 10) => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setClassroomLoading());
	const q = `query=${query}&page=${page}&limit=${limit}`
	try {
		const res = await Axios.get(
			`${URI}/classroom/search-classroom?${q}`,
			headers
		)
		dispatch(setClassroomsSearchResults(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionGetSuggestedClassrooms = (page=1) => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setClassroomLoading());
	const params = `page=${page}`

	try {
		const res = await Axios.get(
			`${URI}/classroom/suggested-classroom?${params}`,
			headers
		)
		dispatch(setSuggestedClassrooms(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

export const actionGetJoinedClassroom = (page=1) => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setClassroomLoading());
	const params = `page=${page}`

	try {
		const res = await Axios.get(
			`${URI}/classroom/joined-classroom?${params}`,
			headers
		)
		console.log(res.data)
		dispatch(setJoinedClassrooms(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

// export const actionCloseSeminarPaymentForm = () => async dispatch => {
// 	dispatch(setShowPaymentForm(false))
// }

export const actionCheckIfUserIsInSeminar = (seminarId) => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setClassroomLoading());

	try {
		const res = await Axios.get(
			`${URI}/seminar/check-user-in-seminar/${seminarId}`,
			headers
		)
		dispatch(setShowPaymentForm(true))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}





// export const actionUpdate = data => async (dispatch, getState) => {

// 	const headers = getHeaders();
// 	if (headers === null) return

// 	dispatch(setClassroomLoading());
// 	try {
// 		const res = await Axios.post(
// 			`${URI}/classroom/update`,
// 			data,
// 			headers
// 		)
// 		dispatch(update(res.data))
// 	}
// 	catch (err) {
// 		showErrorType(err, dispatch)
// 	}

// }

export const actionDeleteClassroom = classroom => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return

	try {
		const res = await Axios.delete(
			`${URI}/classroom/${classroom._id}`,
			headers
		)
		dispatch(deleteClassroom(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}


export default classroomSlice.reducer;