import React, { useReducer, useEffect } from "react";
import ReactDom from "react-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import {
  actionToggleUserPostsWithPointsData
} from "../../app/Reducers/post/postSlice";
import Axios from 'axios';
import { getHeaders } from '../../app/helpers';
import PostCards from "../PostCards/PostCards";

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const initialState = {
  posts: [],
  hasNextPage: false,
  limit: 10,
  nextPage: null,
  page: 1,
  pagingCounter: 1,
  prevPage: null,
  totalDocs: 0,
  totalPages: 1,
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  width: "calc(100% - 40px)",
  maxWidth: "1070px",
  height: "calc(100vh - 100px)",
  overflow: "auto"
};

const joinArraysOfSelectedUserWithoutDuplicate = (
  oldArray,
  incommingArray,
  selectedUserId
) => {
  const combinedObject = {};
  // Iterate through the first array and add objects to the combined object
  oldArray.forEach((item) => {
    if(item.createdBy._id == selectedUserId){
      const id = item._id; // Change this to the unique property of your objects
      combinedObject[id] = item;
    }
  });
  // Iterate through the second array and add objects to the combined object (checking for duplicates)
  incommingArray.forEach((item) => {
    if(item.createdBy._id == selectedUserId){
      const id = item._id; // Change this to the unique property of your objects
      if (!combinedObject[id]) {
        combinedObject[id] = item;
      }
    }
  });
  // Convert the combined object back into an array
  const combinedArray = Object.values(combinedObject);
  return combinedArray;
}

const reducer = (state, action) => {
  const { 
    type,
    payload
  } = action;

  switch (type){
    case "fetchMorePosts" : {
      const {
        userId,
        docs,
        hasNextPage,
        limit,
        nextPage,
        page,
        pagingCounter,
        prevPage,
        totalDocs,
        totalPages,
      } = payload
      const posts = joinArraysOfSelectedUserWithoutDuplicate(
        [...state.posts],
        [...docs],
        userId
      )
      return {
        ...state,
        posts,
        hasNextPage,
        limit,
        nextPage,
        page,
        pagingCounter,
        prevPage,
        totalDocs,
        totalPages,
      };
      
    }
    default:
      return state;
  }
}

const PostsWithPointsPortal = () => {
  const dispatch = useDispatch();
  const {
    showUserPostsWithPointsData,
    adminDashboardSelectedUserId,
    adminDashboardSelectedUser
  } = useSelector(state => state.post);
  const [state, dispatchInComponent] = useReducer(reducer, initialState)

  const getUserPostsWithPoints = async (userId, page) => {
    const headers = getHeaders();
    if (headers === null) return

    let query = `&page=${page}`
    query = query.substring(1);

    try {
      const res = await Axios.get(
        `${URI}/posts/get-user-posts-with-points/${userId}?${query}`,
        headers
      )
      console.log(res.data)
      dispatchInComponent({
        type: "fetchMorePosts",
        payload: {
          ...res.data,
          userId
        }
      })

      // console.log(res.data)
    }
    catch (err) {
      console.log("Testing here")
    }
  }

  const onClose = () => {
    dispatch(actionToggleUserPostsWithPointsData(false))
  };

  useEffect(()=>{
    if(adminDashboardSelectedUserId)
      getUserPostsWithPoints(adminDashboardSelectedUserId, 1)
  },[adminDashboardSelectedUserId])

  return ReactDom.createPortal(<>
      <div className="GroupFormContainer">
        <Modal
          keepMounted
          open={showUserPostsWithPointsData}
          onClose={() => onClose()}
          aria-labelledby="modal-Group"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              style={{ marginBottom: 10 }}
            >
              <Typography id="modal-Group" variant="h6" component="h2">
                {adminDashboardSelectedUser ? `${adminDashboardSelectedUser.firstName}'s Posts` : ""}
              </Typography>

              <CloseIcon
                className="close-form-trigger"
                onClick={() => onClose()}
              />
            </Stack>
            <div className="gridItemLayout">
              {state.posts.map(post=> <PostCards
                                      key={post._id}
                                      minified={true}
                                      postData={post}/>
              )}
            </div>
            {
              state.posts.length && state.hasNextPage ? 
              <p
              className='load-more-comments'
              onClick={()=>{
                getUserPostsWithPoints(adminDashboardSelectedUserId, state.nextPage)
              }}>Load more...</p> : ""
            }
          </Box>
        </Modal>
      </div>
    </>,
    document.getElementById("user-posts-with-points-list-portal")
  );
};

export default PostsWithPointsPortal;