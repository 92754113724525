import React, {useState,memo,useCallback, useEffect} from 'react';
import './Todo.scss';
import TodoMoreOptions from './TodoMoreOptions';
import TodoActions from './TodoActions'
import { useDispatch, useSelector } from 'react-redux';
import {
    actionSetTodoInSideNav
} from '../../app/Reducers/todo/todoSlice';
import TodoTags from './TodoTags/TodoTags';
import TimeProgress from '../TimeProgress/TimeProgress'
import Tooltip from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';
import debounce from "lodash.debounce";
import { getHeaders } from '../../app/helpers';
import Axios from 'axios';
import { 
    systemRoles,
    formatDateInfo
} from '../Hooks/Constants';

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const Todo = ({
    todo,
    showDate = false
}) => {

    const dispatch = useDispatch();
    const {
        userData
    } = useSelector(state => state.user)
    const [todoData, setTodoData] = useState(todo)
    
    const [partialDesc, setPartialDesc] = useState(true);
    const sliderDefaultValue = todo?.points ? todo.points : 0

    const getShortDesc = desc => {
        if(desc.length > 28)
            return desc.substring(0,28) + "..."
        return desc.substring(0,28)
    }

    const handlePointChange = useCallback(
        debounce(
          async (todoId, val) => {
            const headers = getHeaders();
            if(headers === null) return
            try{
                const res = await Axios.put(
                    `${URI}/todos/set-todo-points`,
                    {
                        todoId,
                        points: val
                    },
                    headers
                )
                const { todoData, usersTotalPoints } = res.data
                setTodoData(prevTodo=>{
                    return {
                        ...prevTodo,
                        points: todoData.points
                    }
                })
            } catch(err){
                console.log(err)
            }
          }, 500
        ), []
    );

    useEffect(()=>setTodoData(todo),[todo])
    useEffect(()=>setTodoData(todo),[])

    return <div className='TodoContainer'>
        <TodoTags todo={todoData}/>
        <div className="todo-info">
            <Tooltip title={"View Details"}>
                <p
                className="todo-name"
                onClick={() => {
                    dispatch(actionSetTodoInSideNav(todo._id))
                }}>
                    {todo.name}
                </p>
            </Tooltip>
            {/* <p>{todo?.goal}</p> */}
            
            {todo?.description?.length ? <>
                <p className={`todo-description ${partialDesc ? "one-line" : ""}`}>{ partialDesc ? getShortDesc(todo.description) : todo.description }</p>
                {
                todo.description.length > 28 ? 
                <p
                className="todo-more-trigger"
                onClick={()=>setPartialDesc(!partialDesc)}>
                    {partialDesc ? "More..." : "Less..."}
                </p> : ""
                }
            </> : ""}
            
            <TimeProgress todo={todo}/>
            
        </div>
        {userData._id === todoData.assignedTo ? <>
            <TodoActions todo={todoData}/>
            <TodoMoreOptions todo={todoData} iconFontSize={"20px"}/>
        </> : ""}
        { showDate ? <p className="commentDate">{formatDateInfo(todoData.updatedAt)}</p> : "" }
        {
        userData.systemRole == systemRoles.superAdmin ||
        userData.systemRole == systemRoles.admin ?
        <div style={{
            paddingLeft: "8px",
            paddingRight: "8px"
        }}>
            <Slider
            defaultValue={sliderDefaultValue}
            valueLabelDisplay="auto"
            onChange={(e, val) => handlePointChange(todoData._id, val)}
            size="small"
            step={1}
            marks
            min={0} max={10}/>
        </div> : "" }
    </div>
}

export default memo(Todo);