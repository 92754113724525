//import TodoMoreOptions from '.components/TodoMoreOptions/TodoMoreOptions';//cut it and paste to the component
import React, { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {v1 as uuid} from "uuid";
import EditTodo from "./EditTodo";
import DeleteTodo from "./DeleteTodo"
import { useDispatch, useSelector } from 'react-redux';
import {
    actionDeleteTodo,
    actionSetSelectedTodoAndOpenCreateForm,
    actionSetTodoPopupMessage,
    actionSetSelectedTodoAndOpenEditForm
    // actionLinkTodoToOtherTodo
} from '../../app/Reducers/todo/todoSlice';
import LinkIcon from '@mui/icons-material/Link';

const TodoMoreOptions = ({
    todo,
    iconFontSize="inherit"
}) => {
    const dispatch = useDispatch();
    const {
        userData
    } = useSelector(state => state.user);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [editTodoOpen, setEditTodoOpen] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleDelete = () => {
        if(!todo?.moments?.length && todo.status === 0){
            setDeleteConfirmationOpen(true)
        } else{
            dispatch(actionSetTodoPopupMessage(true,`error`,`"You cannot delete Todo with moments, already started, paused or done. Also only the user assigned in Todo can delete it."`))
        }
    }

    const onDeleteTodo = todoId => {
        dispatch(actionDeleteTodo(todoId))
        setDeleteConfirmationOpen(false)
    }

    const menuOptions = [
        {
            id: uuid(),
            name: "Edit",
            icon: <EditIcon fontSize="small"/>,
            callback: () => {
                if(todo.assignedTo?._id === userData._id || todo.assignedTo === userData._id){
                    dispatch(actionSetSelectedTodoAndOpenEditForm(todo))
                } else {
                    dispatch(actionSetTodoPopupMessage(
                        true,
                        "error", 
                        "Unable to edit todo. You can only edit assigned to you."
                    ))
                }
            }
        },
        {
            id: uuid(),
            name: "Create Linked Todo",
            icon: <LinkIcon fontSize="small"/>,
            callback: () => {
                if(todo.assignedTo?._id === userData._id || todo.assignedTo === userData._id){
                    // setEditTodoOpen(todo,true)
                    dispatch(actionSetSelectedTodoAndOpenCreateForm(todo))
                } else {
                    dispatch(actionSetTodoPopupMessage(
                        true,
                        "error", 
                        "Unable to edit todo. You can only edit assigned to you."
                    ))
                }
            }
        },
        {
            id: uuid(),
            name: "Delete",
            icon: <DeleteIcon fontSize="small"/>,
            callback: () => {
                if(todo.assignedTo?._id === userData._id || todo.assignedTo === userData._id){
                    handleDelete()
                }
                else{
                    dispatch(actionSetTodoPopupMessage(
                        true,
                        "error", 
                        "Unable to delete todo. You can only delete assigned to you."
                    ))
                }
            }
        }
    ];

    const getMenuItems = () => {
        return menuOptions.map(option=>(
            <MenuItem
            key={option.id}
            onClick={()=>{
                option.callback()
                setAnchorEl(null)
            }}>
                <ListItemIcon style={{minWidth: 32}}>
                    {option.icon}
                </ListItemIcon>
                <ListItemText>{option.name}</ListItemText>
            </MenuItem>
        ))
    }
    
    return <div className='TodoMoreOptionsContainer'>
        {/* <EditTodo/> */}
        <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size="small"
        onClick={handleClick}
        sx={{padding: "2px"}}>
            <MoreHorizIcon sx={{fontSize: iconFontSize}}/>
        </IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            {getMenuItems()}
        </Menu>
        {/* <EditTodo
            todo={todo}
            editTodoOpen={editTodoOpen}
            setEditTodoOpen={setEditTodoOpen}
        /> */}
        <DeleteTodo
            todo={todo}
            deleteConfirmationOpen={deleteConfirmationOpen}
            setDeleteConfirmationOpen={setDeleteConfirmationOpen}
            onDeleteTodo={onDeleteTodo}
        />
        
    </div>
}

export default TodoMoreOptions;