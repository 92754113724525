//import BigBroSideOptions from '.components/BigBroSideOptions/BigBroSideOptions';//cut it and paste to the component
import React from 'react';
import './BigBroSideOptions.scss';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import {
    actionGetChallenges,
    actionGetMoments
} from '../../app/Reducers/moment/momentSlice';
import { useSelector, useDispatch } from 'react-redux';

const BigBroSideOptions = ({

}) => {

    const dispatch = useDispatch();

    return <div className='BigBroSideOptionsContainer'>
        <h2 className="heading-style-01">Training data filter</h2>
        <div className="search-filter">
            <TextField id="training-data-filter" label="Search" variant="outlined" />
        </div>
        <div className="filter-by-data">
            <h2>Filters</h2>
            <FormGroup className="filter-list">
                <FormControlLabel control={<Checkbox defaultChecked />} label="Not answered" />
                <FormControlLabel control={<Checkbox />} label="Anwered" />
            </FormGroup>
        </div>
        <div className="filter-by-moment-type">
            <h2>Filters by type</h2>
            <button onClick={()=>dispatch(actionGetChallenges)}>Questions</button>
            <button onClick={()=>dispatch(actionGetMoments)}>Reflections</button>
            <FormGroup className="filter-list">
                <FormControlLabel control={<Checkbox defaultChecked />} label="Challenges" />
                <FormControlLabel control={<Checkbox />} label="Reflections" />
            </FormGroup>
        </div>
    </div>
}

export default BigBroSideOptions;