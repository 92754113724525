import { createSlice, current } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';
import moment from 'moment';

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const initialState = {
	todoInSideNav: null,
	scheduleInSideNav: null,
	todoList: [],
	thrillingTasks: [],
	calendarSchedules: [],
	isSideNavOpen: false,
	isTodoListLoading: false,
	isTodoDetailsLoading: false,
	isTodoPostsLoading: false,
	todoHistory: [],
	recentlyFinishedTask: [],
	selectedTodo: null,
	createTodoFormOpen: false,
	editTodoFormOpen: false,
	todoCategoryList: [],
	createTodoCategoryFormOpen: false,
	editTodoCategoryFormOpen: false,
	triggeredFromCreateTodoForm: false,
	triggeredFromEditTodoForm: false,
	totalTodoPoints: 0,
	todoWithPointsCount: 0,
	todoPostCount: 0,

	// schedule
	scheduleToUpdate: null,
	openEditScheduleForm: false,
	currSelectedDate: moment(new Date()).format('YYYY-MM-DD'),
	calendarLoading: false,

	// linked tasks
	linkedTasks: [],

	// list of todo pagination
	hasNextPage: false,
	hasPrevPage: false,
	limit: 10,
	nextPage: null,
	page: 1,
	pagingCounter: 1,
	prevPage: null,
	totalDocs: 0,
	totalPages: 1,

	// list of todo posts pagination
	hasNextPageOfTodoPosts: false,
	nextPageOfTodoPosts: null,
	pageOfTodoPosts: 1,

	// list of todo parameters
	todoTypesArray: [],
	todoStatusArray: [0,1,2],
	dateFilter: "all",
	goalFilter: "",

	// popup messages: 
	popupType: "error",
	popupOpen: false,
	popupMessage: "Error",
			
	// show todo list
	showTodoList: true,

	// creation of todo
	showCreateTodoForm: false,
	createTodoTitle: "",
	createTodoDescription: "",
	createTodoGoal: "",
			

	// moments
	sideNavMoments: [],
	// list of todo pagination
	hasNextPageInTodoMoments: false,
	limitInTodoMoments: 10,
	nextPageInTodoMoments: null,
	totalDocsInTodoMoments: 0,
	totalPagesInTodoMoments: 1,

	// data to show in the admin dashboard
	showUserTodosWithPointsData: false,
	adminDashboardSelectedUserId: null,
	adminDashboardSelectedUser: null,

};

export const todoSlice = createSlice({
	name: 'todo',
	initialState,
	reducers: {
		toggleUserTodosWithPointsData: (state,action) => {
			state.showUserTodosWithPointsData = action.payload.open
			state.adminDashboardSelectedUserId = action.payload.user ? action.payload.user._id : null
			state.adminDashboardSelectedUser = action.payload.user ? action.payload.user : null
		},
		setAppendTodoMoments: (state, action) => {
			// moments
			if(state.sideNavMoments.length == 2){
				state.sideNavMoments = [...action.payload.docs]
			}
			else{
				// prevent data duplicates
				const ids = new Set([...state.sideNavMoments].map(d => d._id));
				state.sideNavMoments = [...state.sideNavMoments, ...action.payload.docs.filter(d => !ids.has(d._id))];
			}
			// list of todo pagination
			state.hasNextPageInTodoMoments = action.payload.hasNextPage
			state.limitInTodoMoments = action.payload.limit
			state.nextPageInTodoMoments = action.payload.nextPage
			state.totalDocsInTodoMoments = action.payload.totalDocs
			state.totalPagesInTodoMoments = action.payload.totalPages
		},
		setSelectedTodo: (state, action) => {
			state.selectedTodo = action.payload
			state.createTodoFormOpen = true
		},
		setSelectedTodoAndOpenEditForm: (state, action) => {
			state.selectedTodo = action.payload
			state.editTodoFormOpen = true
		},
		toggleEditTodoFormOpen: (state, action) => {
			state.editTodoFormOpen = action.payload
		},
		setTodoListLoading: (state, action) => {
			state.isTodoListLoading = true
		},
		setTodoDetailsLoading: (state, action) => {
			state.isTodoDetailsLoading = true
		},
		setTodoDetailsAndTodoListLoading: (state, action) => {
			state.isTodoListLoading = true
			state.isTodoDetailsLoading = true
		},
		setShowTodoDetailsPanelWithLoading: (state, action) => {
			state.isTodoDetailsLoading = true
			state.isSideNavOpen = true
		},
		setTodoInSideNav: (state, action) => {
			state.todoInSideNav = action.payload.todo
			state.todoHistory = action.payload.history
			state.scheduleInSideNav = null
			state.isSideNavOpen = true
			state.isTodoListLoading = false
			state.isTodoDetailsLoading = false
			state.todoPostCount = action.payload.todo.posts.length
			
			if(action.payload.todo.moments.length > 2) {
				state.sideNavMoments = [...action.payload.todo.moments].splice(0,2)
				state.hasNextPageInTodoMoments = true
				state.limitInTodoMoments = 10
				state.nextPageInTodoMoments = 1
			}
			else{
				state.sideNavMoments = action.payload.todo.moments
				state.hasNextPageInTodoMoments = false
				state.nextPageInTodoMoments = null
			}
		},
		updateTodoInSideNavMoment: (state, action) => {
			state.todoInSideNav = {
				...state.todoInSideNav,
			}
			state.sideNavMoments = [...state.sideNavMoments].map(moment=>{
				if(moment._id === action.payload._id){
					return action.payload
				}
				return moment
			})
			state.isTodoDetailsLoading = false
		},
		deleteMomentInSideNav: (state, action) => {
			state.sideNavMoments = [...state.sideNavMoments].filter(moment=>moment._id !== action.payload._id)
			state.isTodoDetailsLoading = false
		},
		setTodoAndScheduleInSideNav: (state, action) => {
			state.todoInSideNav = action.payload.todoInSideNav
			state.scheduleInSideNav = action.payload.scheduleInSideNav
			state.isSideNavOpen = true
			// todo details dapat ito
			state.isTodoListLoading = false
			state.isTodoDetailsLoading = false


			if(action.payload.todo.moments.length > 2) {
				state.sideNavMoments = [...action.payload.todoInSideNav.moments].splice(0,2)
				state.hasNextPageInTodoMoments = true
				state.limitInTodoMoments = 10
				state.nextPageInTodoMoments = 1
			}
			else{
				state.sideNavMoments = action.payload.todoInSideNav.moments
			}
		},
		setTodoList: (state, action) => {
			state.todoList = action.payload.docs
			state.isTodoListLoading = false
			state.hasNextPage = action.payload.hasNextPage
			state.hasPrevPage = action.payload.hasPrevPage
			state.limit = action.payload.limit
			state.nextPage = action.payload.nextPage
			state.page = action.payload.page
			state.pagingCounter = action.payload.pagingCounter
			state.prevPage = action.payload.prevPage
			state.totalDocs = action.payload.totalDocs
			state.totalPages = action.payload.totalPages
			
			state.thrillingTasks = [...action.payload.docs].filter(todo => todo.isThrillingTask)
			state.totalTodoPoints = action.payload.totalPoints
			state.todoWithPointsCount = action.payload.count
			
			if(action.payload.todoTypesArray)
				state.todoTypesArray = action.payload.todoTypesArray
			if(action.payload.todoStatusArray)
				state.todoStatusArray = action.payload.todoStatusArray
			if(action.payload.dateFilter)
				state.dateFilter = action.payload.dateFilter
			if(action.payload.hasOwnProperty("goalFilter") )
				state.goalFilter = action.payload.goalFilter
		},
		appendTodoList: (state,action) => {
			state.todoList = [action.payload.todo,...state.todoList]
			state.isTodoListLoading = false
			state.isTodoDetailsLoading = false
			if(action.payload.calendar){
				state.calendarSchedules = [...state.calendarSchedules,action.payload.calendar]
			}
			if(action.payload?.todo?.isThrillingTask){
				state.thrillingTasks = [action.payload.todo, ...state.thrillingTasks]
			}
			if(action.payload?.todo?.linkedTodos && state.todoInSideNav){
				if([...action.payload.todo.linkedTodos].includes(state.todoInSideNav._id)){
					state.todoInSideNav = {
						...state.todoInSideNav,
						linkedTodos: [...state.todoInSideNav.linkedTodos, action.payload.todo]
					}
				}
			}
			state.createTodoFormOpen = false
			state.selectedTodo = null
		},
		appendTodosInList: (state,action) => {
			state.todoList = [...action.payload.todoList, ...state.todoList]
			state.pageNum = action.payload.pageNum;
		},
		updateTodoInList: (state,action) => {
			const {
				todoData,
				calendar,
				popupType = null,
				popupOpen = null,
				popupMessage = null,
				moment,
				eventAction = ""
			} = action.payload;
			
			let isTodoInTodoList = false;
			// update todo list
			state.todoList = [...state.todoList].map(todoItem => {
				if(todoData?._id === todoItem?._id){
					isTodoInTodoList = true
					return todoData
				}
				return todoItem 
				// return todoData?._id === todoItem?._id ? todoData : todoItem
			})

			if(isTodoInTodoList){
				state.thrillingTasks = [...state.thrillingTasks].map(todoItem => {
					return todoData?._id === todoItem?._id ? todoData : todoItem
				})
			}

			state.isTodoListLoading = false
			state.isTodoDetailsLoading = false
			state.popupOpen = popupOpen ? popupOpen : false
			state.editTodoFormOpen = false

			if(popupType) state.popupType = popupType
			if(popupMessage) state.popupMessage = popupMessage

			let moments = state.sideNavMoments

			if(moment){
				moments = [...moments, moment]
			}

			// update the side nav
			const todoInSideNav = {...state.todoInSideNav}
			if(todoInSideNav){
				if(todoInSideNav._id === todoData._id){
					let sideNavUpdate = {
						...state.todoInSideNav
					}
					if(
						eventAction === "pauseTodo" ||
						eventAction === "endTodo" ||
						eventAction === "resumeTodo" ||
						eventAction === "startTodo"
					){
						sideNavUpdate = {
							...sideNavUpdate,
							status: todoData.status,
							timeConsumed: todoData.timeConsumed
						}
						// state.todoInSideNav = {
						// 	...state.todoInSideNav,
						// 	status: todoData.status,
						// 	timeConsumed: todoData.timeConsumed
						// }
					}
					else{
						state.sideNavMoments = moments
					}
					if(todoData.hasOwnProperty("isThrillingTask")){
						sideNavUpdate = {
							...sideNavUpdate,
							isThrillingTask: todoData.isThrillingTask
						}
					}
					state.todoInSideNav = sideNavUpdate
				}
			}

			// update also the calendardata
			// update the names of the calendar affected by the name change
			if(state.calendarSchedules.length){ 
				state.calendarSchedules = [...state.calendarSchedules.map(calendar=>{
					return calendar.todoId === todoData._id ? {
						...calendar,
						body: todoData._id,
						title: todoData.name 
					} : calendar
				})]
			}
			
			// find if calendar exist
			let scheduleIndex = calendar ? state.calendarSchedules.findIndex(s=>s._id===calendar._id) : -1;
			if(calendar){
				if(scheduleIndex>-1){
				  const schedulesUpdate = [...state.calendarSchedules]
				  schedulesUpdate[scheduleIndex] = calendar;
				  state.calendarSchedules = schedulesUpdate;
				} else {
					state.calendarSchedules = [...state.calendarSchedules, calendar];
				}

				// if calendar has data
				if(calendar?._id){
					// find first the if the returned calendar is in the todoHistory
					const historyExist = state.todoHistory.findIndex(history => history._id === calendar._id) > -1;
					if(historyExist){
						state.todoHistory = [...state.todoHistory].map(history => {
							if(history._id === calendar._id){
								return {
									_id: calendar._id,
									start: calendar.start,
									end: calendar.end,
									owner: calendar.owner
								}
							}
							return history;
						})
					} else {
						state.todoHistory = [
											...state.todoHistory,
											{
													_id: calendar._id,
													start: calendar.start,
													end: calendar.end,
													owner: calendar.owner
											}
						]
					}
				}
			}
		},
		setCalendarSchedules: (state,action) => {
			// if returned calendar schedule has data
			state.currSelectedDate = action.payload.currSelectedDate;
			state.calendarSchedules = [...action.payload.overAllCalendarData];
			state.calendarLoading = false;
			   
		},
		addCalendarSchedule: (state,action) => {
			state.calendarSchedules = [...state.calendarSchedules,action.payload]
			state.calendarLoading = false
		},
		openSideNav: (state,action) => {
			state.isSideNavOpen = action.payload
			if(action.payload === false){ // means closing the sidenav
				state.scheduleInSideNav = null
				state.todoInSideNav = null
			}
		},
		addMoment: (state,action) => {
			if(state.todoInSideNav){
				state.sideNavMoments = [action.payload,...state.sideNavMoments]
			}
			if(state.scheduleInSideNav){
				state.scheduleInSideNav = action.payload;
			}
			state.isTodoDetailsLoading = false
		},
		deleteTodo: (state,action) => {
			
			/**/
			const {
				popupOpen,
				popupType,
				popupMessage,
				_id
			} = action.payload;

			if(action.payload?.isThrillingTask){
				state.thrillingTasks = [...state.thrillingTasks].filter(thrillingTask => thrillingTask._id !== _id)
			}
			else{
				state.todoList = [...state.todoList].filter(todo => todo._id !== _id )
			}
			if(state.todoInSideNav){
				if(state.todoInSideNav.linkedTodos){
					if(state.todoInSideNav.linkedTodos.findIndex(todo => todo._id == _id) > -1){
						state.todoInSideNav = {
							...state.todoInSideNav,
							linkedTodos: [...state.todoInSideNav.linkedTodos].filter(linkedTodo => linkedTodo._id !== _id)
						}
					}
				}
			}

			/**/
			if(state.todoInSideNav){
				if(state.todoInSideNav._id === _id){
					state.isSideNavOpen = false;
				}
			}
			/**/
			state.popupOpen = popupOpen ? popupOpen : false;
			if(popupType) state.popupType = popupType
			if(popupMessage) state.popupMessage = popupMessage
			state.isTodoListLoading = false
			state.isTodoDetailsLoading = false
			
		},
		testDelete: (state,action) => {
			// state.todoList = [...state.todoList].filter(todo => {
			//	 console.log(`${todo._id} === ${todoId}`)
			//	 return todo._id != todoId
			// })
			// state.todoList = [...state.todoList].slice(1,state.todoList.length)
		},
		setTodoPopUpMessage: (state,action) => {
			const {
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;

			state.popupOpen = popupOpen ? popupOpen : false;
			if(popupOpen){
				if(popupType) state.popupType = popupType
				if(popupMessage) state.popupMessage = popupMessage
			}
			state.isTodoListLoading = false
			state.isTodoDetailsLoading = false
		},
		toggleTodoList: (state,action) => {
			state.showTodoList = action.payload;
		},
		updateCalendarSchedule: (state,action) => {
			state.openEditScheduleForm = false;
			const moments = [...state.sideNavMoments]
			state.todoInSideNav = {
				...action.payload.todo,
				// moments
			}
			state.sideNavMoments = moments
			state.todoHistory = state.todoHistory.map(rec => {
				if(rec._id === action.payload.scheduleId){
					return {
						...rec,
						start: action.payload.start,
						end: action.payload.end 
					}
				}
				return rec
			})
			state.calendarSchedules = [...state.calendarSchedules.map(schedule => {
				if(schedule._id === action.payload.scheduleId){
					return {
						...schedule,
						start: action.payload.start,
						end: action.payload.end 
					}
				}
				return schedule
			})]
			state.calendarLoading = false
		},
		toggleEditScheduleForm: (state, action) => {
			state.openEditScheduleForm = action.payload.open
			state.scheduleToUpdate = action.payload.scheduleData
		},
		setCalendarLoading: (state,action) => {
			state.calendarLoading = action.payload
		},
		setRecentlyFinishedTask: (state,action) => {
			state.recentlyFinishedTask = action.payload.docs;
			state.isTodoListLoading = false
		},
		setTodoRequestLoading: (state, action) => {
			state.isTodoListLoading = action.payload
		},
		toggleCreateTodoFormOpen: (state, action) => {
			state.createTodoFormOpen = action.payload
		},
		toggleCreateTodoCategoryFormOpen: (state, action) => {
			state.createTodoCategoryFormOpen = action.payload.open

			if(action.payload.srcForm == "createTodoForm"){
				state.triggeredFromCreateTodoForm = action.payload.triggeredFromForm
			}
			else if(action.payload.srcForm == "editTodoForm"){
				state.triggeredFromEditTodoForm = action.payload.triggeredFromForm
			}

			state.createTodoFormOpen = false
			state.editTodoFormOpen = false
		},
		toggleEditTodoCategoryFormOpen: (state, action) => {
			state.createTodoCategoryFormOpen = action.payload.open
			state.triggeredFromEditTodoForm = action.payload.triggeredFromEditTodoForm
			state.editTodoFormOpen = false
		},
		appendTodoCategory: (state, action) => {
			state.createTodoCategoryFormOpen = false
			state.isTodoListLoading = false

			if(state.triggeredFromCreateTodoForm){
				state.createTodoFormOpen = true
			}
			else if(state.triggeredFromEditTodoForm){
				state.editTodoFormOpen = true
			}

			if(state.triggeredFromCreateTodoForm || state.triggeredFromEditTodoForm){
				state.triggeredFromCreateTodoForm = false
				state.triggeredFromEditTodoForm = false
			}

			const tempArray = [...state.todoCategoryList]

			action.payload.map(todoCategory => {
				if([...state.todoCategoryList].findIndex(e=>e._id === todoCategory._id) === -1){
					tempArray.push(todoCategory)
				}
			})
			
			state.todoCategoryList = tempArray;

		},
		deleteTodoCategory: (state, action) => {
				state.todoCategoryList = [...state.todoCategoryList].filter(todoCategory => todoCategory._id != action.payload._id)
		},
		setUserLinkedTasks: (state, action) => {
			state.linkedTasks = action.payload
		},
		setTodoPostsLoading: (state, action) => {
			state.isTodoPostsLoading = action.payload
		},
		updateUserLinkedTasks: (state, action) => {
			// action.payload
			const {
				update,
				path
			} = action.payload

			const getTodoId = (data) => {
				if(typeof data === 'string')
					return data
				return data._id
			}

			const isEndOfPath = i => {
				return i === path.length - 1;
			}
			// this one will check the trailing path path[1-n]
			const returnUpdatedTodo = (todo, depth) => {
				if(isEndOfPath(depth)){ // means you are in the last of the path.
					return {
						...todo,
						linkedTodos: [...todo.linkedTodos].map(todo => {
							if(getTodoId(todo) == path[depth]){
								return {
									...todo,
									linkedTodos: update[0].linkedTodos
								}
							}
							return todo
						})
					}
				}
				else{ // find the next path in the current todo linkedTodos
					return {
						...todo,
						linkedTodos: [...todo.linkedTodos].map(todo => {
							if(getTodoId(todo) == path[depth]){
								return returnUpdatedTodo(todo, depth+1) 
							}
							return todo
						})
					}
				}

			}

			// forrest means array of trees
			// this one will check the fist path path[0]
			const updatedLinkedTasks = [...state.linkedTasks].map( // this iterate the forrest
				todo=>{
					if(todo._id == path[0]){ // if the target element is found, replace it
						return returnUpdatedTodo(todo, 1) // todo is the children of the current tree
					}
					return todo
				}
			)

			state.linkedTasks = updatedLinkedTasks;

		},
		setTodoPosts: (state, action) => {
			state.todoInSideNav = {
				...state.todoInSideNav,
				posts: action.payload
			}
		},
		addPostToSideNav: (state, action) => {
			state.todoInSideNav = {
				...state.todoInSideNav,
				posts: [action.payload, ...state.todoInSideNav.posts]
			}
		},
		setPostToSideNav: (state, action) => {
			const {
				docs,
				hasNextPage,
				nextPage,
				page,
				totalDocs
			} = action.payload;
			state.todoInSideNav.posts = [...state.todoInSideNav.posts,...docs];
			state.hasNextPageOfTodoPosts = hasNextPage;
			state.nextPageOfTodoPosts = nextPage;
			state.pageOfTodoPosts = page;
			state.todoPostCount = totalDocs;
		}
	}
});

export const {
	setSelectedTodoMoments,
	setTodoListLoading,
	setTodoDetailsLoading,
	setTodoDetailsAndTodoListLoading,
	setTodoInSideNav,
	updateTodoInSideNavMoment,
	setTodoAndScheduleInSideNav,
	deleteMomentInSideNav,
	setTodoList,
	appendTodoList,
	appendTodosInList,
	updateTodoInList,
	setCalendarSchedules,
	openSideNav,
	addMoment,
	deleteTodo,
	addCalendarSchedule,
	setTodoPopUpMessage,
	toggleTodoList,
	updateCalendarSchedule,
	toggleEditScheduleForm,
	setCalendarLoading,
	setRecentlyFinishedTask,
	setTodoRequestLoading,
	setShowTodoDetailsPanelWithLoading,
	setSelectedTodo,
	toggleCreateTodoFormOpen,
	toggleCreateTodoCategoryFormOpen,
	appendTodoCategory,
	setSelectedTodoAndOpenEditForm,
	toggleEditTodoFormOpen,
	toggleEditTodoCategoryFormOpen,
	setAppendTodoMoments,
	deleteTodoCategory,
	setUserLinkedTasks,
	updateUserLinkedTasks,
	// appendThrillingTasks
	testDelete,
	setTodoPostsLoading,
	setTodoPosts,
	addPostToSideNav,
	setPostToSideNav,
	toggleUserTodosWithPointsData
} = todoSlice.actions;


export const actionToggleUserTodosWithPointsData = (
	open = true,
	user = null
) => dispatch => {
	dispatch(toggleUserTodosWithPointsData({open, user}))
}

export const actiontestDelete = todo => dispatch => {
	dispatch(testDelete(todo))
}

const showErrorType = (err,dispatch) => {
	if (err.response) {
		dispatch(setTodoPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: err.response.data
			}
		))
	} else if (err.request) {
		dispatch(setTodoPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: 'Server error. No response received, request may have timed out, been blocked or server is down.'
			}
		))
	} else {
		dispatch(setTodoRequestLoading(false))
		console.log('Error:', err.message);
	}
}

export const actionCreateTodoPost = (data,file) => async (dispatch,getState) => {

	let headers = getHeaders();
	if(headers === null) return

	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	try{
		let formData = new FormData();
		formData.append('userId', data.userId);
		formData.append('postDescription', data.postDescription);
		formData.append('energy', data.energy);
		formData.append('emotion', data.emotion);
		formData.append('postImage', data.postImage);
		if(data.group){
			formData.append('group', data.group);
		}
		if(data.todo){
			formData.append('todo', data.todo);
		}
		// check if there is image data to be send
		if(file){
			formData.append('image', file);
		}

		const res = await Axios.post(
			`${URI}/posts/create-post`,
			formData,
			headers
		);
		setTimeout(() => {
			dispatch(addPostToSideNav(res.data))
		}, 1000);
		
	} catch(err){
		console.log(err)
	}
}

export const actionGetTodoPosts = (todoId, page=1) => async dispatch => {
	
	const headers = getHeaders();
	if(headers === null) return

	try{
		dispatch(setTodoPostsLoading(true))
		
		let cancel
		const params = `?page=${page}`
		const res = await Axios.get(
				`${URI}/posts/get-todo-posts/${todoId}${params}`,
				{
					...headers,
					cancelToken: new Axios.CancelToken(c => cancel = c)
				}
		)
		dispatch(setPostToSideNav(res.data))
	}
	catch(err){
		showErrorType(err, dispatch)
	}

}

export const actionGetMoreTodoPosts = (todoId, page=1) => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	try{
		dispatch(setTodoPostsLoading(true))
		
		let cancel
		const params = `?page=${page}`
		const res = await Axios.get(
				`${URI}/posts/get-todo-posts/${todoId}${params}`,
				{
					...headers,
					cancelToken: new Axios.CancelToken(c => cancel = c)
				}
		)
		dispatch(setPostToSideNav(res.data))
	}
	catch(err){
		showErrorType(err, dispatch)
	}

}

export const actionGetUsersLinkedTask = userId => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return
	
	try{
		const res = await Axios.get(
			`${URI}/todos/get-users-linked-task/${userId}`,
			headers
		)
		dispatch(setUserLinkedTasks(res.data))
	} catch(err){
		console.log("nag error sa linked task")
		showErrorType(err, dispatch)
	}

}

export const actionGetTodoLinkedTask = (todoId, path) => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return
	try{
		const res = await Axios.get(
			`${URI}/todos/get-todo-linked-task/${todoId}`,
			headers
		)

		dispatch(updateUserLinkedTasks({
			update: res.data,
			path
		}))
	} catch(err){
		showErrorType(err, dispatch)
	}

}

export const actionGetMoreTodoMoments = (
	todoId,
	nextPage=1
) => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return	
	const params = `?todoId=${todoId}&page=${nextPage}`

	const res = await Axios.get(
			`${URI}/todos/get-moments-of-todo${params}`,
			headers
	)

	dispatch(setAppendTodoMoments(res.data))
	
}

export const actionEditTodoCategory = noteCategoryUpdate => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	const res = await Axios.put(
			`${URI}/note-category/updateTodoCategory`,
			noteCategoryUpdate,
			headers
	)

	// dispatch(updateTodoCategory(res.data))
}

export const actionAddTodoCategory = todoCategory => async (dispatch) => {

	const headers = getHeaders();
	if(headers === null) return

	dispatch(setTodoListLoading());
	try{
		const res = await Axios.post(
			`${URI}/todo-category/create-todo-category`,
			todoCategory,
			headers
		)

		dispatch(appendTodoCategory([res.data]))
	}
	catch(err){
		showErrorType(err, dispatch)
	}

}

export const actionGetTodoCategories = () => async (dispatch) => {

	const headers = getHeaders();
	if(headers === null) return

	dispatch(setTodoListLoading());
	try{
		const res = await Axios.get(
			`${URI}/todo-category/get-todo-category-list`,
			headers
		)
		dispatch(appendTodoCategory(res.data))
	}
	catch(err){
		showErrorType(err, dispatch)
	}

}

export const actionToggleCreateTodoCategoryFormOpen = (
	open,
	triggeredFromForm = false,
	srcForm=""
) => async dispatch => {
	dispatch(toggleCreateTodoCategoryFormOpen({
		open,
		triggeredFromForm,
		srcForm
	}))
}

// export const actionToggleEditTodoCategoryFormOpen = (
// 	open,
// 	triggeredFromEditTodoForm = false
// ) => async dispatch => {
// 	dispatch(toggleEditTodoCategoryFormOpen({
// 		open,
// 		triggeredFromEditTodoForm
// 	}))
// }

export const actionSetSelectedTodoAndOpenCreateForm = todo => async dispatch => {
	dispatch(setSelectedTodo(todo))
}

export const actionToggleCreateTodoFormOpen = open => async dispatch => {
	dispatch(toggleCreateTodoFormOpen(open))
}

export const actionToggleEditTodoFormOpen = open => async dispatch => {
	dispatch(toggleEditTodoFormOpen(open))
}

export const actionSetSelectedTodoAndOpenEditForm = todo => async dispatch => {
	dispatch(setSelectedTodoAndOpenEditForm(todo))
}

// export const actionCreateTodoCategory = noteCategoryData => async dispatch => {

// 	const headers = getHeaders();
// 	if(headers === null) return

// 	const res = await Axios.post(
// 			`${URI}/note-category/createTodoCategory`,
// 			noteCategoryData,
// 			headers
// 	)

// 	dispatch(appendTodoCategory(res.data))
// }

// export const actionCreateLinkedTodo = todo => async dispatch => {	
//	 const headers = getHeaders();
//	 if(headers === null) return

//	 try{
//		 const res = await Axios.put(
//			 `${URI}/todos/create-linked-todo`,
//			 {
//				 todoId,
//				 todoIdToLink
//			 },
//			 headers
//		 )
//		 dispatch(updateTodoInList({
//			 todoData: res.data,
//			 popupType: "success",
//			 popupMessage: `Todo is successfully linked`,
//			 eventAction:"updateTodo"
//		 }))
//		 // console.log(res)
//	 } catch(err){
// 		showErrorType(err, dispatch)
// 	}
// }

export const actionSetTodoInSideNav = (todoId,schedule = null) => async (dispatch, getState) => {

	const headers = getHeaders();
	if(headers === null) return
	const selectedTodoId = schedule ? schedule.todoId : todoId;
			
	dispatch(setShowTodoDetailsPanelWithLoading())
	try{
		const res = await Axios.get(
			`${URI}/todos/get-todo-detail/${selectedTodoId}`,
			headers
		)
		
		if(schedule){
			dispatch(setTodoAndScheduleInSideNav({
				todo: res.data.todoDetails,
				sideNav: schedule,
				history: res.data.history,
				// moments: res.data?.moments ? res.data.moments : []
			}))
		}
		else{
			dispatch(setTodoInSideNav({
				todo: res.data.todoDetails,
				history: res.data.history,
				// moments: res.data?.moments ? res.data.moments : []
			}))
		}
	} catch(err){
		showErrorType(err, dispatch)
	}

};

export const actionGetCalendarDetail = scheduleId => (dispatch,getState) => {
			
	const headers = getHeaders();
	if(headers === null) return

	const {todo} = getState();

	const selectedSchedule = todo.calendarSchedules.find(schedule => schedule._id === scheduleId)
			
	actionSetTodoInSideNav(selectedSchedule.todo._id)

}

export const actionGetTodoList = (
	userId,
	paramObj
) => async (dispatch,getState) => {

	const headers = getHeaders();
	if(headers === null) return
			
	const {todo} = getState();
	const todoTypesArray = paramObj?.todoTypesArray ? paramObj.todoTypesArray : todo.todoTypesArray
	const todoStatusArray = paramObj?.todoStatusArray ? paramObj.todoStatusArray : todo.todoStatusArray
	const dateFilter = paramObj?.dateFilter ? paramObj.dateFilter : todo.dateFilter
	const page = paramObj?.page ? paramObj.page : todo.page
	const goalFilter = paramObj?.hasOwnProperty("goalFilter") ? paramObj.goalFilter : todo.goalFilter
	const limit = paramObj?.limit ? paramObj.limit : todo.limit;
			
	dispatch(setTodoListLoading())
			
	try{

		let query = '';

		if(todoTypesArray.length){	  query+=`&todoTypesFilter=${todoTypesArray}`}
		if(todoStatusArray){			query+=`&todostatusfilter=${todoStatusArray}`}
		if(dateFilter){				 query+=`&dateFilter=${dateFilter}`		  }
		if(goalFilter.length){		  query+=`&goalFilter=${goalFilter}`		  }
		if(page){					   query+=`&page=${page}`					  }
		if(limit){					  query+=`&limit=${limit}`					}
		if(query.length){			   query = query.substring(1);				 }

		const res = await Axios.get(
			`${URI}/todos/user-todos/${userId}?${query}`,
			headers
		)
		dispatch(setTodoList({
			...res.data.results,
			totalPoints: res.data?.usersTotalPoints?.totalPoints ? res.data.usersTotalPoints.totalPoints : 0,
			count: res.data?.usersTotalPoints?.count ? res.data.usersTotalPoints.count : 0,
			todoTypesArray,
			todoStatusArray,
			dateFilter,
			goalFilter,
			page
		}))
		
	} catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionGetRecentlyFinishedTask = userId => async (dispatch,getState) => {
	const headers = getHeaders();
	if(headers === null) return
			
	dispatch(setTodoListLoading());
	try{
		const res = await Axios.get(
			`${URI}/todos/user-todos/${userId}?todostatusfilter=${[3]}&page=${1}&limit=${10}`,
			headers
		)
		dispatch(setRecentlyFinishedTask(res.data.results))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionAppendTodoData = todo => dispatch => {
	dispatch(appendTodoList(todo))
}

export const actionAddTodoInList = todo => async (dispatch,getState) => {

	const headers = getHeaders();
	if(headers === null) return

	const {user} = getState();
	const userId = user.userData ? user.userData._id : null;

	dispatch(setTodoListLoading());
	try{
		const res = await Axios.post(
			`${URI}/todos/create`,
			{
				...todo,
				userId
			},
			headers
		)
		dispatch(appendTodoList({todo:res.data}))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
			
}

export const actionAddTodoAndStart = todo => async (dispatch,getState) => {
			
	const headers = getHeaders();
	if(headers === null) return
			
	const {user} = getState();
	const {chats} = getState();

	const userId = user.userData ? user.userData._id : null;

	let todoToSaveParams = {
		...todo,
		userId
	}

	if(todo.botTrainingData){
		// get also the training data retrieved
		todoToSaveParams = {
			...todoToSaveParams,
			userInquiryToBot: chats.botData.userQuery
		}
	}

	dispatch(setTodoDetailsAndTodoListLoading());
	try{
		const res = await Axios.post(
			`${URI}/todos/create-and-start`,
			{
				...todo,
				userId
			},
			headers
		)
		dispatch(appendTodoList(res.data))
	}
	catch(err){
		showErrorType(err, dispatch)
	}

}

export const actionPauseTodo = todoId => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	dispatch(setTodoDetailsAndTodoListLoading());
	try{
		const res = await Axios.put(
			`${URI}/todos/pause`,
			{ todoId },
			headers
		)
		const responseData = {
			...res.data,
			eventAction:"pauseTodo"
		}
		dispatch(updateTodoInList(responseData))
	}
	catch(err){
		showErrorType(err, dispatch)
	}

}

export const actionUpdateTodo = todoUpdate => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	dispatch(setTodoDetailsAndTodoListLoading());
	try{
		const res = await Axios.put(
			`${URI}/todos/update`,
			todoUpdate,
			headers
		)
		dispatch(updateTodoInList({
			todoData: res.data,
			calendar: null,
			popupOpen: true,
			popupType: "success",
			popupMessage: `Todo Updated`,
			eventAction:"updateTodo"
		}))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionEndTodo = (
	reflectionAndBotData
) => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	dispatch(setTodoDetailsAndTodoListLoading());
	try{
		const res = await Axios.post(
			`${URI}/todos/end`,
			reflectionAndBotData,
			headers
		)
		const responseData = {
			...res.data,
			eventAction:"endTodo"
		}
		dispatch(updateTodoInList(responseData))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionResumeTodo = todo => async (dispatch,getState) => {

	const headers = getHeaders();
	if(headers === null) return

	const {user} = getState();
	const userId = user.userData ? user.userData._id : null;

	const schedule = {
		todoId: todo._id,
		calendarId: '0',
		title: "",
		category: 'time',
		dueDateClass: '',
		userId: userId
	}

	dispatch(setTodoDetailsAndTodoListLoading());
	try{
		const res = await Axios.post(
			`${URI}/todos/resume`,
			schedule,
			headers
		)
		const responseData = {
			...res.data,
			eventAction:"resumeTodo"
		}
		dispatch(updateTodoInList(responseData))
	}
	catch(err){
		showErrorType(err, dispatch)
	}

}

export const actionAddTodo = todo => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	const schedule = {
		todoId: todo._id,
		calendarId: '0',
		title: '',
		category: 'time',
		dueDateClass: ''
	}

	dispatch(setTodoListLoading())
	try{
		const res = await Axios.post(
			`${URI}/todos/start`,
			schedule,
			headers
		)
		const responseData = {
			...res.data,
			eventAction:"startTodo"
		}
		dispatch(updateTodoInList(responseData))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionStartTodo = todo => async (dispatch,getState) => {

	const headers = getHeaders();
	if(headers === null) return

	const {user} = getState();
	const userId = user.userData ? user.userData._id : null;

	const schedule = {
		todoId: todo._id,
		calendarId: '0',
		title: '',
		category: 'time',
		dueDateClass: '',
		userId: userId
	}
			
	dispatch(setTodoDetailsAndTodoListLoading());
	try{
		const res = await Axios.post(
			`${URI}/todos/start`,
			schedule,
			headers
		)
		const responseData = {
			...res.data,
			calendar: {
				...res.data.calendar
			},
			eventAction:"startTodo"
		}
		dispatch(updateTodoInList(responseData))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionGetCalendarData = (
	date=null,
	resetCalendarData=false,
	userId=null,
	view='day'
) => async (dispatch,getState) => {
			
	const headers = getHeaders();
	if(headers === null) return
	const state = getState();

	dispatch(setCalendarLoading(true));
	try{
			
		const selectedUserId = userId ? userId : state.user.selectedUserData?._id;
		const currSelectedDate = date ? date : state.todo.currSelectedDate;

		const linkParam = selectedUserId ? `?userid=${selectedUserId}&view=${view}` : "";
		const calRes = await Axios.get(`${URI}/calendar/get-calendar-on-date/${currSelectedDate}${linkParam}`,headers)
		const breakCalRes = await Axios.get(`${URI}/break/get-break-on-date/${currSelectedDate}${linkParam}`,headers)

		const overAllCalendarData = [
			...calRes.data.map(schedule=>{
				return {
				...schedule,
				id: schedule._id
				}
			}),
			...breakCalRes.data.map(schedule=>{
				return {
				...schedule,
				id: schedule._id
				}
			})
		]
		console.log(calRes.data)

		dispatch(setCalendarSchedules(
			{
				overAllCalendarData,
				resetCalendarData,
				currSelectedDate
			}
		))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionOpenSideNav = isOpen => dispatch => {
	dispatch(openSideNav(isOpen))
}

export const actionAddMoment = (moment, imageFile=null) => async (dispatch,getState) => {
	let headers = getHeaders();
	if(headers === null) return
	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	// Get form datas
	const keys = Object.keys(moment);	
	let formData = new FormData();
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		formData.append(key, moment[key]);
	}
	if(imageFile){ formData.append('image', imageFile); }

	dispatch(setTodoDetailsLoading())

	try{
		const res = await Axios.put(
			`${URI}/todos/add-moment`,
			formData,
			headers
		)
		setTimeout(()=>{
			dispatch(addMoment(res.data))
		},500)
	}
	catch(err){
		showErrorType(err, dispatch)
	}

}

export const actionEditMoment = (momentUpdate, imageFile=null) => async (dispatch,getState) => {

	let headers = getHeaders();
	if(headers === null) return

	const keys = Object.keys(momentUpdate);

	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	dispatch(setTodoDetailsLoading())
	try{
		let formData = new FormData();
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			formData.append(key, momentUpdate[key]);
		}
		if(imageFile){
			formData.append('image', imageFile);
		}
		const editedMoment = await Axios.put(
			`${URI}/moments/edit-moment`,
			formData,
			headers
		)
		dispatch(updateTodoInSideNavMoment(editedMoment.data))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionDeleteMomentInSideNav = momentId => async (dispatch,getState) => {

	const headers = getHeaders();
	if(headers === null) return

	dispatch(setTodoDetailsLoading())
	try{
		const momentToDelete = await Axios.delete(
			`${URI}/moments/delete-moment/${momentId}`,
			headers
		)
		dispatch(deleteMomentInSideNav(momentToDelete.data))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionDeleteTodo = todoId => async (dispatch,getState) => {
			
	const headers = getHeaders();
	if(headers === null) return
			
	dispatch(setTodoDetailsAndTodoListLoading())
	try{
		const deletedTodo = await Axios.delete( `${URI}/todos/${todoId}`, headers )
		dispatch(deleteTodo({
			...deletedTodo.data,
			popupOpen: true,
			popupType: "success",
			popupMessage: `Todo named "${deletedTodo.data.name}" is successfully removed`
		}))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionAddBreaktime = (startDate = null, endDate = null) => async (dispatch,getState) => {

	const headers = getHeaders();
	if(headers === null) return

	const {user} = getState();
	const userId = user.userData ? user.userData._id : null;

	const breakData = {
		title: "Lunch break",
		userId
	};

	dispatch(setCalendarLoading(true))
	try{
		const res = await Axios.post(
						`${URI}/break`,
						breakData,
						headers
					);
		const breakSchedule = {
			...res.data,
			id: res.data._id
		}
		dispatch(addCalendarSchedule(breakSchedule))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
			
}

export const actionGetBreaktimeOnDateRange = (urlParam = "") => async (dispatch,getState) => {

	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.get(
			`${URI}/break/break-get-week-average-comparison?${urlParam}`,
			headers
		);
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionScheduleChangeTime = (
	scheduleId,
	todoId,
	prevStart,
	prevEnd,
	start,
	end
) => async (dispatch,getState) => {

	const headers = getHeaders();
	if(headers === null) return

	dispatch(setCalendarLoading(true))
	try{
		const res = await Axios.put(
			`${URI}/calendar/schedule-change-time`,
			{
				scheduleId,
				todoId,
				prevStart,
				prevEnd,
				start,
				end
			},
			headers
		)
		dispatch(updateCalendarSchedule(res.data))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionAppendTodosInList = (userId,todoList) => async (dispatch,getState) => {
	dispatch(appendTodosInList({
		todoList,
		pageNum: 1
	}))
}

export const actionTodoSearch = (searchText,paramObj) => async (dispatch,getState) => {

	const headers = getHeaders();
	if(headers === null) return

	const {todo} = getState();
	const page = paramObj?.page ? paramObj.page : todo.page
	try{
		const searchResults = await Axios.get(`${URI}/todos/search/${searchText}`,headers)
	}
	catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionGetTodosListInGoal = data => async (dispatch) => {
	const headers = getHeaders();
	if (headers === null) return
	dispatch(setTodoListLoading(true));
	const {
		goalId
	} = data;

	try {
		const res = await Axios.get(
			`${URI}/todos/getTodosListInGoal/${goalId}`,
			headers
		)
		dispatch(setTodoList({
			...res.data,
			goalFilter: goalId
		}))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionCreateThrillingTask = todo => async (dispatch,getState) => {
			
	const headers = getHeaders();
	if(headers === null) return
			
	const {user} = getState();
	const userId = user.userData ? user.userData._id : null;

	// dispatch(setTodoListLoading());
	try{
		const res = await Axios.post(
			`${URI}/todos/createThrillingTask`,
			{
				...todo,
				userId
			},
			headers
		)
		dispatch(appendTodoList({todo: res.data}))
	}
	catch(err){
		showErrorType(err, dispatch)
	}
			
}

export const actionSetTodoPopupMessage = ( popupOpen, popupType, popupMessage) => (dispatch,getState) => {
	dispatch(setTodoPopUpMessage({popupOpen, popupType, popupMessage}))
}

export const actionHideTodoPopupMessage = () => dispatch => {
	dispatch(setTodoPopUpMessage({popupOpen: false}))
}

export const actionToggleTodoList = open => dispatch => {
	dispatch(toggleTodoList(open))
}

export const actionToggleEditScheduleForm = (open,scheduleData=null) => dispatch => {
	dispatch(toggleEditScheduleForm({open,scheduleData}));
}

export const actionDeleteTodoCategory = noteCategory => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	const res = await Axios.delete(
			`${URI}/note-category/${noteCategory._id}`,
			headers
	)
	
	dispatch(deleteTodoCategory(res.data))
}

export const isSideNavOpen = state => state.todo.isSideNavOpen;
export const todoList = state => state.todo.todoList;
export const todoInSideNav = state => state.todo.selectedTodoInSideNav;
// export const calendarSchedules = state => state.todo.calendarSchedules;

export default todoSlice.reducer;