import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const initialState = {
	isLoading: false,
	motivationMessageList: [],
	selectedMotivationMessage: null,
	createMotivationMessageFormOpen: false,
	editMotivationMessageFormOpen: false,

	// list of todo pagination
	hasNextPage: false,
	hasPrevPage: false,
	limit: 10,
	nextPage: null,
	page: 1,
	pagingCounter: 1,
	prevPage: null,
	totalDocs: 0,
	totalPages: 1,

	// popup messages:
	popupType: "error",
	popupOpen: false,
	popupMessage: "Error"
};


export const motivationMessageSlice = createSlice({
	name: 'motivationMessage',
	initialState,
	reducers: {
		setMotivationMessageLoading: (state, action) => {
			state.isMotivationMessageLoading = action.payload
		},
		createMotivationMessage: (state, action) => {
			const {
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;

			state.motivationMessageList = [action.payload, ...state.motivationMessageList]
			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupType) state.popupType = popupType
			if (popupMessage) state.popupMessage = popupMessage
			state.isMotivationMessageLoading = false
		},
		addMotivationMessageDataOnList: (state, action) => {
			state.motivationMessageList = [action.payload, ...state.motivationMessageList]
			state.createMotivationMessageFormOpen = false
			state.isMotivationMessageLoading = false
		},
		setMotivationMessageList: (state, action) => {
			state.motivationMessageList = action.payload ? action.payload.docs : []
			state.isMotivationMessageLoading = false
		},
		update: (state, action) => {
			const {
				_id,
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;
			state.motivationMessageList = [...state.motivationMessageList].map(data => data._id === action.payload._id ? action.payload : data)
			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupType) state.popupType = popupType
			if (popupMessage) state.popupMessage = popupMessage
			state.isMotivationMessageLoading = false
			state.editMotivationMessageFormOpen = false
			state.selectedMotivationMessage = null
		},
		deleteData: (state, action) => {
			const {
				_id,
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;
			state.motivationMessageList = [...state.motivationMessageList].filter(data => data._id !== _id)

			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupType) state.popupType = popupType
			if (popupMessage) state.popupMessage = popupMessage
			state.isMotivationMessageLoading = false
		},
		toggleCreateMotivationMessageFormOpen: (state, action) => {
			state.createMotivationMessageFormOpen = action.payload
		},
		toggleEditMotivationMessageFormOpen: (state, action) => {
			const {
				open,
				selectedMotivationalMessage
			} = action.payload
			state.editMotivationMessageFormOpen = open
			state.selectedMotivationMessage = selectedMotivationalMessage
		},
		setPopUpMessage: (state, action) => {
			const {
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;

			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupOpen) {
				if (popupType) state.popupType = popupType
				if (popupMessage) state.popupMessage = popupMessage
			}
			state.isMotivationMessageLoading = false
			state.isMotivationMessageLoading = false
		},
		deleteMotivationMessage: (state, action) => {
			const {
				_id,
				// popupOpen,
				// popupType,
				// popupMessage
			} = action.payload;
			state.motivationMessageList = [...state.motivationMessageList].filter(data => data._id !== _id)

			// state.popupOpen = popupOpen ? popupOpen : false;
			// if (popupType) state.popupType = popupType
			// if (popupMessage) state.popupMessage = popupMessage
			state.isMotivationMessageLoading = false
		}
	}
});


export const {
	setMotivationMessageLoading,
	create,
	setMotivationMessageList,
	update,
	deleteData,
	setPopUpMessage,
	toggleCreateMotivationMessageFormOpen,
	toggleEditMotivationMessageFormOpen,
	deleteMotivationMessage,
	addMotivationMessageDataOnList
} = motivationMessageSlice.actions;
const showErrorType = (err, dispatch) => {
	if (err.response) {
		dispatch(setPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: err.response.data
			}
		))
	} else if (err.request) {
		console.log(err.response)
		dispatch(setPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: 'Server error. No response received, request may have timed out, been blocked or server is down.'
			}
		))
	} else {
		dispatch(setMotivationMessageLoading(false))
		console.log('Error:', err.message);
	}
}

export const actionToggleCreateMotivationMessageFormOpen = open => async (dispatch, getState) => {
	dispatch(toggleCreateMotivationMessageFormOpen(open))
}

export const actionToggleEditMotivationMessageFormOpen = (
	open = true,
	selectedMotivationalMessage = null
) => async dispatch => {
	dispatch(toggleEditMotivationMessageFormOpen({
		open,selectedMotivationalMessage
	}))
}

export const actionCreateMotivationMessage = data => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setMotivationMessageLoading());
	try {
		const res = await Axios.post(
			`${URI}/motivation-message/create-motivation-message`,
			data,
			headers
		)
		console.log(res.data)
		dispatch(addMotivationMessageDataOnList(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

/*/
import {
actionUpdateMotivationMessageMessage
} from '../../app/Reducers/motivation-message/motivationMessageSlice';

dispatch(actionUpdateMotivationMessageMessage({
_id: message._id,
// message: "value here"
})
/**/
export const actionEditMotivationMessage = data => async (dispatch) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setMotivationMessageLoading());
	const {
		_id,
		message
	} = data;

	try {
		const res = await Axios.put(
			`${URI}/motivation-message/update-motivation-message`,
			{ _id, message },
			headers
		)
		dispatch(update(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}


/*/
import {
actionGetMotivationMessageListInCreatedBy
} from '../../app/Reducers/motivation-message/motivationMessageSlice';

dispatch(actionGetMotivationMessageListInCreatedBy({
createdById: createdBy._id
})
export const actionGetMotivationMessageListInCreatedBy = data => async (dispatch) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setMotivationMessageLoading());
	const {
		createdBy
	} = data;

	try {
		const res = await Axios.get(
			`${URI}/motivation-message/getMotivationMessageListInCreatedBy?createdById=${createdBy}`,
			headers
		)
		console.log(res)
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}
/**/

export const actionGetMotivationMessageList = () => async (dispatch) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setMotivationMessageLoading());

	try {
		const res = await Axios.get(
			`${URI}/motivation-message/get-motivation-message-list`,
			headers
		)
		console.log(res)
		dispatch(setMotivationMessageList(res.data))
		// console.log(res)
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}



export const actionUpdate = data => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setMotivationMessageLoading());
	try {
		const res = await Axios.post(
			`${URI}/motivation-message/update`,
			data,
			headers
		)
		dispatch(update(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

export const actionDeleteMotivationMessage = motivationMessage => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return

	try {
		const res = await Axios.delete(
			`${URI}/motivation-message/${motivationMessage._id}`,
			headers
		)
		dispatch(deleteMotivationMessage(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export default motivationMessageSlice.reducer;