import React, { useEffect } from 'react'
import './Completion.scss'
import { useDispatch } from "react-redux";
import { actionJoinSeminar } from '../../app/Reducers/classroom/classroomSlice';
import { useSearchParams } from 'react-router-dom';
import { redirect } from "react-router-dom";

const Completion = ({
  
}) => {
  const dispatch = useDispatch()

  const [queryParameters] = useSearchParams()

  const recordData = async () => {
    const seminarId = localStorage.getItem('selectedSeminar');
    if(seminarId){
      try{
        await dispatch(actionJoinSeminar({
          seminarId,
          stripePaymentRef: queryParameters.get("payment_intent"),
          status: queryParameters.get("redirect_status")
        }))
      } catch(err){
        console.log(err)
      }
    }

    const {
      protocol,
      host
    } = window.location
    const params = `seminar=${seminarId}`
    window.location.href = `${protocol}//${host}/payment-completed?${params}`;
  }
  
  useEffect(()=>{
    recordData()
  }, [])
  
	return <div className='CompletionContainer'>
		<h1>Payment Loading...</h1>
	</div>
}

export default Completion;