//import OpenAIChatbox from '.components/OpenAIChatbox/OpenAIChatbox';//cut it and paste to the component
import React, {useState,useEffect,useRef,useReducer} from 'react';
// import './OpenAIChatbox.scss';
import {v1 as uuid} from "uuid";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Axios from 'axios';
import {
  getHeaders,
  openAIFormatText
} from '../../app/helpers';
import DOMPurify from 'dompurify'
import ReactMarkdown from 'react-markdown'

const schema = yup.object().shape({
	chat: yup.string()
});

const initialState = {
  openAIChat: [],
}

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const reducer = (state, action) => {
  const { 
    type,
    payload
  } = action;

  switch (type){
    case "askQuestion" : {
      console.log(payload)
      const openAIChat = [...state.openAIChat, payload]
      return {
        ...state,
        openAIChat
      }
    }
    case "botAnswer" : {
      console.log(payload)
      const openAIChat = [...state.openAIChat, payload]
      return {
        ...state,
        openAIChat
      }
    }
    default:
      return state;
  }
}

const sanitizedData = (data) => ({
  __html: DOMPurify.sanitize(data)
})

const OpenAIChatbox = ({}) => {

	const chatThreadRef = useRef()
  const [state, dispatch] = useReducer(reducer, initialState)
	
  // form setup
	const { control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
		defaultValues: {
			message:""
		},
		resolver: yupResolver(schema)
	});

  const actionSendMessageToBot = async message => {

    const headers = getHeaders();
    if(headers === null) return
  
    try{
      dispatch({
        type: "askQuestion",
        payload: {
          _id: uuid(),
          role: "assistant",
          content: message,
          isSender: true
        }
      })
      /**/
      const res = await Axios.get(
        `${URI}/bot/ask?q=${message}`,
        headers
      )
      // res.message data structure:
      // {
      //   "role": "assistant",
      //   "content": "The most popular stock in the stock market can vary over time, as popularity is influenced by various factors such as market trends, company performance, news events, and investor sentiment. As of October 2021, some of the most popular and widely-held stocks globally include technology giants like Apple Inc. (AAPL), Amazon.com Inc. (AMZN), Microsoft Corporation (MSFT), Tesla Inc. (TSLA), and Alphabet Inc. (GOOGL). However, it's important to note that the popularity of stocks can change, and investors should conduct thorough research and analysis before making any investment decisions."
      // }
      console.log(res)
      dispatch({
        type: "botAnswer",
        payload: {
          _id: uuid(),
          ...res.data.message,
          isSender: false
        }
      })
      /**/
    }
    catch(err){
      console.log(err)
    }
  }

	const onSubmit = async ({message}) => {
		reset()
		actionSendMessageToBot(message)
	}

	useEffect(() => {
		if (chatThreadRef) {
			chatThreadRef.current.addEventListener('DOMNodeInserted', event => {
				const { currentTarget: target } = event;
				target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
			});
		}
	},[state.openAIChat])

	return <div className='OpenAIChatboxContainer'>
		
		<div className="chat-box-header">
			<div className="user-icon">
				<img src="" alt="" />
			</div>
			<p className="name">Hungry2Learn bot</p>
		</div>

		<div
		className="conversation-container"
		ref={chatThreadRef}>
			
			{state.openAIChat.map(dialog => {
				const { _id, content, isSender }  = dialog;

				// console.log(message)

				// console.log(JSON.parse(CustomHTMLParser(note.content)))

				return <div key={_id} className={`chat-dialog ${isSender ? "sender" : ""}`}>
          {
          isSender ?
            <div className="message">{content}</div>
            :
            <ReactMarkdown children={content}/>
            // <div dangerouslySetInnerHTML={sanitizedData(openAIFormatText(content))} />
          }
          
				</div>
			})}

		</div>

		<div className="OpenAIChatbox-form-container">
			<form className="chatbox-form-container-wrapper" onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="message"
					control={control}
					defaultValue={""}
					render={({field}) => <TextField
						{...field}
						className="textArea"
						label="Message"
						variant="outlined"
						placeholder={"Message"}
						error={!!errors.message}
						type="text"
						size="small"
						sx={{ width: '100%' }}
						helperText={errors?.message ? errors?.message?.message : ''}
					/>
				}/>
				<Button
				type="submit"
				variant="outlined">Send</Button>
			</form>
		</div>

	</div>
}

export default OpenAIChatbox;