import React, {useState,useEffect,useCallback,memo} from 'react';
import './Dashboard.scss';
import MemberSelection from '../../components/MemberSelection/MemberSelection';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
    actionGetAverageCountData,
    actionGetUserEmergentIndexGraph
} from '../../app/Reducers/dashboard/dashboardSlice';
import {
    actionGetUsersLinkedTask
} from '../../app/Reducers/todo/todoSlice';
import { 
    actionGetUserEmergentIndex,
    actionSetSelectedUserData,
} from '../../app/Reducers/user/userSlice';
import { actionGetPostsOverAllScore } from '../../app/Reducers/post/postSlice';
import TodoList from "../../components/Todo/TodoList";
import MomentDashboardData from './MomentDashboardData/MomentDashboardData';
import TodoSideNav from "../../components/Todo/TodoSideNav";
import { useSearchParams } from 'react-router-dom';
import { systemRoles } from '../../components/Hooks/Constants';
import TaskTableAndPostTab from './TaskTableAndPostTab/TaskTableAndPostTab';
import EmergentIndexData from './EmergentIndexData';
import Axios from "axios";
// import Trades from '../../components/Trades/Trades';

const sendMessageToChatbot = async (message) => {
    const url = 'https://api.openai.com/v1/engines/gpt-3.5-turbo/completions';
    
    try {
      const response = await Axios.post(url, {
        prompt: message,
        max_tokens: 50, // Adjust the token limit as needed
      }, {
        headers: {
          'Authorization': `Bearer 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`,
        },
      });
  
      return response.data.choices[0].text;
    } catch (error) {
      console.error('Error communicating with the chatbot:', error);
      return 'Sorry, I encountered an error.';
    }
  }
  

const Dashboard = ({
}) => {
    const [params, setParams] = useSearchParams({ user: "" })
    const id = params.get("user")
    const {
        isDashboardLoading
    } = useSelector(state => state.dashboard);

    const {
        userData,
        selectedUserData,
    } = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [parentControlShow, setParentControlShow] = useState(false)
    const [calendarValue, setCalendarValue] = useState(new Date())


    const onSelectedDateChange = useCallback((newCalendarValue) => {
        setCalendarValue(newCalendarValue);
        const formattedEndDate = `endDate=${moment(newCalendarValue).format('YYYY-MM-DD')}`;
        dispatch(actionGetAverageCountData(selectedUserData._id,`${formattedEndDate}`))
    },[calendarValue])

    const closeTodoList = () => {
        setParentControlShow(false)
    }

    const onSelectUser = useCallback(e => {
        const endDate = calendarValue ? calendarValue : new Date();
        const formattedEndDate = `endDate=${moment(endDate).format('YYYY-MM-DD')}`;
        const id = e.target.value;
        window.history.replaceState(null, "", `/dashboard?user=${id}`)
        console.log(params.get("user"))
        setParams(prev=>{
            prev.set("user", id)
            return prev
        }, {replace: true})
        dispatch(actionSetSelectedUserData(id))
        dispatch(actionGetAverageCountData(id, `${formattedEndDate}`))
        dispatch(actionGetUsersLinkedTask(id))
        dispatch(actionGetPostsOverAllScore(id))
    },[])

    // const topPanel = useMemo(()=><div className="top-panel">
    //     <div className="mobile-horizontal-slider">
    //         <div className='WeeklyGraphSliderContainer'>
    //             <ProductivityAndEventContent/>
    //             {/* <PostSlider>
    //                 <ProductivityAndEventContent/>
    //                 {taskCategories.map(taskCategory=>(
    //                     <ProductivityAndEventContentPerCategory
    //                     key={taskCategory._id}
    //                     name={taskCategory.name}
    //                     taskCategoryData={taskCategory.categoryDataList}/>
    //                 ))}
    //             </PostSlider> */}
    //         </div>
    //     </div>
    // </div>,[taskCategories])
    useEffect(()=>{
        dispatch(actionGetUserEmergentIndex(id))
        dispatch(actionGetUserEmergentIndexGraph(id))
    },[id])
    useEffect(()=>{
        const endDate = calendarValue ? calendarValue : new Date();
        const formattedEndDate = `endDate=${moment(endDate).format('YYYY-MM-DD')}`;
        dispatch(actionSetSelectedUserData(id))
        dispatch(actionGetAverageCountData(id, `${formattedEndDate}`))
        dispatch(actionGetUsersLinkedTask(id))
        dispatch(actionGetPostsOverAllScore(id))
    },[])


    if(!userData) return ""

    return <div className={`DashboardContainer ${parentControlShow ? "show-task-list" : ""}`}>
        <TodoList
            defaultShow={false}
            parentControlShow={parentControlShow}
            callBack={closeTodoList}
        />
        <div className="dashboardWrapper">
            <div className="page-header-container">
                <h2 className="section-title-01">Dashboard</h2>
            </div>
            <div className="dashboard-actions-container">
                {
                userData.systemRole == systemRoles.superAdmin ||
                userData.systemRole == systemRoles.admin ?
                <MemberSelection onSelectUser={onSelectUser} /> : ""
                }
                <div style={{
                    marginLeft: "auto",
                    marginBottom: "8px"
                }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Select Date"
                            value={calendarValue}
                            onChange={onSelectedDateChange}
                            slotProps={{ textField: { size: 'small' } }}
                            renderInput={(params) => <TextField {...params}/>}
                        />
                    </LocalizationProvider>
                </div>
            </div>
            {/* <Trades /> */}
            {/* {topPanel} */}
            <EmergentIndexData/>
            {/* <div className="emergentIndexDataWrapper">
                <EmergentIndex defaultOpen={true}/>
                <ProductivityAndEventContent/>
            </div> */}
            <MomentDashboardData />
            <TaskTableAndPostTab userId={params.get("user")} />
            {isDashboardLoading ? <div className="loading-wrapper">
                <div className="loader"></div>
            </div> : ""}
        </div>
        <TodoSideNav/>
    </div>
        
}

export default memo(Dashboard);