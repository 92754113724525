import React, {memo} from 'react';
import './TermsOfService.scss';


const TermsOfService = ({
}) => {
    return <div
    className='TermsOfServiceContainer'
    style={{
      backgroundImage: "url('/long-text-bg.jpg')"
    }}
    >
      <div className="textWrapper">
        <h1>TERMS OF SERVICE AGREEMENT</h1>
        <p>Welcome to our task management app! This Terms of Service Agreement ("Agreement") governs your use of our app ("App"), so please read it carefully. By using our App, you agree to be bound by this Agreement. If you do not agree with any of the terms and conditions of this Agreement, please do not use our App.</p>
        <dl>
          <dt>License</dt>
          <dd>We grant you a limited, non-exclusive, non-transferable, revocable license to access and use our App for personal or commercial purposes. You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our App except as generally and ordinarily permitted through the App according to these terms.</dd>
        </dl>
        <dl>
          <dt>Intellectual Property</dt>
          <dd>Our App, including its software, design, graphics, text, images, and other content, is owned by us or our licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use any of our trademarks, logos, or service marks without our prior written permission.</dd>
        </dl>
        <dl>
          <dt>User Content</dt>
          <dd>You are solely responsible for any content, including text, images, and other materials, that you upload, publish, or display on our App ("User Content"). By posting User Content on our App, you grant us a non-exclusive, perpetual, irrevocable, royalty-free, worldwide license to use, copy, modify, publish, display, distribute, and create derivative works of your User Content in any media formats and through any media channels.</dd>
        </dl>
        <dl>
          <dt>Prohibited Uses</dt>
          <dd>You may not use our App for any illegal or unauthorized purpose, including but not limited to: (a) violating any intellectual property or other proprietary rights; (b) transmitting any viruses, worms, or other harmful software; (c) engaging in any fraudulent or deceptive activity; (d) impersonating any person or entity; (e) interfering with or disrupting the operation of our App; or (f) violating any applicable laws, rules, or regulations.</dd>
        </dl>
        <dl>
          <dt>Disclaimer of Warranties</dt>
          <dd>Our App is provided "as is" and without any warranty or condition, whether express, implied, or statutory. We do not warrant that our App will be uninterrupted, error-free, or free from viruses or other harmful components. We disclaim all warranties, including but not limited to: (a) any implied warranties of merchantability, fitness for a particular purpose, and non-infringement; and (b) any warranties arising out of course of dealing, usage, or trade.</dd>
        </dl>
        <dl>
          <dt>Limitation of Liability</dt>
          <dd>To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our App, even if we have been advised of the possibility of such damages. Our liability under this Agreement shall be limited to the amount you paid us, if any, for use of our App.</dd>
        </dl>
        <dl>
          <dt>Indemnification</dt>
          <dd>You agree to defend, indemnify, and hold us harmless from and against any claims, damages, costs, liabilities, and expenses (including but not limited to attorneys' fees) arising out of or in connection with your use of our App or your User Content.</dd>
        </dl>
        <dl>
          <dt>Modification of Agreement</dt>
          <dd>We may modify this Agreement at any time in our sole discretion. Any such modifications will be effective immediately upon posting the modified Agreement on our App. Your continued use of the app means you agree to the terms of service.</dd>
        </dl>
        <dl>
          <dt>Modification of Agreement</dt>
          <dd>We may modify this Agreement at any time in our sole discretion. Any such modifications will be effective immediately upon posting the modified Agreement on our App. Your continued use of the app means you agree to the terms of service.</dd>
        </dl>
      </div>
    </div>
}

export default memo(TermsOfService);


