//import NoteEditor from '.components/NoteEditor/NoteEditor';//cut it and paste to the component
import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';

const NoteEditor = ({
    notes,
    onSaveNote,
    setShowNoteEditor
}) => {
    const [updatedNote, setUpdatedNote] = useState(notes);
    // const [toolBarSettings, setToolBarSettings] = useState([ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]);
    // default is without for todo... this is the common note
    
    const {
        notesFor
    } = useSelector(state => state.note);


    return <div className='NoteEditorContainer'>

        <CKEditor
            editor={ ClassicEditor }
            data={notes}
            config={{
                toolbar: notesFor === "Planning" ? [ 'bold', 'italic', 'link' ] : [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]
            }}
            onReady={ editor => {
                // You can store the "editor" and use when it is needed.
                // console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                // console.log(editor)
                setUpdatedNote(data)
                // console.log( { event, editor, data } );
            } }
            onBlur={ ( event, editor ) => { // this might be applied on submit.
                // console.log( 'Blur.', editor );
            } }
            onFocus={ ( event, editor ) => { // this might be applied on click
                // console.log( 'Focus.', editor );
            } }
        />
        <div className="note-action-wrapper">
            <Button
                variant="outlined"
                onClick={() => onSaveNote(updatedNote)}
            >Save</Button>
            <Button
                variant="outlined"
                onClick={() => setShowNoteEditor(false)}
            >Cancel</Button>
        </div>
    </div>
}

export default NoteEditor;