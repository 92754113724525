import React, { useState, useReducer, useEffect } from 'react'
import Comment from './Comment';
import { getHeaders } from '../../app/helpers';
import CommentForm from '../CommentForm/CommentForm';
import Axios from 'axios';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const URI = "https://wingenio.world";
const schema = yup.object().shape({
  comment: yup.string().required("Write your comment before submitting")
});

const initialState = {
  comments: [],
  hasNextPage: false,
  limit: 10,
  nextPage: null,
  page: 1,
  pagingCounter: 1,
  prevPage: null,
  totalDocs: 0,
  totalPages: 1,
}

const reducer = (state, action) => {
  const { 
    type,
    payload
  } = action;
  switch (type){
    case "initialize" : {
      return {...state, ...payload};
    }
    case "update" : {
      if(payload.page == 1){ // overwrite the inital comments
        return {...state, ...payload};
      }
      else{
        let loadedComments = [...state.comments]
        // before putting the comments, remove the duplicate
        const fetchedComments = [...payload.comments]

        loadedComments = loadedComments.filter(loadedComment=>{
          return fetchedComments.findIndex(e => e._id.toString() != loadedComment._id.toString()) > -1;
        })

        const comments = [...loadedComments, ...fetchedComments]
        
        return {
          ...state,
          ...payload,
          comments
        };
      }
    }
    case "addComment" : {
      const comments = [...state.comments, payload]
      return { ...state, comments};
    }
    case "deleteComment" : {
      const comments = [...state.comments].filter(comment => comment._id !== payload._id);
      return { ...state, comments};
    }
    default:
      return state;
  }
}

export const CommentList = ({
  initialCommentArray=[],
  totalCommentCount,
  postData,
  updateCommentCount
}) => {

  const [state, dispatch] = useReducer(reducer, initialState)

  const loadMoreComments = async ({
    postId,
    page,
    limit
  }) => {
  
    const headers = getHeaders();
    if(headers === null) return;
  
    try{
      let query = `&postId=${postId}`;
      if(page)				{	query+=`&page=${page}` }
      if(limit)				{ query+=`&limit=${10} `}
      if(query.length){ query = query.substring(1); }
      const res = await Axios.get( 
          `${URI}/comments/load-more-comments?${query}`,
          headers
      )
      dispatch({
        type: "update",
        payload: {
          ...res.data,
          comments: [...res.data.docs]
        }
      })
      console.log(res)
    }
    catch(err){
      // alert(err, dispatch)
    }
  }

  const sendComment = async data => {
    const headers = getHeaders();
    if(headers === null) return
    try{
      const res = await Axios.post(
          `${URI}/comments/create-comment`,
          {
            ...data,
            postId: postData._id,
            postOwner: postData.createdBy
          },
          headers
      )
      dispatch({ type: "addComment", payload: res.data })
      updateCommentCount(totalCommentCount+1)
    }
    catch(err){
      console.log(err)
    }
  }

  const deleteComment = async commentId => {
    const headers = getHeaders();
    if(headers === null) return
    try{
      const res = await Axios.delete(
        `${URI}/comments/delete-comment/${commentId}`,
        headers
      )
      dispatch({ type: "deleteComment", payload: res.data })
      updateCommentCount(totalCommentCount-1)
    }
    catch(err){
      // showErrorType(err, dispatch)
    }

  }

  const { setError, control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
    defaultValues: {
      comment: ""
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = async ({comment}) => {
    sendComment({
      comment
    })
  }

  useEffect(() => {
		if (isSubmitSuccessful) {
			reset({ comment: "" });
		};
	}, [isSubmitSuccessful, reset]);

  useEffect(()=>{
    // const hasLoadMore = initialCommentArray.length < totalCommentCount;
    // if(hasLoadMore){
      dispatch({
        type: "initialize",
        payload:{
          comments: initialCommentArray,
          hasNextPage: true,
          limit: 10,
          nextPage: 1,
          page: 0,
          pagingCounter: 0,
          totalDocs: totalCommentCount,
        }
      })
    // }
  },[])

  return (
    <div className='CommentListContainer'>
      
      <div className="commentListWrapper"> 
        {state.comments.map(
          comment=><Comment
                    key={comment._id}
                    commentData={comment}
                    postId={comment.postRef}
                    deleteComment={deleteComment}/>
        )}
        {initialCommentArray.length < totalCommentCount ? <>
          {state.hasNextPage ? 
            <p
            className='load-more-comments'
            onClick={() => loadMoreComments({
              postId: postData._id,
              page: state.nextPage
            })}>Load more...</p>:""
          }
        </> : ""}
      </div>
      <CommentForm
      control = {control}
      onSubmit = {onSubmit}
      errors = {errors}
      handleSubmit = {handleSubmit}
      />
    </div>
  )
}

export default CommentList;