//import TodoTags from '.components/TodoTags/TodoTags';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import { ReactComponent as StarIcon } from '../../../Assets/ic_star.svg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import Tooltip from '@mui/material/Tooltip';
import LinkIcon from '@mui/icons-material/Link';
import { getTodoType } from "../../Hooks/Constants";
import { getTodoStatus } from '../../Hooks/Constants';

const TodoTags = ({
    todo
}) => {
    const statusData = getTodoStatus(todo?.status);
    
    return (
        <ul className="tags">
            <li>
                <Tooltip title={`Todo status: ${statusData.text}`}>
                    <span
                    className="tag status"
                    style={{
                        color: statusData.color,
                        background: statusData.background
                    }}>{statusData.text}</span>
                </Tooltip>
            </li>
            <li>
                <Tooltip title={`Todo type: ${getTodoType(todo.type)}`}>
                    <span className="tag type">
                        <span className="type-icon-container">
                            {todo.type === 0 || todo.type === 2 ? <AccessTimeIcon sx={{ width: '16px', display: 'block' }}/> : ""}
                            {todo.type === 1 || todo.type === 2 ? <LocalLibraryIcon sx={{ width: '16px', display: 'block' }}/> : ""}
                            {todo.type === 3 ? <SupervisorAccountIcon sx={{ width: '16px', display: 'block' }}/> : ""}
                        </span>
                    </span>
                </Tooltip>
            </li>
            {
            todo?.linkedTodos?.length ?
            <li>
                <Tooltip title={`Linked Task`}>
                    <span className="tag type">
                        <LinkIcon sx={{ fontSize: 16 }}/>
                    </span>
                </Tooltip>
            </li> : ""
            }
            <li>
                <Tooltip title={`Priority: ${todo?.priorityLevel}`}>
                    <span className="tag priority">
                        <span className="icon-left">
                            <StarIcon/> 
                        </span>
                        <span className="text">
                            P {todo?.priorityLevel}
                        </span>
                    </span>
                </Tooltip>
            </li>
            {todo.isThrillingTask ? 
            <li>
                <Tooltip title={`Thirilling Task`}>
                    <span className="tag thrillingTask">
                        <span className="text">Thirilling Task</span>
                    </span>
                </Tooltip>
            </li> : ""
            }
            {/* {todo.points ?  */}
            <li>
                <Tooltip title={`Points`}>
                    <span className="tag points">
                        <span className="text">{todo?.points ? todo.points : 0} Points</span>
                    </span>
                </Tooltip>
            </li> 
            
            {/* : "" */}
            {/* } */}
        </ul>
    )
}

export default TodoTags;