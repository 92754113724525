import React, { useEffect } from 'react'
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import './CommentForm.scss'
import { Controller } from "react-hook-form";
import Tooltip from '@mui/material/Tooltip';

export const EditCommentForm = ({
  control,
  onSubmit,
  errors,
  handleSubmit,
  setValue,
  defaultValue=""
}) => {

  useEffect(()=>{
    setValue("comment", defaultValue)
},[])

 
  return (
    <div className='EditCommentFormWrapper'>
      <form className="EditCommentForm" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="comment"
          control={control}
          defaultValue={defaultValue}
          render={({field}) => <TextField
            {...field}
            multiline={true}
            className="textArea"
            // label="Comment"
            variant="outlined"
            placeholder={"comment"}
            error={!!errors.comment}
            size="small"
            type="comment"
            sx={{
              width: '100%',
              fontSize: '12px'
            }}
            helperText={errors?.comment ? errors?.comment?.message : ''}
          />
        }/>
        <Tooltip title={"Update"}>
          <IconButton
          color="primary"
          aria-label="add an alarm"
          type="submit">
            <UpdateIcon />
          </IconButton>
        </Tooltip>
      </form>
    </div>
  )
}

export default EditCommentForm;