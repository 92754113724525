//import UserSearchAndSelectionModal from '.components/UserSearchAndSelectionModal/UserSearchAndSelectionModal';//cut it and paste to the component
import React, {useRef} from 'react';
import ReactDom from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import {
	actionSearchUsers,
  actionsShowUserSelection
} from '../../app/Reducers/user/userSlice';
import { NavLink } from "react-router-dom";
import KeywordSearch from '../KeywordSearch/KeywordSearch';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import UserCard from '../UserCard/UserCard';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "100px",
  left: "50%",
  transform: "translate(-50%, 0)",
  width: "calc(100% - 20px)",
  maxWidth: "900px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const UserSearchAndSelectionModal = ({
  showRoleAssignmentButton = false,
  showAddToGroupButton = false,
  selectedGroup = null
}) => {
	const searchInputRef = useRef(null)
  const dispatch = useDispatch()
	const {
    searchedUsersList,
    showRoleCandidates,
    activeRoleType
	} = useSelector((state) => state.user);
  const onClose = () => {
    dispatch(actionsShowUserSelection(false));
  };

	return ReactDom.createPortal(<>
    <div className='UserSearchAndSelectionModal'>
      <Modal
        keepMounted
        open={showRoleCandidates}
        onClose={() => onClose()}
        aria-labelledby="modal-Group"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            style={{ marginBottom: 10 }}
          >
            <Typography id="modal-Group" variant="h6" component="h2">
              {showAddToGroupButton ? "Add Users to Group" : "Select Users for a Role"}
            </Typography>

            <CloseIcon
              className="close-form-trigger"
              onClick={() => onClose()}
            />
          </Stack>
            
          <KeywordSearch
            searchInputRef={searchInputRef}
            reducerFunctionCallback={actionSearchUsers}
            label={"Search User"}
          />
          <div className="followLayoutContainer">
            {
            activeRoleType == 10 || activeRoleType == 1 ?
            <h3 className="section-title-04">{
              activeRoleType == 10 ? "Super Admin" :
              activeRoleType == 1 ? "Admin" :
              "Moderator"
            } Selection</h3>:""
            }
            <div className="suggestedPeopleList">
              {searchedUsersList.length ? (
                searchedUsersList.map((user) => <UserCard
                                                key={user._id}
                                                user={user}
                                                showRoleAssignmentButton={showRoleAssignmentButton}
                                                showAddToGroupButton={showAddToGroupButton}
                                                selectedGroup={selectedGroup}/>)
              ) : (
                <p style={{marginBottom: "12px"}}>Searched users</p>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  </>,
  document.getElementById("user-search-and-selection-wrapper-portal"))

}

export default UserSearchAndSelectionModal;

