import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const initialState = {
	isLoading: false,
	tradingPortfolioInfoList: [],
	setupFTMOTradingAccountFormOpen: false,
	selectedUserTradingPortfolio: {
		balance: 0,
		equity: 0,
		lotSize:0,
		userTotalBalance:0,
		totalDealsCount:0,
		totalClosedDealsCount:0,
		averageProfit:0,
		averageLoss:0,
		totalProfit:0,
		totalLoss:0,
		profitCount:0,
		lossCount:0,
		winRate:0,
		lossRate:0,
		rrr:0,
		expectancy:0,
		profitFactor:0,
		sharpeRatio:0
	},

	// list of todo pagination
	hasNextPage: false,
	hasPrevPage: false,
	limit: 10,
	nextPage: null,
	page: 1,
	pagingCounter: 1,
	prevPage: null,
	totalDocs: 0,
	totalPages: 1,

	// popup messages:
	popupType: "error",
	popupOpen: false,
	popupMessage: "Error"
};


export const tradingPortfolioInfoSlice = createSlice({
	name: 'tradingPortfolioInfo',
	initialState,
	reducers: {
		setTradingPortfolioInfoLoading: (state, action) => {
			state.isTradingPortfolioInfoLoading = action.payload
		},
		createTradingPortfolioInfo: (state, action) => {
			const {
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;

			state.tradingPortfolioInfoList = [action.payload, ...state.tradingPortfolioInfoList]
			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupType) state.popupType = popupType
			if (popupMessage) state.popupMessage = popupMessage
			state.isTradingPortfolioInfoLoading = false
		},
		addTradingPortfolioInfoDataOnList: (state, action) => {
			state.tradingPortfolioInfoList = [action.payload, ...state.tradingPortfolioInfoList]
			state.isTradingPortfolioInfoLoading = false
		},
		setTradingPortfolioInfoList: (state, action) => {
			state.tradingPortfolioInfoList = action.payload
			state.isTradingPortfolioInfoLoading = false
		},
		update: (state, action) => {
			const {
				// _id,
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;
			state.tradingPortfolioInfoList = [...state.tradingPortfolioInfoList].map(data => data._id === action.payload._id ? action.payload : data)
			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupType) state.popupType = popupType
			if (popupMessage) state.popupMessage = popupMessage
			state.isTradingPortfolioInfoLoading = false
		},
		deleteData: (state, action) => {
			const {
				_id,
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;
			state.tradingPortfolioInfoList = [...state.tradingPortfolioInfoList].filter(data => data._id !== _id)

			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupType) state.popupType = popupType
			if (popupMessage) state.popupMessage = popupMessage
			state.isTradingPortfolioInfoLoading = false
		},
		toggleSetupFTMOTradingAccountFormOpen: (state, action) => {
			state.setupFTMOTradingAccountFormOpen = action.payload
		},
		setPopUpMessage: (state, action) => {
			const {
				popupOpen,
				popupType,
				popupMessage
			} = action.payload;

			state.popupOpen = popupOpen ? popupOpen : false;
			if (popupOpen) {
				if (popupType) state.popupType = popupType
				if (popupMessage) state.popupMessage = popupMessage
			}
			state.isTradingPortfolioInfoLoading = false
			state.isTradingPortfolioInfoLoading = false
		},
		deleteTradingPortfolioInfo: (state, action) => {

		},
		setSelectedUserTradingPortfolio: (state, action) => {
			state.selectedUserTradingPortfolio = action.payload
			state.setupFTMOTradingAccountFormOpen = false
		}
	}
});


export const {
	setTradingPortfolioInfoLoading,
	create,
	setTradingPortfolioInfoList,
	update,
	deleteData,
	setPopUpMessage,
	toggleSetupFTMOTradingAccountFormOpen,
	deleteTradingPortfolioInfo,
	addTradingPortfolioInfoDataOnList,
	setSelectedUserTradingPortfolio
} = tradingPortfolioInfoSlice.actions;
const showErrorType = (err, dispatch) => {
	if (err.response) {
		dispatch(setPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: err.response.data
			}
		))
	} else if (err.request) {
		console.log(err.response)
		dispatch(setPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: 'Server error. No response received, request may have timed out, been blocked or server is down.'
			}
		))
	} else {
		dispatch(setTradingPortfolioInfoLoading(false))
		console.log('Error:', err.message);
	}
}


export const actionGetTradingPortfolioInfoOfUser = id => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	// dispatch(setTradingPortfolioInfoLoading());
	try {
		const res = await Axios.get(
			`${URI}/trading-portfolio-info/get-trading-portfolio-info-of-user/${id}`,
			headers
		)
		dispatch(setSelectedUserTradingPortfolio(res.data))
		console.log(res.data)
		// dispatch(addTradingPortfolioInfoDataOnList(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}


export const actionToggleSetupFTMOTradingAccountFormOpen = open => async (dispatch, getState) => {
	dispatch(toggleSetupFTMOTradingAccountFormOpen(open))
}

export const actionCreateTradingPortfolioInfo = data => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setTradingPortfolioInfoLoading());
	try {
		const res = await Axios.post(
			`${URI}/trading-portfolio-info/create-tradingPortfolioInfo`,
			data,
			headers
		)
		dispatch(addTradingPortfolioInfoDataOnList(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

export const actionGetTradingPortfolioInfoListInUserId = data => async (dispatch) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setTradingPortfolioInfoLoading());
	const {
		userId
	} = data;

	try {
		const res = await Axios.get(
			`${URI}/trading-portfolio-info/getTradingPortfolioInfoListInUserId?userId=${userId}`,
			headers
		)
		console.log(res)
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionUpdateFTMOCredentials = data => async (dispatch) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setTradingPortfolioInfoLoading());
	const {
		login,
		password,
		server,
	} = data;

	try {
		const res = await Axios.put(
			`${URI}/trading-portfolio-info/setup-ftmo-credentials`,
			{
				login,
				password,
				server,
			},
			headers
		)
		dispatch(setSelectedUserTradingPortfolio(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionUpdateTradingPortfolioInfoPassword = data => async (dispatch) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setTradingPortfolioInfoLoading());
	const {
		_id,
		password
	} = data;

	try {
		const res = await Axios.put(
			`${URI}/trading-portfolio-info/updateTradingPortfolioInfoPassword`,
			{
				_id,
				password
			},
			headers
		)
		console.log(res)
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionUpdateTradingPortfolioInfoServer = data => async (dispatch) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setTradingPortfolioInfoLoading());
	const {
		_id,
		server
	} = data;

	try {
		const res = await Axios.put(
			`${URI}/trading-portfolio-info/updateTradingPortfolioInfoServer`,
			{
				_id,
				server
			},
			headers
		)
		console.log(res)
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionUpdate = data => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setTradingPortfolioInfoLoading());
	try {
		const res = await Axios.post(
			`${URI}/trading-portfolio-info/update`,
			data,
			headers
		)
		dispatch(update(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

export const actionDeleteTradingPortfolioInfo = tradingPortfolioInfo => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return

	try {
		const res = await Axios.delete(
			`${URI}/trading-portfolio-info/${tradingPortfolioInfo._id}`,
			headers
		)
		dispatch(deleteTradingPortfolioInfo(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}


export default tradingPortfolioInfoSlice.reducer;