
import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';
import {actionAppendTodosInList} from "../todo/todoSlice";
import {v1 as uuid} from "uuid";
import { a } from '@react-spring/web';

/* Just a test code... remove it afterwards */ 
const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;
/* Just a test code... remove it afterwards */

const initialState = {
    userPlanningList: [],
    selectedPlan: null,
    selectedCalendarWeekDate: (new Date()).toDateString(),
    isPlanningFormOpen: false,
    plannedSchedule: [],
    planTaskList: [],
    selectedUserPlanList:[],
    planPreview: [],
    weekPlanList: [],

    schedulesToBeRemoved: [],

    // popup messages: 
    popupType: "error",
    popupOpen: false,
    popupMessage: "Error",
};

const planningColors = [
    "#4472c4",
    "#ed7d31",
    "#a5a5a5",
    "#ffc001",
    "#5b9bd5",
    "#70ad47",
    "#264478",
    "#e9452a",
    "#33acaf",
    "#7b6862",
    "#06c"
]

export const planningSlice = createSlice({
    name: 'planning',
    initialState,
    reducers: {
        setUserPlanningList: (state, action) => {
            state.selectedPlan = action
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload
        },
        setSelectedCalendarWeekDate: (state, action) => {
            state.selectedCalendarWeekDate = action.payload;
        },
        setPlanningFormOpen: (state, action) => {
            state.isPlanningFormOpen = action.payload;
        },
        setPopupOpen: (state, action) => {

            const {
                popupType = "error",
                popupOpen = false,
                popupMessage = "Error",
            } = action.payload;

            state.popupOpen = popupOpen;

            if(popupOpen){
                state.popupType = popupType;
                state.popupMessage = popupMessage;
            }
        },
        setPlannedSchedule: (state, action) => {
            // state.selectedCalendarWeekDate = action.payload.start;
            if(action?.payload?.planData){
                state.plannedSchedule = JSON.parse(action.payload.planData).map(sched=>{
                    const { calendarId, category, isVisible, title, id, body, start, end } = sched;
                    return {
                        calendarId,
                        category,
                        isVisible,
                        title,
                        id,
                        body,
                        start,
                        end
                    }
                });
                state.selectedCalendarWeekDate = action.payload.start;
                console.log(JSON.parse(action.payload.planData))
            }
        },
        setPlanTaskList: (state, action) => {
            state.planTaskList = action.payload
        },
        addTaskInPlanTaskList: (state, action) => {
            state.planTaskList = [
                ...state.planTaskList,
                {
                    id:uuid(),
                    taskName:"",
                    estimate:0,
                    priority:0,
                    taskKey:uuid()
                }
            ]
        },
        removeTaskToPlanTaskList: (state, action) => {
            const index = action.payload
            state.planTaskList = [...state.planTaskList].filter((taskIndex,i) => i != index)
        },
        setSelectedUserPlanList: (state, action) => {
            // console.log(action.payload)
            // state.selectedCalendarWeekDate = action.payload;
            state.selectedUserPlanList = action.payload
        },
        appendSelectedUserPlanList: (state, action) => {
            state.selectedUserPlanList = [
                action.payload,
                ...state.selectedUserPlanList
            ]
            state.popupOpen = true
            state.popupType = "success"
            state.popupMessage = `Plan is saved.`
        },
        deleteSchedule: (state, action) => {
            // temprary create array of the schedules to be removed
            const i = state.schedulesToBeRemoved.findIndex(schedule => schedule.taskId === action.payload.taskId)
            if(i>-1){
                state.schedulesToBeRemoved = state.schedulesToBeRemoved.map(schedule=>{
                    if(schedule.taskId === action.payload.taskId){
                        return {
                            ...schedule,
                            timeToDeduct: schedule.timeToDeduct + action.payload.timeToDeduct
                        }                        
                    }
                    return schedule
                })
            }
            else {
                state.schedulesToBeRemoved = [
                    ...state.schedulesToBeRemoved,
                    action.payload
                ]
            }

            state.plannedSchedule = state.plannedSchedule.filter(schedule => schedule.id !== action.payload.id)

        },
        deletePlan: (state, action) => {
            state.selectedUserPlanList = [...state.selectedUserPlanList].filter(
                plan => plan._id !== action.payload._id
            )
        },
        setWeekPlanList: (state, action) => {

            state.plannedSchedule = action.payload.planList.reduce((a,plan,index)=>[
                                        ...a,
                                        ...JSON.parse(plan.planData).map(p=>{
                                            return {
                                                ...p,
                                                id:uuid(),
                                                tempTaskId: uuid(),
                                                // bgColor: planningColors[index],
                                                // borderColor: planningColors[index],
                                                // color: "#fff",
                                                planId: plan._id
                                            }
                                        })
                                    ],[]);

        }
    }
})

export const {
    setUserPlanningList,
    setSelectedPlan,
    setSelectedCalendarWeekDate,
    setPlanningFormOpen,
    setPopupOpen,
    setPlannedSchedule,
    setPlanTaskList,
    setWeekPlanList,
    addTaskInPlanTaskList,
    removeTaskToPlanTaskList,
    setSelectedUserPlanList,
    appendSelectedUserPlanList,
    deleteSchedule,
    deletePlan
} = planningSlice.actions;

export const actionSaveCalendarChanges = () => (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return

    const {planning, user} = getState();

    const userId = user.selectedUserData._id;

    const updatedPlanData = [...planning.plannedSchedule].reduce((a,schedule)=>{
        const i = a.findIndex(plan => plan?.planId === schedule.planId);

        const { calendarId, planId, category, isVisible, title, id, body, start, end } = schedule;
        const formattedSchedule = {
            calendarId,
            planId,
            category,
            isVisible,
            title,
            id,
            body,
            start,
            end
        }

        if(i > -1){ // if plan is found
            console.log("plan is found")
            return [...a.map(plan=>{
                return plan.planId === formattedSchedule.planId ? {
                    ...plan,
                    planData: [...plan.planData, formattedSchedule]
                } : plan
            })]
        }
        else{   
            return [...a,{
                planId: formattedSchedule.planId ? formattedSchedule.planId : "000000000000000",
                planData: [formattedSchedule]
            }]
        }

    },[]).map(plan=>{
        return {
            ...plan,
            planData: JSON.stringify(plan.planData)
        }
    })
    /**/
    Axios.post(
        `${URI}/planning/update-plan`,
        {
            userId,
            updatedPlanData,
            schedulesToBeRemoved: planning.schedulesToBeRemoved
        },
        headers
    ).then( res => {
        console.log(res.data)
        // dispatch(setSelectedPlan(plan))
        // dispatch(setPlannedSchedule(res.data))
        // if(res?.data){
        //     console.log(JSON.parse(res.data))
        // }
    })
    /**/
    console.log(updatedPlanData)

}

export const actionDeleteSchedule = schedulesToBeRemoved => dispatch => {
    dispatch(deleteSchedule(schedulesToBeRemoved))
}

export const actionGetSelectedPlanning = plan => (dispatch) => {
    const headers = getHeaders();
    if(headers === null) return

    Axios.get(
        `${URI}/planning/${plan._id}`,
        headers
    ).then( res => {
        console.log(res.data)
        dispatch(setSelectedPlan(plan))
        dispatch(setPlannedSchedule(res.data))
        // if(res?.data){
        //     console.log(JSON.parse(res.data))
        // }
    })
}

export const actionGetSelectedUserPlanList = () => (dispatch, getState) => {
    
    const headers = getHeaders();
    if(headers === null) return

    const {user} = getState();
    const userId = user.selectedUserData._id;

    Axios.get(
        `${URI}/planning/user-plans/${userId}`,
        headers
    ).then( res => {
        console.log(res)
        if(res?.data){

            dispatch(setSelectedUserPlanList(res.data))
        }
    })

}

export const actionRemoveTaskToPlanTaskList = index => dispatch => {
    dispatch(removeTaskToPlanTaskList(index))
}

export const actionSetPlanTaskList = taskList => dispatch => {
    dispatch(setPlanTaskList(taskList))
}

export const actionAddTaskInPlanTaskList = () => dispatch => {
    dispatch(addTaskInPlanTaskList())
}

export const actionSetPopupOpen = popupObjData => dispatch => {
    dispatch(setPopupOpen(popupObjData))
}

export const actionSetSelectedCalendarWeekDate = date => async (dispatch,getState) => {
    // console.log(date)

    const headers = getHeaders();
    if(headers === null) return

    const {user} = getState();
    const userId = user.selectedUserData._id;

    dispatch(setSelectedCalendarWeekDate(date))

    console.log("dumaan herererere")
    Axios.get(
        `${URI}/planning/get-weekly-user-plans/${userId}?date=${date}`,
        headers
    ).then( res => {
        console.log(res.data)
        dispatch(setWeekPlanList(res.data))
        // if(res?.data){
        //     dispatch(setSelectedUserPlanList(res.data))
        // }
    })
}

export const actionGetUserPlanningList = (userId) => async (dispatch,getState) => {

}

export const actionGetPlanningDetails = (planningId) => async (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return

    console.log("Dumann here ----")
    Axios.get(
        `${URI}/planning/planning-details/${planningId}`,
        headers
    ).then( res => {
        console.log(res.data)
    })
    
}

export const actionGetWeekPlan = (date = new Date) => async (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return

    const {user, planning} = getState();
    const userId = user.selectedUserData._id;
    const todoStatusArray = [0]; // meaning not started
    const dateFilter = "weekly"
    const date = planning.selectedCalendarWeekDate

    Axios.get(
        `${URI}/todos/user-todos/${userId}?todostatusfilter=${todoStatusArray}&dateFilter=${dateFilter}&date=${date}`,
        headers
    ).then( res => {
        if(res?.data?.docs){
            dispatch(setPlannedSchedule(res.data.results.docs))
        }
    })

}

export const actionRecordPlan = (
    user,
    todoList,
    calendarPlanData,
    selectedDateRange = []
) => async (dispatch,getState) => {
    
    const headers = getHeaders();
    if(headers === null) return

    const {planning} = getState();
    const {planTaskList} = planning; // this one contains all the task to be saved.
    // calendarPlanData - this is the planning data generated in calendar. filter this to get only the generated data
    
    // Filter the calendarPlanData. This is the plan to be saved
    // const generatedPlanningTasks = planTaskList.map(task => calendarPlanData.find(calSched => calSched.id === task.id))
    
    console.log(calendarPlanData)
    console.log(planTaskList)
    const generatedPlanningTasks = calendarPlanData.filter(schedule => planTaskList.findIndex(task => task.id === schedule.body) > -1)
    console.log(generatedPlanningTasks)
    /**/
    Axios.post(
        `${URI}/planning/create`,
        {
            user: user,
            todoList: todoList,
            calendarPlanData: JSON.stringify(generatedPlanningTasks),
            selectedDateRange: selectedDateRange
        },
        headers
    ).then(res => {
        if(res?.data?.plan?.assignedTo){
            const todos = res?.data?.todos ? res.data.todos : [];
            const userId = res.data.plan.assignedTo;
            const {user} = getState();
            if(user.userData._id === userId){
                dispatch(actionAppendTodosInList(userId,todos))
            }

            const { _id, title, status, createdBy } = res.data.plan;

            dispatch(appendSelectedUserPlanList({
                _id,
                title,
                status,
                createdBy
            }))
        }
    })
    /**/
}

export const actionSetPlanningFormOpen = open => dispatch => {
    dispatch(setPlanningFormOpen(open))
}

export const actionDeletePlan = planId => async dispatch => {
    const headers = getHeaders();
    if(headers === null) return
    try{
        const deletedPlan = await Axios.delete( `${URI}/planning/delete-plan/${planId}`, headers )
        dispatch(deletePlan(deletedPlan.data))
    } catch(err) {
        console.log(err)
    }
}

export default planningSlice.reducer;