import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import {
	getHeaders,
	mergeAndRemoveDuplicatesById,
	mergeArrayKeysById
} from '../../helpers.js';
import moment from 'moment';


/* Just a test code... remove it afterwards */ 
const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;
/* Just a test code... remove it afterwards */

/** 
	Helper functions
**/
const getDayName = dateStr => { 
	const dateSplit = (new Date(dateStr)).toString().split(' '),
		dayName = dateSplit[0],
		dayNum = dateSplit[2];
	return `${dayName} ${dayNum}`;
}

export const weeklyDefaultValues = [
	{x: "Sun", y:0},
	{x: "Mon", y:0},
	{x: "Tue", y:0},
	{x: "Wed", y:0},
	{x: "Thu", y:0},
	{x: "Fri", y:0},
	{x: "Sat", y:0}
]

const getWeeklyProductivityData = ({
	averageCountData,
	calendarRecords
}) => {
	const taskListStatusOnDateRangeTemp = [];
	const todoListTypesOnDateRangeTemp = [];
	const commentMoments = [];
	const insightMoments = [];
	const challengeMoments = [];
	const reflectionMoments = [];
	const keyInsightMoments = [];
	const conceptMoments = [];
	const keyConceptMoments = [];
	const resultMoments = [];
	const keyResultMoments = [];
	const objectiveMoments = [];
	const keyQuestionMoments = [];
	const tasksInDateRange = [];
	const taskCategories = [];
	let tempTaskCategoryList = [];

	const weeklyProductivityAndEvent = [
		{
			id: "productiveHours",
			name: "Productive hours",
			data: calendarRecords.map(calRec => {
				return {
					x: getDayName(calRec.date), y: calRec.productiveHours
				}
			})
		},
		{
			id: "thrillingTasksProductiveHours",
			name: "Thrilling Tasks Hours",
			data: calendarRecords.map(calRec => {
				return {
					x: getDayName(calRec.date), y: calRec.thrillingTaskProductiveHours
				}
			})
		},
		{
			id: "numberOfEvents",
			name: "Number of events",
			data: calendarRecords.map(calRec => {
				return {
					x: getDayName(calRec.date), y: calRec.tasks.length
				}
			})
		}
	]

	calendarRecords?.forEach(record => {
		if (record.tasks.length || record.thrillingTasks.length) {
			// loop through normal task and thrilling task
			const allTasks = [...record.tasks, ...record.thrillingTasks];

			allTasks.forEach(task => {
				if (!taskListStatusOnDateRangeTemp.includes(task._id)) {
					if (task.category) {
						tempTaskCategoryList.push({
							categoryId: task.category._id,
							categoryName: task.category.name,
							calendarRecords: []
						})
					}

					taskListStatusOnDateRangeTemp.push(task._id)

					// collect all the category that you work on date range
					if (task.category) {
						const categoryIndex = taskCategories.findIndex(category => category._id === task.category._id)
						if (categoryIndex === -1) {
							taskCategories.push({
								...task.category,
								count: 1
							})
						}
						else {
							taskCategories[categoryIndex].count++;
						}
					}

					// collect all the moments on date range
					if (task.moments) {
						task.moments.forEach(moment => {
							switch (moment.momentType) {
								case 1: { commentMoments.push(moment); break; }//"Comment"
								case 2: { insightMoments.push(moment); break; }//"Insight"
								case 3: { challengeMoments.push(moment); break; }//"Challenge"
								case 4: { reflectionMoments.push(moment); break; }//"Reflection"
								case 5: { keyInsightMoments.push(moment); break; }//"Key Insight"
								case 6: { conceptMoments.push(moment); break; }//"Concept"
								case 7: { keyConceptMoments.push(moment); break; }//"Key Concept"
								case 8: { resultMoments.push(moment); break; }//"Result"
								case 9: { keyResultMoments.push(moment); break; }//"Key Result"
								case 10: { objectiveMoments.push(moment); break; }// "Objective"
								case 11: { keyQuestionMoments.push(moment); break; }// "Key Question"
							}
						})
					}
					tasksInDateRange.push(task)
				}
			})
		}
	});

	// make sure that the collected categories has no duplicate
	tempTaskCategoryList = tempTaskCategoryList.filter((v, i, a) => a.findIndex(v2 => (v2.categoryId === v.categoryId)) === i)

	// get all the line graph task data of each category
	const taskCategoryList = tempTaskCategoryList.map(taskCategory => {

		// productive hours of the category
		const productiveHours = [...calendarRecords].map(calendarRecord => {
			const categoryHours = calendarRecord.tasksHistoryData
				.filter(task => task.todoCategory == taskCategory.categoryId)
				.reduce((a, c) => a + c.timeConsumed, 0)
			return {
				x: getDayName(calendarRecord.date),
				y: categoryHours
			}
		})

		// number of task of the category
		const numberOfTasks = [...calendarRecords].map(calendarRecord => {
			const categoryTask = calendarRecord.tasks
				.filter(task => task?.category?._id == taskCategory.categoryId)

			return {
				x: getDayName(calendarRecord.date),
				y: categoryTask.length
			}
		})

		// number of thrilling task hours of the category
		const numberOfThrillingTasks = [...calendarRecords].map(calendarRecord => {
			const categoryThrilingHours = calendarRecord.thrillingTasksHistoryData
				.filter(task => task.todoCategory == taskCategory.categoryId)
				.reduce((a, c) => a + c.timeConsumed, 0)
			return {
				x: getDayName(calendarRecord.date),
				y: categoryThrilingHours
			}
		})

		return {
			id: taskCategory.categoryId,
			name: taskCategory.categoryName,
			categoryDataList: [
				{
					id: "productiveHours",
					name: "Productive hours",
					data: productiveHours
				},
				{
					id: "thrillingTasksProductiveHours",
					name: "Thrilling Tasks Hours",
					data: numberOfThrillingTasks
				},
				{
					id: "numberOfEvents",
					name: "Number of events",
					data: numberOfTasks
				}
			]
		}
	})

	const lastElement = [...calendarRecords].pop();
	
	const dailyProductivityData = [
		{
			count: lastElement.productiveHours,
			label: "Productive Hours"
		},
		{
			count: (24 - lastElement.productiveHours),
			label: "Unlogged Hours"
		}
	]

	return {
		isDashboardLoading: false,
		averageCountData,
		weeklyProductivityAndEvent,
		dailyProductivityData,
		taskCategories: taskCategoryList,
		taskListStatusOnDateRange: taskListStatusOnDateRangeTemp,
		todoListTypesOnDateRange: todoListTypesOnDateRangeTemp,
		commentMoments: commentMoments,
		insightMoments: insightMoments,
		challengeMoments: challengeMoments,
		reflectionMoments: reflectionMoments,
		keyInsightMoments: keyInsightMoments,
		conceptMoments: conceptMoments,
		keyConceptMoments: keyConceptMoments,
		resultMoments: resultMoments,
		keyResultMoments: keyResultMoments,
		objectiveMoments: objectiveMoments,
		keyQuestionMoments: keyQuestionMoments,
		tasksInDateRange: tasksInDateRange,
	}
}

const initialState = {
	weeklyBurnoutIndex: {
		id: "weeklyBurnoutIndex",
		name: "Weekly Burnout Index",
		data: [{x: "Sun", y:0},
		{x: "Mon", y:0},
		{x: "Tue", y:0},
		{x: "Wed", y:0},
		{x: "Thu", y:0},
		{x: "Fri", y:0},
		{x: "Sat", y:0}]
	},
	weeklyProductivityAndEvent: [
		{
			id: "productiveHours",
			name: "Productive hours",
			data: [...weeklyDefaultValues]
		},
		{
			id: "thrillingTasksHours",
			name: "Thrilling Tasks Hours Hours",
			data: [...weeklyDefaultValues]
		},
		{
			id: "numberOfEvents",
			name: "Number of events",
			data: [...weeklyDefaultValues]
		}
	],
	averageCountData: [
		// {id: "burnoutIndex", name: "Average Burnout Index", current: 0, previous: 0},
		{id: "numberOfEvents", name: "Average No. of Events", current: 0, previous: 0},
		{id: "productiveHours", name: "Average Productive Hours", current: 0, previous: 0},
		// {id: "bugsFixed", name: "Total Bugs Fixed", current: 0, previous: 0}
	],
	commentMoments: [],
	insightMoments: [],
	challengeMoments: [],
	reflectionMoments: [],
	keyInsightMoments: [],
	conceptMoments: [],
	keyConceptMoments: [],
	resultMoments: [],
	keyResultMoments: [],
	objectiveMoments: [],
	keyQuestionMoments: [],
	tasksInDateRange: [],
	taskCategories: [],
	insightsAndKeyInsights:[
		{
			_id:"1",
			moment:"I need to restructure the notes data",
			momentType:2,
			todo:"644796a5f72ea263e4533f46"
		},
		{
			_id:"2",
			moment:"est voluptate distinctio molestias ullam autem quas?",
			momentType:2,
			todo:"644796a5f72ea263e4533f46"
		},
		{
			_id:"3",
			moment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. At fuga architecto quidem nesciunt, earum blanditiis omnis illo, consequuntur quam libero repellendus nihil quibusdam",
			momentType:2,
			todo:"644796a5f72ea263e4533f46"
		},
		{
			_id:"4",
			moment:"testing here",
			momentType:5,
			todo:"644796a5f72ea263e4533f46"
		}
	],
	objectivesAndResults:[
		{
			_id:"1",
			moment:"I need to restructure the notes data",
			momentType:10,
			todo:"644796a5f72ea263e4533f46"
		},
		{
			_id:"2",
			moment:"est voluptate distinctio molestias ullam autem quas?",
			momentType:8,
			todo:"644796a5f72ea263e4533f46"
		},
		{
			_id:"3",
			moment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. At fuga architecto quidem nesciunt, earum blanditiis omnis illo, consequuntur quam libero repellendus nihil quibusdam",
			momentType:9,
			todo:"644796a5f72ea263e4533f46"
		},
		{
			_id:"4",
			moment:"testing here",
			momentType:8,
			todo:"644796a5f72ea263e4533f46"
		}
	],
	questionsAndConcepts:[
		{
			_id:"1",
			moment:"I need to restructure the notes data",
			momentType:11,
			todo:"644796a5f72ea263e4533f46"
		},
		{
			_id:"2",
			moment:"est voluptate distinctio molestias ullam autem quas?",
			momentType:7,
			todo:"644796a5f72ea263e4533f46"
		},
		{
			_id:"3",
			moment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. At fuga architecto quidem nesciunt, earum blanditiis omnis illo, consequuntur quam libero repellendus nihil quibusdam",
			momentType:3,
			todo:"644796a5f72ea263e4533f46"
		},
		{
			_id:"4",
			moment:"testing here",
			momentType:3,
			todo:"644796a5f72ea263e4533f46"
		}
	],
	momentPerHourDefaultValues: [
		{ x: "1am", y: 0 },
		{ x: "2am", y: 0 },
		{ x: "3am", y: 0 },
		{ x: "4am", y: 0 },
		{ x: "5am", y: 0 },
		{ x: "6am", y: 0 },
		{ x: "7am", y: 0 },
		{ x: "8am", y: 0 },
		{ x: "9am", y: 0 },
		{ x: "10am", y: 0 },
		{ x: "11am", y: 0 },
		{ x: "12pm", y: 0 },
		{ x: "1pm", y: 0 },
		{ x: "2pm", y: 0 },
		{ x: "3pm", y: 0 },
		{ x: "4pm", y: 0 },
		{ x: "5pm", y: 0 },
		{ x: "6pm", y: 0 },
		{ x: "7pm", y: 0 },
		{ x: "8pm", y: 0 },
		{ x: "9pm", y: 0 },
		{ x: "10pm", y: 0 },
		{ x: "11pm", y: 0 },
		{ x: "12am", y: 0 },
	],
	taskListStatusOnDateRange: [],
	todoListTypesOnDateRange: [],
	todoTypes: [
		{ typeCode: 0, label: "Working", count: 1, color: "#00ab55"},
		{ typeCode: 1,  label: "Learning", count: 1, color: "#fec02f" },
		{ typeCode: 2, label: "Learning and working", count: 1, color: "#1890FF"},
		{ typeCode: 3, label: "Administration", count: 1 , color: "#637381"} 
	],
	todoStatus: [
		{ statusCode: 0, label: "Not Started", count: 1},
		{ statusCode: 1, label: "On Going", count: 1},
		{ statusCode: 2, label: "Paused", count: 1},
		{ statusCode: 3, label: "Done", count: 1}
	],
	energy: [
		{ energy: 0, label: "Energy", count: 0 },
		{ energyTotal: 1,  label: "Less Energy", count: 1 }
	],
	energyGraphData: {
		id: "energyGraphData",
		name: "Daily Energy Graph",
		data: [
			{ x: "1am", y: 0 },
			{ x: "6am", y: 0 },
			{ x: "1pm", y: 0 },
			{ x: "12am", y: 0 },
		]
	},
	colorPallete: [
		'#eca251',
		'#8435c8',
		'#0398a8'
	],
	dailyProductivityData: [
		{
			count: 0,
			label: "Productive Hours"
		},
		{
			count: 24,
			label: "Unlogged Hours"
		}
	],
	allTaskWithinDateRange: [],
	isDashboardLoading: false,
	usersDataInGlobalDashboard: [],
	usersPostsWithoutPoints: [],
	emergentIndexData: [
		{
			id: "postPointsAverage",
			name: "Post points ave",
			data: [...weeklyDefaultValues]
		},
	],
};

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setWeeklyBurnoutIndex: (state, action) => {
			state.weeklyBurnoutIndex = action.payload
		},
		setWeeklyProductivityAndEvent: (state, action) => {
			state.weeklyProductivityAndEvent = action.payload
		},
		setAverageCountData: (state, action) => {
			state.averageCountData = action.payload
		},
		setMomentPerHourDefaultValues: (state, action) => {
			state.momentPerHourDefaultValues = action.payload
		},
		setWeeklyProductivity: (state, action) => {
			const {
				averageCountData,
				calendarRecords,
				totalPoints
			} = action.payload;
			const weeklyData = getWeeklyProductivityData({averageCountData,calendarRecords})
			state.isDashboardLoading = weeklyData.isDashboardLoading
			state.averageCountData = weeklyData.averageCountData
			state.weeklyProductivityAndEvent = weeklyData.weeklyProductivityAndEvent
			state.dailyProductivityData = weeklyData.dailyProductivityData
			state.taskCategories = weeklyData.taskCategories
			state.taskListStatusOnDateRange = weeklyData.taskListStatusOnDateRange
			state.todoListTypesOnDateRange = weeklyData.todoListTypesOnDateRange
			state.commentMoments = weeklyData.commentMoments
			state.insightMoments = weeklyData.insightMoments
			state.challengeMoments = weeklyData.challengeMoments
			state.reflectionMoments = weeklyData.reflectionMoments
			state.keyInsightMoments = weeklyData.keyInsightMoments
			state.conceptMoments = weeklyData.conceptMoments
			state.keyConceptMoments = weeklyData.keyConceptMoments
			state.resultMoments = weeklyData.resultMoments
			state.keyResultMoments = weeklyData.keyResultMoments
			state.objectiveMoments = weeklyData.objectiveMoments
			state.keyQuestionMoments = weeklyData.keyQuestionMoments
			state.tasksInDateRange = weeklyData.tasksInDateRange
		},
		setDashboardLoading: (state, action) => {
			state.isDashboardLoading = true
		},
		setEnergy: (state, action) => {
			console.log(action.payload)
			let energy = 0;
			let diff = 0;
			const energyData = [];
				// { x: "1am", y: 0 },

			action.payload.forEach((momentData, i) => {
				energy += momentData.energy
				diff += 5 - momentData.energy
				if(i === 0){
					energyData.push(
						{			   
							x: moment(momentData.createdAt).subtract(1, 'minutes').format('LT'),
							y: 0
						}
					)
				}
				energyData.push(
					{
						x: moment(momentData.createdAt).format('LT'),
						y: momentData.energy
					}
				)
			});
			state.energy = [
				{ energy: 0, label: "Energy", count: energy },
				{ energyTotal: 1,  label: "Less Energy", count: diff }
			]
			state.energyGraphData = {
				...state.energyGraphData,
				data: energyData
			}
		},
		updateTasksInDateRange: (state, action) => {
			state.tasksInDateRange = [...state.tasksInDateRange].map(todo=>{
				if(todo._id == action.payload._id){
					return {
						...todo,
						points: action.payload.points
					}
				}
				return todo;
			})
		},
		setUsersDataInGlobalDashboard: (state, action) => {
			state.usersDataInGlobalDashboard = [...action.payload]
			// [{
			// 		userData, {}
			// 		todos [],
			//		dashboardData
			// }]
		},
		updateUsersDataInGlobalDashboard: (state, action) => {
			const {
				userId,
				averageCountData,
				calendarRecords
			} = action.payload;
			const weeklyData = getWeeklyProductivityData({averageCountData,calendarRecords})

			state.usersDataInGlobalDashboard = [...state.usersDataInGlobalDashboard].map(user=>{
				if(user.userData._id === userId){
					const todos = mergeAndRemoveDuplicatesById(user.todos, [...weeklyData.tasksInDateRange])
					return {
						...user,
						todos,
						dashboardData: {
							isDashboardLoading: weeklyData.isDashboardLoading,
							averageCountData: weeklyData.averageCountData,
							weeklyProductivityAndEvent: weeklyData.weeklyProductivityAndEvent,
							dailyProductivityData: weeklyData.dailyProductivityData,
							taskCategories: weeklyData.taskCategories,
							taskListStatusOnDateRange: weeklyData.taskListStatusOnDateRange,
							todoListTypesOnDateRange: weeklyData.todoListTypesOnDateRange,
							commentMoments: weeklyData.commentMoments,
							insightMoments: weeklyData.insightMoments,
							challengeMoments: weeklyData.challengeMoments,
							reflectionMoments: weeklyData.reflectionMoments,
							keyInsightMoments: weeklyData.keyInsightMoments,
							conceptMoments: weeklyData.conceptMoments,
							keyConceptMoments: weeklyData.keyConceptMoments,
							resultMoments: weeklyData.resultMoments,
							keyResultMoments: weeklyData.keyResultMoments,
							objectiveMoments: weeklyData.objectiveMoments,
							keyQuestionMoments: weeklyData.keyQuestionMoments,
							// tasksInDateRange: weeklyData.tasksInDateRange,
						}
					}
				}
				return user
			})
		},
		updateUserTaskDataInGlobalDashboard: (state, action) => {
			state.usersDataInGlobalDashboard = [...state.usersDataInGlobalDashboard].map(
				user => {
					if(user.userData._id === action.payload.userId){
						return {
							userData: {
								...user.userData,
								totalPoints: action.payload.totalPoints
							},
							todos: user.todos.map(todo=>{
								if(todo._id == action.payload.todoId){
									return {
										...todo,
										points: action.payload.points
									}
								}
								return todo
							})
						}
					}
					return user
				}
			)
			console.log(state.usersDataInGlobalDashboard)
			console.log(action.payload)
		},
		setUsersPostsData: (state, action) => {
			console.log(action.payload)
			state.usersPostsWithoutPoints = action.payload
		},
		setUserEmergentIndexGraph: (state, action) => {
			let count = 0;
			let accumulatedIE = 0

			const {
				postDatas, todoDatas
			} = action.payload;

			const ieArray = mergeArrayKeysById(postDatas, todoDatas)
			console.log(ieArray)
			const emergentIndexData = ieArray.map(dayData => {
				const {
					_id,
					totalPostsPoints = 0,
					postCount = 0,
					totalTodosPoints = 0,
					todoCount = 0,
				} = dayData
				
				const divisor = postCount + todoCount
				const emergentIndex = divisor ? parseFloat(((totalPostsPoints + totalTodosPoints) / divisor).toFixed(2)) : 0
				if(emergentIndex){
					accumulatedIE += emergentIndex;
					count++;
					return {
						x: `w ${_id}`,
						y: accumulatedIE/count,
					}
				}
				else{
					return {
						x: `w ${_id}`,
						y: accumulatedIE/count,
					}
				}
			})

			count = 0;
			accumulatedIE = 0
			const postData = postDatas.map(dayData => {
				const {
					_id,
					totalPostsPoints = 0,
					postCount = 0
				} = dayData
				
				const emergentIndex = postCount ? parseFloat(((totalPostsPoints) / postCount).toFixed(2)) : 0
				if(emergentIndex){
					accumulatedIE += emergentIndex;
					count++;
					return {
						x: `w ${_id}`,
						y: accumulatedIE/count
					}
				}
				else{
					return {
						x: `w ${_id}`,
						y: accumulatedIE/count
					}
				}
			})

			count = 0;
			accumulatedIE = 0
			const todoData = todoDatas.map(dayData => {
				const {
					_id,
					totalTodosPoints = 0,
					todoCount = 0,
				} = dayData
				
				const emergentIndex = todoCount ? parseFloat(((totalTodosPoints) / todoCount).toFixed(2)) : 0
				if(emergentIndex){
					accumulatedIE += emergentIndex;
					count++;
					return {
						x: `w ${_id}`,
						y: accumulatedIE/count
					}
				}
				else{
					return {
						x: `w ${_id}`,
						y: accumulatedIE/count
					}
				}
			})
			state.emergentIndexData = [
				{
					id: "emergentIndex",
					name: "Emergent index",
					data: emergentIndexData
				},
				{
					id: "postAverageData",
					name: "Post Average",
					data: postData
				},
				{
					id: "todoAverageData",
					name: "Todo Average",
					data: todoData
				},
			]
		}
	}
});

export const {
	updateUserTaskDataInGlobalDashboard,
	setWeeklyBurnoutIndex,
	setWeeklyProductivityAndEvent,
	setAverageCountData,
	updateUsersDataInGlobalDashboard,
	setMomentPerHourDefaultValues,
	setWeeklyProductivity,
	setDashboardLoading,
	setEnergy,
	updateTasksInDateRange,
	setUsersDataInGlobalDashboard,
	setUsersPostsData,
	setUserEmergentIndexGraph
} = dashboardSlice.actions;

export const actionGetLatestTodosOfUsers = () => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	const res = await Axios.get(
			`${URI}/todos/get-latest-todos-of-users`,
			headers
	)
	console.log(res.data)

	dispatch(setUsersDataInGlobalDashboard(res.data))
}

export const actionGetLatestPostsOfUsers = () => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	const res = await Axios.get(
			`${URI}/posts/get-latest-posts-of-users`,
			headers
	)
	console.log("testing")
	dispatch(setUsersPostsData(res.data))
}



export const actionSetWeeklyBurnoutIndex = () => dispatch => {
	// Axios.post(`${URI}/users/login`,thirdPartyUserData).then( res => {
	//	 console.log(res.data)
		// dispatch(updateTodoInList(res.data))
	// })
}

// export const actionGetDailyMomentEnergy = (userId, urlParam = "") => async dispatch => {

//	 const headers = getHeaders();
//	 if(headers === null) return
			
//	 // console.log("Nasa daily moment energy");
//	 const res = await Axios.get(
//		 `${URI}/moments/get-moment-energy/${userId}?${urlParam}`,
//		 headers
//	 );
//	 dispatch(setEnergy(res.data))
// }

// average count values and the line graph datas must be the same
export const actionGetAverageCountData = (userId, urlParam = "") => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return
			
	dispatch(setDashboardLoading());
	const url = `${URI}/calendar/calendar-get-week-average-comparison/${userId}?${urlParam}`
	const res = await Axios.get( url, headers );
	console.log(res.data)
	dispatch(setWeeklyProductivity(res.data))

}

export const actionGetUserDashboardDetails = (userId, urlParam = "") => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	const url = `${URI}/calendar/calendar-get-week-average-comparison/${userId}?${urlParam}`
	const res = await Axios.get( url, headers );

	dispatch(updateUsersDataInGlobalDashboard({
		userId,
		...res.data
	}))

}

export const actionSetTodoPoints = (todoId,points) => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return
	try{
		const res = await Axios.put(
			`${URI}/todos/set-todo-points`,
			{
				todoId,
				points
			},
			headers
		)
		dispatch(updateTasksInDateRange(res.data.todoData))
	}
	catch(err){
		// showErrorType(err, dispatch)
		console.log(err)
	}
}

export const actionGetUserEmergentIndexGraph = userId => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return
	try{
		const res = await Axios.get(
			`${URI}/users/get-user-emergent-index-graph/${userId}`,
			headers
		)
		// console.log(res.data)
		// const {
		// 	postDatas,
		// 	todoDatas
		// } = res.data;
		dispatch(setUserEmergentIndexGraph(res.data))
	}
	catch(err){
		// showErrorType(err, dispatch)
		console.log(err)
	}
}




export const actionSetTodoPointsInGlobalDashboard = (userId,todoId,points) => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.put(
				`${URI}/todos/set-todo-points`,
				{
					todoId,
					points
				},
				headers
		)
		console.log(res.data)

		dispatch(updateUserTaskDataInGlobalDashboard({
			points: res.data.todoData.points,
			todoId: res.data.todoData._id,
			userId: res.data.usersTotalPoints._id,
			totalPoints: res.data.usersTotalPoints.totalPoints
		}))
	}
	catch(err){
		// showErrorType(err, dispatch)
		console.log(err)
	}
}


export const weeklyBurnoutIndex = state => state.dashboard.weeklyBurnoutIndex;
export const weeklyProductivityAndEvent = state => state.dashboard.weeklyProductivityAndEvent;
export const averageCountData = state => state.dashboard.averageCountData;
// export const burnoutIndexData = state => state.dashboard.burnoutIndexData;
export const momentPerHourDefaultValues = state => state.dashboard.momentPerHourDefaultValues;
export const weeklyProductivityDefaultValues = state => state.dashboard.weeklyProductivityDefaultValues;
export const dailyProductivityData = state => state.dashboard.dailyProductivityData;

export default dashboardSlice.reducer;