import React,{useState,useEffect,useRef,useCallback} from 'react';
import './WingenioCalendar.scss';
import Calendar from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import moment from 'moment';
import { useSelector,useDispatch } from 'react-redux';
import {
  actionGetCalendarData,
  actionSetTodoInSideNav,
} from '../../app/Reducers/todo/todoSlice';
import {
  actionSetPostInSideNav
} from '../../app/Reducers/post/postSlice';
import { 
  actionSetSelectedUserData
} from '../../app/Reducers/user/userSlice';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import MemberSelection from '../../components/MemberSelection/MemberSelection';
import ThrillingTasks from '../../components/ThrillingTasks/ThrillingTasks'
// import Goals from '../../components/Goals/Goals';
import CreateTodo from '../Todo/CreateTodo';
import EmergentIndex from '../EmergentIndex/EmergentIndex';
import { actionGetUserEmergentIndex } from '../../app/Reducers/user/userSlice';
// import OpenAIChatbox from '../ChatBox/OpenAIChatbox';
import Motivations from '../Motivations/Motivations';

const calendars = [
  {
    id: "1",
    name: "My Calendar",
    color: "#ffffff",
    bgColor: "#9e5fff",
    dragBgColor: "#9e5fff",
    borderColor: "#9e5fff"
  },
  {
    id: "2",
    name: "Company",
    color: "#ffffff",
    bgColor: "#00a9ff",
    dragBgColor: "#00a9ff",
    borderColor: "#00a9ff"
  }
];

const WingenioCalendar = ({
    
}) => {
  const cal = useRef(null);
  const dispatch = useDispatch();
  const { calendarSchedules, calendarLoading } = useSelector(state => state.todo);
  const {
    userData,    
  } = useSelector(state => state.user);
  // const [selectedGoal, setSelectedGoal] = useState("")
  const [calendarValue, setCalendarValue] = useState(new Date())

  const getCalendarOnDate = date => {
    const formatterDate = moment(new Date(date)).format('YYYY-MM-DD');
    const calendar = cal.current.calendarInst;
    const view = calendar._viewName;
    dispatch(actionGetCalendarData(formatterDate,false,null,view))
  }

  const changeView = useCallback(view => {
    const calendar = cal.current.calendarInst;  
    if(calendar._viewName != view){
      calendar.changeView(view);
      getCalendarOnDate(calendar.getDate());
    }
  },[])

  const next = useCallback(() => {
    const calendar = cal.current.calendarInst;
    calendar.next()
    const next = calendar.getDate()._date
    setCalendarValue(next)
    getCalendarOnDate(next)
  },[])

  const prev = useCallback(() => {
    const calendar = cal.current.calendarInst;
    calendar.prev()
    const prev = calendar.getDate()._date
    setCalendarValue(prev)
    getCalendarOnDate(prev)
  },[])

  const today = useCallback(() => {
    const calendar = cal.current.calendarInst;
    calendar.today()
    const today = calendar.getDate()._date
    setCalendarValue(today)
    getCalendarOnDate(today)
  },[])

  const onSelectedDateChange = useCallback((newCalendarValue) => {
    const calendar = cal.current.calendarInst;
    calendar.setDate(newCalendarValue)
    setCalendarValue(newCalendarValue);
    getCalendarOnDate(newCalendarValue)
  },[calendarValue])

  const onSelectUser = useCallback(e => {
    const userId = e.target.value
    const formatterDate = moment(new Date()).format('YYYY-MM-DD');
    const calendar = cal.current.calendarInst;
    const view = calendar._viewName;
    dispatch(actionGetCalendarData(formatterDate,false,userId,view))
    dispatch(actionSetSelectedUserData(userId))
    dispatch(actionGetUserEmergentIndex(userId))
  },[])

  const onScheduleClick = e => {
    console.log(e.schedule.body)
    if(e.schedule.calendarId == 0){ // in my design 0, means todo data or break data
      dispatch(actionSetTodoInSideNav(e.schedule.body))
    }
    if(e.schedule.calendarId == 10){ // in my design 10, means post data
      dispatch(actionSetPostInSideNav(e.schedule.body))
    }
  }

  // useEffect(()=>{
  //   dispatch(actionSetSelectedUserData(userData._id))
  // },userData)

  useEffect(() => {
    getCalendarOnDate(new Date())
    const container = cal.current;
    container.scrollTop = 500;
  }, [])

  useEffect(()=>{},[calendarSchedules,calendarLoading])

  return <div className='CalendarContainer'>
      <div className="calendar-actions-container">
        <MemberSelection onSelectUser={onSelectUser} />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div style={{marginBottom: "8px"}}>
          <DatePicker
              label="Select Date"
              value={calendarValue}
              onChange={onSelectedDateChange}
              slotProps={{ textField: { size: 'small' } }}
              renderInput={(params) => <TextField {...params}/>}
          />
          </div>
        </LocalizationProvider>
      </div>
      <div className="calendar-actions-wrapper">
        <div className="view-action-container">
          <Button size="small" onClick={()=>changeView('day')} variant="outlined">Daily</Button>
          <Button size="small" onClick={()=>changeView('week')} variant="outlined">Weekly</Button>
        </div>
        <div className="move-calendar-date-actions-wrapper">
          <Button size="small" onClick={()=>prev()} variant="outlined">Prev</Button>
          <Button size="small" onClick={()=>today()} variant="outlined">Today</Button>
          <Button size="small" onClick={()=>next()} variant="outlined">Next</Button>
        </div>
      </div>
      <EmergentIndex userId={userData._id}/>
      <Motivations/>
      <ThrillingTasks />

      {/* /}
      <Goals
      setCreateNotesOpen={setCreateNotesOpen}
      setSelectedGoal={setSelectedGoal}
      />
      {/* */}

      <CreateTodo
        // goalName={selectedGoal}
      />
      {calendarLoading ? <>
        <div className="loading-wrapper">
            <div className="loader"></div>
        </div>
      </> : ""}
      <Calendar
        ref={cal}
        height="1000px"
        calendars={calendars}
        taskView={false}  // e.g. true, false, or ['task', 'milestone']
        scheduleView={['allday','time']} // e.g. true, false, or ['allday', 'time']
        isReadOnly={true}
        schedules={
          [...calendarSchedules].map(sched => {
            if(sched.todo){
              sched = {
                ...sched,
                title: `${sched.title}`,
                bgColor: "#eef8f2",
                borderColor: "#00AB55",
                body: sched.todoId,
                color: "#00AB55",
                start: new Date(sched.start),
                end: sched.end ? new Date(sched.end) : new Date()
              }
              if(sched.todo.isThrillingTask){
                sched = {
                  ...sched,
                  color: "#8435c8",
                  borderColor: "#8435c8",
                  bgColor: "#edeaf4"
                }
              }
            }
            else{
              sched = {
                ...sched,
                body: sched.post,
                color: "#b78103",
                borderColor: "#b78103",
                bgColor: "rgba(255, 193, 7, 0.16)"
              }
            }
            return sched
          })
        }
        // view={'day'}
        view={'week'}
        onClickSchedule={onScheduleClick}
      />
      
  </div>
}

export default WingenioCalendar;