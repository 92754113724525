//import NotesAside from '.components/NotesAside/NotesAside';//cut it and paste to the component
import React, { useState, useMemo } from 'react';
import NotesList from './NotesList';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import {
    actionToggleNotes,
    actionToggleNotesFilter
} from '../../app/Reducers/layout/layoutSlice';
import { useSelector, useDispatch } from 'react-redux';
import TodoCategoryList from '../NoteCategory/TodoCategoryList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Select from "@mui/material/Select";
import Box from '@mui/material/Box';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const NotesAside = ({
    fixed=false,
    enableCopyText,
    enableAddTodo,
    enableAddGoal,
}) => {

    const dispatch = useDispatch()

    const {
        showNotes,
        showNoteFilter
    } = useSelector(state => state.layout);
    
    const [open, setOpen] = useState(false)
    const [expand, setExpand] = useState(false);

    const notesList = useMemo(() => {
        return <NotesList
            enableCopyText={enableCopyText}
            enableAddTodo={enableAddTodo}
            enableAddGoal={enableAddGoal}
        />
    }, []);

    return <div className={`NotesAsideContainer ${fixed ? 'fixed' : ""} ${showNotes ? 'open' : ""} ${expand ? 'expand' : ""}`}>

        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        style={{marginBottom:12}}
        className="aside-data"
        sx={{
            marginTop: "-2px",
            marginBottom: "10px"
        }}>
            <h2 className="section-title-01">Notes</h2>
            <Box
            sx={{
                '& > :not(style)': {
                m: 0,
                p: "3px"
                },
            }}
            >
                {
                expand ?
                <Tooltip title={"Shorten"}>
                    <IconButton
                    size="small"
                    sx={{marginLeft: "8px"}}
                    onClick={()=>setExpand(false)}>
                        <CloseFullscreenIcon style={{color: "#637381"}} fontSize="inherit" />
                    </IconButton>
                </Tooltip>:
                <Tooltip title={"Expand"}>
                    <IconButton
                    size="small"
                    sx={{marginLeft: "8px"}}
                    onClick={()=>setExpand(true)}>
                        <OpenInFullIcon style={{color: "#637381"}} fontSize="inherit" />
                    </IconButton>
                </Tooltip>
                }
                <Tooltip title="Update Note Categories">
                    <IconButton
                    sx={{ marginLeft: "0", padding: "5px"}}
                    aria-label="Show Filter"
                    onClick={()=>dispatch(actionToggleNotesFilter(!showNoteFilter))}>
                        {showNoteFilter ? <FilterAltOffIcon/> : <FilterAltIcon/> }
                    </IconButton>
                </Tooltip>
                <Tooltip title="Close notes">
                    <IconButton
                    sx={{ marginLeft: "0", padding: "5px"}}
                    aria-label="Show Filter"
                    onClick={()=>dispatch(actionToggleNotes(!showNotes))}>
                        <CloseIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        </Stack>

        <TodoCategoryList/>
        
        {showNotes ? notesList : ""}
    </div>
}

export default NotesAside;