import React, {useState} from "react";
import ReactDom from "react-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
	actionToggleSetupFTMOTradingAccountFormOpen,
	actionUpdateFTMOCredentials,
} from "../../app/Reducers/tradingPortfolioInfo/tradingPortfolioInfoSlice";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 2,
};

const schema = yup.object().shape({
	login: yup.string().required("Provide valid login"),
	password: yup.string().required("Provide valid password"),
	server: yup.string().required("Provide valid server"),
});

const SetupFTMOTradingAccountForm = ({}) => {
	const dispatch = useDispatch();
	const { 
		setupFTMOTradingAccountFormOpen
	} = useSelector(state => state.tradingPortfolioInfo);

	// form setup
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isSubmitSuccessful },
	} = useForm({
		defaultValues: {
			login: "",
			password: "",
			server: "",
		},
		resolver: yupResolver(schema),
	});

	const onClose = () => {
		dispatch(actionToggleSetupFTMOTradingAccountFormOpen(false));
		reset({
			login: "",
			password: "",
			server: "",
		});
	};

	const onSubmit = ({ login, password, server }) => {
		dispatch(actionUpdateFTMOCredentials({login, password, server}));
	};
	return ReactDom.createPortal(
		<>
			<div className="setup-trading-infoFormContainer">
				<Modal
					keepMounted
					open={setupFTMOTradingAccountFormOpen}
					onClose={() => onClose()}
					aria-labelledby="modal-setup-trading-info"
					aria-describedby="keep-mounted-modal-description"
				>
					<Box sx={style} spacing={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="flex-start"
							spacing={2}
							style={{ marginBottom: 10 }}
						>
							<Typography
								id="modal-setup-trading-info"
								variant="h6"
								component="h2"
							>
								FTMO login credentials
							</Typography>

							<CloseIcon
								className="close-form-trigger"
								onClick={() => onClose()}
							/>
						</Stack>
						<form className="form-container" onSubmit={handleSubmit(onSubmit)}>
							<Stack direction="column" alignItems="center" spacing={2}>

								<RHFTextField
									control={control}
									name="login"
									label="FTMO login"
									placeholder="FTMO login"
									error={!!errors.login}
									helperText={errors?.login ? errors?.login?.message : ""}
									type="text"
								/>

								<RHFTextField
									control={control}
									name="password"
									label="password"
									placeholder="password"
									error={!!errors.password}
									helperText={errors?.password ? errors?.password?.message : ""}
									type="password"
								/>

								<RHFTextField
									control={control}
									name="server"
									label="server"
									placeholder="server"
									error={!!errors.server}
									helperText={errors?.server ? errors?.server?.message : ""}
									type="text"
								/>

								<Stack spacing={1} direction="row" justifyContent="center">
									<Button type="submit" variant="contained">
										Update
									</Button>
									<Button
										component={Link}
										to="/"
										type="button"
										variant="contained"
									>
										Cancel
									</Button>
								</Stack>
							</Stack>
						</form>
					</Box>
				</Modal>
			</div>
		</>,
		document.getElementById("setup-ftmo-trading-info-form-wrapper-portal")
	);
};

export default SetupFTMOTradingAccountForm;
