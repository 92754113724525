import React, {useState, useCallback, useEffect} from 'react'
import './Motivations.scss';
import { useSelector,useDispatch } from 'react-redux';
import { actionGetNotifications } from '../../app/Reducers/user/userSlice';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NotificationItem from '../Notifications/NotificationItem';
import {
  systemRoles,
  NotificationType
} from "../Hooks/Constants"


export const Motivations = ({

}) => {
  const dispatch = useDispatch();
  const {
    notifications
  } = useSelector(state => state.user);
  
  useEffect(()=>{
		dispatch(actionGetNotifications(1))
	},[])

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className={`MotivationsContainer`}>
      <div className="tabStyle">
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Coach" />
          <Tab label="Notifications" />
        </Tabs>
      </div>
      
      {/* {
      notifications
      .filter(notification => notification.type == NotificationType.systemMotivation)
      .map(notification=>(
        // <p>testing here</p>
        <NotificationItem key={notification._id} data={notification}/>
      ))} */}
      {
        tabIndex == 0 ?
        <div className='notificationsList'>
          {notifications.filter(
            notification => (
                            notification?.triggeredBy?.systemRole == systemRoles.superAdmin ||
                            notification?.triggeredBy?.systemRole == systemRoles.admin ||
                            notification.type == NotificationType.systemMotivation
                            )
          ).map(notification=>(<NotificationItem key={notification._id} data={notification}/>))}
        </div> :
        <div className='notificationsList'>
          {notifications.filter(
            notification => (
                            notification?.triggeredBy?.systemRole != systemRoles.superAdmin &&
                            notification?.triggeredBy?.systemRole != systemRoles.admin &
                            notification.type != NotificationType.systemMotivation
                            )
          ).map(notification=>(
            <NotificationItem key={notification._id} data={notification}/>
          ))}
        </div> 
      }
    </div>
  )
}

export default Motivations;