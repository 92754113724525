//import TrainingDataReviews from '.components/TrainingDataReviews/TrainingDataReviews';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import ReactDom from 'react-dom';
import './TrainingDataReviews.scss';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import AvatarWithName from '../../components/AvatarWithName/AvatarWithName'
import Rating from '@mui/material/Rating';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
    actionCloseTrainginDataReviewsPanel
} from '../../app/Reducers/bot/botSlice';

const TrainingDataReviews = ({
    fixed=true
}) => {

    const dispatch = useDispatch();

    const {
        selectedTrainingData,
        trainingDataReviewsPanelOpen
    } = useSelector(state => state.bot);

    return ReactDom.createPortal(<>
    <div className={`TrainingDataReviewsContainer ${fixed ? "fixed" : ""} ${trainingDataReviewsPanelOpen ? "open":""}`}>
        
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        style={{
            padding: "0 2px 19px",
            borderBottom: "1px solid #E7E7E7"
        }}>
            <h2 className="section-title-01">Training Data Details</h2>
            {/* {sideNavTodoData ? <TodoActions todo={sideNavTodoData}/> : ""} */}
            <CloseIcon 
            className="close-form-trigger"
            onClick={()=>dispatch(actionCloseTrainginDataReviewsPanel())}/>
        </Stack>

        {/* <p className="rating-detail"><span className="training-data-label">Inquiry:</span> {rating.userInquiryToBot}</p>
        <p className="rating-detail"><span className="training-data-label">Response:</span> {rating.botResponse}</p> */}

        {selectedTrainingData ? <>
            <div className="training-data-details-wrapper">
                <p className="rating-detail">
                    <span className="training-data-label">Inquiry: </span>
                    {selectedTrainingData.inquiry}
                </p>
                <p className="rating-detail">
                    <span className="training-data-label">Answer: </span>
                    {selectedTrainingData.answer}
                </p>
                <p className="rating-detail">
                    <span className="training-data-label">Intent: </span>
                    {selectedTrainingData.intent}
                </p>
                <p className="rating-detail">
                    <span className="training-data-label">Suggestion Priority: </span>
                    {selectedTrainingData.suggestionPriority}
                </p>
                
                <div className="avatar-and-label">
                    <span className="training-data-label">Filed By: </span>
                    <AvatarWithName 
                        name={`${selectedTrainingData.filedBy.firstName} ${selectedTrainingData.filedBy.lastName}`}
                        imgSrc={""}
                    />
                </div>
                <div className="avatar-and-label">
                    <span className="training-data-label">Answered By: </span>
                    <AvatarWithName 
                        name={`${selectedTrainingData.answeredBy.firstName} ${selectedTrainingData.answeredBy.lastName}`}
                        imgSrc={""}
                    />
                </div>
                <p className="training-data-date-detail">
                    <span className="icon"><AccessTimeIcon sx={{width: "20px",heigth: "20px"}}/></span>
                    <span className="label">Created At: </span>
                    <p className="date">{moment(selectedTrainingData.createdAt).format('LLL')}</p>
                </p>
                <p className="training-data-date-detail">
                    <span className="icon"><AccessTimeIcon sx={{width: "20px",heigth: "20px"}}/></span>
                    <span className="label">Updated At: </span>
                    <p className="date">{moment(selectedTrainingData.updatedAt).format('LLL')}</p>
                </p>
                {/* <p className="rating-detail">
                    <span className="training-data-label">Created At:</span>
                    {moment(selectedTrainingData.createdAt).format()}
                </p>
                <p className="rating-detail">
                    <span className="training-data-label">Updated At:</span>
                    {moment(selectedTrainingData.updatedAt).format()}
                </p> */}

                {/* {selectedTrainingData._id}
                
                {selectedTrainingData.moment} */}

            </div>
            <div className="selected-training-data-wrapper">
                <h2 className="section-title-02">Ratings And Reviews</h2>
                <ul className="rating-list">
                    {selectedTrainingData.ratings.map(rating=><li key={rating._id} className="rating-data">
                        
                        <AvatarWithName 
                            name={`${rating.ratedBy.firstName} ${rating.ratedBy.lastName}`}
                            imgSrc={""}
                        />
                        <div className="inquiqy-and-response-wrapper">
                            <p className="rating-detail"><span className="training-data-label">Inquiry:</span> {rating.userInquiryToBot}</p>
                            <p className="rating-detail"><span className="training-data-label">Response:</span> {rating.botResponse}</p>
                        </div>
                        <div className="rating-container">
                            <p className="rating-info">Rating ( {Math.round(rating.rating)}/5 )</p> 
                            <Rating
                            name="simple-controlled"
                            size="small"
                            value={rating.rating}
                            readOnly/>  
                        </div>
                        <p className="rating-detail"><span className="training-data-label">Comment:</span> {rating.comment}</p>
                        
                    </li>)}
                </ul>
            </div>
        </> : ""}
        
    </div>
    </>,document.getElementById("ratings-wrapper-portal"))
}

export default TrainingDataReviews;