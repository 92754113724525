//import Home from '.components/Home/Home';//cut it and paste to the component
import React, { useEffect, useState, memo } from 'react';
import './Home.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
	actionGetGlobalPosts
} from '../../app/Reducers/post/postSlice';
import TodoList from "../../components/Todo/TodoList";
import PostInfiniteScroll from '../../components/PostInfiniteScroll/PostInfiniteScroll';

const Home = ({

}) => {
	const [parentControlShow, setParentControlShow] = useState(false)
	const dispatch = useDispatch();

	const {
		globalPosts,
		globalpage,
		isPostsLoading,
    globalhasNextPage,
		globalnextPage
	} = useSelector(state => state.post)

	const closeTodoList = () => { setParentControlShow(false) }
	const loadMoreCallback = pageNum => dispatch(actionGetGlobalPosts(pageNum))
	
	useEffect(()=>{
		dispatch(actionGetGlobalPosts(globalpage))
	},[])

	return <div className='HomeContainer'>
		{isPostsLoading ? <>
			<div className="loading-wrapper">
				<div className="loader"></div>
			</div>
		</> : ""}
		<div className="todo-list-wrapper">
			<TodoList
				defaultShow={false}
				parentControlShow={parentControlShow}
				callBack={closeTodoList}
			/>
		</div>
		<div className="optionsContainerAndinfo pp-wrap-inner">
			<PostInfiniteScroll 
				posts={globalPosts}
				page={globalpage}
				nextPage={globalnextPage}
				hasNextPage={globalhasNextPage}
				loading={isPostsLoading}
				enablePostLayoutChange={true}
				loadMoreCallback={loadMoreCallback}
			/>
		</div>
	</div>
}

export default memo(Home);