//import PostUpload from '.components/PostUpload/PostUpload';//cut it and paste to the component
import React, {useState, useRef, useEffect} from 'react';
import './PostUpload.scss';
import { FileDrop } from 'react-file-drop';
import PostHeader from '../PostCards/PostHeader';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { emotions } from "../Hooks/Constants";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {
  actionCreatePost
} from '../../app/Reducers/post/postSlice';
import PostInputs from './PostInputs';
import moment from 'moment';

const PostUpload = ({
  setShowUploadForm,
  group = null,
  classroom = null
}) => {

  const dispatch = useDispatch()

  const [imageToUpload, setImageToUpload] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [isCursorOnFrame, setIsCursorOnFrame] = useState(false)
  const fileInputRef = useRef()
  const [selectedEmotion, setSelectedEmotion] = useState(-1)
  const {
		userData
	} = useSelector(state => state.user)

  const dateOpened = new Date()

  const { setError, control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
    defaultValues: {
      postDescription: ""
    }
  });

  const onSubmit = async data => {
    
    let postData = {
      ...data,
      emotion: selectedEmotion,
      userId: userData._id,
      startTimeToWritePosts: dateOpened
    }
    if(group){
      postData = {
        ...postData,
        group: group._id 
      }
    }
    if(classroom){
      postData = {
        ...postData,
        classroom: classroom._id 
      }
    }

    dispatch(actionCreatePost(
      postData,
      imageToUpload
    ))
    setShowUploadForm(false)
  }

  const processUploadedFile = file => {
    if(
      file.type=="image/jpg" ||
      file.type=="image/jpeg" ||
      file.type=="image/png"
    ){
      // do something with the file object
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageToUpload(file)
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
    else{
      console.log("only image files are accepted")
    }
  }

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf('image') !== -1) {
        const file = item.getAsFile();
        const imageURL = URL.createObjectURL(file);
				console.log(imageURL)
        // You can now use imageURL to display the pasted image, e.g., set it as the background of a div
        // or render it using an <img> element.
        // For simplicity, we'll display it as a background image here.
				setImageToUpload(file)
				setImagePreview(imageURL);

      }
    }
  };

  const onFileInputChange = (event) => {
    const file = event.target.files[0];
    processUploadedFile(file)
  }
  
  const onFileDrop = (files, event) =>  {
    const file = files[0];
    processUploadedFile(file)
  }

  const onTargetClick = (files, event) => {
    fileInputRef.current.click()
  }

  const emotion = selectedEmotion > -1 ? emotions[selectedEmotion-1]._id ? `Is Feeling ${emotions[selectedEmotion-1].name} ${emotions[selectedEmotion-1].emoji}` : null : null;

  return <div className='PostUploadContainer'>
    <form
    className="post-form form-container"
    encType="multipart/form-data"
    onSubmit={handleSubmit(onSubmit)}>

      <div className="cardHeader">
        <PostHeader feeling={emotion} post={{createdBy: userData}}/>
        <Tooltip title={"Close"}>
          <IconButton
          size="small"
          onClick={()=>setShowUploadForm(false)}>
            <CloseIcon/>
          </IconButton>
        </Tooltip>
      </div>
      
      <PostInputs
        control={control}
        errors={errors}
        setSelectedEmotion={setSelectedEmotion}
        handlePaste={handlePaste}
      />

      <div
      className={`uploadWrapper ${isCursorOnFrame ? "cursorOnFrame" : ""}`}>
        {
        imageToUpload ? 
        <div className="uploadImageWrapper">
          <Tooltip
          className="uploadImageEditButton"
          title={"Change image"}>
            <IconButton
            size="small"
            onClick={()=>setImageToUpload(null)}>
              <EditIcon/>
            </IconButton>
          </Tooltip>
          <img src={imagePreview} alt="upload image here" loading="lazy"/>
        </div> : 
        <FileDrop
          className={`fileDropUpload`}
          onFrameDragEnter={(event) => setIsCursorOnFrame(true)}
          onFrameDragLeave={(event) => setIsCursorOnFrame(false)}
          onFrameDrop={(event) => setIsCursorOnFrame(false)}
          onDrop={onFileDrop}
          accept="image/*"
        >
          <p>Drop some files here!</p>
          <Controller
          name="postImage"
          control={control}
          defaultValue={""}
          render={({field}) => 
            <input
              {...field}
              onChange={onFileInputChange}
              ref={fileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
            />
          }/>
        </FileDrop>
        }
      </div>

      <Button
      type="submit"
      variant="contained"
      color="primary"
      style={{
        width: "100%",
        borderRadius: "8px",
        height: "48px",
        fontSize: "16px",
      }}>Post</Button>

    </form>
  </div>
}

export default PostUpload;