import Axios from "axios";
import {v1 as uuid} from "uuid";
import youtube from "../../apis/youtube";
import { stopWords } from "../../components/Hooks/Constants";

const getSubjectSuggestion = (res,suggestedSubjectQuery,userMessage) => {
    const {links, subjects, response} = res;

    
    let answer = [];
    if(response.answer){
        answer = [{
            _id: uuid(),
            name: "Rio",
            message: response.answer,
            ratingsEnabled: true,
            isSender: false,
            withSelection: false,
            responseData: res,
            userMessage
        }]
    }

    
    if(links.length){
        let linkSuggestion = [];
        linkSuggestion = links.map(link => {
            return {
                _id: uuid(),
                name: "Rio",
                message: link,
                isSender: false,
                withSelection: false,
                isLinkReference: true,
                responseData: res,
                userMessage
            }
        })
        answer = [
            ...answer,
            ...linkSuggestion
        ]
    }



    return {
        chat_id: "00000000000",
        messages: [
            ...answer,
            {
                _id: uuid(),
                name: "Rio",
                message: "Do you need tutorial for this? I may suggest top 3 youtube tutorial links for your inquiry.",
                isSender: false,
                isLink: false,
                withSelection: true,
                responseData: res,
                userMessage
            }
        ],
        subjects: subjects,
        links: links,
        showOptions: true,
        subjectSuggestion: subjects?.at(-1),
        suggestedSubjectQuery
    }
}

const getLinkSuggestion = (res, suggestedSubjectQuery, userMessage) => {

    const {links, response, subjects} = res;
    const linkChatMessages = links.map(link=>{
        return {
            _id: uuid(),
            name: "Rio",
            message: link,
            isSender: false,
            withSelection: false,
            isLinkReference: true,
            ratingsEnabled: true,
            responseData: res,
            userMessage
        }
    })

    return {
        chat_id: "00000000000",
        messages:[
            {
                _id: uuid(),
                name: "Rio",
                message: response.answer,
                isSender: false,
                withSelection: false,
                ratingsEnabled: true,
                responseData: res,
                userMessage
            },
            {
                _id: uuid(),
                name: "Rio",
                message: "You may check out these links for more information.",
                isSender: false,
                withSelection: false,
                responseData: res,
                userMessage
            },
            ...linkChatMessages
        ],
        subjects: subjects,
        links: links,
        showOptions: false,
        suggestedSubjectQuery
    }
}

export const getYoutubeSuggestion = async query => {

    const response = await youtube.get('/search',{
        params: {
            q: query
        }
    })
    
    if(response.data.items){
        return response.data.items;
    }
    return []
}

const removeStopWordsFromString = str => {
    const formattedStr = str
                        .toLowerCase()
                        .replace(/[^a-zA-Z0-9 +]/gi, '')
                        .replace(/ +(?= )/g,'')
                        .trim();
    
    return  formattedStr
            .split(" ")
            .reduce(
                (result, word) =>   {
                    return stopWords.findIndex(sWords => sWords === word) > -1 ? 
                            result : 
                            `${result} ${word}`
                }
            ,"")
            .trim();
}

export const analize = async (conversationURL, message) => {

    const formattedMsgForBot = removeStopWordsFromString(message.toLowerCase())
        
    // analize using backend data
    const res = await Axios.get(`${conversationURL}/${formattedMsgForBot}`)

    if(res.data){
        const {suggestionPriority} = res.data;

        if(suggestionPriority === "subject") {
            return getSubjectSuggestion(res.data, formattedMsgForBot, message);
        }

        else if(suggestionPriority === "link"){
            return getLinkSuggestion(res.data, formattedMsgForBot, message)
        }

    }
    
}

