import React, {memo} from 'react';
import './PrivacyPolicy.scss';


const PrivacyPolicy = ({
}) => {


    return <div
    className='PrivacyPolicyContainer'
    style={{
      backgroundImage: "url('/long-text-bg.jpg')"
    }}>
      <div className="textWrapper">
        <h1>Privacy Policy</h1>
        <p>[Insert app name] ("we" or "us") is committed to protecting the privacy of our users. This Privacy Policy describes how we collect, use, and share personal information collected through our work scheduler app (the "App").</p>
        <dl>
          <dt>Collection of Information</dt>
          <dd>We collect personal information that you voluntarily provide to us when you use the App, such as your name, email address, phone number, and work schedule. We may also collect certain technical information automatically, such as your device type, operating system, IP address, and app usage data.</dd>
        </dl>
        <dl>
          <dt>Use of Information</dt>
          <dd>We use the personal information collected to create and manage work schedules, send notifications and reminders, and provide personalized recommendations. We may also use the information for analytics and to improve the App's functionality. We may share the information collected with third-party service providers who assist us in providing the App's services.</dd>
        </dl>
        <dl>
          <dt>Data Security</dt>
          <dd>We implement reasonable security measures to protect the personal information collected from unauthorized access or disclosure. However, we cannot guarantee that our security measures will prevent all unauthorized access or disclosure.</dd>
        </dl>
        <dl>
          <dt>User Choices</dt>
          <dd>You may access and control your personal information by updating your profile information or opting out of notifications. You may also request that your personal information be deleted from our servers. However, we may retain certain information for legal or business purposes.</dd>
        </dl>
        <dl>
          <dt>Cookies and Tracking Technologies</dt>
          <dd>We may use cookies or other tracking technologies to collect user data. This data may be used for analytics and to improve the App's functionality. You may opt-out of cookies and other tracking technologies by adjusting your device settings.</dd>
        </dl>
        <dl>
          <dt>Age Restrictions</dt>
          <dd>The App is not intended for use by children under the age of [insert age]. We do not knowingly collect personal information from children under [insert age] without parental consent.</dd>
        </dl>
        <dl>
          <dt>Changes to Privacy Policy</dt>
          <dd>We may update this Privacy Policy from time to time. We will notify users of any material changes by posting a notice in the App or by other means.</dd>
        </dl>
        <dl>
          <dt>Contact Information</dt>
          <dd>If you have any questions or concerns regarding this Privacy Policy, please contact us at [insert contact information].</dd>
        </dl>
        <dl>
          <dd>By using the App, you agree to the terms of this Privacy Policy. If you do not agree with the terms, please do not use the App.</dd>
        </dl>
      </div>
    </div>
}

export default memo(PrivacyPolicy);


