//import TimeProgress from '.components/TimeProgress/TimeProgress';//cut it and paste to the component
import React, {useEffect,useState,useCallback} from 'react';
import './TimeProgress.scss';
import LinearProgress from '@mui/material/LinearProgress';
import Counter from "./Counter"
import { TodoStatus } from '../Hooks/Constants';


const TimeProgress = ({
    todo,
    
}) => {

    const estimateInSec = todo.timeEstimate * 3600;

    const renderedTime = useCallback(lastStartDate => {

        const latestDate = new Date();
        const todoUpdatedDate = new Date(lastStartDate);

        const diffTime = Math.abs(latestDate - todoUpdatedDate);
        const diffSecs = diffTime / (1000);

        return Math.round(diffSecs)

    }, [])

    const totalTimeConsumed = useCallback(todoDetail => {
        const rt = renderedTime(todoDetail.start);
        
        if(todoDetail.status === TodoStatus.started){
            return todoDetail.timeConsumed + rt;
        }
        return todoDetail.timeConsumed
    },[])

    const [progress, setProgress] = useState(totalTimeConsumed(todo)/estimateInSec*100)

    useEffect(()=>{
        setProgress(totalTimeConsumed(todo)/estimateInSec*100)
    },[todo])

    return <div className='TimeProgressContainer'>
        {/* <p>{timeUsed}</p> */}
        
        <p>Estimate: {todo.timeEstimate.toFixed(2)} hours</p>
        <div className="progress-bar-wrapper">
            {
            progress > 100 ? 
            <LinearProgress
            color="error"
            variant="determinate"
            value={100} /> : 
            <LinearProgress
            variant="determinate"
            value={progress} />
            }
        </div>
            
        {
            <Counter
            ongoing={todo.status === 1}
            initialTimeRendered={totalTimeConsumed(todo)}
            estimateInSec={estimateInSec}
            callback={setProgress}
            progress={progress}
            />
        }
        
    </div>
}

export default TimeProgress;