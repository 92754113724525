import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {v1 as uuid} from "uuid";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from 'react-redux';
import {
	actionDisableUser,
	actionEnableDisabledUser
} from '../../app/Reducers/user/userSlice';
import { systemRoles } from '../Hooks/Constants';

const UserCardMoreOptions = ({
	user,
	iconFontSize="inherit"
}) => {
	const dispatch = useDispatch();
	const {
		userData
	} = useSelector(state => state.user);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => setAnchorEl(event.currentTarget);

	const handleClose = () => setAnchorEl(null);

	const menuOptions = [];

	const adminOptions = [
		user.accountDisabled ? {
			id: uuid(),
			name: "Enable Account",
			icon: <PersonIcon fontSize="small"/>,
			callback: () => {
				dispatch(actionEnableDisabledUser(user._id))
			}
		} : {
			id: uuid(),
			name: "Disable Account",
			icon: <PersonOffIcon fontSize="small"/>,
			callback: () => {
				dispatch(actionDisableUser(user._id))
			}
		}
	]

	

	const getMenuItems = () => {
		const menuList =	userData.systemRole == systemRoles.superAdmin ?
											[...menuOptions, ...adminOptions] : 
											[...menuOptions];

		return menuList.map(option=>(
			<MenuItem
			key={option.id}
			onClick={()=>{
				option.callback()
				setAnchorEl(null)
			}}>
				<ListItemIcon style={{minWidth: 32}}>
					{option.icon}
				</ListItemIcon>
				<ListItemText>{option.name}</ListItemText>
			</MenuItem>
		))
	}
			
	return <div className='UserCardMoreOptionsContainer'>
		{/* <EditTodo/> */}
		<IconButton
		id="basic-button"
		aria-controls={open ? 'basic-menu' : undefined}
		aria-haspopup="true"
		aria-expanded={open ? 'true' : undefined}
		size="small"
		onClick={handleClick}
		sx={{padding: "2px"}}>
			<MoreVertIcon sx={{fontSize: iconFontSize}}/>
		</IconButton>
		<Menu
			id="basic-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
			'aria-labelledby': 'basic-button',
			}}
		>
			{getMenuItems()}
		</Menu>
	</div>
}

export default UserCardMoreOptions;