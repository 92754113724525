import React from "react";
import ReactDom from "react-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  actionToggleCreateGroupFormOpen,
  actionCreateGroup,
} from "../../app/Reducers/group/groupSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const schema = yup.object().shape({
  name: yup.string().required("Provide valid name"),
});

const CreateGroupForm = ({}) => {
  const dispatch = useDispatch();
  const { createGroupFormOpen } = useSelector((state) => state.group);

  // form setup
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      groupImage: "",
    },
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    dispatch(actionToggleCreateGroupFormOpen(false));
    reset({
      name: "",
      description: "",
      groupImage: "",
    });
  };

  const onSubmit = ({
    name,
    description,
    groupImage,
  }) => {
    let data = {name};
    if(description) { data = { ...data, description } }
    if(groupImage)  { data = { ...data, groupImage  } }
    dispatch(actionCreateGroup(data));
  };

  return ReactDom.createPortal(<>
      <div className="GroupFormContainer">
        <Modal
          keepMounted
          open={createGroupFormOpen}
          onClose={() => onClose()}
          aria-labelledby="modal-Group"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              style={{ marginBottom: 10 }}
            >
              <Typography id="modal-Group" variant="h6" component="h2">
                Create Group
              </Typography>

              <CloseIcon
                className="close-form-trigger"
                onClick={() => onClose()}
              />
            </Stack>
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" alignItems="center" spacing={2}>
                <RHFTextField
                  control={control}
                  name="name"
                  label="name"
                  placeholder="name"
                  error={!!errors.name}
                  helperText={errors?.name ? errors?.name?.message : ""}
                  type="text"
                />

                <RHFTextField
                  control={control}
                  name="description"
                  label="description"
                  placeholder="description"
                  error={!!errors.description}
                  helperText={
                    errors?.description ? errors?.description?.message : ""
                  }
                  type="text"
                />

                <RHFTextField
                  control={control}
                  name="groupImage"
                  label="groupImage"
                  placeholder="groupImage"
                  error={!!errors.groupImage}
                  helperText={
                    errors?.groupImage ? errors?.groupImage?.message : ""
                  }
                  type="text"
                />

                <Stack spacing={1} direction="row" justifyContent="center">
                  <Button type="submit" variant="contained">
                    Create
                  </Button>
                  <Button
                    component={Link}
                    to="/"
                    type="button"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Modal>
      </div>
    </>,
    document.getElementById("create-group-form-wrapper-portal")
  );
};

export default CreateGroupForm;
// Put this on the home page: <div id="create-group-form-wrapper-portal"></div>
