//import Navigation from '.components/Navigation/Navigation';//cut it and paste to the component
import React, {useState, useEffect} from 'react';
import './Navigation.scss';
import { NavLink } from "react-router-dom";
import { ReactComponent as LogoIcon } from '../../Assets/ic_logo.svg';
import { ReactComponent as TaskIcon } from '../../Assets/ic_tasks.svg';
import { ReactComponent as DashboardIcon } from '../../Assets/ic_dashboard.svg';
import { ReactComponent as ClassroomIcon } from '../../Assets/ic_classroom.svg';
import { ReactComponent as TeamIcon } from '../../Assets/ic_team.svg';
import { ReactComponent as UserIcon } from '../../Assets/ic_user.svg';
import { ReactComponent as InstructorIcon } from '../../Assets/ic_instructor.svg';
// import { ReactComponent as CalendarIcon } from '../../Assets/ic_calendar.svg';
import { ReactComponent as UserSettings } from '../../Assets/ic_user_settings.svg';
import { ReactComponent as FollowersIcon } from '../../Assets/ic_followers.svg';
import HomeIcon from '@mui/icons-material/Home';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Button from '@mui/material/Button';
import SlideToggle from './SlideToggle';
import TodoViewFilter from '../NavigationFilters/TodoViewFilter';
import MomentViewFilter from '../NavigationFilters/MomentViewFilter';
import { useSelector, useDispatch } from 'react-redux';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GroupsIcon from '@mui/icons-material/Groups';
import {
	actionLogout,
} from '../../app/Reducers/user/userSlice';
import {
	actionToggleMinimizedMenu,
	actionToggleNavigation
} from '../../app/Reducers/layout/layoutSlice';
import UserGroups from '../UserGroups/UserGroups';
import {
	systemRoles,
	isCoachOrTeacherOrAdmin,
	isAdmin
} from '../Hooks/Constants';

const Navigation = ({

}) => {

	const dispatch = useDispatch()
	const {
		isNavigationOpen,
		minimizeMenu
	} = useSelector(state => state.layout);
	const {
		userData
	} = useSelector(state => state.user);

	const handleLogout = () => dispatch(actionLogout());

	return <nav className={`NavigationContainer ${isNavigationOpen ? "open" : ""}`}>
		<div className="nav-logo-container">
			<LogoIcon/>
			<p>Hungry2Learn</p>
		</div>
		<section className="nav-section">
			<h2 className="nav-section-header">{`${minimizeMenu ? ". . .":"General"}`}</h2>
			<ul>
				<li>
					<SlideToggle headText={
						<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to="/tasks">
							<span className="icon-container"><TaskIcon/></span>
							<span className="menu-nav-name">My tasks</span>
						</NavLink>
					}>
						<TodoViewFilter/>
					</SlideToggle>
				</li>
				<li>
					<SlideToggle headText={
						<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to="/my-moments">
							<span className="icon-container"><PhotoCameraBackIcon/></span>
							<span className="menu-nav-name">My Moments</span>
						</NavLink>
					}>
						<MomentViewFilter/>
					</SlideToggle>
				</li>
				<li>
					<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to={`/classrooms?user=${userData._id}`}>
						<span className="icon-container"><ClassroomIcon/></span>
						<span className="menu-nav-name">My Classes</span>
					</NavLink>
				</li> 
				<li>
					<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to={`/transactions`}>
						<span className="icon-container"><ReceiptIcon/></span>
						<span className="menu-nav-name">Transactions</span>
					</NavLink>
				</li> 
				<li>
					<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to={`/dashboard?user=${userData._id}`}>
						<span className="icon-container"><DashboardIcon/></span>
						<span className="menu-nav-name">Dashboard</span>
					</NavLink>
				</li> 
				<li>
					<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to={`/trading?user=${userData._id}`}>
						<span className="icon-container"><DashboardIcon/></span>
						<span className="menu-nav-name">Trading Dashboard</span>
					</NavLink>
				</li>  
				<li>
					<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to={`/profile?user=${userData._id}`}>
						<span className="icon-container"><UserIcon/></span>
						<span className="menu-nav-name">Personal feeds</span>
					</NavLink>
				</li>
				<li>
					<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to={`/home`}>
						<span className="icon-container"><HomeIcon/></span>
						<span className="menu-nav-name">Global feeds</span>
					</NavLink>
				</li>
				<li>
					<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to={`/followers`}>
						<span className="icon-container"><FollowersIcon/></span>
						<span className="menu-nav-name">Connections</span>
					</NavLink>
				</li>
				<li>
					<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to={`/group-list`}>
						<span className="icon-container"><FollowersIcon/></span>
						<span className="menu-nav-name">Groups</span>
					</NavLink>
				</li>
				{/* <li>
					<SlideToggle headText={
						userData?.defaultSelectedGroup ? <>
							<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to={`/group/${userData.defaultSelectedGroup}`}>
								<span className="icon-container"><GroupsIcon/></span>
								<span className="menu-nav-name">Groups</span>
							</NavLink> 
						</>:
						<span className="nav-link">
							<span className="icon-container"><GroupsIcon/></span>
							<span className="menu-nav-name">Groups</span>
						</span>
					}>
						<UserGroups/>
					</SlideToggle>
				</li> */}
							 
			</ul>
		</section>
		{
			isCoachOrTeacherOrAdmin(userData.systemRole) ?
				<section className="nav-section">
					<h2 className="nav-section-header">{`${minimizeMenu ? ". . .":"Management"}`}</h2>
					<ul>
					{isAdmin(userData.systemRole) ? <>
						<li>
							<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to="/manage-group">
								<span className="icon-container"><TeamIcon/></span>
								<span className="menu-nav-name">Manage Groups</span>
							</NavLink>
						</li>
						<li>
							<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to="/manage-accounts">
								<span className="icon-container"><TeamIcon/></span>
								<span className="menu-nav-name">Manage Accounts</span>
							</NavLink>
						</li>
						<li>
							<NavLink onClick={()=>console.log("This will become a new chat page")} className="nav-link" to="/motivational-qoutes">
								<span className="icon-container"><FormatQuoteIcon/></span>
								<span className="menu-nav-name">Motivational Qoutes</span>
							</NavLink>
						</li>
						{/* <li>
							<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to="/planning">
								<span className="icon-container"><CalendarIcon/></span>
								<span className="menu-nav-name">Planning</span>
							</NavLink>
						</li> */}
						<li>
							<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to="/user-settings">
								<span className="icon-container"><UserSettings/></span>
								<span className="menu-nav-name">User Settings</span>
							</NavLink>
						</li>
						<li>
							<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to="/global-dashboard">
								<span className="icon-container"><DashboardIcon/></span>
								<span className="menu-nav-name">Global Dashboard</span>
							</NavLink>
						</li>
					</> : ""}
					{isCoachOrTeacherOrAdmin(userData.systemRole) ? <>
						<li>
							<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to={`/my-classes?user=${userData._id}`}>
								<span className="icon-container"><InstructorIcon/></span>
								<span className="menu-nav-name">My Teaching Classes</span>
							</NavLink>
						</li>
					</> : ""}

						
						{/* <li>
							<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to="/bigbrosuggestion">
								<span className="icon-container"><ChatIcon/></span>
								<span className="menu-nav-name">BigBro Suggestion</span>
							</NavLink>
						</li>
						<li>
							<NavLink onClick={()=>dispatch(actionToggleNavigation(false))} className="nav-link" to="/bigbrotraining">
								<span className="icon-container"><TrainingIcon/></span>
								<span className="menu-nav-name">BigBro Training</span>
							</NavLink>
						</li> */}
					</ul>
				</section> : "" 
		}
		<section className="nav-section logout-wrapper">
			<ul>
				<li>
					<span className="nav-link" onClick={()=>dispatch(actionToggleMinimizedMenu(!minimizeMenu))}>
						<span className="icon-container">
							{minimizeMenu ? <OpenInFullIcon sx={{ color: "#637381" }}/> : <CloseFullscreenIcon sx={{ color: "#637381" }} />}
						</span>
						<span className="menu-nav-name">Minimize</span>
					</span>
				</li>
				<li>
					<Button
					className="nav-link"
					onClick={handleLogout}
					size={"small"}
					sx={{
						width: "100%",
						justifyContent: "flex-start",
						textTransform: "none",
						minWidth: "auto",
						height: "auto",
						padding: 0
					}}
					>
						<span className="icon-container"><ExitToAppIcon/></span>
						<span className="menu-nav-name">Logout</span>
					</Button>
				</li>
			</ul>
		</section>
	</nav>
}

export default Navigation;