//import PostUploadModal from '.components/PostUploadModal/PostUploadModal';//cut it and paste to the component
import React, {useState,useRef,memo} from 'react';
import ReactDom from 'react-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import './PostUploadModal.scss';
import { FileDrop } from 'react-file-drop';
import PostHeader from '../PostCards/PostHeader';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { emotions } from "../Hooks/Constants";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {
  actionCreatePost,
  actionCreatePostOpen
} from '../../app/Reducers/post/postSlice';
import {
  actionCreateTodoPost
} from '../../app/Reducers/todo/todoSlice';
import PostInputs from './PostInputs';

const style = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #5c5c5c',
  boxShadow: 24,
  p: 2,
  borderRadius: 3
};

const PostUploadModal = ({
  setShowUploadForm,
  group = null
}) => {

  const dispatch = useDispatch()

  const [imageToUpload, setImageToUpload] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [isCursorOnFrame, setIsCursorOnFrame] = useState(false)
  const fileInputRef = useRef()
  const [selectedEmotion, setSelectedEmotion] = useState(-1)
  // const [energy, setEnergy] = useState(5)
  const {
		userData
	} = useSelector(state => state.user)

  const {
		createPostOpen,
    postForTodo
	} = useSelector(state => state.post)

  const { setError, control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
    defaultValues: {
      postDescription: ""
    }
  });

  const onSubmit = async data => {
    
    let postData = {
      ...data,
      emotion: selectedEmotion,
      // energy,
      userId: userData._id
    }
    if(group){
      postData = {
        ...postData,
        group: group._id 
      }
    }
    if(postForTodo){
      postData = {
        ...postData,
        todo: postForTodo._id 
      }
      dispatch(actionCreateTodoPost( // post in todo sidenav
        postData,
        imageToUpload
      ))
    }
    else{ // post on post page
      dispatch(actionCreatePost(
        postData,
        imageToUpload
      ))
    }
    console.log(postData)
    /**/
    onClose()
    /**/
  }

  const processUploadedFile = file => {
    if(
      file.type=="image/jpg" ||
      file.type=="image/jpeg" ||
      file.type=="image/png"
    ){
      // do something with the file object
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageToUpload(file)
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
    else{
      console.log("only image files are accepted")
    }
  }

  const onFileInputChange = (event) => {
    const file = event.target.files[0];
    processUploadedFile(file)
  }
  
  const onFileDrop = (files, event) =>  {
    const file = files[0];
    processUploadedFile(file)
  }

  const onTargetClick = (files, event) => {
    fileInputRef.current.click()
  }

  const emotion = selectedEmotion > -1 ? emotions[selectedEmotion-1]._id ? `Is Feeling ${emotions[selectedEmotion-1].name} ${emotions[selectedEmotion-1].emoji}` : null : null;

  const onClose = () => {
    dispatch(actionCreatePostOpen(false))
  }

  return ReactDom.createPortal(<>
    <div className='PostUploadModalContainer'>
      <Modal
        keepMounted
        open={createPostOpen}
        onClose={()=>onClose()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} spacing={2}>
          <form
          className="post-form form-container"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}>

            <div className="cardHeader">
              <PostHeader
              feeling={emotion}
              post={{createdBy: userData}}
              postForTodo={postForTodo}
              />
              <Tooltip title={"Close"}>
                <IconButton
                size="small"
                onClick={()=>onClose()}>
                  <CloseIcon/>
                </IconButton>
              </Tooltip>
            </div>

            <PostInputs
              control={control}
              errors={errors}
              // functions
              setSelectedEmotion={setSelectedEmotion}
              // energy={energy}
              // setEnergy={setEnergy}
            />

            <div
            className={`uploadWrapper ${isCursorOnFrame ? "cursorOnFrame" : ""}`}>
              {
              imageToUpload ? 
              <div className="uploadImageWrapper">
                <Tooltip
                className="uploadImageEditButton"
                title={"Change image"}>
                  <IconButton
                  size="small"
                  onClick={()=>setImageToUpload(null)}>
                    <EditIcon/>
                  </IconButton>
                </Tooltip>
                <img src={imagePreview} alt="upload image here" loading="lazy"/>
              </div> : 
              <FileDrop
                className={`fileDropUpload`}
                onFrameDragEnter={(event) => setIsCursorOnFrame(true)}
                onFrameDragLeave={(event) => setIsCursorOnFrame(false)}
                onFrameDrop={(event) => setIsCursorOnFrame(false)}
                onDrop={onFileDrop}
                accept="image/*"
              >
                <p>Drop some files here!</p>
                <Controller
                name="postImage"
                control={control}
                defaultValue={""}
                render={({field}) => 
                  <input
                    {...field}
                    onChange={onFileInputChange}
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    className="hidden"
                  />
                }/>
              </FileDrop>
              }
            </div>

            <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              width: "100%",
              borderRadius: "8px",
              height: "48px",
              fontSize: "16px",
            }}>Post</Button>

          </form>
        </Box>
      </Modal>
    </div>
  </>,document.getElementById("create-post-wrapper-portal"))
}

export default PostUploadModal;