//import Login from '.components/Login/Login';//cut it and paste to the component
import React,{useEffect} from 'react';
import './LoginForm.scss';
import * as yup from "yup";
import Button from '@mui/material/Button';
import {
    actionLogin
} from '../../app/Reducers/user/userSlice';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import RHFCheckbox from "../ReactHookFormElements/RHFCheckbox";
import { NavLink } from "react-router-dom";

const schema = yup.object().shape({
    email: yup.string().required("Provide valid email"),
    password: yup.string()
                .required('No password provided.') 
                .min(8, 'Password is too short - should be 8 chars minimum.')
});

const Login = ({

}) => {

    const dispatch = useDispatch()

    // form setup
    const { setError, control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
        defaultValues: {
            // email: thirdPartyUserData ? thirdPartyUserData.loginHint : "",
            email: "",
            password: "",
            keepLoggedIn: true
        },
        resolver: yupResolver(schema)
    });

    const onSubmit = async data => {
        console.log(data)
        const {email, password} = data;
        const loggedIn = await dispatch(actionLogin({email, password}))
        if(!loggedIn){
            [
                {
                  type: "manual",
                  name: "email",
                  message: "Wrong email and password"
                },
                {
                  type: "manual",
                  name: "password",
                  message: "Wrong email and password"
                }
            ].forEach(({ name, type, message }) =>
                setError(name, { type, message })
            );
        }
    }

    return (
        <form className="login-form form-container" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="title">Login</h1>
            <Stack
            direction="column"
            alignItems="center"
            spacing={2}>
                <RHFTextField
                    control={control}
                    name="email"
                    label="Email"
                    placeholder="Email"
                    error={!!errors.email}
                    helperText={errors?.email ? errors?.email?.message : ''}
                    type="email"
                />
                <RHFTextField
                    control={control}
                    name="password"
                    label="Password"
                    placeholder="Password"
                    error={!!errors.password}
                    helperText={errors?.password ? errors?.password?.message : ''}
                    type="password"
                />
                <div className="forgot-password-and-login-options">
                    <RHFCheckbox
                        control={control}
                        name="keepLoggedIn"
                        label="Remember Me"
                        defaultValue={true}
                    />
                    <NavLink className="text-link" to="/auth/forgot-password">Forgot Password</NavLink>
                </div>
                <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                    width: "100%",
                    borderRadius: "40px",
                    height: "48px",
                    fontSize: "16px",
                }}>Login</Button>
                <p>Not registered yet? <NavLink className="text-link" to="/auth/registration">Create an Account</NavLink></p>
            </Stack>

        </form>
    )
}

export default Login;