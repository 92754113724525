import React, {useMemo} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TaskMomentsTable from '../TaskMomentsTable/TaskMomentsTable';
import PostList from '../../../components/PostList/PostList';
import GridViewIcon from '@mui/icons-material/GridView';
import ReorderIcon from '@mui/icons-material/Reorder';
import IconButton from '@mui/material/IconButton';
import {
  actionSetTaskAndPostDashboardView
} from '../../../app/Reducers/layout/layoutSlice';

import "./TaskTableAndPostTab.scss"

const TaskTableAndPostTab = ({
  userId
}) => {
  const {
    taskAndPostDashboardGridView
  } = useSelector(state => state.layout)
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const momentsTable = useMemo(()=><TaskMomentsTable userId={userId}/>,[userId])
  const postList = useMemo(()=><PostList userId={userId}/>,[userId])

	return <div className={`TaskTableAndPostTabContainer`}>
    <div className="headerInTaskTableAndPostTabContainer">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Tasks" />
        <Tab label="Posts" />
      </Tabs>
      <div className="viewOptionWrapper">
        <IconButton onClick={
          ()=>dispatch(actionSetTaskAndPostDashboardView(!taskAndPostDashboardGridView))
        }>
          {taskAndPostDashboardGridView ? <GridViewIcon /> : <ReorderIcon />}
        </IconButton>
      </div>
    </div>
    {value == 0 ? momentsTable : postList }

  </div>
}

export default TaskTableAndPostTab;