//import ForgotPassword from '.components/ForgotPassword/ForgotPassword';//cut it and paste to the component
import React, { useState } from 'react';
// import './ForgotPasswordForm.scss';
import { useSelector, useDispatch } from 'react-redux';
import VerifyEmail from '../VerifyEmail/VerifyEmail';
import ForgotPasswordForm from './ForgotPasswordForm';
import EmailForm from './EmailForm';
import PopupNotifications from '../PopupNotifications/PopupNotifications';
import {
  actionHideUserPopupMessage
} from '../../app/Reducers/user/userSlice'

const ForgotPassword = ({}) => {

    const [email, setEmail] = useState("")
    const {
      popupType,
      popupOpen,
      popupMessage,
      isUserRequestLoading,
      showForgotPassword
    } = useSelector(state => state.user);

    const dispatch = useDispatch();

    const handlePopupClose = () => {
      dispatch(actionHideUserPopupMessage());
    } 

    return <div className='ForgotPasswordContainer form-wrapper'>
        {
          showForgotPassword ?
          <ForgotPasswordForm email={email} setEmail={setEmail}/> :
          <EmailForm setEmail={setEmail}/>
        }
        {
          isUserRequestLoading ? 
          <div className="loading-wrapper">
            <div className="loader"></div>
          </div> : ""
        }
        <PopupNotifications
        open={popupOpen} 
        handleClose={handlePopupClose}
        severity={popupType}>
            {popupMessage}
        </PopupNotifications>
    </div>
}

export default ForgotPassword;