import React, {memo, useEffect} from 'react';
import { useSelector } from 'react-redux';
import LineGraph from './Graphs/LineGraph';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const ProductivityAndEventContent = ({
    graphData = null,
    height = 300,
    tabIndex = 0
}) => {
    const { 
        weeklyProductivityAndEvent,
        emergentIndexData,
        colorPallete
    } = useSelector(state => state.dashboard);

    return <>
        {
        tabIndex == 0 ?
        <LineGraph 
            graphDataArray = {emergentIndexData}
            colorPallete = {colorPallete}
            height = {300}
            // title = {"Emergent Index data"}
        />
        :
        <LineGraph
            graphDataArray={graphData ? graphData : weeklyProductivityAndEvent}
            colorPallete={colorPallete}
            height = {height}
            // title = {"Weekly Productivity and Task Hours"}
        />
        }
    </>
}

export default ProductivityAndEventContent;