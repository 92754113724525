import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const initialState = {
	goals: [],
	goalsIsLoading: true,

	// list of goals pagination
	hasNextPage: false,
	hasPrevPage: false,
	limit: 10,
	nextPage: null,
	page: 1,
	pagingCounter: 1,
	prevPage: null,
	totalDocs: 0,
	totalPages: 1,

	// popup messages: 
	popupType: "error",
	popupOpen: false,
	popupMessage: "Error",
};

export const goalSlice = createSlice({
	name: 'goal',
	initialState,
	reducers: {
		setGoalsIsLoading: (state, action) => {
			state.goalsIsLoading = action.payload
		},
		setGoals: (state, action) => {
			state.goalsIsLoading = false
			state.goals = action.payload
		},
		updateGoal: (state, action) => {
			state.goals = [...state.goals].map(goal => {
				return action.payload._id === goal._id ? action.payload : goal
			})
			state.goalsIsLoading = false
		},
		upsertGoal: (state, action) => {
			const found = false;
			state.goals = [...state.goals].map(goal => {
				if (action.payload._id === goal._id) {
					found = true;
					return action.payload
				}
				return goal
			})
			if (!found) {
				state.goals = [...state.goals, action.payload]
			}
			state.goalsIsLoading = false
		},
		deleteGoal: (state, action) => {
			state.goals = [...state.goals].filter(goal=> goal._id !== action.payload._id)
		},
		setGoalPopUpMessage: (state,action) => {
			const {
					popupOpen,
					popupType,
					popupMessage
			} = action.payload;

			state.popupOpen = popupOpen ? popupOpen : false;
			if(popupOpen){
					if(popupType) state.popupType = popupType
					if(popupMessage) state.popupMessage = popupMessage
			}
			state.goalsIsLoading = false
		},
	}
});

export const {
	// createGoal,
	setGoals,
	updateGoal,
	upsertGoal,
	deleteGoal,
	setGoalsIsLoading,
	setGoalPopUpMessage
} = goalSlice.actions;

const showErrorType = (err,dispatch) => {
	if (err.response) {
		dispatch(setGoalPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: err.response.data
			}
		))
	} else if (err.request) {
		console.log(err.response)
		dispatch(setGoalPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: 'Server error. No response received, request may have timed out, been blocked or server is down.'
			}
		))
	} else {
		dispatch(setGoalsIsLoading(false))
		console.log('Error:', err.message);
	}
}

export const actionDeleteGoal = goalId => async dispatch => {
	
	const headers = getHeaders();
	if (headers === null) return

	dispatch(setGoalsIsLoading());
	console.log("working eherere in delte")
	try {
		const res = await Axios.delete(
			`${URI}/goals/delete-goal/${goalId}`,
			headers
		)
		dispatch(deleteGoal(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionUpsertGoal = goalData => dispatch => {
	dispatch(upsertGoal(goalData))
}

export const actionUpdateGoal = goalData => dispatch => {
	dispatch(updateGoal(goalData))
}

export const actionSetGoalsIsLoading = (loading = true) => async dispatch => {
	dispatch(setGoalsIsLoading(loading))
}

export const actionCreateGoal = data => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setGoalsIsLoading());
	try {
		const res = await Axios.post(
			`${URI}/goals/create-goal`,
			data,
			headers
		)
		dispatch(upsertGoal(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

// export const actionGetGoals = (userId) => async (dispatch, getState) => {
// 	const headers = getHeaders();
// 	if (headers === null) return

// 	dispatch(setGoalsIsLoading(true))

// 	try {
// 		const res = await Axios.get(
// 			`${URI}/goals/get-user-goals/${userId}`,
// 			headers
// 		)
// 		console.log(res.data)
// 		dispatch(setGoals(res.data))
// 	} catch (e) {
// 		console.log(e)
// 	}
// }

export const actionSetGoalStatus = (goalId, status) => async dispatch => {
	let headers = getHeaders();
	if (headers === null) return

	dispatch(setGoalsIsLoading(true))

	const res = await Axios.put(
		`${URI}/goals/edit-goal`,
		{ id: goalId, status },
		headers
	)

	dispatch(updateGoal(res.data))
}

export const actionEditStartedGoal = (id, updatedName) => async dispatch => {

	console.log(id, updatedName)
	const headers = getHeaders();
	if (headers === null) return

	dispatch(setGoalsIsLoading(true))

	try {
		const res = await Axios.put(
			`${URI}/goals/edit-goal`,
			{
				id,
				name: updatedName
			},
			headers
		)
		console.log(res.data)
		dispatch(updateGoal(res.data))
	} catch (e) {
		console.log(e)
	}
}

export const actionUpdateGoalsHidden = data => async (dispatch) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setGoalsIsLoading());
	const {
		_id,
		hidden
	} = data;

	try {
		const res = await Axios.put(
			`${URI}/goals/updateGoalsHidden`,
			{
				_id,
				hidden
			},
			headers
		)
		console.log(res)
		dispatch(updateGoal(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionStartGoal = goalId => async dispatch => {
	const headers = getHeaders();
	if (headers === null) return

	dispatch(setGoalsIsLoading());

	try {
		const res = await Axios.put(
			`${URI}/goals/start-goal`,
			{ goalId },
			headers
		)
		console.log(res)
		dispatch(updateGoal(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
	
}

export const actionPauseGoal = goalId => async dispatch => {
	const headers = getHeaders();
	if (headers === null) return

	dispatch(setGoalsIsLoading());

	try {
		const res = await Axios.put(
			`${URI}/goals/pause-goal`,
			{ goalId },
			headers
		)
		console.log(res)
		dispatch(updateGoal(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
	
}

export default goalSlice.reducer;