//import ClassListSection from '.components/ClassListSection/ClassListSection';//cut it and paste to the component
import React, { useEffect } from 'react';
import ClassroomCard from '../../components/ClassroomCard/ClassroomCard';

const ClassListSection = ({
  title,
  joinText,
  loadClassFunction,
  joinFunction,
  classList,
  hasNextPage,
  nextPage,
}) => {
  
	useEffect(()=>{
    loadClassFunction()
	},[])

	return <div className='ClassListSectionContainer'>
    <div className="followLayoutContainer">
      <h3 className='section-title-04'>{title}</h3>
      <div className="suggestedPeopleList">
        {classList.map(
          classData =>  <ClassroomCard
                        key={classData._id}
                        classData={classData}
                        joinFunction={joinFunction}
                        joinText={joinText}/>
        )}
      </div>
      {
        hasNextPage &&
        <p
        className='load-more-comments'
        style={{marginTop: 24}}
        onClick={()=>loadClassFunction(nextPage)}>Load more...</p>
      }
    </div>
	</div>
}

export default ClassListSection;
