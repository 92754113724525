import React from 'react';

import {
	XYPlot,
	XAxis,
	YAxis,
	HorizontalGridLines,
	VerticalBarSeries
} from 'react-vis';

const IdeaDataComparison = ({
	dataSet,
	title,
	height=300,
	width=300
}) => {

	const comparisonData = () => {
		return <>
			<XYPlot
			xType="ordinal"
			width={width}
			height={height}
			xDistance={100}
			yDomain={[0, 5]}>
				<HorizontalGridLines />
				<XAxis />
				<YAxis />
				<VerticalBarSeries data={dataSet.set1} />
				<VerticalBarSeries className="vertical-bar-series-example" data={dataSet.set2} />
			</XYPlot>
		</>
	}

	const singleDataSet = () => {
		return <>
			<XYPlot
			xType="ordinal"
			width={width}
			height={height}
			xDistance={100}
			yDomain={[0, 5]}>
				{/* <VerticalGridLines /> */}
				<HorizontalGridLines />
				<XAxis />
				<YAxis />
				<VerticalBarSeries data={dataSet.set1} />
			</XYPlot>
		</>
	}

	return <div className='ideaDataComparisonWrapper'>
		{title ? <div class="heading-container">
			<h5>{title}</h5>
		</div> : ""}
		{dataSet.set2 ? comparisonData() : singleDataSet()}
		
	</div>
}

export default IdeaDataComparison;

