import React, { useEffect, useState} from 'react'
// import './PaymentCompleted.scss'
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';

const PaymentCompleted = ({
  
}) => {
  const dispatch = useDispatch()

  const [queryParameters] = useSearchParams()
  
  useEffect(()=>{

  }, [])
  
	return <div className='PaymentCompletedContainer'>
		<h1 className='section-title-01'>Registration Complete</h1>
    <p>Congratulations</p>
    <p>{queryParameters.get("payment_intent")}</p>
    <p>{queryParameters.get("payment_intent_client_secret")}</p>
    <p>{queryParameters.get("redirect_status")}</p>

	</div>
}

export default PaymentCompleted;