//import BigBroTraining from '.components/BigBroTraining/BigBroTraining';//cut it and paste to the component
import React, { useEffect } from 'react';
import './BigBroTraining.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TrainingRowData from './TrainingRowData';
import { ReactComponent as RefreshIcon } from '../../Assets/ic_refresh.svg';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {
    actionGetTrainingData,
    actionCreateTrainingDataForm,
    actionHideBotPopupMessage,
    actionTrainBot
} from '../../app/Reducers/bot/botSlice';
import Axios from "axios";
import BigBroSideOptions from '../../components/BigBroSideOptions/BigBroSideOptions';
import TrainingDataReviews from '../../components/TrainingDataReviews/TrainingDataReviews';
import PopupNotifications from '../../components/PopupNotifications/PopupNotifications';

const BigBroTraining = ({

}) => {

    const dispatch = useDispatch();
    const {
        trainingDatas,
        popupType,
        popupMessage,
        popupOpen
    } = useSelector(state => state.bot)

    const sampleTrainingData = [
        {
            _id:"0",
            inquiry: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
            answer: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rem at repudiandae iure sequi minus iusto reiciendis magni laboriosam, sed obcaecati corrupti? Voluptate corrupti, sint maxime eligendi tempora optio accusamus ab?",
            intent: "react.reacthooks",
            subjects: [
                "Subject Name",
                "test here",
                "Subject testing"
            ],
            links: [
                "https://wingenio.world/bigbrotraining",
                "https://www.youtube.com/watch?v=Hn-enjcgV1o&amp;list=RDMMJ080kIGiifE&amp;index=7"
            ]
        }
    ]

    // const [trainingDatas, setTrainingDatas] = useState(sampleTrainingData)

    const removeTrainingData = (_id) => {
        console.log(_id)
        // setTrainingDatas(
        //     trainingDatas.filter(
        //         trainingData => trainingData._id !== _id
        //     )
        // )
    }

    const handlePopupClose = () => {
        dispatch(actionHideBotPopupMessage());
    }

    useEffect(()=>{
        dispatch(actionGetTrainingData())
    },[])

    return <div className='BigBroTrainingContainer'>
        <div className="training-options-and-table">
            <BigBroSideOptions/>
            <div className="training-table-wrapper">
                <div className="heading-wrapper">
                    <h2 className="heading-style-01">Training data filter</h2>
                    <Tooltip title="Refresh">
                        <IconButton
                        sx={{ marginLeft: "10", padding: "5px"}}
                        aria-label="refresh"
                        onClick={()=>dispatch(actionGetTrainingData())}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>

                    <Button
                    onClick={()=>{dispatch(actionCreateTrainingDataForm())}}
                    type="button"
                    variant="contained"
                    sx={{margin:"0 20px 0 auto"}}>Add Training Data</Button>
                    
                    <Button
                    onClick={()=>dispatch(actionTrainBot())}
                    type="button"
                    variant="contained">Train Bot</Button>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Inquiry</TableCell>
                                <TableCell align="center">Answer</TableCell>
                                <TableCell align="center">Intent  </TableCell>
                                <TableCell align="center">Subject</TableCell>
                                <TableCell align="center">Source link</TableCell>
                                <TableCell align="center">Update</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainingDatas.map( 
                                trainingData => <TrainingRowData
                                                key={trainingData._id}
                                                trainingData={trainingData}
                                                removeTrainingData={removeTrainingData}/>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
        <TrainingDataReviews/>
        <PopupNotifications
        open={popupOpen} 
        handleClose={handlePopupClose}
        severity={popupType}>
            {popupMessage}
        </PopupNotifications>
    </div>
}

export default BigBroTraining;