import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import ChangeTimelinePicture from '../../components/ChangeTimelinePicture/ChangeTimelinePicture'
import { useSelector, useDispatch } from 'react-redux';
import { actionGetAssociatedPost } from '../../app/Reducers/post/postSlice';
import PostCards from '../../components/PostCards/PostCards';

const ViewTimelinePicture = ({
	src,
	editable=false,
	onTimelinePictureChange,
	associatedPostId = null
}) => {

	const {
		associatedPost
	} = useSelector(state => state.post)

	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true)
		dispatch(actionGetAssociatedPost(associatedPostId))
	};
	const handleClose = () => {
		setOpen(false)
		dispatch(actionGetAssociatedPost(null))
	};
	const [showEditPictureInput, setShowEditPictureInput] = useState(false);

	return (
		<div className='ViewTimelinePictureContainer'>
			<img onClick={handleOpen} src={src} loading="lazy"/>
			
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className='editPicture'>
					<div className="actionsPictureBtn">
						{editable ? <>
							<IconButton
							aria-label="edit" 
							onClick={()=>setShowEditPictureInput(open => !open)}
							className="editPictureBtn">
								{showEditPictureInput ? <ImageIcon /> : <EditIcon />}
							</IconButton>
						</> : ''}

						<IconButton
						aria-label="close" 
						onClick={handleClose}
						className="editPictureBtn">
							<CloseIcon />
						</IconButton>

					</div>
					{
					showEditPictureInput ?
						<ChangeTimelinePicture
						closeModal={handleClose}
						onTimelinePictureChange={onTimelinePictureChange}/> : 
						<img className='imageData' src={src} loading="lazy"/>
					}
					{associatedPost ? <PostCards postData={associatedPost} showImage={false}/> : ""}
				</Box>
			</Modal>
		</div>
	);
}

export default ViewTimelinePicture;
