//import TodoHistoryData from '.components/TodoHistoryData/TodoHistoryData';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useDispatch, useSelector } from 'react-redux';
import {
    actionToggleEditScheduleForm,
    actionSetTodoPopupMessage
} from '../../app/Reducers/todo/todoSlice';

const TodoHistoryData = ({
    record
}) => {

    const dispatch = useDispatch()

    const {
        userData
    } = useSelector(state => state.user);

    const onChangeTimeUpdate = () => {
        console.log(record)
        if(record.owner === userData._id){
            dispatch(actionToggleEditScheduleForm(true,record))
        }
        else{
            dispatch(actionSetTodoPopupMessage(
                true,
                "error", 
                "Unable to edit todo. You can only edit todo assigned to you."
            ))
        }
    }

    return <div className='TodoHistoryDataContainer'>
        <p
        className="todo-detail clickable"
        onClick={onChangeTimeUpdate}>
            <span className="icon"><AccessTimeIcon sx={{
                width: "20px",
                heigth: "20px"
            }}/></span>
            <span className="label">Start Time: </span>
            <span className="value">{record.start ? moment(record.start).format('LLL') : "Not Started"}</span>
        </p>
        <p
        className="todo-detail clickable"
        onClick={onChangeTimeUpdate}>
            <span className="icon"><AccessTimeIcon sx={{
                width: "20px",
                heigth: "20px"
            }}/></span>
            <span className="label">End Time: </span>
            <span className="value">{record.end ? moment(record.end).format('LLL') : "Not Ended"}</span>
        </p>
    </div>
}

export default memo(TodoHistoryData);