import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import ReactDom from "react-dom";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import SaveIcon from '@mui/icons-material/Save';
import { FileDrop } from 'react-file-drop';
import Tooltip from '@mui/material/Tooltip';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import './ImageActions.scss'
import PostCards from '../PostCards/PostCards';
import { actionTogglePostDetails } from '../../app/Reducers/post/postSlice';
import ChangeProfilePicture from '../ChangeProfilePicture/ChangeProfilePicture';

const ImageActions = ({
	editable 			= false,
	editComponent = null,
}) => {

	const dispatch = useDispatch()

	const {
		showEditProfilePicture,
    showEditTimelinePicture,
		userData
	} = useSelector(state => state.user)

	const {
		showPostModal,
		selectedPost,
		selectedDataFor
	} = useSelector(state => state.post)
	
	const [showEditPictureInput, setShowEditPictureInput] = React.useState(false);

	const [imageToUpload, setImageToUpload] = useState(null)
	const [imagePreview, setImagePreview] = useState(null)
	const [isCursorOnFrame, setIsCursorOnFrame] = useState(false)
	const fileInputRef = useRef()

	const handleClose = () => {
		dispatch(actionTogglePostDetails({open: false}))
	};

	const { control } = useForm({ 
		defaultValues: {
			postDescription: ""
		}
	});

	const processUploadedFile = file => {
		if(
			file.type=="image/jpg" ||
			file.type=="image/jpeg" ||
			file.type=="image/png"
		){
			const reader = new FileReader();
			reader.onloadend = () => {
				setImageToUpload(file)
				setImagePreview(reader.result)
			}
			reader.readAsDataURL(file)
		}
		else{
			console.log("only image files are accepted")
		}
	}

	const onFileInputChange = (event) => {
		const file = event.target.files[0];
		processUploadedFile(file)
	}
		
	const onFileDrop = (files, event) =>	{
		const file = files[0];
		processUploadedFile(file)
	}

	return ReactDom.createPortal(
		<div className='ImageActionsContainer'>
			{/* <img onClick={handleOpen} src={src} loading="lazy"/> */}
			{/* <Button onClick={handleOpen}>Open modal</Button> */}
			<Modal
				open={showPostModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className='editPicture'>

						<div className="actionsPictureBtn">
							{
							selectedPost?.createdBy?._id == userData?._id ? <>
								{imageToUpload &&
								<IconButton
								aria-label="save"
								onClick={()=>setShowEditPictureInput(open => !open)}
								className="savePictureBtn"
								type="submit">
									<SaveIcon color="white"/>
								</IconButton>}

								<IconButton
								aria-label="edit" 
								onClick={()=>setShowEditPictureInput(open => !open)}
								className="editPictureBtn">
									{showEditPictureInput ? <ImageIcon /> : <EditIcon />}
								</IconButton>

							</> : ''}

							<IconButton
							aria-label="close" 
							onClick={handleClose}
							className="editPictureBtn">
								<CloseIcon />
							</IconButton>

						</div>
						{
						showEditPictureInput ? <>
						{
							// if user provide an Edit component for profile or timeline change
							editComponent ? 
								(showEditProfilePicture || showEditTimelinePicture) ? editComponent : ""
							:
							selectedDataFor == "post" ?
							<div
								className={`uploadWrapper ${isCursorOnFrame ? "cursorOnFrame" : ""}`}>
									{
									imageToUpload ? 
									<div className="uploadImageWrapper">
										<Tooltip
										className="uploadImageEditButton"
										title={"Change image"}>
											<IconButton
											size="small"
											onClick={()=>setImageToUpload(null)}>
												<EditIcon/>
											</IconButton>
										</Tooltip>
										<img src={imagePreview} alt="upload image here" loading="lazy"/>
									</div> : 
									<FileDrop
										className={`fileDropUpload`}
										onFrameDragEnter={(event) => setIsCursorOnFrame(true)}
										onFrameDragLeave={(event) => setIsCursorOnFrame(false)}
										onFrameDrop={(event) => setIsCursorOnFrame(false)}
										onDrop={onFileDrop}
										accept="image/*"
									>
										<p>Drop some files here!</p>
										<Controller
										name="postImage"
										control={control}
										defaultValue={""}
										render={({field}) => 
											<input
												{...field}
												onChange={onFileInputChange}
												ref={fileInputRef}
												type="file"
												accept="image/*"
												className="hidden"
											/>
										}/>
									</FileDrop>
									}
							</div>
							:
							<ChangeProfilePicture closeModal={handleClose}/>
						}
						</>
						:
						selectedPost ? <>
							<img
							className='imageData'
							src={selectedPost ? selectedPost.postImage : ""}
							loading="lazy"/>
							<PostCards postData={selectedPost} showImage={false}/>
						</> : ""
						}
						{/* {
							showEditPictureInput ? <ChangeProfilePicture closeModal={handleClose}/> : ''
						} */}
						
				</Box>
			</Modal>
		</div>,
		document.getElementById("view-image-wrapper-portal")
	);
}

export default ImageActions;
