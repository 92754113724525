//import GroupManagement from '.components/GroupManagement/GroupManagement';//cut it and paste to the component
import React, { useEffect, useState, memo, useCallback } from 'react';
import './Group.scss';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {
  actionGetGroupMemberMoments,
  actionToggleCreateGroupFormOpen,
  actionGetUserCreatedGroupList,
  actionGetUserGroups,
	actionToggleCreatedGroupList,
  actionGetGroupMomentOnDateRange
} from "../../app/Reducers/group/groupSlice";
import { ReactComponent as DashboardIcon } from '../../Assets/ic_dashboard.svg';
import { ReactComponent as UserIcon } from '../../Assets/ic_user.svg';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { NavLink } from "react-router-dom";
import GroupSelection from '../../components/Group/GroupSelection';
// import GroupList from '../../components/Group/GroupList';
import { ReactComponent as SubmitIcon } from '../../Assets/ic_submit.svg';
import { ReactComponent as ChatIcon } from '../../Assets/ic_chat.svg';
import { ReactComponent as TargetIcon } from '../../Assets/ic_target.svg';
import { ReactComponent as QuestionIcon } from '../../Assets/ic_question.svg';
import { ReactComponent as ConceptIcon } from '../../Assets/ic_concept.svg';
import { ReactComponent as InsightIcon } from '../../Assets/ic_insight.svg';
import { ReactComponent as ResultIcon } from '../../Assets/ic_result.svg';
import { ReactComponent as KeyQuestionIcon } from '../../Assets/ic_keyquestion.svg';
import { ReactComponent as KeyConceptIcon } from '../../Assets/ic_keyconcept.svg';
import { ReactComponent as KeyInsightIcon } from '../../Assets/ic_keyinsight.svg';
import { ReactComponent as KeyResultIcon } from '../../Assets/ic_keyresult.svg';

const GroupManagement = ({

}) => {

  const dispatch = useDispatch();

	const {
		groupMomentsData,
    createdGroupList,
    showCreatedGroupList
	} = useSelector(state => state.group)

	const {
		userData
	} = useSelector(state => state.user)
  
  const [calendarValue, setCalendarValue] = useState(new Date())
  const [selectedGroupInDropDown, setSelectedGroupInDropDown] = useState(null)

  const onSelectedDateChange = useCallback((newCalendarValue) => {
    setCalendarValue(newCalendarValue);
    if(selectedGroupInDropDown){
      const params = `endDate=${moment(newCalendarValue).format('YYYY-MM-DD')}`;
      dispatch(actionGetGroupMomentOnDateRange(selectedGroupInDropDown,params))
    }
  },[calendarValue])

  const onSelectGroup = useCallback(e => {
    setSelectedGroupInDropDown(e.target.value)
    const params = `endDate=${moment(calendarValue).format('YYYY-MM-DD')}`;
    dispatch(actionGetGroupMomentOnDateRange(e.target.value,params))
  },[calendarValue])

  useEffect(()=>{
    dispatch(actionGetUserCreatedGroupList())
    dispatch(actionGetUserGroups(userData._id))
  },[])

	return <div className='GroupManagementContainer'>
    <div className="pageContentWrapper">
      <div className="page-header-container">
        <h2 className="section-title-01">Group</h2>
        <Button 
        variant="outlined"
        onClick={()=>dispatch(actionToggleCreatedGroupList(!showCreatedGroupList))}
        >
          Your Groups
        </Button>
        <Button 
        variant="outlined"
        onClick={()=>dispatch(actionToggleCreateGroupFormOpen(true))}
        >
          Create Group
        </Button>
      </div>

      <div className="dashboard-actions-container">

        <GroupSelection
        groupList={createdGroupList}
        onSelectGroup={onSelectGroup} />

        <div style={{marginLeft: "auto"}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Select Date"
              value={calendarValue}
              onChange={onSelectedDateChange}
              renderInput={(params) => <TextField size="small" {...params}/>}
            />
          </LocalizationProvider>
        </div>
        
      </div>
      {/* <GroupList/> */}

      <TableContainer component={Paper}>
        <Table className='groupManagementTable' sx={{ width: "100%" }} aria-label="simple table">
          <TableHead className='tableHeadContainer'>
            <TableRow>
              <TableCell align="left" style={{fontWeight: "bold", width: "250px"}}>Name</TableCell>
              <TableCell align="center" style={{fontWeight: "bold"}}>
                <Tooltip title={"Objectives"}><span className='iconHeading'><TargetIcon/></span></Tooltip>
              </TableCell>

              <TableCell align="center" style={{fontWeight: "bold"}}>
                <Tooltip title={"Questions"}><span className='iconHeading'><QuestionIcon/></span></Tooltip>
              </TableCell>
              <TableCell align="center" style={{fontWeight: "bold"}}>
                <Tooltip title={"Key Questions"}><span className='iconHeading'><KeyQuestionIcon/></span></Tooltip>
              </TableCell>
              <TableCell align="center" style={{fontWeight: "bold"}}>
                <Tooltip title={"Insights"}><span className='iconHeading'><InsightIcon/></span></Tooltip>
              </TableCell>
              <TableCell align="center" style={{fontWeight: "bold"}}>
                <Tooltip title={"Key Insights"}><span className='iconHeading'><KeyInsightIcon/></span></Tooltip>
              </TableCell>
              <TableCell align="center" style={{fontWeight: "bold"}}>
                <Tooltip title={"Concepts"}><span className='iconHeading'><ConceptIcon/></span></Tooltip>
              </TableCell>
              <TableCell align="center" style={{fontWeight: "bold"}}>
                <Tooltip title={"Key Concepts"}><span className='iconHeading'><KeyConceptIcon/></span></Tooltip>
              </TableCell>
              <TableCell align="center" style={{fontWeight: "bold"}}>
                <Tooltip title={"Results"}><span className='iconHeading'><ResultIcon/></span></Tooltip>
              </TableCell>
              <TableCell align="center" style={{fontWeight: "bold"}}>
                <Tooltip title={"Key Results"}><span className='iconHeading'><KeyResultIcon/></span></Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupMomentsData.map(member => {
              return <TableRow
                key={member._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">
                  <NavLink className="nav-link" to={`/dashboard?user=${member.user._id}`}>{member.user.firstName} {member.user.lastName}</NavLink>
                  <div className="userDataLinks">
                    <Tooltip title={`${member.user.firstName}'s Dashboard`}>
                      <NavLink className="nav-link" to={`/dashboard?user=${member.user._id}`}><DashboardIcon/></NavLink>
                    </Tooltip>
                    <Tooltip title={`${member.user.firstName}'s Profile`}>
                      <NavLink className="nav-link" to={`/profile?user=${member.user._id}`}><UserIcon/></NavLink>
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell align="center">{member.moments.objective}</TableCell>
                <TableCell align="center">{member.moments.question}</TableCell>
                <TableCell align="center">{member.moments.keyQuestion}</TableCell>
                <TableCell align="center">{member.moments.insight}</TableCell>
                <TableCell align="center">{member.moments.keyInsight}</TableCell>
                <TableCell align="center">{member.moments.concept}</TableCell>
                <TableCell align="center">{member.moments.keyConcept}</TableCell>
                <TableCell align="center">{member.moments.result}</TableCell>
                <TableCell align="center">{member.moments.keyResult}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
	</div>
}

export default memo(GroupManagement);