//import SlideToggle from '.components/SlideToggle/SlideToggle';//cut it and paste to the component
import React, {useState,useRef} from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const SlideToggle = ({
    headText,
    children,
    fullContentTrigger = false,
    headTextTrigger = false,
    defaultOpen = false
}) => {

    const [open, setopen] = useState(defaultOpen)
    const childrenElementsRef = useRef(null);
    const [childrenHeight, setChildrenHeight] = useState(defaultOpen ? "auto" : 0)

    const getHeadTextTrigger = () => {
        return (
            <div
            className="slide-trigger-wrapper"
            onClick={()=>{
                if(open){
                    setopen(false)
                    setChildrenHeight(0);
                }
                else{
                    setopen(true)
                    setChildrenHeight(childrenElementsRef.current.offsetHeight); 
                }
            }}>
                {headText}
                {/* <span className={`trigger ${open ? "open" : ""}`}>
                    <KeyboardArrowUpIcon fontSize="inherit" />
                </span> */}
            </div>
        )
    }

    const getArrowTrigger = () => {
        return (
        <div className="slide-trigger-wrapper">
            {headText}
            <span className={`trigger ${open ? "open" : ""}`}>
                <IconButton
                color="primary"
                aria-label="delete"
                size="small"
                onClick={()=>{
                    if(open){
                        setopen(false)
                        setChildrenHeight(0);
                    }
                    else{
                        setopen(true)
                        setChildrenHeight(childrenElementsRef.current.offsetHeight); 
                    }
                }}>
                    <KeyboardArrowUpIcon fontSize="inherit" />
                </IconButton>
            </span>
        </div>
        )
    }

    const getFullContentTrigger = () => {
        return (
            <div
            className="slide-trigger-wrapper full-content-trigger"
            onClick={()=>{
                if(open){
                    setopen(false)
                    setChildrenHeight(0);
                }
                else{
                    setopen(true)
                    setChildrenHeight(childrenElementsRef.current.offsetHeight); 
                }
            }}>
                {headText}
                <span className={`trigger ${open ? "open" : ""}`}>
                    <IconButton
                    color="primary"
                    aria-label="delete"
                    size="small">
                        <KeyboardArrowUpIcon fontSize="inherit" />
                    </IconButton>
                </span>
            </div>
        )
    }

    return <div className='SlideToggleContainer'>
        { headTextTrigger ?
            getHeadTextTrigger() :
            fullContentTrigger ? 
                getFullContentTrigger() :
                getArrowTrigger()
        }
        <div
        className="hidden-content"
        style={{
            height: childrenHeight,
            transition: "all 300ms ease",
            overflow: "hidden",
            // borderRadius: "8px"
        }}
        >
            <div ref={childrenElementsRef}>
                {children}
            </div>
        </div>

    </div>

}

export default SlideToggle;