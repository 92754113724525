//import Trades from '.components/Trades/Trades';//cut it and paste to the component
import React, {useEffect, useState} from 'react';
import './Trades.scss';
import LineGraph from '../../pages/Dashboard/Graphs/LineGraph';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import Button from '@mui/material/Button';
import {
  uploadTradeHistoryFile
} from '../../app/Reducers/user/userSlice';

const Trades = ({
}) => {
  const dispatch = useDispatch()
  const [fileContent, setFileContent] = useState(null);
  const {
    selectedUserTradingPortfolio
  } = useSelector(state => state.tradingPortfolioInfo);

  const { setError, control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
    defaultValues: {
      postDescription: ""
    }
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFileContent(file);
    }
  };

  const onSubmit = () => {
    dispatch(uploadTradeHistoryFile(fileContent))
  }

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // useEffect(() => {
    
  // },[userData])

  if(!selectedUserTradingPortfolio) return ""
  
	return (
		<div className='TradesContainer'>
      {/* <p onClick={()=>getUserselectedUserTradingPortfolio()}>testing here</p> */}
      <form
      className="post-form form-container"
      encType="multipart/form-data"
      onSubmit={handleSubmit(onSubmit)}>

        {/* <p>Upload form for the file in your trades</p>
        <Controller
        name="postImage"
        control={control}
        defaultValue={""}
        render={({field}) => 
          <input
            {...field}
            onChange={handleFileChange}
            // ref={fileInputRef}
            type="file"
            accept=".txt"
            className="hidden"
          />
        }/>

        {fileContent && (
          <div>
            <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              width: "100%",
              borderRadius: "8px",
              height: "48px",
              fontSize: "16px",
            }}>Submit</Button>
          </div>
        )} */}

      {/* <Button
      type="submit"
      variant="contained"
      color="primary"
      style={{
        width: "100%",
        borderRadius: "8px",
        height: "48px",
        fontSize: "16px",
      }}>Submit</Button> */}


        {
        selectedUserTradingPortfolio.length ? 
        <LineGraph 
          graphDataArray = {[]}
          // colorPallete = {colorPallete}
          height = {300}
        /> : ""
        }
      </form>
      
      <div className="tradingStatistics">
        <div className="statisticsRow">
          <div className="data">
            <p className="label">Equity</p>
            <p className="value">{USDollar.format(selectedUserTradingPortfolio.equity)}</p>
          </div>
          <div className="data">
            <p className="label">Average profit</p>
            <p className="value">{USDollar.format(selectedUserTradingPortfolio.averageProfit)}</p>
          </div>
        </div>
        <div className="statisticsRow">
          <div className="data">
            <p className="label">Balance</p>
            <p className="value">{USDollar.format(selectedUserTradingPortfolio.balance)}</p>
          </div>
          <div className="data">
            <p className="label">Average loss</p>
            <p className="value">{USDollar.format(selectedUserTradingPortfolio.averageLoss)}</p>
          </div>
        </div>
        <div className="statisticsRow">
          <div className="data">
            <p className="label">No. of trades</p>
            <p className="value">{selectedUserTradingPortfolio.totalClosedDealsCount}</p>
          </div>
          <div className="data">
            <p className="label">Average RRR</p>
            <p className="value">{selectedUserTradingPortfolio.rrr.toFixed(2)}</p>
          </div>
        </div>
        <div className="statisticsRow">
          <div className="data">
            <p className="label">Lots</p>
            <p className="value">{selectedUserTradingPortfolio.lotSize.toFixed(2)}</p>
          </div>
          <div className="data">
            <p className="label">Expectancy</p>
            <p className="value">{selectedUserTradingPortfolio.expectancy.toFixed(2)}</p>
          </div>
        </div>
        <div className="statisticsRow">
          <div className="data">
            <p className="label">Win rate</p>
            <p className="value">{selectedUserTradingPortfolio.winRate.toFixed(2)}</p>
          </div>
          <div className="data">
            <p className="label">Profit factor</p>
            <p className="value">{selectedUserTradingPortfolio.profitFactor.toFixed(2)}</p>
          </div>
        </div>
        <div className="statisticsRow">
          <div className="data">
            <p className="label">Sharpe Ratio</p>
            <p className="value">{selectedUserTradingPortfolio.sharpeRatio.toFixed(2)}</p>
          </div>
        </div>
      </div>
		</div>
	)
}

export default Trades;