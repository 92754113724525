//import LineGraph from '.components/LineGraph/LineGraph';//cut it and paste to the component
import React, {useEffect} from 'react';
import {
    XAxis,
    FlexibleWidthXYPlot,
    YAxis,
    HorizontalGridLines,
    LineSeries,
} from 'react-vis';
import './LineGraph.scss';

const LineGraph = ({
    graphDataArray,
    colorPallete,
    height = 300,
    title = null
}) => {

    useEffect(() => {}, [window.innerWidth]);


    return graphDataArray ? <div className="line-and-bar-graph" >
        {title ? <div className="heading-container">
            <h3 className='section-title-04'>{title}</h3>
        </div> : ""}
        <div className="graphContainer">
            <FlexibleWidthXYPlot
            height={height}
            xType="ordinal">
                <HorizontalGridLines style={{ stroke: '#dedede' }} />
                <XAxis
                    title="Date"
                    style={{
                        line: { stroke: '#ADDDE1' },
                        ticks: { stroke: '#ADDDE1' },
                        text: { stroke: 'none', fill: '#6b6b76', fontWeight: 600 }
                    }}
                    tickLabelAngle={-35}
                    animation={false}
                />
                <YAxis />
                {/* Line Graph data */}
                {graphDataArray.map((graphData,i) => <LineSeries key={graphData.id}
                    animation
                    className=""
                    style={{ strokeWidth: '3px', stroke: colorPallete[i] }}
                    data={graphData.data}
                />)}
                
                
            </FlexibleWidthXYPlot>
        </div>
        <ul className="dataLegends" style={{ width: "300px" }}>
            {graphDataArray.map((e, i) => <li key={i} className="legend-container">
                <span
                    className="legend"
                    style={{
                        backgroundColor: colorPallete[i],
                        height: "2px"
                    }}
                >.</span>
                <span className="label">{e.name}</span>
            </li>)}
        </ul>
    </div> : ""
}

export default LineGraph;