import React, {memo, useEffect, useState } from 'react';
import './TradingDashboard.scss';
import Trades from '../../components/Trades/Trades';
import { useSelector, useDispatch } from 'react-redux';
import {
	actionGetTradingPortfolioInfoOfUser,
	actionToggleSetupFTMOTradingAccountFormOpen
} from '../../app/Reducers/tradingPortfolioInfo/tradingPortfolioInfoSlice';
import SetupFTMOTradingAccountForm from '../../components/SetupFTMOTradingAccountForm/SetupFTMOTradingAccountForm';

const TradingDashboard = ({
}) => {
  const dispatch = useDispatch()
	const {
    userData
  } = useSelector(state => state.user);

  const getUserTradeData = async () => {
    dispatch(actionGetTradingPortfolioInfoOfUser(userData._id))
  }

	useEffect(()=>{
		getUserTradeData()
	},[])
	return <div className='TradingDashboardContainer'>
		<div className="dashboardWrapper">
			<div className="page-header-container">
				<h2 className="section-title-01">Trading Dashboard</h2>
				<p onClick={()=>{ dispatch(actionToggleSetupFTMOTradingAccountFormOpen(true)) }}>Set FTMO login credentials</p>
			</div>
			<Trades />
		</div>
		<SetupFTMOTradingAccountForm/>
	</div>
}

export default memo(TradingDashboard);


