//import ImageDropZone from '.components/ImageDropZone/ImageDropZone';//cut it and paste to the component
import React, {useState, useEffect, useCallback, memo} from 'react';
import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import Slider from '@mui/material/Slider';
// import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as ChangeIcon } from '../../Assets/ic_change.svg';

const ImageDropZone = ({
	fileInputRef,
	handleFileChange,
	handleDrop,
	editorRef,
	image,
	editorWidth=null,
	maxWidth=null,
	yRatio=65/150, // landscape ratio is the default
	border=30
}) => {

	const computedWidth = editorWidth > 600 && editorWidth > (window.innerWidth - 680) ?
												editorWidth :
												window.innerWidth - 680; // 680 is the computed form size and spacings
	const windowWidth = maxWidth ? 
											maxWidth + 80 > window.innerWidth ? 
											(window.innerWidth - 80) : 
											maxWidth :										
											computedWidth > 600 && window.innerWidth > (600+160)?
											600 : // if computed width is greater than, 600+160, make it 600
											window.innerWidth - 160 //: // if computed width is less than, 400, cover the entire window
	const [scale, setScale] = useState(0);
	const width = windowWidth;
	const height = width*yRatio;

	const [windowSize, setWindowSize] = useState({
		width: width,
		height: height,
	});

	useEffect(() => {
		const handleResize = () => {
			const computedWidth = editorWidth > 600 && editorWidth > (window.innerWidth - 680) ?
														editorWidth :
														window.innerWidth - 680; // 680 is the computed form size and spacings
			const windowWidth = maxWidth ? 
													maxWidth + 80 > window.innerWidth ? 
													(window.innerWidth - 80) : 
													maxWidth :
													computedWidth > 600 && window.innerWidth > (600+160)?
														600 : // if computed width is greater than, 600+160, make it 600
														window.innerWidth - 160 //: // if computed width is less than, 400, cover the entire window
			const width = windowWidth;
			const height = width*yRatio;
		  setWindowSize({
				width,
				height,
		  });
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div className="imageEditorContainer">
			<div
			className="inputWrapper"
			style={{padding: 10}}>

				{
				image ?
				<p className='fileName'>{fileInputRef?.current?.files[0]?.name}</p> :
				<input
				type="file"
				ref={fileInputRef}
				accept="image/*"
				onChange={handleFileChange}
				style={{color: "#fff"}}/> 
				}

				<Tooltip title={"Change image"}>
					<IconButton
					aria-label="change"
					size="small"
					sx={{
						margin: 0,
						width: 24
					}}
					onClick={()=>{
						handleFileChange(null)
						console.log("test")	
					}}>
						<ChangeIcon className='changeIcon'/>
					</IconButton>
				</Tooltip>

			</div>

			<Dropzone
				onDrop={handleDrop}
				noClick
				noKeyboard
				style={{ width: '250px', height: '250px' }}
			>
				{({ getRootProps, getInputProps }) => (
					<div {...getRootProps()}>
						<AvatarEditor
						ref={editorRef}
						width={windowSize.width}//300
						height={windowSize.height}//150
						border={border}
						borderRadius={0}
						color={[0, 0, 0, 0.6]} // RGBA
						scale={1+(scale/100)}
						rotate={0}
						image={image} 
						/>
						<input {...getInputProps()} />
					</div>
				)}
			</Dropzone>

			<div
			className="sliderWrapper"
			style={{
				padding: "0 16px"
			}}>
				<Slider
					size="small"
					defaultValue={0}
					aria-label="Small"
					valueLabelDisplay="auto"
					color="white"
					onChange={ (e, val) => setScale(val) } 
				/>
			</div>
			
		</div>
	)
}

export default memo(ImageDropZone);