//import ReflectionForm from '.components/ReflectionForm/ReflectionForm';//cut it and paste to the component
import React, {useRef,useState,useEffect,useCallback} from 'react';
import './ReflectionForm.scss';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import { TodoTypes } from "../../Hooks/Constants";
import Rating from '@mui/material/Rating';
import debounce from "lodash.debounce";
import {
    actionEndTodo,
} from '../../../app/Reducers/todo/todoSlice';
import {
    actionAppendMomentSubjects,
} from '../../../app/Reducers/moment/momentSlice';
import {
	actionSendMessageToBot
} from '../../../app/Reducers/chats/chatsSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

const schema = yup.object().shape({
    reflectionDescription: yup.string(),
});

const ReflectionForm = ({
    todo,
    reflectionFormOpen,
    setReflectionFormOpen,
}) => {

    const dispatch = useDispatch();
    const {momentSubjects} = useSelector(state => state.moment);
    const feedbackInputRef = useRef(null)
    
    // form setup
    const { control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
        defaultValues: {
            reflectionDescription: "",
            reflectionSubject: ""
        },
        resolver: yupResolver(schema)
    });

    const [selectedTags, setSelectedTags] = useState([])
    const [selectedSuggestedTags, setSelectedSuggestedTags] = useState([
        {
            id: 1,
            name: "#WhatILearn",
            selected: false
        },
        {
            id: 2,
            name: "#ForImprovement",
            selected: false
        }
    ])
    const [reflection, setReflection] = useState("")
    const [subjectTextInput, setSubjectTextInput] = useState("")
    const [textTags, setTextTags] = useState([])
    const [todoType, setTodoType] = useState(0)

    const [botSuggestionRating, setBotSuggestionRating] = useState(0)
    const [botSuggestionFeedback, setBotSuggestionFeedback] = useState("")

    // reset the form after successfull submit of form data
    useEffect(() => {
        if (isSubmitSuccessful) {
            setReflectionFormOpen(false)
        };
    }, [isSubmitSuccessful, reset]);
    
    const onSubjectInputChange = e => setSubjectTextInput(e.target.value);

    // on form submit
    const onSubmit = () => {

        console.log(todo)

        let endTodoParams = {
            todoId: todo._id,
            reflection: reflection,
            subject: subjectTextInput,
            tags: selectedTags,
            todoType: todoType
        }

        if(botSuggestionRating || botSuggestionFeedback.length){
            endTodoParams = {
                ...endTodoParams,
                botSuggestionRating,
                botSuggestionFeedback,
                userInquiryToBot: todo.userInquiryToBot,
                botResponse: todo.botResponse,
                trainingData: todo.trainingData
            }
        }

        if(todo.linkFromThirdParty){
            endTodoParams = {
                ...endTodoParams,
                linkFromThirdParty: todo.linkFromThirdParty
            }
        }

        dispatch(actionEndTodo(endTodoParams))
        if(subjectTextInput.length){
            dispatch(actionAppendMomentSubjects(subjectTextInput))
        }
        if(reflection.length){
            dispatch(actionSendMessageToBot(reflection))
        }
    }

    const handleRatingChange = useCallback((e) => {
        setBotSuggestionRating(Number(e.target.value))
    },[])

    const getHashtags = useCallback(reflection => {
        const str = reflection.target.value;
        setReflection(str)
        let textHashTags = str.match(/#[a-z0-9_]+/ig);
        textHashTags = textHashTags ? textHashTags : [];
        setTextTags(textHashTags)
    },[selectedSuggestedTags])

    const onSuggestedTagClick = useCallback(tag => {
        const suggestedTagChange = [...selectedSuggestedTags].map(suggestedTag => {
            return suggestedTag.name === tag.name ? {
                ...suggestedTag,
                selected: !suggestedTag.selected
            } : suggestedTag
        })
        setSelectedSuggestedTags(suggestedTagChange)
    })

    const handleFeedbackChange = useCallback(debounce((e) => {
        setBotSuggestionFeedback(e)
    }, 500),[])

    const onFeedbackChange = e => {
        handleFeedbackChange(e.target.value)
    }

    useEffect(() => {
        const allTags = [...new Set([...textTags,...selectedSuggestedTags.filter(t=>t.selected).map(sst => sst.name)])];
        setSelectedTags(allTags)
    }, [textTags,selectedSuggestedTags]);

    return <div className='ReflectionFormContainer'>
        <Modal
            keepMounted
            open={reflectionFormOpen}
            onClose={()=>setReflectionFormOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style} spacing={2}>
                <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                style={{marginBottom:10}}>

                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        {`Your Reflection`}
                    </Typography>

                    <CloseIcon 
                    className="close-form-trigger"
                    onClick={()=>setReflectionFormOpen(false)}/>

                </Stack>

                <form className="todo-edit-form" onSubmit={handleSubmit(onSubmit)}>
                    
                    <Stack spacing={2}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="todo-type-selection-label">Todo type</InputLabel>
                                    <Select
                                        labelId="todo-type-selection-label"
                                        id="todo-type-selection"
                                        value={todoType}
                                        label="Todo type"
                                        onChange={e => setTodoType(e.target.value)}
                                    >
                                    {TodoTypes.map(type=><MenuItem key={type.value} value={type.value}>{type.text}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                        <Controller
                            name="reflectionDescription"
                            control={control}
                            // defaultValue={""}
                            value={reflection}
                            render={
                                ({field}) => <TextField
                                            {...field}
                                            className="textArea"
                                            label="New Realization(s)"
                                            variant="outlined"
                                            placeholder={"Todo Description"}
                                            error={!!errors.reflectionDescription}
                                            type="text"
                                            value={reflection}
                                            onChange={getHashtags}
                                            multiline
                                            helperText={errors?.reflectionDescription ? errors?.reflectionDescription?.message : ''}
                                        />
                            }
                        />
                        

                        <Autocomplete
                            id="subject-input"
                            freeSolo
                            options={momentSubjects.map((option) => option)}
                            onChange={(e, newValue) => {setSubjectTextInput(newValue)}}
                            renderInput={(params) => <TextField 
                                {...params}
                                label="Subject"
                                onChange={onSubjectInputChange}
                            />}
                        />

                        {todo.trainingData ? <>
                            <div className="rio-rating-wrapper">
                                <span className="label">Bot Suggestion Rating:</span>
                                <Rating
                                    name="simple-controlled"
                                    size="small"
                                    value={botSuggestionRating}
                                    onChange={e=>handleRatingChange(e)}
                                />
                            </div>

                            <TextField
                            className="feedback"
                            label="Bot Suggestion Feedback"
                            variant="outlined"
                            inputRef={feedbackInputRef}
                            onChange={onFeedbackChange}/>

                        </> : ""}

                        <section className="selected-tag-wrapper">
                            <h4 className="tag-selection-heading">Selected Tags</h4>
                            <ul className="tag-list selected-tag-list">
                                {selectedTags.map(tag=>
                                    <li 
                                    key={tag} 
                                    className="reflect-selected-tag"
                                    onClick={()=>setSelectedSuggestedTags([...selectedSuggestedTags,tag])}
                                    >
                                        <Tooltip title="Remove"><span>{tag}</span></Tooltip>
                                    </li>
                                )}
                            </ul>
                        </section>
                        
                        <section className="tag-list-wrapper">
                            <h4 className="tag-selection-heading">Suggested Tags</h4>
                            <ul className="tag-list  tag-list-container">
                                {selectedSuggestedTags.map(tag=>
                                    <li 
                                    key={tag.id} 
                                    className={`reflect-tag ${tag.selected ? "selected" : ""}`}
                                    onClick={() => onSuggestedTagClick(tag)}
                                    >{tag.name}</li>
                                )}
                            </ul>
                        </section>

                        
                        
                        <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end">
                            <Button type="submit" variant="outlined">Done</Button>
                            <Button onClick={()=>setReflectionFormOpen(false)} type="button" variant="outlined">Cancel</Button>
                        </Stack>

                    </Stack>

                </form>
            </Box>
        </Modal>
    </div>
}

export default ReflectionForm;