import React, {useEffect} from 'react';
import "./MotivationalQoutes.scss"
import OpenAIChatbox from '../../components/ChatBox/OpenAIChatbox';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import CreateMotivationMessageForm from '../../components/Motivations/CreateMotivationForm';
import EditMotivationForm from '../../components/Motivations/EditMotivationForm';
import {
  actionToggleCreateMotivationMessageFormOpen,
  actionGetMotivationMessageList
} from '../../app/Reducers/motivations/motivationSlice';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Qoute from './Qoute';

const MotivationalQoutes = ({

}) => {

  const {
    motivationMessageList
	} = useSelector(state => state.motivation);
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(actionGetMotivationMessageList())
  },[])

  return <div className="MotivationalQoutesContainer">
    <div className="chatBoxWrapper">
      <OpenAIChatbox />
    </div>
    <div className="motivationListContainer">
      <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      style={{
        marginBottom: "10px",
        paddingBottom: "10px",
        borderBottom: "1px solid #E7E7E7"
      }}>
        <h2 className='section-heading-uppercase'>
          <FormatQuoteIcon fontSize="small"/> Motivation Qoutes List
        </h2>
        <Tooltip title="Add Task">
          <IconButton
          sx={{ marginLeft: "10", padding: "5px"}}
          aria-label="add"
          onClick={()=>dispatch(actionToggleCreateMotivationMessageFormOpen(true))}>
            <AddIcon sx={{fontSize: 18}}/>
          </IconButton>
        </Tooltip>
      </Stack>
      <ul className="motivationList">
        {
        motivationMessageList.map(motivationMessage=>(
          <li
          key={motivationMessage._id}
          className="motivation">
            <Qoute motivationMessage={motivationMessage} />            
          </li>
        ))
        }
      </ul>
    </div>
    <CreateMotivationMessageForm />
    <EditMotivationForm />
  </div>
}

export default MotivationalQoutes;