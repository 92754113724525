import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import {
  actionToggleUpdateSeminarFormOpen,
  actionUpdateSeminar,
  actionArchiveSeminar
} from '../../app/Reducers/classroom/classroomSlice';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

const schema = yup.object().shape({
  name: yup.string().required("Provide valid name"),
  // description: yup.string().required("Provide valid description"),
  // seminarLink: yup.string().required("Provide valid seminarLink"),
  // speaker: yup.string().required("Provide valid speaker")
});


const UpdateSeminarForm = ({

}) => {

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const dispatch = useDispatch()
  const {
    updateSeminarFormOpen,
    selectedClassroom,
    selectedSeminar
  } = useSelector(state => state.classroom)


  // form setup
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues: {
      name: "", description: "", seminarLink: "", speaker: "", price: 0
    },
    resolver: yupResolver(schema)
  });

  const onClose = () => {
    dispatch(actionToggleUpdateSeminarFormOpen(false))
    reset({
      name: "",
      description: "",
      price: 0,
      seminarLink: ""
    });
    setStartTime(null);
    setEndTime(null);
  }

  const onSubmit = ({
    name,
    description,
    price,
    seminarLink
  }) => {
    const {
      coach = null,
      teacher = null,
      _id
    } = selectedClassroom;
    const speaker = coach ? coach : teacher

    dispatch(actionUpdateSeminar(
      selectedSeminar._id,
      {
        name,
        description,
        price,
        seminarLink,
        start: startTime,
        end: endTime,
        speacker: speaker._id,
        classroomId: _id
      }
    ))
  }

  useEffect(()=>{
    if(selectedSeminar){
      setValue("name", selectedSeminar?.name)
      setValue("description", selectedSeminar?.description)
      setValue("seminarLink", selectedSeminar?.seminarLink)
      setValue("price", selectedSeminar?.price)
      setStartTime(new Date(selectedSeminar.start))
      setEndTime(new Date(selectedSeminar.end))
    }
  },[selectedSeminar])

  return ReactDom.createPortal(<>
    <div className='SeminarFormContainer'>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Modal
        keepMounted
        open={updateSeminarFormOpen}
        onClose={() => onClose()}
        aria-labelledby="modal-Seminar"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            style={{ marginBottom: 10 }}>
            <Typography id="modal-Seminar" variant="h6" component="h2">
              Edit Seminar
            </Typography>

            <CloseIcon
              className="close-form-trigger"
              onClick={() => onClose()} />
          </Stack>
          <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction="column"
              alignItems="center"
              spacing={2}>


              <RHFTextField
                control={control}
                name="name"
                label="name"
                placeholder="name"
                error={!!errors.name}
                helperText={errors?.name ? errors?.name?.message : ''}
                type="text"
              />


              <RHFTextField
                control={control}
                name="description"
                label="description"
                placeholder="description"
                error={!!errors.description}
                helperText={errors?.description ? errors?.description?.message : ''}
                type="text"
              />

              <RHFTextField
                control={control}
                name="seminarLink"
                label="seminarLink"
                placeholder="seminarLink"
                error={!!errors.seminarLink}
                helperText={errors?.seminarLink ? errors?.seminarLink?.message : ''}
                type="text"
              />

              <Stack spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                width: "100%",
                maxWidth: "600px"
              }}
              >
                  <DateTimePicker
                      label="Start time"
                      value={startTime}
                      name="start"
                      onChange={timeValue=>setStartTime(timeValue)}
                      renderInput={(params) => <TextField {...params} />}
                  />
                  <DateTimePicker
                      label="End time"
                      value={endTime}
                      name="end"
                      onChange={timeValue=>setEndTime(timeValue)}
                      renderInput={(params) => <TextField {...params} />}
                  />
              </Stack>

              <RHFTextField
                control={control}
                name="price"
                label="price"
                placeholder="price"
                error={!!errors.price}
                helperText={errors?.price ? errors?.price?.message : ''}
                type="text"
              />

              {/* <RHFTextField
                control={control}
                name="speaker"
                label="speaker"
                placeholder="speaker"
                error={!!errors.speaker}
                helperText={errors?.speaker ? errors?.speaker?.message : ''}
                type="text"
              /> */}

              <Stack
                spacing={1}
                direction="row"
                justifyContent="center">
                <Button type="submit" variant="contained">Update</Button>
                <Button
                  component={Link}
                  to="/"
                  type="button"
                  variant="contained">Cancel</Button>
                {/* <Button
                  onClick={()=>dispatch(actionArchiveSeminar(selectedSeminar._id, true))}
                  startIcon={selectedSeminar?.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                  type="button"
                  variant={selectedSeminar?.archived ? "contained" : "outlined"}>{selectedSeminar?.archived ? "Unarchive" : "Archive"}</Button> */}
                <Button
                  onClick={()=>dispatch(actionArchiveSeminar(selectedSeminar._id, !selectedSeminar?.archived))}
                  startIcon={selectedSeminar?.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                  type="button"
                  variant={selectedSeminar?.archived ? "contained" : "outlined"}>{selectedSeminar?.archived ? "Unarchive" : "Archive"}</Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Modal>
      </LocalizationProvider>
    </div>
  </>, document.getElementById("update-seminar-form-wrapper-portal"))
}

export default UpdateSeminarForm;