import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SlideToggle from '../Navigation/SlideToggle';
import "./EmergentIndex.scss"
import { actionGetUserEmergentIndex } from '../../app/Reducers/user/userSlice';

const EmergentIndex = ({
	userId = "",
	defaultOpen = false
}) => {
	const {
		postsCount,
		postsTotalPoints,
		postsWithPointsCount,
		todosCount,
		todosTotalPoints,
		todosWithPointsCount
	} = useSelector(state => state.user);

	const dispatch = useDispatch();

	useEffect(()=>{
		if(userId.length)
			dispatch(actionGetUserEmergentIndex(userId))
	},[userId])
	

	return <div className='EmergentIndexHeadingWrapper'>
		<SlideToggle headText={
			<p className='EmergentIndexHeading'>Emergent Index: 
				<span style={{
					fontWeight: "bold",
					marginLeft: "auto"
				}}>
				{todosTotalPoints+postsTotalPoints}</span>/
				{todosWithPointsCount+postsWithPointsCount} =
				<span style={{fontWeight: "bold"}}>{
					((todosTotalPoints+postsTotalPoints)/(todosWithPointsCount+postsWithPointsCount)).toFixed(1)
				}</span>
			</p>
		}
		defaultOpen={defaultOpen}
		>
			<>
				<div className="tableWrapper">
					<table className='emergentIndexTable'>
						<tbody>
							{[
							{title: "Total Posts Points",			value: postsTotalPoints},
							{title: "Total Posts With Points Count",value: postsWithPointsCount},
							{title: "Total Posts Number", 					value: postsCount},
							{title: "Total Posts Without points", 	value: postsCount - postsWithPointsCount},
							].map((row) => (<tr key={row.title} >
								<td>{row.title}</td>
								<td align="right">{row.value}</td>
							</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="tableWrapper">
					<table className='emergentIndexTable'>
						<tbody>
							{[
							{title: "Total Todos Points",			value: todosTotalPoints},
							{title: "Total Todos With Points Count",value: todosWithPointsCount},
							{title: "Total Todos Number", 					value: todosCount},
							{title: "Total Todos Without points", 	value: todosCount - todosWithPointsCount},
							].map((row) => (<tr key={row.title} >
								<td>{row.title}</td>
								<td align="right">{row.value}</td>
							</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		</SlideToggle>
	</div>
}

export default EmergentIndex;