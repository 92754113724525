//import GroupListSection from '.components/GroupListSection/GroupListSection';//cut it and paste to the component
import React, { useEffect } from 'react';
import GroupCard from '../../components/GroupCard/GroupCard';

const GroupListSection = ({
  title,
  joinText,
  loadClassFunction,
  joinFunction,
  classList,
  hasNextPage,
  nextPage,
}) => {
  
	useEffect(()=>{
    loadClassFunction()
	},[])

	return <div className='GroupListSectionContainer'>
    <div className="followLayoutContainer">
      <h3 className='section-title-04'>{title}</h3>
      <div className="suggestedPeopleList">
        {classList.map(
          classData =>  <GroupCard
                        key={classData._id}
                        classData={classData}
                        joinFunction={joinFunction}
                        joinText={joinText}/>
        )}
      </div>
      {
        hasNextPage &&
        <p
        className='load-more-comments'
        style={{marginTop: 24}}
        onClick={()=>loadClassFunction(nextPage)}>Load more...</p>
      }
    </div>
	</div>
}

export default GroupListSection;
