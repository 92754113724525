
//import PostInputs from '.components/PostInputs/PostInputs';//cut it and paste to the component
import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import { Controller } from "react-hook-form";
import EmotionOptions from '../EmotionOptions/EmotionOptions';

const PostInputs = ({
	control,
	errors,
	setSelectedEmotion,
	handlePaste
}) => {

  const [showEmotion, setShowEmotion] = useState(false)

  return <div className='PostInputsContainer'>
		<Controller
			name="postDescription"
			control={control}
			defaultValue={""}
			render={({field}) => <TextField
				{...field}
				className="textArea"
				onPaste={handlePaste}
				label="Post description"
				variant="outlined"
				placeholder={"Post description"}
				error={!!errors.postDescription}
				type="postDescription"
				sx={{ width: '100%' }}
				helperText={errors?.postDescription ? errors?.postDescription?.message : ''}
			/>
		}/>

		<div className="mood-container" style={{display: "block"}}>
			<p
			className='moodLabel'
			onClick={()=>{
				setShowEmotion(show=>!show)
				setSelectedEmotion(-1)
			}}>
				Tell us your feeling.
			</p>
			<EmotionOptions
				showEmotion={showEmotion}
				setShowEmotion={setShowEmotion}
				setSelectedEmotion={setSelectedEmotion}
			/>
		</div>

  </div>
}

export default PostInputs;

