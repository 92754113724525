//import ClassList from '.components/ClassList/ClassList';//cut it and paste to the component
import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
import './ClassList.scss';
import ClassroomCard from '../../components/ClassroomCard/ClassroomCard';
import {
  actionGetSuggestedClassrooms,
  actionSearchClassroom,
  actionToggleFindClassSection,
  actionGetJoinedClassroom,
  actionRequestToJoinClassroom,
} from '../../app/Reducers/classroom/classroomSlice';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as AddClassIcon } from '../../Assets/ic_add_class.svg';
import { useSearchParams } from 'react-router-dom';
import KeywordSearch from '../../components/KeywordSearch/KeywordSearch';
import ClassListSection from '../../components/ClassListSection/ClassListSection';

const ClassList = ({

}) => {
  const [params, setParams] = useSearchParams({ user: "" })
  const dispatch = useDispatch();
  const {
		isLoading,
    showFindClassSection,
    classroomsSearchResults,
    
    // suggested classroom
    suggestedClassrooms,
    suggestedClassroomsHasNextPage,
    suggestedClassroomsNextPage,

    // joined classroom
    joinedClassrooms,
    joinedClassroomsHasNextPage,
    joinedClassroomsNextPage,
	} = useSelector(state => state.classroom)

  const id = params.get("user")
	const searchInputRef = useRef(null)

	// useEffect(()=>{
  //   dispatch(actionGetSuggestedClassrooms())
	// },[id])

	useEffect(()=>{
    dispatch(actionGetSuggestedClassrooms(1))
    dispatch(actionGetJoinedClassroom(1))
	},[])

	// if(!selectedClassroom) return ""

	return <div className='ClassListContainer'>
		{isLoading ? <>
			<div className="loading-wrapper">
				<div className="loader"></div>
			</div>
		</> : ""}	
    <div className="page-header-container">
      <h2 className="section-title-01">My Classes</h2>

      <Button
      style={{
        marginTop: "8px",
        width: "200px"
      }}
      variant={"contained"}
      onClick={()=>{
        dispatch(actionToggleFindClassSection(!showFindClassSection))
      }}
      startIcon={<AddClassIcon />}>{showFindClassSection ? "Hide Find classes" : "Find classes"}</Button>
    </div>
    {
    showFindClassSection ?
    <div className="followLayoutContainer">
      <div className="wrapHeading" style={{marginBottom: "20px"}}>
        <h3 className='section-title-04'>Search Classroom Results</h3>
        <KeywordSearch
        searchInputRef = { searchInputRef }
        reducerFunctionCallback = { actionSearchClassroom }
        label="Search"
        triggerTextLengthGreaterThan = {1}
        />
      </div>
      <div className="suggestedPeopleList">
        {classroomsSearchResults.map(classData => <ClassroomCard key={classData._id} classData={classData}/>)}
      </div>
    </div> : ""
    }

    <ClassListSection
      title=        {"Joined Classes"}
      classList=    {joinedClassrooms}
      hasNextPage=  {joinedClassroomsHasNextPage}
      nextPage=     {joinedClassroomsNextPage}
      loadClassFunction = {
        page => dispatch(actionGetJoinedClassroom(page))
      }
    />

    <ClassListSection
      title=        {"Classes you might be interested"}
      joinText=     {"Join Classroom"}
      classList=    {suggestedClassrooms}
      hasNextPage=  {suggestedClassroomsHasNextPage}
      nextPage=     {suggestedClassroomsNextPage}
      loadClassFunction = {
        page => dispatch(actionGetSuggestedClassrooms(page))
      }
      joinFunction={
        classroomId => dispatch(actionRequestToJoinClassroom(classroomId))
      }
    />
    {/* <div className="followLayoutContainer">
      <h3 className='section-title-04'>Classes you might be interested</h3>
      <div className="suggestedPeopleList">
        {suggestedClassrooms.map(classData => <ClassroomCard key={classData._id} classData={classData}/>)}
      </div>
      {
        suggestedClassroomsHasNextPage && <button>Next</button>
      }
    </div> */}
    
    {/* <div className="classList">
      {Array.from(Array(10).keys()).map(x=><ClassroomCard key={x}/>)}
    </div> */}
    {/* <CreateClassroomForm /> */}
	</div>
}

export default ClassList;