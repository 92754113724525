//import MemberSelection from '.components/MemberSelection/MemberSelection';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo,useCallback} from 'react';
import './MemberSelection.scss';
import { useSelector,useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { ReactComponent as UserIcon } from '../../Assets/ic_user.svg';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
  actionGetTeamMembers
} from '../../app/Reducers/user/userSlice';
import {
  systemRoles
} from "../Hooks/Constants";
import { actionSetSelectedUserData } from '../../app/Reducers/user/userSlice';

const MemberSelection = ({
  onSelectUser
}) => {
    const dispatch = useDispatch();
    const { 
      teamMembers,
      selectedUserData,
      userData
    } = useSelector(state => state.user);

    useEffect(() => {
      console.log("dumaan herererererere")
      dispatch(actionSetSelectedUserData(userData._id))
      if(
        userData.systemRole == systemRoles.superAdmin ||
        userData.systemRole == systemRoles.admin
      ){
        dispatch(actionGetTeamMembers())
      }
      return () => { /*  cleanup */ }
    }, [userData])

    return <div className='MemberSelectionContainer'>
        <div className="owner-detail">
          <div className="asignee-info">
            {/* <span className="icon"><UserIcon/></span> */}
            {selectedUserData ? 
            <FormControl
            fullWidth
            size="small">
              <InputLabel id="selected-user-label">User</InputLabel>
              {teamMembers.length ?
              <Select
                labelId="selected-user-label"
                id="selected-user"
                value={selectedUserData._id}
                label="User"
                onChange={onSelectUser}
              >
                {teamMembers.map(teamMember => 
                  <MenuItem key={teamMember._id} value={teamMember._id}>
                    <ListItemIcon sx={{
                      minWidth: "24px",
                      width: "24px",
                      borderRadius: "100%",
                      overflow: "hidden",
                      marginRight: "8px"
                    }}>
                      {teamMember?.profilePicture ?

                      <img src={teamMember.profilePicture} alt={`${teamMember.firstName} ${teamMember.lastName}`} /> :
                      <UserIcon/>}
                    </ListItemIcon>
                    {teamMember.firstName} {teamMember.lastName}
                  </MenuItem>
                )}
              </Select> : ""
              }
            </FormControl>
            : ""}
          </div>
        </div>
    </div>
}

export default MemberSelection;