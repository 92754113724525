import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const ConfirmationDialogRaw = ({
  onClose,
  open,
  callBack,
  dialog,
  description
}) => {
  
  const radioGroupRef = React.useRef(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogTitle>{dialog}</DialogTitle>
      <DialogContent dividers>
        <p>{description}</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={()=>onClose()}>Cancel</Button>
        <Button onClick={()=>{ callBack(); onClose(); }}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialogRaw;

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  callBack: PropTypes.func.isRequired,
  dialog: PropTypes.string,
  description: PropTypes.string,
};

