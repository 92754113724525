import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import {v1 as uuid} from "uuid";
import { analize, getYoutubeSuggestion } from '../../BotAnalisis/BotAnalisis'
import { stopWords } from "../../../components/Hooks/Constants";
/* Just a test code... remove it afterwards */ 
const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;
/* Just a test code... remove it afterwards */
const token = localStorage.getItem('wstoken');
const headers = {
    headers: {
        "auth-token": token
    }
}

/*
    chatObject: {
        _id: uuid(),
        name: "Rio",
        message: "Hello",
        isSender: false
    }
*/

const initialState = {
    botData: {
        _id: "00000000000", // this is the chat id
        name: "Rio",
        description: "Reflect bot assistant",
        conversation: [],
        seen: false,
        show: false,
        online: true,
        showOptions: false,
        subjects: [],
        links: [],
        suggestedSubjectQuery: "",
        requestFor: "youtube",
        userQuery: null
    },
    chatList: [],
    showOptions: false,
    subjects: [],
    links: []
};

export const chatSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        sendMessage: (state,action) => {
            const { chat_id } = action.payload[0];

            const messageData = action.payload.map(chat => {
                return {
                    ...chat,
                    _id: uuid()
                }
            })
            
            state.chatList = state.chatList.map(chatData=>{
                // not entering in if...
                if(chatData._id === chat_id){ // find the chat data in the chat list
                    return {
                        ...chatData,
                        conversation: [
                            ...chatData.conversation,
                            ...messageData
                        ]
                    }
                }
                return chatData
            })
        },
        sendMessageToBot: (state, action) => {
            const {
                chat_id,
                messages,
                subjects,
                links,
                showOptions = false,
                subjectSuggestion = null,
                suggestedSubjectQuery,
                userQuery = null,
                responseData = null,
            } = action.payload;

            let botDataToSave = {
                ...state.botData,
                conversation: [...state.botData.conversation, ...messages],
                subjectSuggestion,
                showOptions,
                responseData,
                show: true,
                suggestedSubjectQuery: suggestedSubjectQuery ? suggestedSubjectQuery : state.botData.suggestedSubjectQuery
            }

            if(userQuery){
                botDataToSave = {
                    ...botDataToSave,
                    userQuery
                }
            }


            state.botData = botDataToSave;
        },
        removeOptionsInChatbox: (state, action) => {
            state.botData.showOptions = false
        },
        showSelectedChatbox: (state, action) => {
            state.chatList = state.chatList.map(chat => {
                if(chat._id === action.payload.id){
                    return {
                        ...chat,
                        show: true,
                    }
                }
                return chat
            })
        },
        closeSelectedChatbox: (state, action) => {
            state.chatList = state.chatList.map(chat => {
                if(chat._id === action.payload.id){
                    return {
                        ...chat,
                        show: false,
                    }
                }
                return chat
            })
        },
        showBotChatbox: (state, action) => {
            state.botData = {
                ...state.botData,
                show: true,
            }
        },
        hideBotChatbox: (state, action) => {
            state.botData = {
                ...state.botData,
                show: false,
            }
        },
        optionConfirm: (state, action) => {
            state.botData.showOptions = false
        }
    }
});

export const {
    sendMessage,
    sendMessageToBot,
    removeOptionsInChatbox,
    showSelectedChatbox,
    closeSelectedChatbox,
    showBotChatbox,
    hideBotChatbox,
    optionConfirm
} = chatSlice.actions;

const messageDataStructureFormat = (
    msg = "...",
    sender="Rio",
    isSender=false
) => {
    return {
        chat_id: "00000000000",
        messages: [{
            _id: uuid(),
            name: sender,
            message: msg,
            isSender: isSender
        }],
        subjects: [],
        links: []
    }
}

const youtubeMessageDataStructureFormat = (youtubeSuggestion=[],dialog) => {
    return {
        chat_id: "00000000000",
        messages: youtubeSuggestion.map(suggestion => {
            return {
                _id: uuid(),
                name: "Rio",
                message: "...",
                isSender: false,
                // means that the data structure is not the same as the normal message
                isCustomMessageYT: suggestion ? true : false,
                customMessageYT: suggestion,
                // to be added fields in youtube
                // withSelection: false,
                // isLinkReference: true,
                responseData: dialog.responseData,
                userMessage: dialog.userMessage
            }
        }),
        subjects: [],
        links: []
    }
}

export const actionOptionConfirm = dialog => async (dispatch,getState) => {
    const {chats : {botData} } = getState()

    dispatch(sendMessageToBot(messageDataStructureFormat("yes", "jemuel", true)))

    let youtubeSuggestion = [];
    if(botData.subjectSuggestion){
        youtubeSuggestion = await getYoutubeSuggestion(botData.subjectSuggestion)
    }
    else {
        youtubeSuggestion = await getYoutubeSuggestion(botData.userQuery)
    }
    const youtubeFormattedMsg = youtubeMessageDataStructureFormat(youtubeSuggestion, dialog)

    dispatch(sendMessageToBot(youtubeFormattedMsg))

}

export const actionSendMessage = messageData => (dispatch,getState) => {
    dispatch(sendMessage(messageData))
}

export const actionRemoveOptionsInChatbox = () => dispatch => {
    dispatch(removeOptionsInChatbox())
}



export const actionSendMessageToBot = message => async (dispatch,getState) => {
    // dispatch(sendMessage([messageData]))
    const conversationURL = `${URI}/bigbro`;
    
    dispatch(sendMessageToBot(messageDataStructureFormat(message, "jemuel", true)))

    // the bot will analize the query

    const resData = await analize(conversationURL,message);

    dispatch(sendMessageToBot({
        ...resData,
        userQuery: message
    })) // this is the chatbot response
}

export const actionShowSelectedChatBox = id => dispatch => {
    dispatch(showSelectedChatbox({id}))
}
export const actionCloseSelectedChatBox = id => dispatch => {
    dispatch(closeSelectedChatbox({id}))
}
export const actionShowBotChatBox = id => dispatch => {
    dispatch(showBotChatbox({id}))
}
export const actionCloseBotChatBox = id => dispatch => {
    dispatch(hideBotChatbox({id}))
}

export default chatSlice.reducer;