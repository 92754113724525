const CustomHTMLParser = htmlString => {

    const TextElem = e => ({
        type:
          'TextElem',
        textContent:
          e.textContent
    })
    
    const Elem = e => ({
        type:
            'Elem',
        tagName: 
            e.tagName,
        attributes:
            Array.from(e.attributes, ({name, value}) => [name, value]),
        children:
            Array.from(e.childNodes, fromNode)
    })
    
    const fromNode = e => {
        switch (e?.nodeType) {
            case 1: return Elem(e)
            case 3: return TextElem(e)
            default: throw Error(`unsupported nodeType: ${e.nodeType}`) 
        }
    }

    const stringToHTML = function (str) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(str, 'text/html');
        return doc.body;
    };
    
    const html2json = e => JSON.stringify(Elem(e), null, '  ')
    
    return html2json(stringToHTML(htmlString))

}

export default CustomHTMLParser;