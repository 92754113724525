import React, {useState, useRef} from 'react'
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { emotions } from "../Hooks/Constants";
import { useForm } from "react-hook-form";
import PostInputs from '../PostUpload/PostInputs';
import PostHeader from '../PostCards/PostHeader';
import ImageDropZone from '../ImageDropZone/ImageDropZone';
import './ChangeTimelinePicture.scss';

const ChangeTimelinePicture = ({
	closeModal,
	onTimelinePictureChange
}) => {

	const [image, setImage] = useState("");
	const [selectedEmotion, setSelectedEmotion] = useState(-1)
	
	// let editor;
	const fileInputRef = useRef(null);
	const editorRef = useRef(null);

	const {
		userData
	} = useSelector(state => state.user)

	const {
		setError, 
		control, 
		handleSubmit, 
		reset,
		formState: {errors,isSubmitSuccessful}
	} = useForm({ 
		defaultValues: {
		  postDescription: ""
		}
	});
		
	const handleFileChange = e => {
		const file = e.target.files[0];
		e.target.files[0].crossOrigin = 'anonymous';
		setImage(URL.createObjectURL(file));
	};
		
	const handleDrop = (dropped) => {
		setImage(dropped[0])
	}

	const onSubmit = async data => {
		if (editorRef) {
			const img = editorRef.current;
			img.crossOrigin = 'anonymous';
			const croppedPicture = img.getImage().toDataURL(); // add 'image/jpeg' in the parameter to make it jpg
			const timelinePicture = await fetch(croppedPicture).then(r => r.blob());
			onTimelinePictureChange({
				...data,
				emotion: selectedEmotion
			},timelinePicture)
			closeModal()
		}
	}

	const emotion = selectedEmotion > -1 ? emotions[selectedEmotion-1]._id ? `Is Feeling ${emotions[selectedEmotion-1].name} ${emotions[selectedEmotion-1].emoji}` : null : null;

	return (
		<form
		className='ChangeTimelinePicture'
		encType="multipart/form-data"
		onSubmit={handleSubmit(onSubmit)}>
		{/* <div className="ChangeTimelinePicture"> */}
			<div className="pictureInputContainer">
				<div className="changeTimelineInputWrapper">
					<ImageDropZone
						fileInputRef={fileInputRef}
						handleFileChange={handleFileChange}
						handleDrop={handleDrop}
						editorRef={editorRef}
						editorWidth={1300}
						image={image}
						yRatio={65/150}
					/>
				</div>
				<div className="postDescription">
					<div className="cardHeader">
						<PostHeader feeling={emotion} owner={userData}/>
					</div>
					<PostInputs
						control={control}
						errors={errors}
						// functions
						setSelectedEmotion={setSelectedEmotion}
					/>
				</div>
			</div>
			<div className="informationUpdate">
				<Button
				type="submit"
				variant="contained"
				color="primary"
				// onClick={onSubmit}
				style={{
					width: "100%",
					borderRadius: "8px",
					height: "48px",
					fontSize: "16px",
				}}>Update</Button>
			</div>
		{/* </div> */}
		</form>
	);
}
export default ChangeTimelinePicture;