//import EditNote from '.components/EditNote/EditNote';//cut it and paste to the component
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
    actionUpdateNotes,
    actionToggleChangeNoteTitleForm
} from '../../app/Reducers/note/noteSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

const schema = yup.object().shape({
    noteTitle: yup.string().required(),
});


const EditNote = ({
    note
}) => {

    const dispatch = useDispatch();

    const {
		showChangeNoteTitleForm,
        selectedNote
	} = useSelector(state => state.note)

    // form setup
    const { control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
        defaultValues: {
            noteTitle: selectedNote ? selectedNote.title : ""
        },
        resolver: yupResolver(schema)
    });

    // reset the form after successfull submit of form data
    useEffect(() => {
        if (isSubmitSuccessful) {
            dispatch(actionToggleChangeNoteTitleForm(false))
        };
    }, [isSubmitSuccessful, reset]);

    // on form submit
    const onSubmit = ({noteTitle}) => {
        // updateNote({
        //     ...note,
        //     title: noteTitle
        // })
        dispatch(actionUpdateNotes({
            ...selectedNote,
            title: noteTitle
        }))

        dispatch(actionToggleChangeNoteTitleForm(false))
    }

    if(!selectedNote) return ""

    return <div className='EditNoteContainer'>
        <Modal
            keepMounted
            open={showChangeNoteTitleForm}
            onClose={()=>dispatch(actionToggleChangeNoteTitleForm(false))}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style} spacing={2}>
                <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                style={{marginBottom:10}}>

                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        {`Update Note Title`}
                    </Typography>

                    <CloseIcon 
                    className="close-form-trigger"
                    onClick={()=>dispatch(actionToggleChangeNoteTitleForm(false))}/>

                </Stack>
                <form className="todo-edit-form" onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>

                        <Controller
                        name="noteTitle"
                        control={control}
                        defaultValue={""}
                        render={({field}) => <TextField
                            {...field}
                            className="textArea"
                            label="Note Name"
                            variant="outlined"
                            placeholder={"Note Name"}
                            error={!!errors.noteTitle}
                            type="text"
                            multiline
                            helperText={errors?.noteTitle ? errors?.noteTitle?.message : ''}
                        />
                        }/>

                        <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end">
                            <Button type="submit" variant="outlined">Save</Button>
                            <Button onClick={()=>dispatch(actionToggleChangeNoteTitleForm(false))} type="button" variant="outlined">Cancel</Button>
                        </Stack>

                    </Stack>
                </form>
            </Box>
        </Modal>
    </div>
}

export default EditNote;