//import NotesList from '.components/NotesList/NotesList';//cut it and paste to the component
import React, {useState,useEffect} from 'react';
import './Notes.scss';
import Notes from './Notes';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import CreateNote from "./CreateNote";
import { useSelector, useDispatch } from 'react-redux';
import {
    actionGetUserNotes,
    actionGetUserPlanningNotes,
    actionDeleteNote,
    actionHideNotePopupMessage,
    actionUpdateNotes
} from '../../app/Reducers/note/noteSlice';
import PopupNotifications from '../PopupNotifications/PopupNotifications';
import GoalsNote from '../GoalsNote/GoalsNote';
import ChangeTodoCategoryForm from '../Todo/ChangeTodoCategoryForm';
import MenuItem from '@mui/material/MenuItem';
import RHFSelect from "../ReactHookFormElements/RHFSelect";
import { useForm } from "react-hook-form";
import Select from "@mui/material/Select";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
 import EditNote from './EditNote';
import InputLabel from '@mui/material/InputLabel';

const NotesList = ({
    enableCopyText,
    enableAddTodo,
    enableAddGoal
}) => {
    const {noteList} = useSelector(state => state.note);
    const dispatch = useDispatch();
    const [createNotesOpen, setCreateNotesOpen] = useState(false)
    const [searchNote, setSearchNote] = useState('')
    const { notesFor } = useSelector(state => state.note);
    const [selectedCategory, setSelectedCategory ] = useState("")

    const {
        popupType,
        popupMessage,
        popupOpen,
		selectedNote,
		openChangeTodoCategoryForm
    } = useSelector(state => state.note);
    const {
        todoCategoryList
    } = useSelector(state => state.todo);

    const updateNote = updatedNote => {
        dispatch(actionUpdateNotes(updatedNote))
    }

    const deleteNote = noteId => {
        dispatch(actionDeleteNote(noteId))
    }

    const handlePopupClose = () => {
        dispatch(actionHideNotePopupMessage());
    }

    const {
		control,
		handleSubmit,
		reset,
		formState: {errors,isSubmitSuccessful} } = useForm({ 
		// resolver: yupResolver(schema)
	});


    useEffect(() => {
        dispatch(notesFor === "Planning" ? actionGetUserPlanningNotes() : actionGetUserNotes());
    }, [])

    // const NotesComponent = useMemo(note => {
    //     return <Notes
    //         key={note._id}
    //         note={note}
    //         updateNote={updateNote}
    //         deleteNote={deleteNote}
    //         enableCopyText={enableCopyText}
    //         enableAddTodo={enableAddTodo}
    //         enableAddGoal={enableAddGoal}
    //     />
    // },[note])
    const onSelectCategory = e => {
        // console.log(e.target.value)
        setSelectedCategory(e.target.value)
    }
    
    return <div className='NotesListContainer'>
        <Stack direction="row" alignItems="center" spacing={2}>
            {noteList.length ? 
                <TextField
                    size="small"
                    id="outlined-name"
                    label="Search Notes"
                    value={searchNote}
                    sx={{flex:1}}
                    onChange={e=>{
                        console.log(e.target.value);
                        setSearchNote(e.target.value)
                    }}
                /> : ""
            }
            <Button
            variant="contained"
            endIcon={<AddIcon />}
            sx={{
                width: 250
            }}
            onClick={()=>{
                // notesFor === "Planning" ?
                // setCreateNotesOpen(true) : // show planning note
                setCreateNotesOpen(true) // show regular scribble note
            }}>{notesFor === "Planning" ? "New Planning Note" : "New Note"}</Button>
        </Stack>
        
        {todoCategoryList.length ?
            <FormControl
            fullWidth
            size='small'
            sx={{marginTop: "10px",marginBottom: "10px"}}>
                <InputLabel
                id="category-filter-label">Select Category</InputLabel>
                <Select 
                label="Filter By Category"
                onChange={onSelectCategory}
                sx={{width: "100%"}}
                value={selectedCategory}
                placeholder='Filter By Category'
                labelId="category-filter-label"
                >
                    <MenuItem value={""}>Select Category</MenuItem>
                    <MenuItem value={"0000000000000000000"}>Unassigned</MenuItem>
                    {todoCategoryList.length ? todoCategoryList.map(
                        noteCategory => (
                            <MenuItem key={noteCategory._id} value={noteCategory._id}>
                                {noteCategory.name}
                            </MenuItem>
                        )
                    ) : ""}
                </Select>
            </FormControl>
        :
        <p style={{
            textAlign: "center",
            padding: "20px"
        }}>No Category List Available</p>}

        {/* <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            // value={age}
            // onChange={handleChange}
            label="Age"
            sx={{width: "100%", marginTop: "10px"}}
        >
            <MenuItem value=""><em>None</em></MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
        </Select> */}

        <div className="note-list-wrapper">
            {/* <GoalsNote/> */}
            {noteList
            .filter(note => (note.title+"")
                            .toLowerCase()
                            .indexOf(searchNote.toLowerCase()) > -1)
            .filter(note => {
                if(selectedCategory.length){
                    if(selectedCategory == "0000000000000000000"){
                        return !note.category
                    }
                    else{
                        return note.category == selectedCategory
                    }
                }
                return true
                // note.category === 
            })
                            .map(note=>(
                                // {NotesComponent(note)}
                                <Notes
                                key={note._id}
                                note={note}
                                updateNote={updateNote}
                                deleteNote={deleteNote}
                                enableCopyText={enableCopyText}
                                enableAddTodo={enableAddTodo}
                                enableAddGoal={enableAddGoal}
                                />
                            )
                )
            }
        </div>
        
        <CreateNote
            createNotesOpen={createNotesOpen}
            setCreateNotesOpen={setCreateNotesOpen}
        />

        <EditNote />

        <ChangeTodoCategoryForm/>

        <PopupNotifications
        open={popupOpen} 
        handleClose={handlePopupClose}
        severity={popupType}>
            {popupMessage}
        </PopupNotifications>
    </div>
}

export default NotesList;