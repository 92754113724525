//import PostHeader from '.components/PostHeader/PostHeader';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import moment from 'moment';
import { formatDateInfo } from '../Hooks/Constants';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
	actionFollowUser,
  actionUnfollowUser
} from '../../app/Reducers/user/userSlice';
import Tooltip from '@mui/material/Tooltip';

const PostHeader = ({
  feeling,
  post,
  postForTodo=null,
  todo=null
}) => {

  const {
		userData
	} = useSelector(state => state.user);
	const dispatch = useDispatch();

  const isAlreadyFollowing = userId => {
    const followedUsers = userData?.following ? userData.following : []

    return followedUsers.indexOf(userId) > -1;
  }

  return post?.createdBy ? <>
    <div className={`userPostImage ${post.createdBy?.profilePicture ? "" : "noPicture"}`}>
      <NavLink to={`/profile?user=${post.createdBy._id}`}>
        <img src={post.createdBy.profilePicture ? post.createdBy.profilePicture : '/profiletemp.jpg'} loading="lazy"/>
      </NavLink>
    </div>
    <div className="nameAndDateContainer">
      
        <p className="name">
          <NavLink to={`/profile?user=${post.createdBy._id}`} className='semiBold'>{post.createdBy.firstName} {post.createdBy.lastName}</NavLink>
          {postForTodo ? <>&nbsp;to add post in <span className="semiBold"> {postForTodo.name}</span></> : ""}
          {todo ? <>&nbsp;added post in task <span className="semiBold"> {todo.name}</span></> : ""}
          {post?.group?.name ? <>&nbsp;posted in <NavLink className="semiBold" to={`/group/${post.group._id}`}> {post.group.name}</NavLink></> : ""}
        {feeling ? <span className="post-feeling">- {feeling}</span> : ""}
        </p>
      <div className="postOtherInfo">
        <p className="date">{formatDateInfo(post.createdAt)}</p>
        {userData._id == post.createdBy._id ? "" :
          isAlreadyFollowing(post.createdBy._id) ? <Tooltip title={`Unfollow ${post.createdBy.firstName}?`}>
          <p
          className='followText following'
          onClick={()=>dispatch(actionUnfollowUser(post.createdBy._id))}>Following</p>
          </Tooltip> :
          <p
          className='followText'
          onClick={()=>dispatch(actionFollowUser(post.createdBy._id))}>Follow</p>
        }
      </div>
    </div>
  </> : ""
}

export default PostHeader;