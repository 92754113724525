import React, { useEffect } from "react";
import ReactDom from "react-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
	actionToggleUpdateClassroomFormOpen,
	actionUpdateClassroom,
} from "../../app/Reducers/classroom/classroomSlice";
import RHFSelect from "../ReactHookFormElements/RHFSelect";
import MenuItem from '@mui/material/MenuItem';

const style = {
	position: "absolute",
	top: "40px",
	left: "50%",
	transform: "translate(-50%, 0)",
	width: "calc(100% - 80px)",
	maxWidth: "700px",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 2,
	height: "calc(100vh - 80px)",
	overflow: "auto"
};

const schema = yup.object().shape({
	name: yup.string().required("Provide valid name"),
	description: yup.string(),
	classLink: yup.string(),
	role: yup.number(),
});
const roles = [ {_id: 1,name: "Coach"}, {_id: 2,name: "Teacher"} ]

const UpdateClassroomForm = ({}) => {
	const dispatch = useDispatch();
	const {
    updateClassroomFormOpen,
		selectedClassroom,
  } = useSelector((state) => state.classroom);

	// form setup
	const {
		control,
		handleSubmit,
		reset,
    setValue,
		formState: { errors, isSubmitSuccessful },
	} = useForm({
		defaultValues: {
			name: "",
			description: "",
			classroomImage: "",
			classroomTimelineImage: "",
			classLink: "",
			schedule: "",
			units: "",
			announcements: "",
			teacher: "",
			coach: "",
			admin: "",
			members: "",
		},
		resolver: yupResolver(schema),
	});

	const onClose = () => {
		dispatch(actionToggleUpdateClassroomFormOpen(false));
		reset({
			name: "",
			description: "",
			classroomImage: "",
			classroomTimelineImage: "",
			classLink: "",
			schedule: "",
			units: "",
			announcements: "",
			teacher: "",
			coach: "",
			admin: "",
			members: "",
		});
	};

	const onSubmit = ({
		name,
		description,
		classLink,
		role
	}) => {
		console.log("dumaan here sa submit")
		dispatch(
			actionUpdateClassroom(selectedClassroom._id,{
				name,
				description,
				classLink,
				role
			})
		);
	};

  useEffect(()=>{
    if(selectedClassroom){
      setValue("name",selectedClassroom?.name)
      setValue("description",selectedClassroom?.description)
      setValue("classLink",selectedClassroom?.classLink)
      setValue("role",selectedClassroom?.role)
    }
  },[selectedClassroom])

	return ReactDom.createPortal(
		<>
			<div className="ClassroomFormContainer">
				<Modal
					keepMounted
					open={updateClassroomFormOpen}
					onClose={() => onClose()}
					aria-labelledby="modal-Classroom"
					aria-describedby="keep-mounted-modal-description"
				>
					<Box sx={style} spacing={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="flex-start"
							spacing={2}
							style={{ marginBottom: 10 }}
						>
							<Typography id="modal-Classroom" variant="h6" component="h2">
								Update classroom
							</Typography>

							<CloseIcon
								className="close-form-trigger"
								onClick={() => onClose()}
							/>
						</Stack>
						<form className="form-container" onSubmit={handleSubmit(onSubmit)}>
							<Stack direction="column" alignItems="center" spacing={2}>
								<RHFTextField
									control={control}
									name="name"
									label="name"
									placeholder="name"
									error={!!errors.name}
									helperText={errors?.name ? errors?.name?.message : ""}
									type="text"
								/>

								<RHFTextField
									control={control}
									name="description"
									label="description"
									placeholder="description"
									error={!!errors.description}
									helperText={
										errors?.description ? errors?.description?.message : ""
									}
									type="text"
								/>

								<RHFTextField
									control={control}
									name="classLink"
									label="classLink"
									placeholder="classLink"
									error={!!errors.classLink}
									helperText={
										errors?.classLink ? errors?.classLink?.message : ""
									}
									type="text"
								/>

								{roles.length ?
								<RHFSelect
								id="role-selection"
								name="role"
								className={"todo-type-selection"}
								label="Your Role"
								control={control}
								defaultValue={1}
								variant="outlined"
								margin="normal"
								size="medium"
								style={{
									flexGrow: 1,
									width: "100%"
								}}>
									{roles.map(
										role=>(
										<MenuItem key={role._id} value={role._id}>{role.name}</MenuItem>)
									)}
								</RHFSelect> : ""}

								<Stack spacing={1} direction="row" justifyContent="center">
									<Button type="submit" variant="contained">
										Update
									</Button>
									<Button
										component={Link}
										to="/"
										type="button"
										variant="contained"
									>
										Cancel
									</Button>
								</Stack>
							</Stack>
						</form>
					</Box>
				</Modal>
			</div>
		</>,
		document.getElementById("update-classroom-form-wrapper-portal")
	);
};

export default UpdateClassroomForm;
// Put this on the home page: <div id="create-classroom-form-wrapper-portal"></div>