//import TodoSideNav from '.components/TodoSideNav/TodoSideNav';//cut it and paste to the component
import React, {useState} from 'react';
import ReactDom from 'react-dom';
// import './TodoSideNav.scss';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { actionOpenPostSideNav } from '../../app/Reducers/post/postSlice';
import PostCards from '../PostCards/PostCards';

const PostSideNav = ({
    fixed=false
}) => {
    const [expand, setExpand] = useState(false);
    const dispatch = useDispatch();
    
    const {
        postInSideNav,
        isSideNavOpen,
        isPostDetailsLoading,
    } = useSelector(state => state.post);

    const {
        minimizeMenu
    } = useSelector(state => state.layout);

    const {
        userData
    } = useSelector(state => state.user);

    return ReactDom.createPortal(<>
    <div className={`SideNavContainer ${minimizeMenu ? "minimizedMenu" : ""} ${fixed ? "fixed" : ""} ${isSideNavOpen ? "open":""} ${expand ? "expand":""}`}>
       <div className="side-nav-wrapper">
            <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            style={{
                padding: "0 2px 19px",
                // borderBottom: "1px solid #E7E7E7"
            }}>
                <h2 className="section-title-01">Post Details</h2>
                
                <CloseIcon 
                className="close-form-trigger"
                onClick={()=>dispatch(actionOpenPostSideNav(false))}/>
            </Stack>
            { postInSideNav && <PostCards postData={postInSideNav}/> }
        </div>
    </div>
    
    </>,document.getElementById("post-side-navigation-wrapper-portal"))
}

export default PostSideNav;