//import Planning from '.components/Planning/Planning';//cut it and paste to the component
import React, {useState,useEffect,useRef,useCallback} from 'react';
import './Planning.scss';
import Calendar from "@toast-ui/react-calendar";
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NotesAside from '../../components/Notes/NotesAside';
import TaskEstimatesPlan from '../../components/TaskEstimatesPlan/TaskEstimatesPlan';
import PlanListSelection from '../../components/PlanListSelection/PlanListSelection';
import MemberSelection from '../../components/MemberSelection/MemberSelection';
import PlanList from '../../components/PlanList/PlanList';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import ListIcon from '@mui/icons-material/List';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionSetNotesFor
} from '../../app/Reducers/note/noteSlice';
import {
  actionSetSelectedUserData
} from '../../app/Reducers/user/userSlice';
import {
  actionRecordPlan,
  actionSetSelectedCalendarWeekDate,
  actionSetPlanningFormOpen,
  actionGetWeekPlan,
  actionSetPlanTaskList,
  actionGetSelectedPlanning,
  actionDeleteSchedule,
  actionSaveCalendarChanges
} from '../../app/Reducers/planning/planningSlice';


// const start = new Date();
// const end = new Date(new Date().setMinutes(start.getMinutes() + 30));
const schedules = [
  // {
  //   calendarId: "1",
  //   category: "time",
  //   isVisible: true,
  //   title: "Study",
  //   id: "1",
  //   body: "Test",
  //   start,
  //   end
  // },
  // {
  //   calendarId: "2",
  //   category: "time",
  //   isVisible: true,
  //   title: "Meeting",
  //   id: "2",
  //   body: "Description",
  //   start: new Date(new Date().setHours(start.getHours() + 1)),
  //   end: new Date(new Date().setHours(start.getHours() + 2))
  // }
];

const calendars = [
  {
    id: "1",
    name: "My Calendar",
    color: "#ffffff",
    bgColor: "#9e5fff",
    dragBgColor: "#9e5fff",
    borderColor: "#9e5fff"
  },
  {
    id: "2",
    name: "Company",
    color: "#ffffff",
    bgColor: "#00a9ff",
    dragBgColor: "#00a9ff",
    borderColor: "#00a9ff"
  }
];

const Planning = ({

}) => {
    const cal = useRef(null);
    const {
      selectedCalendarWeekDate,
      plannedSchedule,
      planTaskList
    } = useSelector(state => state.planning);
    const {selectedUserData} = useSelector(state => state.user);

    const [showNotes, setShowNotes] = useState(false)
    const [calendarSchedule, setCalendarSchedule] = useState(schedules)
    const [isCalendarView, setCalendarView] = useState(true)
    const dispatch = useDispatch();
    // const [planTaskList, setplanTaskList] = useState([]);

    const onSelectedDateChange = (newCalendarValue) => {

      console.log("testing")
      const calendar = cal.current.calendarInst;
      calendar.setDate(newCalendarValue)
      dispatch(actionSetSelectedCalendarWeekDate(newCalendarValue.toDateString()))
      setCalendarSchedule([])
      // setCalendarValue(newCalendarValue);
    }
    
    const onClickSchedule = useCallback((e) => {
      const { calendarId, id } = e.schedule;
      const el = cal.current.calendarInst.getElement(id, calendarId);
  
      console.log(e, el.getBoundingClientRect());
    }, []);
  
    const onBeforeCreateSchedule = useCallback((scheduleData) => {
      const schedule = {
        id: String(Math.random()),
        title: scheduleData.title,
        isAllDay: scheduleData.isAllDay,
        start: scheduleData.start,
        end: scheduleData.end,
        category: scheduleData.isAllDay ? "allday" : "time",
        dueDateClass: "",
        location: scheduleData.location,
        // raw: {
        //   class: scheduleData.raw["class"]
        // },
        state: scheduleData.state
      };

      console.log(schedule)
  
      cal.current.calendarInst.createSchedules([schedule]);
    }, []);
  
    const onBeforeDeleteSchedule = useCallback((e) => {

      const { id, tempTaskId, calendarId, start, end, body, planId } = e.schedule;
  
      dispatch(
        actionDeleteSchedule({
          tempTaskId,
          taskId: body,
          timeToDeduct: (end._date - start._date)/1000/3600,
          planId,
          id
        })
      )
      // this function delete the UI in the frontend
      cal.current.calendarInst.deleteSchedule(id, calendarId);

    }, []);
  
    // on calendar view update
    const onBeforeUpdateSchedule = useCallback((e) => {
      
      const { schedule, changes } = e;

      cal.current.calendarInst.updateSchedule(
        schedule.id,
        schedule.calendarId,
        changes
      );

      const calObjectItems = cal.current.calendarInst._controller.schedules.items;
      let updatedTime = 0;
      
      for (const [key, value] of Object.entries(calObjectItems)) {
        if(value.body === schedule.body){
          updatedTime += (value.end.getTime() - value.start.getTime()) / 1000 / 60 / 60;
        }
      }


      console.log(planTaskList)
      // console.log(
      //   planTaskList => [...planTaskList].map(noteData => {
      //     return  noteData.id === schedule.body ? {
      //               ...noteData,
      //               estimate: updatedTime
      //             } : noteData
      //   })
      // )

      dispatch(
        actionSetPlanTaskList(
          [...planTaskList].map(noteData => {
          return  noteData.id === schedule.body ? {
                    ...noteData,
                    estimate: updatedTime
                  } : noteData
          })
        )
      )
      
    }, [planTaskList]);

    const _getFormattedTime = (time) => {
      const date = new Date(time);
      const h = date.getHours();
      const m = date.getMinutes();
  
      return `${h}:${m}`;
    }
  
    const _getTimeTemplate = (schedule, isAllDay) => {
      var html = [];
  
      if (!isAllDay) {
        html.push("<strong>" + _getFormattedTime(schedule.start) + "</strong> ");
      }
      if (schedule.isPrivate) {
        html.push('<span class="calendar-font-icon ic-lock-b"></span>');
        html.push(" Private");
      } else {
        if (schedule.isReadOnly) {
          html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
        } else if (schedule.recurrenceRule) {
          html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
        } else if (schedule.attendees.length) {
          html.push('<span class="calendar-font-icon ic-user-b"></span>');
        } else if (schedule.location) {
          html.push('<span class="calendar-font-icon ic-location-b"></span>');
        }
        html.push(" " + schedule.title);
      }
  
      return html.join("");
    }
  
    const templates = {
      time: function (schedule) {
        return _getTimeTemplate(schedule, false);
      }
    };

    const savePlan = (selectedDateRange) => {
      if(selectedUserData){
        // See the calendar instance
        const calendarPlanData = Object.entries(cal.current.calendarInst._controller.schedules.items).map(([key,value]) => value);
        // pass the following to the API
        const filteredPlanData = calendarPlanData.map(cpd => {
          const { bgColor, body, borderColor, calendarId, category, color, dragBgColor, end, id, start, title } = cpd;
          return {
            bgColor, body, borderColor, calendarId, category, color, dragBgColor, end, id, start, title
          }
        })
        // remove unnessesary data here
        console.log(calendarPlanData)
        dispatch(actionRecordPlan(
          selectedUserData,
          planTaskList,
          filteredPlanData, // JSON.stringify(calendarPlanData),
          selectedDateRange
        ))
      }
      else {
        alert("Select a member to plan")
      }
      
    }

    const next = useCallback(() => {
      const calendar = cal.current.calendarInst;
      calendar.next()
      const d = new Date(calendar._renderDate._date)
      dispatch(actionSetSelectedCalendarWeekDate(d.toDateString()))
    },[])
  
    const prev = useCallback(() => {
      const calendar = cal.current.calendarInst;
      calendar.prev()
      const d = new Date(calendar._renderDate._date)
      dispatch(actionSetSelectedCalendarWeekDate(d.toDateString()))
    },[])

    const today = useCallback(() => {
      const calendar = cal.current.calendarInst;
      calendar.today()
      const d = new Date(calendar._renderDate._date)
      dispatch(actionSetSelectedCalendarWeekDate(d.toDateString()))
    },[])

    const onSelectUser = useCallback(e => {
      setCalendarSchedule([])
      dispatch(actionSetSelectedUserData(e.target.value))
    },[])

    const onSelectPlan = useCallback(selectedPlan => {
      const calendar = cal.current.calendarInst;
      calendar.setDate(new Date(selectedPlan.start))
      console.log("testttttt")
      dispatch(actionGetSelectedPlanning(selectedPlan))
    },[])

    useEffect(()=>{ dispatch(actionSetNotesFor("Planning")) },[])

    useEffect(()=>{},[selectedCalendarWeekDate,plannedSchedule])

    useEffect(() => {
      today()
    }, [])
  
    return (
      <div className="PlanningContainer">
        <div className="header-container">
          <h1 onClick={() => {
            dispatch(actionGetWeekPlan())
          }}>Planning</h1>
          <div className="dropdown-actions">

            <MemberSelection onSelectUser={onSelectUser} />
            <PlanListSelection onSelectPlan={onSelectPlan}/>
          </div>
          <div className="actions-wrapper">
            
              <Button 
              onClick={()=>setShowNotes(show=>!show)}
              aria-label="note"
              variant="contained" startIcon={<NoteAltIcon fontSize="inherit" />}>
                Planning Notes
              </Button>

              <Button 
              onClick={()=>setCalendarView(view=>!view)}
              aria-label="plan list"
              variant="contained" startIcon={<ListIcon fontSize="inherit" />}>
                Plan list
              </Button>

              <Button 
              onClick={()=>dispatch(actionSetPlanningFormOpen(true))}
              aria-label="create note"
              className="hideOnDesktop"
              variant="contained" startIcon={<CreateIcon fontSize="inherit" />}>
                Create
              </Button>
            
          </div>
        </div>
        
        
        <div className="planning-wrapper">
        {isCalendarView ?
        <>
          <div className="calendar-layout">
            <div className="calendar-actions-wrapper">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Select Date"
                    value={new Date(selectedCalendarWeekDate)}
                    onChange={onSelectedDateChange}
                    renderInput={
                      (params) => <TextField
                                  size="small"
                                  {...params}/>
                    }
                />
              </LocalizationProvider>
              <Button onClick={()=>dispatch(actionSaveCalendarChanges())} variant="outlined">Save Changes</Button>
              <div className="move-calendar-date-actions-wrapper">
                <Button onClick={()=>prev()} variant="outlined">Prev</Button>
                <Button onClick={()=>today()} variant="outlined">Today</Button>
                <Button onClick={()=>next()} variant="outlined">Next</Button>
              </div>
            </div>
            <Calendar
              ref={cal}
              height="1000px"
              view="week"
              useCreationPopup={true}
              useDetailPopup={true}
              template={templates}
              calendars={calendars}
              // schedules={}
              schedules={[
                ...plannedSchedule.map(sched => {
                  return {
                    ...sched,
                    start: new Date(sched.start._date),
                    end: new Date(sched.end._date)
                  }
                }),
                ...calendarSchedule.map(sched => {
                  return {
                    ...sched
                  }
                })
              ]}
              taskView={false}  // e.g. true, false, or ['task', 'milestone']
              scheduleView={['time']} // e.g. true, false, or ['allday', 'time']
              onClickSchedule={onClickSchedule}
              onBeforeCreateSchedule={onBeforeCreateSchedule}
              onBeforeDeleteSchedule={onBeforeDeleteSchedule}
              onBeforeUpdateSchedule={onBeforeUpdateSchedule}
            />
          </div>
          <TaskEstimatesPlan
            onSelectUser = {onSelectUser}
            onSelectedDateChange = {onSelectedDateChange}
            setCalendarSchedule = {setCalendarSchedule}
            planTaskList = {planTaskList}
            savePlan = {savePlan}
          />
        </>
        :
        <PlanList/>
        }
        </div>

        <NotesAside
        showNotes={showNotes}
        setShowNotes={setShowNotes}
        fixed={true}
        enableCopyText={true}/>
      </div>
    );
}

export default Planning;