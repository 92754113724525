import React, { useCallback, useEffect, useReducer } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from 'react-redux';
import MomentSet from '../../../components/Moments/MomentSet';
import { TodoStatus } from '../../../components/Hooks/Constants';
import { getHeaders } from '../../../app/helpers';
import Axios from 'axios';
import Todo from '../../../components/Todo/Todo';
import { useSearchParams } from 'react-router-dom';

import "./TaskMomentsTable.scss"

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const joinArraysOfSelectedUserWithoutDuplicate = (
  oldArray,
  incommingArray,
  selectedUserId
) => {
  const combinedObject = {};

  // Iterate through the first array and add objects to the combined object
  oldArray.forEach((item) => {
    if(item.assignedTo == selectedUserId){
      const id = item._id; // Change this to the unique property of your objects
      combinedObject[id] = item;
    }
  });

  // Iterate through the second array and add objects to the combined object (checking for duplicates)
  incommingArray.forEach((item) => {
    if(item.assignedTo == selectedUserId){
      const id = item._id; // Change this to the unique property of your objects
      if (!combinedObject[id]) {
        combinedObject[id] = item;
      }
    }
  });

  // Convert the combined object back into an array
  const combinedArray = Object.values(combinedObject);

  return combinedArray;
}

const initialState = {
  todos: [],
  hasNextPage: false,
  limit: 10,
  nextPage: null,
  page: 0,
  pagingCounter: 1,
  prevPage: null,
  totalDocs: 0,
  totalPages: 1,
}

const reducer = (state, action) => {
  const { 
    type,
    payload
  } = action;

  switch (type){
    case "fetchMoreTodos" : {
      const {
        userId,
        docs,
        hasNextPage,
        limit,
        nextPage,
        page,
        pagingCounter,
        prevPage,
        totalDocs,
        totalPages,
      } = payload
      const todos = joinArraysOfSelectedUserWithoutDuplicate(
        [...state.todos],
        [...docs],
        userId
      )
      return {
        ...state,
        todos,
        hasNextPage,
        limit,
        nextPage,
        page,
        pagingCounter,
        prevPage,
        totalDocs,
        totalPages,
      };
    }
    default:
      return state;
  }
}

const TaskMomentsTable = ({
  userId
}) => {

  const {
    selectedUserData
  } = useSelector(state => state.user);
  const {
    taskAndPostDashboardGridView
  } = useSelector(state => state.layout)
  
  const [params, setParams] = useSearchParams({ user: "" })
  const [state, dispatch] = useReducer(reducer, initialState)

  const getDoneTodos = async page => {
    const headers = getHeaders();
    if(headers === null) return
    
    // const userId = params.get("user")
    const id = userId// ? userId : selectedUserData._id
    const limit = 10;
		let query = `&todostatusfilter=${[TodoStatus.done]}`

		query+=`&page=${page}&limit=${limit}`
    query = query.substring(1);
		
    const res = await Axios.get(
      `${URI}/todos/user-todos-with-moments/${id}?${query}`,
      headers
    )
    dispatch({
      type: "fetchMoreTodos",
      payload: {
        ...res.data,
        userId
      }
    })
    
  }

  useEffect(()=>{
		getDoneTodos(1)
	},[selectedUserData])

	return <div className='TaskMomentsTableContainer'>
		
    {
    taskAndPostDashboardGridView ? 
    <>{
      state.todos.length ?
      <div className="gridItemLayout">
        {state.todos.map(task => {
          return (
            <Todo key={task._id} todo={task} showDate={true}/>
          )
        })}
      </div> : <p align="left">No tasks and moments data to show.</p>
    }</>
    :
    <TableContainer>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead className='tableHeadContainer'>
          <TableRow>
            <TableCell align="left" style={{fontWeight: "bold", width: "250px"}}>Task</TableCell>
            <TableCell align="left" style={{fontWeight: "bold"}}>Objectives</TableCell>
            <TableCell align="left" style={{fontWeight: "bold"}}>Results</TableCell>
            <TableCell align="left" style={{fontWeight: "bold"}}>Questions</TableCell>
            <TableCell align="left" style={{fontWeight: "bold"}}>Concepts</TableCell>
            <TableCell align="left" style={{fontWeight: "bold"}}>Insights</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            state.todos.length ?
              <>
                {state.todos.map(task => {
                  return (
                    <TableRow
                      key={task._id}
                      sx={{
                        verticalAlign:"top",
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                    >
                      <TableCell align="left" >
                        <Todo todo={task} showDate={true}/>
                      </TableCell>

                      <TableCell
                      align="left"
                      className={`${
                        task
                        .moments
                        .filter(moment => moment.momentType == 10 ).length ? "" : "noData"
                      }`}>
                        <MomentSet
                          title={"Objectives"}
                          moments={task.moments}
                          filterFunction={moment => moment.momentType == 10}
                        />
                      </TableCell>

                      <TableCell
                      align="left"
                      className={`${
                        task
                        .moments
                        .filter(moment => moment.momentType == 8 || moment.momentType == 9 ).length ? "" : "noData"
                      }`}>
                        <MomentSet
                          title={"Results"}
                          moments={task.moments}
                          filterFunction={moment => moment.momentType == 8 || moment.momentType == 9 }
                        />
                      </TableCell>

                      <TableCell
                      align="left"
                      className={`${
                        task
                        .moments
                        .filter(moment => moment.momentType == 3 || moment.momentType == 11 ).length ? "" : "noData"
                      }`}>
                        <MomentSet
                          title={"Questions"}
                          moments={task.moments}
                          filterFunction={moment => moment.momentType == 3 || moment.momentType == 11 }
                        />
                      </TableCell>

                      <TableCell
                      align="left"
                      className={`${
                        task
                        .moments
                        .filter(moment => moment.momentType == 6 || moment.momentType == 7 ).length ? "" : "noData"
                      }`}>
                        <MomentSet
                          title={"Concepts"}
                          moments={task.moments}
                          filterFunction={moment => moment.momentType == 6 || moment.momentType == 7 }
                        />
                      </TableCell>

                      <TableCell
                      align="left"
                      className={`${
                        task
                        .moments
                        .filter(moment => moment.momentType == 2 || moment.momentType == 5 ).length ? "" : "noData"
                      }`}>
                        <MomentSet
                          title={"Insights"}
                          moments={task.moments}
                          filterFunction={moment => moment.momentType == 2 || moment.momentType == 5 }
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
              :
              <TableRow>
                <TableCell align="left">No tasks and moments data to show.</TableCell>
              </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
    }
    {
      state.todos.length && state.hasNextPage ? 
      <p
      className='load-more-comments'
      onClick={()=>{
        getDoneTodos(state.nextPage)
      }}>Load more...</p> : ""
    }
	</div>
}

export default TaskMomentsTable;

