import React from 'react';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {
  actionRequestForgotPassword
} from '../../app/Reducers/user/userSlice';
import Stack from '@mui/material/Stack';
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { Link } from "react-router-dom";

const schema = yup.object().shape({
  email: yup.string().required("Provide valid email").email(),
});

const EmailForm = ({
  setEmail
}) => {

    const dispatch = useDispatch()

    // form setup
    const { control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
        defaultValues: {
          email: ""
        },
        resolver: yupResolver(schema)
    });

    const onSubmit = ({email}) => {
      dispatch(actionRequestForgotPassword(email))
      setEmail(email)
    }

    return <div className='EmailFormContainer form-wrapper'>
      <form className="moment-create-form form-container" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="title">Forgot Password</h1>
        <Stack 
        direction="column"
        alignItems="center"
        spacing={2}>
          <RHFTextField
              control={control}
              name="email"
              label="Email"
              placeholder="Email"
              error={!!errors.email}
              helperText={errors?.email ? errors?.email?.message : ''}
              type="email"
          />
          <Stack
          spacing={1}
          direction="row"
          justifyContent="center">
            <Button type="submit" variant="contained">Change</Button>
            <Button
            component={Link}
            to="/"
            type="button"
            variant="contained">Cancel</Button>
          </Stack>
        </Stack>
      </form>
    </div>
}

export default EmailForm;