import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import { Provider } from 'react-redux';
import { store } from './app/store';
// import {Msal2ProviderConfig} from './authConfig'
import { createTheme, ThemeProvider } from '@mui/material/styles';

// this is the code
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from "./authConfig";
// import reportWebVitals from './reportWebVitals';

// import {Providers} from '@microsoft/mgt-element'
// import {Msal2Provider} from '@microsoft/mgt-msal2-provider'

import { BrowserRouter } from "react-router-dom";

import './App.css';

// const msalInstance = new PublicClientApplication(msalConfig);
// Providers.globalProvider = new Msal2Provider(Msal2ProviderConfig)

const theme = createTheme({
  palette: {
    primary: {
      main: '#00AB55'
    },
    secondary: {
      main: '#E33E7F'
    },
    discourage:{
      main: '#C4CDD5'
    },
    white:{
      main: '#fff'
    }
  },
  typography: {
    fontFamily: [
      '"Public Sans", sans-serif'
    ].join(','),
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#00AB55',
        },
      },
    },
  },

});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        {/* <MsalProvider instance={msalInstance}> */}
          <BrowserRouter>
            <App />
          </BrowserRouter>
        {/* </MsalProvider> */}
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// npm i @reduxjs/toolkit react-dom --save --force
