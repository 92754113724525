import React, {useEffect} from 'react';
import EmergentIndex from '../../components/EmergentIndex/EmergentIndex';
import ProductivityAndEventContent from './ProductivityAndEventContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const EmergentIndexData = ({

}) => {

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return <div className="emergentIndexDataContainer">
    <div className="tabStyle eITabWrapper">
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Emergent Index" />
        <Tab label="Weekly productivity" />
      </Tabs>
    </div>
    <div className="emergentIndexDataWrapper">
      <EmergentIndex defaultOpen={true}/>
      <ProductivityAndEventContent tabIndex={tabIndex}/>
    </div>
  </div>
}

export default EmergentIndexData;