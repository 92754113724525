
import React, { useEffect } from "react";
import ReactDom from "react-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
// import {
//   actionTogglecreateRoleFormOpen,
//   actionCreateRole,
// } from "../../app/Reducers/role/roleSlice";
import {
  actionToggleCreateRoleForm,
  actionCreateRole,
  actionGetRoleList
} from "../../app/Reducers/user/userSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const schema = yup.object().shape({
  name: yup.string().required("Provide valid name"),
  access: yup.string().required("Provide valid access"),
});

const CreateRoleForm = ({}) => {
  const dispatch = useDispatch();
  const {
    createRoleFormOpen
  } = useSelector((state) => state.user);

  // form setup
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      name: "",
      access: "",
    },
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    dispatch(actionToggleCreateRoleForm(false));
    reset({
      name: "",
      access: "",
    });
  };

  const onSubmit = ({ name, access }) => {
    dispatch(actionCreateRole({name, access}));
  };

  return ReactDom.createPortal(
    <>
      <div className="RoleFormContainer">
        <Modal
          keepMounted
          open={createRoleFormOpen}
          onClose={() => onClose()}
          aria-labelledby="modal-Role"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              style={{ marginBottom: 10 }}
            >
              <Typography id="modal-Role" variant="h6" component="h2">
                Create Role
              </Typography>

              <CloseIcon
                className="close-form-trigger"
                onClick={() => onClose()}
              />
            </Stack>
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" alignItems="center" spacing={2}>
                <RHFTextField
                  control={control}
                  name="name"
                  label="name"
                  placeholder="name"
                  error={!!errors.name}
                  helperText={errors?.name ? errors?.name?.message : ""}
                  type="text"
                />

                <RHFTextField
                  control={control}
                  name="access"
                  label="access"
                  placeholder="access"
                  error={!!errors.access}
                  helperText={errors?.access ? errors?.access?.message : ""}
                  type="text"
                />

                <Stack spacing={1} direction="row" justifyContent="center">
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                  <Button
                    component={Link}
                    to="/"
                    type="button"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Modal>
      </div>
    </>,
    document.getElementById("create-role-form-wrapper-portal")
  );
};

export default CreateRoleForm;
// Put this on the home page: <div id="create-role-form-wrapper-portal"></div>
