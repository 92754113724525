import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';

const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;

const initialState = {
  isLoading: false,
  showCreatedGroupList: false,
  createGroupFormOpen: false,
  groupJoinRequestListOpen: false,
  updateGroupFormOpen: false,
  groupMembersListOpen: false,
  showFindGroupSection: false,
  
  selectedGroup: null,

  groupList: [],
  createdGroupList: [],
	groupMomentsData: [],
  groupJoinRequests: [],
  groupMembers: [],
  groupsSearchResults: [],

  // list of group list pagination
  hasNextPage: false,
  hasPrevPage: false,
  limit: 10,
  nextPage: null,
  page: 1,
  pagingCounter: 1,
  prevPage: null,
  totalDocs: 0,
  totalPages: 1,

  // list of searched groups
  searchedGroupList: [],
  searchedGrouphasNextPage: false,
  searchedGroupnextPage: null,

  // suggested classroom
  suggestedGroups: [],
  suggestedGroupsHasNextPage: true,
  suggestedGroupsNextPage: 1,

  // joined classroom
  joinedGroups: [],
  joinedGroupsHasNextPage: true,
  joinedGroupsNextPage: 1,

  // popup messages:
  popupType: "error",
  popupOpen: false,
  popupMessage: "Error"
};


export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGroupLoading: (state, action) => {
      state.isGroupLoading = action.payload
    },
    addToUserCreatedGroup: (state, action) => {
      const {
        popupOpen,
        popupType,
        popupMessage
      } = action.payload;

      // state.groupList = [action.payload, ...state.groupList]
      state.createGroupFormOpen = false
      state.createdGroupList = [...state.createdGroupList, action.payload]

      state.popupOpen = popupOpen ? popupOpen : false;
      if (popupType) state.popupType = popupType
      if (popupMessage) state.popupMessage = popupMessage
      state.isGroupLoading = false
    },
    addGroupDataOnList: (state, action) => {
      state.groupList = [...state.groupList, action.payload]
      state.isGroupLoading = false
    },
    setGroupList: (state, action) => {
      state.groupList = action.payload
      state.isGroupLoading = false
    },
    update: (state, action) => {
      const {
        _id,
        popupOpen,
        popupType,
        popupMessage
      } = action.payload;
      state.groupList = [...state.groupList].map(data => data._id === action.payload._id ? action.payload : data)
      state.popupOpen = popupOpen ? popupOpen : false;
      if (popupType) state.popupType = popupType
      if (popupMessage) state.popupMessage = popupMessage
      state.isGroupLoading = false
    },
    deleteData: (state, action) => {
      const {
        _id,
        popupOpen,
        popupType,
        popupMessage
      } = action.payload;
      state.groupList = [...state.groupList].filter(data => data._id !== _id)

      state.popupOpen = popupOpen ? popupOpen : false;
      if (popupType) state.popupType = popupType
      if (popupMessage) state.popupMessage = popupMessage
      state.isGroupLoading = false
    },
    toggleCreateGroupFormOpen: (state, action) => {
      state.createGroupFormOpen = action.payload
    },
    setPopUpMessage: (state, action) => {
      const {
        popupOpen,
        popupType,
        popupMessage
      } = action.payload;

      state.popupOpen = popupOpen ? popupOpen : false;
      if (popupOpen) {
        if (popupType) state.popupType = popupType
        if (popupMessage) state.popupMessage = popupMessage
      }
      state.isGroupLoading = false
      state.isGroupLoading = false
    },
    deleteGroup: (state, action) => {

    },
		setGroupMomentData: (state, action) => {
			state.groupMomentsData = action.payload;
		},
    setCreatedGroupList: (state, action) => {
			state.createdGroupList = action.payload;
		},
    setSelectedGroup: (state, action) => {
			state.selectedGroup = action.payload;
		},
    requestToJoinGroup: (state, action) => {
			state.selectedGroup = action.payload;
      // state.suggestedGroups = []
		},
    toggleCreatedGroupList: (state, action) => {
			state.showCreatedGroupList = action.payload;
		},
    setGroupJoinRequests: (state, action) => {
      if(action.payload){
			  state.groupJoinRequests = action.payload.joinRequests;
      }
      state.groupJoinRequestListOpen = true
		},
    toggleGroupUsersListOpen: (state, action) => {
      state.groupJoinRequestListOpen = action.payload
    },
    addToGroupMembers: (state, action) => {
      if(action.payload._id){
        state.selectedGroup = {
          ...state.selectedGroup,
          members: [...state.selectedGroup.members, action.payload._id]
        }
      }
    },
		toggleUpdateGroupFormOpen: (state, action) => {
			state.updateGroupFormOpen = action.payload
		},
    updateSelectedGroup: (state, action) => {
      state.selectedGroup = {
				...state.selectedGroup,
				name: 				action.payload.name,
				description: 	action.payload.description,
			}
			state.updateGroupFormOpen = false
    },
    toggleGroupUsersJoinRequestListOpen: (state, action) => {
      state.groupUsersJoinRequestListOpen = action.payload
    },
    setGroupMembers: (state, action) => {
      state.groupMembers = action.payload.members
      state.groupMembersListOpen = true
    },
    toggleGroupMembersListOpen: (state, action) => {
      state.groupMembersListOpen = action.payload
    },
    removeToGroup: (state, action) => {
      const {
				group,
				user
			} = action.payload

			state.selectedGroup = {
				...state.selectedGroup,
				members: group.members,
			}
			state.groupMembers = [...state.groupMembers].filter(userData => userData._id != user._id)
    },
    setSuggestedGroups: (state, action) => {
			if(action.payload.page == 1){
				state.suggestedGroups = action.payload.docs
			}
			else{
				state.suggestedGroups = [
					...state.suggestedGroups,
					...action.payload.docs
				]
			}
			state.suggestedGroupsHasNextPage = action.payload.hasNextPage
			state.suggestedGroupsNextPage = action.payload.nextPage
		},
		setJoinedGroups: (state, action) => {
			// Uncomment this function if you already figureout how to paginate the joined classrooms
			if(action.payload.page == 1){
				state.joinedGroups = action.payload.docs
			}
			else{
				state.joinedGroups = [
					...state.joinedGroups,
					...action.payload.docs
				]
			}
			state.joinedGroupsHasNextPage = action.payload.hasNextPage
			state.joinedGroupsNextPage = action.payload.nextPage
		},
    toggleFindGroupSection: (state, action) => {
      state.showFindGroupSection = action.payload
    },
    setSearchedGroups: (state, action) => {
      const {
				docs,
				hasNextPage,
				nextPage,
			} = action.payload
			
			state.searchedGroupList = docs
			state.searchedGrouphasNextPage =	hasNextPage
			state.searchedGroupnextPage=			nextPage
    }
  }
});


export const {
  setGroupLoading,
  create,
  setGroupList,
  update,
  deleteData,
  setPopUpMessage,
  toggleCreateGroupFormOpen,
  deleteGroup,
  addGroupDataOnList,
	setGroupMomentData,
  setCreatedGroupList,
  addToUserCreatedGroup,
  setSelectedGroup,
  toggleCreatedGroupList,
  toggleGroupUsersListOpen,
  setGroupJoinRequests,
  addToGroupMembers,
  toggleUpdateGroupFormOpen,
  updateSelectedGroup,
  toggleGroupUsersJoinRequestListOpen,
  setGroupMembers,
  toggleGroupMembersListOpen,
  removeToGroup,
  setSuggestedGroups,
  setJoinedGroups,
  toggleFindGroupSection,
  requestToJoinGroup,
  setSearchedGroups
} = groupSlice.actions;

const showErrorType = (err, dispatch) => {
  if (err.response) {
    dispatch(setPopUpMessage(
      {
        popupOpen: true,
        popupType: "error",
        popupMessage: err.response.data
      }
    ))
  } else if (err.request) {
    console.log(err.response)
    dispatch(setPopUpMessage(
      {
        popupOpen: true,
        popupType: "error",
        popupMessage: 'Server error. No response received, request may have timed out, been blocked or server is down.'
      }
    ))
  } else {
    dispatch(setGroupLoading(false))
    console.log('Error:', err.message);
  }
}

export const actionToggleCreatedGroupList = ( show = true ) => dispatch => {
  dispatch(toggleCreatedGroupList(show))
}

export const actionGetGroupDetails = groupId => async dispatch => {
  
  const headers = getHeaders();
	if(headers === null) return
  
	const url = `${URI}/group/group-details/${groupId}`
	const res = await Axios.get(
		url,
		headers
	)
  console.log("dumaan here")
  console.log(res.data)

	dispatch(setSelectedGroup(res.data))

}

export const actionGetUserGroups = userId => async dispatch => {
  const headers = getHeaders();
	if(headers === null) return
  console.log(userId)
	const url = `${URI}/users/user-groups/${userId}`
	const res = await Axios.get(
		url,
		headers
	)

  console.log(res?.data ? res.data.groups : [])
	dispatch(setGroupList(res?.data ? res.data.groups : []))
}

export const actionGetGroupMemberMoments = (urlParam = "") => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	const url = `${URI}/group/group-member-moments?${urlParam}`
	const res = await Axios.get(
		url,
		headers
	)

	dispatch(setGroupMomentData(res.data))
}

export const actionGetGroupMomentOnDateRange = (groupId, params) => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	console.log(groupId)
	const res = await Axios.get(
			`${URI}/group/get-users-moment-in-group-on-date-range/${groupId}?${params}`,
			headers
	)
	console.log(res.data)
	dispatch(setGroupMomentData(res.data))
	// dispatch(updateTasksInDateRange(res.data))
}

export const actionToggleCreateGroupFormOpen = open => async (dispatch, getState) => {
  dispatch(toggleCreateGroupFormOpen(open))
}

export const actionCreateGroup = data => async (dispatch, getState) => {

  const headers = getHeaders();
  if (headers === null) return

  dispatch(setGroupLoading());
  try {
    const res = await Axios.post(
      `${URI}/group/create-group`,
      data,
      headers
    )
    dispatch(addToUserCreatedGroup(res.data))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }

}

export const actionGetUserCreatedGroupList = () => async (dispatch, getState) => {
	const headers = getHeaders();
	if(headers === null) return

  const {user} = getState();
	const userId = user.userData ? user.userData._id : null;

	const url = `${URI}/group/get-user-created-group/${userId}`
	const res = await Axios.get(
		url,
		headers
	)

	dispatch(setCreatedGroupList(res.data))
}

export const actionToggleUpdateGroupFormOpen = (open = true) => dispatch => {
	dispatch(toggleUpdateGroupFormOpen(open))
}

export const actionUpdateGroup = (groupId, data) => async (dispatch, getState) => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setGroupLoading());
	
	try {
		const res = await Axios.put(
			`${URI}/group/update-group/${groupId}`,
			data,
			headers
		)
    console.log("testing here")
		dispatch(updateSelectedGroup(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

/*/
import {
actionUpdateGroupGroupImage
} from '../../app/Reducers/group/groupSlice';

dispatch(actionUpdateGroupGroupImage({
_id: groupImage._id,
// groupImage: "value here"
})
/**/
export const actionUpdateGroupGroupImage = data => async (dispatch) => {

  const headers = getHeaders();
  if (headers === null) return

  dispatch(setGroupLoading());
  const {
    _id,
    groupImage
  } = data;

  try {
    const res = await Axios.put(
      `${URI}/group/updateGroupGroupImage`,
      {
        _id,
        groupImage
      },
      headers
    )
    console.log(res)
  }
  catch (err) {
    showErrorType(err, dispatch)
  }
}

export const actionUpdate = data => async (dispatch, getState) => {

  const headers = getHeaders();
  if (headers === null) return

  dispatch(setGroupLoading());
  try {
    const res = await Axios.post(
      `${URI}/group/update`,
      data,
      headers
    )
    dispatch(update(res.data))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }

}

export const actionDeleteGroup = group => async dispatch => {

  const headers = getHeaders();
  if (headers === null) return

  try {
    const res = await Axios.delete(
      `${URI}/group/${group._id}`,
      headers
    )
    dispatch(deleteGroup(res.data))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }
}

export const actionRequestToJoinGroup = (groupId, userId = null) => async dispatch => {

  const headers = getHeaders();
  if (headers === null) return

  let params = {}
  if(groupId) { params = {...params, groupId  }}
  if(userId) { params = {...params, userId    }}

  try {
    const res = await Axios.put(
      `${URI}/group/request-to-join-group`,
      params,
      headers
    )
    // console.log(res.data.group)
    dispatch(requestToJoinGroup(res.data.group))
    // dispatch(setSelectedGroup(res.data.group))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }
}

export const actionAcceptToGroup = (groupId, userId) => async dispatch => {

  const headers = getHeaders();
  if (headers === null) return

  try {
    const res = await Axios.put(
      `${URI}/group/accept-to-group`,
      { userId, groupId },
      headers
    )
    // dispatch(deleteGroup(res.data))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }
}

export const actionDeclineToGroup = (groupId, userId) => async dispatch => {

  const headers = getHeaders();
  if (headers === null) return

  try {
    const res = await Axios.put(
      `${URI}/group/decline-to-group`,
      { userId, groupId },
      headers
    )
    // dispatch(deleteGroup(res.data))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }
}

export const actionAddUserToGroup = (
  groupId,
	userIdToAdd
) => async dispatch => {

  const headers = getHeaders();
  if (headers === null) return

  try {
    const res = await Axios.put(
      `${URI}/group/add-group-member`,
      {
        groupId,
        userIdToAdd
      },
      headers
    )
    dispatch(addToGroupMembers(res.data))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }

}

export const actionToggleGroupUsersJoinRequestListOpen = (open,groupId) => async (dispatch, getState) => {
	// make a request here to get all the join request to the group
	if(open){
		const headers = getHeaders();
		if (headers === null) return
	
		try {
			const res = await Axios.get(
				`${URI}/group/group-join-requests/${groupId}`,
				headers
			)
			console.log(res.data)
			dispatch(setGroupJoinRequests(res.data))
		}
		catch (err) {
			showErrorType(err, dispatch)
		}
	}
	else{
		dispatch(toggleGroupUsersJoinRequestListOpen(open));
	}
}

export const actionToggleGroupUsersMembersListOpen = (open,groupId) => async (dispatch, getState) => {
	if(open){
		const headers = getHeaders();
		if (headers === null) return
	
		try {
			const res = await Axios.get(
				`${URI}/group/group-members/${groupId}`,
				headers
			)
      console.log("dumaan here")
      console.log(res.data)
			dispatch(setGroupMembers(res.data))
		}
		catch (err) {
			showErrorType(err, dispatch)
		}
	}
	else{
		dispatch(toggleGroupMembersListOpen(open));
	}
}

export const actionToggleGroupJoinRequestListOpen = ( show = true ) => dispatch => {
  dispatch(toggleGroupUsersListOpen(show))
}

export const actionChangeGroupProfilePicture = (data,file) => async (dispatch,getState) => {
		
	let headers = getHeaders();
	if(headers === null) return

	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	try{
		let formData = new FormData();
		for (const [key, value] of Object.entries(data)) {
			formData.append(key, value);
		}

		if(file){ formData.append('image', file); }

		const res = await Axios.put(
			`${URI}/group/change-group-profile-picture`,
			formData,
			headers
		)

		console.log(res.data)
		// dispatch(setProfileLoading())

		// setTimeout(() => {
		// 	dispatch(setProfilePicture(res.data))   
		// }, 1000);
	} catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionChangeGroupTimelinePicture = (data,file) => async (dispatch,getState) => {
		
	let headers = getHeaders();
	if(headers === null) return

	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	try{
		let formData = new FormData();
		for (const [key, value] of Object.entries(data)) {
			formData.append(key, value);
		}

		if(file){ formData.append('image', file); }

		const res = await Axios.put(
			`${URI}/group/change-group-timeline-picture`,
			formData,
			headers
		)

		console.log(res.data)
		// dispatch(setProfileLoading())

		// setTimeout(() => {
		// 	dispatch(setProfilePicture(res.data))   
		// }, 1000);
	} catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionRemoveToGroup = (groupId, userId) => async dispatch => {

  const headers = getHeaders();
  if (headers === null) return

  try {
    const res = await Axios.put(
      `${URI}/group/remove-to-group`,
      { userId, groupId },
      headers
    )
		dispatch(removeToGroup(res.data))
  }
  catch (err) {
    showErrorType(err, dispatch)
  }
	
}

export const actionSearchGroup = (query="", page = 1, limit = 10) => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return
  console.log({
    query,
    page ,
    limit ,
  })
	dispatch(setGroupLoading());
	const q = `query=${query}&page=${page}&limit=${limit}`
  
	try {
		const res = await Axios.get(
			`${URI}/group/search-group?${q}`,
			headers
		)
    // console.log(res.data)
		dispatch(setSearchedGroups(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}
}

export const actionToggleFindGroupSection = (open = true) => dispatch => {
	dispatch(toggleFindGroupSection(open))
}

export const actionGetSuggestedGroups = (page=1) => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setGroupLoading());
	const params = `page=${page}`

	try {
		const res = await Axios.get(
			`${URI}/group/suggested-group?${params}`,
			headers
		)
		// console.log(res.data)
		dispatch(setSuggestedGroups(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

export const actionGetJoinedGroup = (page=1) => async dispatch => {

	const headers = getHeaders();
	if (headers === null) return

	dispatch(setGroupLoading());
	const params = `page=${page}`

	try {
		const res = await Axios.get(
			`${URI}/group/joined-group?${params}`,
			headers
		)
		dispatch(setJoinedGroups(res.data))
	}
	catch (err) {
		showErrorType(err, dispatch)
	}

}

export default groupSlice.reducer;