import React, {useState,useEffect,useCallback} from 'react';
import './TaskEstimatesPlan.scss';
import CustomHTMLParser from "../Hooks/CustomHTMLParser";
import { useSelector, useDispatch } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {v1 as uuid} from "uuid";
import Button from '@mui/material/Button';
import moment from 'moment';
import BasicDateRangePicker from '../DateRangePicker/BasicDateRangePicker';
import CloseIcon from '@mui/icons-material/Close';
import {
    actionGetUserPlanningNotes
} from '../../app/Reducers/note/noteSlice';
import {
    actionSetPlanningFormOpen,
    actionSetPopupOpen,
    actionSetPlanTaskList
} from '../../app/Reducers/planning/planningSlice';
import MemberSelection from '../../components/MemberSelection/MemberSelection';
import ConfirmationModal from '../../components/PopupNotifications/ConfirmationModal';
import PlanningTaskTable from '../../components/PlanningTaskTable/PlanningTaskTable';
import PopupNotifications from '../PopupNotifications/PopupNotifications';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ColorSelection from '../ColorSelection/ColorSelection';

const TaskEstimatesPlan = ({
    onSelectUser,
    onSelectedDateChange,
    setCalendarSchedule,
    savePlan
}) => {
    let workHoursAvailability = 0; // this is the work time needed by the employee
    let availabilityDays = [];
    const startTime = 8; // 8am
    const startTimeBreak = 12; // 12pm
    const endTimeBreak = 13; // 1pm
    const endTime = 17; // 5pm
    const availabilityPerDay = 8;
    
    const [selectedNote, setSelectedNote] = useState("");
    const [weekEndsOff, setWeekEndsOff] = useState(true);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [colorData, setColorData] = useState(
        { name: "Default", color: "#f4f6f8", textColor: "#252423" }
    )

    const {
        noteList
    } = useSelector(state => state.note);
    const {
        isPlanningFormOpen,
        popupType,
        popupOpen,
        popupMessage,
        planTaskList
    } = useSelector(state => state.planning);
    
    // const [planTaskList, actionSetPlanTaskList] = useState([]);
    const [totalEstimatedTime, setTotalEstimatedTime] = useState(0);
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    
    const dispatch = useDispatch();
    
    const handleSelectedNoteChange = useCallback((event) => {
        console.log(event.target.value)
        setSelectedNote(event.target.value);
        setCalendarSchedule([]);
    },[]);

    const getDaysArray = (start,end) => {
        const a=[]
        for(let d=new Date(start); d <= new Date(end); d.setDate(d.getDate()+1)){
            if(weekEndsOff){
                if(d.getDay() !== 6 && d.getDay() !== 0){
                    a.push(new Date(d));
                }
                else{
                    console.log("may weekend")
                }
            }
            else{
                a.push(new Date(d));
            }
        }
        return a;
    };

    const generateDayPlan = overallSchedule => {
        const daySched = [];
        let timeToConsume = availabilityPerDay/2
        let firstHalf = true;
        while(timeToConsume > 0){
            if(overallSchedule.length === 0)
                break

            const plan = overallSchedule.shift();
            
            if(plan.estimate <= timeToConsume){
                daySched.push(plan)
                timeToConsume -= plan.estimate

                if(timeToConsume==0){
                    timeToConsume = firstHalf ? availabilityPerDay/2 : 0;
                    firstHalf = false;
                }
            }
            else{
                daySched.push({
                    ...plan,
                    estimate: timeToConsume
                })
                overallSchedule.unshift({
                    ...plan,
                    id: uuid(),
                    estimate: plan.estimate - timeToConsume
                }) // put back the remaining task time
                
                // console.log(firstHalf)
                // console.log([...daySched])
                // console.log(plan.taskName)
                // console.log("---------------------------------")

                timeToConsume = firstHalf ? availabilityPerDay/2 : 0;
                firstHalf = false;
            }
        }
        return {
            daySched,
            overallSchedule
        }
    }

    const getDayPlan = (daySchedule, startTime) => {
        
        const halfDay = availabilityPerDay/2;
        let tempEstimate = 0;
        
        const generatedSchedule = daySchedule.map(schedule => {

            const initStartTime = startTime.clone();
            const initEndTime = startTime.clone();

            tempEstimate += schedule.estimate;

            // if already halfday, add breaktime
            startTime.add(  (tempEstimate === halfDay) ? schedule.estimate + 1 : schedule.estimate , 'hours')

            return {
                category: "time",
                dueDateClass: "",
                end: initEndTime.add(schedule.estimate, 'hours'),
                id: schedule.id,
                isAllDay: false,
                location: "",
                start: initStartTime,
                state: "Busy",
                title: schedule.taskName,
                body: schedule.taskKey,
                bgColor: colorData.color,
                color: colorData.textColor,
                borderColor: colorData.color,

            }

        })

        return generatedSchedule;
    }

    const generatePlan = () => {
        // Check if there are plans that has no estimate
        if([...planTaskList.filter(plan => plan.estimate <= 0)].length){
            alert("Negative or 0 time estimate is not valid")
            return
        }
        let overallSchedule =   [...planTaskList]
                                .filter(task => task.taskName.length > 0)
                                .map(task => {
                                    return {
                                        ...task
                                    }
                                })
                                .sort((a,b) => b.priority - a.priority)
        let weekSchedule = [];

        const totalTasksTime = overallSchedule.reduce((a,c) => a+c.estimate,0);

        // console.log(totalTasksTime)
        // console.log(workHoursAvailability)

        if(totalTasksTime > workHoursAvailability){
            alert(`Unable to generate plan. Total task time is morethan work hours availability`)
            return 
        }
        
        do{
            const x = generateDayPlan(overallSchedule)
            overallSchedule = [...x.overallSchedule];
            weekSchedule.push([...x.daySched])
        } while (overallSchedule.length);

        const generatedSchedule = weekSchedule.map((daySchedule,i) => {
            const x = moment(availabilityDays[i]).set({"hour": 8, "minute": 0})
            return getDayPlan(daySchedule,x)
        }).flat()

        console.log(generatedSchedule)

        setCalendarSchedule(generatedSchedule)
        dispatch(actionSetPopupOpen({
            popupOpen: true,
            popupType: "success",
            popupMessage: `Plan is generated. Click save plan button to save it.`
        }))
    }

    useEffect(() => {
        dispatch(actionGetUserPlanningNotes());
    }, [])

    useEffect(() => {
        if(!selectedNote) return
        // edit ka dito
        const x = JSON.parse(CustomHTMLParser(selectedNote.content))

        const getTextData = elementData => elementData.children.reduce(
            (a,c) => {
                console.log(c.children)

                if(c.children){
                    if(c.children[0]?.textContent){
                        return a+c.children[0].textContent    
                    }
                    return a
                }
                return a+c.textContent
            },""
        )

        const parseHtmlElement = htmlElement => {
            if(htmlElement.tagName === "P" || htmlElement.tagName === "LI"){
                console.log(htmlElement)
                return getTextData(htmlElement)
            }
            else{// means not p or LI
                switch(htmlElement.tagName){
                    case "H1":
                    case "H2":
                    case "H3":
                    case "H4":
                    case "H5":
                    case "H6": return null
                }
                if(htmlElement.children)
                    return htmlElement.children.map(child => parseHtmlElement(child))                    
            }
        }

        const planTaskListData =    parseHtmlElement(x)
                                    .flat()
                                    .filter(task=>{
                                        console.log(task)
                                        return task && (task.trim().length > 0)
                                    }).map(task=>{
                                        const generatedId = uuid()
                                        return {
                                            id: generatedId,
                                            taskName: task,
                                            estimate: 1,
                                            priority: 0,
                                            taskKey: generatedId,
                                        }
                                    })

        console.log(planTaskListData)
        dispatch(actionSetPlanTaskList(planTaskListData))
        
    }, [selectedNote])

    useEffect(() => {
        setTotalEstimatedTime(planTaskList.reduce((a,c) => a+c.estimate, 0))
    }, [planTaskList])
    
    if(selectedDateRange[0]){
        availabilityDays = getDaysArray(
            selectedDateRange[0].startDate,
            selectedDateRange[0].endDate
        )
        workHoursAvailability = availabilityDays.length * availabilityPerDay
    }

    const onDateRangeChange = range => {
        console.log(range)
        const d = range[0].startDate;
        // dispatch(actionSetSelectedCalendarWeekDate(d.toDateString()))
        onSelectedDateChange(d)
        setSelectedDateRange(range);
    }

    const handlePopupClose = () => {
        dispatch(actionSetPopupOpen({
            popupOpen: false
        }))
    }

    const onChangeColor = colorData => {
        setColorData(colorData)
    }

    return <div className={`TaskEstimatesPlanContainer ${isPlanningFormOpen ? "open" : ""}`}>
        <section className="planning-input-wrapper">
            <div className="planning-input-header">
                <h2 className="section-heading">Create plan</h2>
                <CloseIcon 
                className="close-form-trigger hideOnDesktop"
                onClick={()=>dispatch(actionSetPlanningFormOpen(false))}/>
            </div>
            <MemberSelection onSelectUser={onSelectUser} />
            <FormControl
            fullWidth
            size="small"
            sx={{marginBottom: "16px"}}>
                <InputLabel id="demo-simple-select-label">Select Note</InputLabel>
                {noteList.length ? <>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedNote}
                        label="Select Note"
                        onChange={handleSelectedNoteChange}
                    >
                        {noteList.map(note => <MenuItem 
                        style={{ height: 30 }} key={note._id} value={note}>{note.title}</MenuItem>)}
                    </Select>
                </> : ""}
            </FormControl>
            <div className="color-container small">
                <p>Plan color: </p>
                <span
                    style={{
                        backgroundColor: colorData.color,
                        height: "20px",
                        width: "20px",
                        borderRadius: "20px",
                        fontSize: "0",
                        marginRight: "10px"
                    }}
                >.</span>
                {colorData.name}
                <div className="selection-wrapper">
                    <ColorSelection 
                    onChangeColor={onChangeColor}
                    />
                </div>
            </div>
        </section>

        <PlanningTaskTable setTotalEstimatedTime={setTotalEstimatedTime}/>
        
        <section className="sprint-dates planning-input-wrapper">
            <div className="header-container">
                <h2 className="section-heading-2">Sprint Date Range</h2>
                <FormControlLabel
                sx={{marginLeft: "auto"}}
                control={
                    <Switch
                    checked={weekEndsOff}
                    onChange={e=>setWeekEndsOff(e.target.checked)}
                    name="weekEndsOff"
                    size="small"/>
                }
                label="Week Ends Off"
                />
            </div>
            <BasicDateRangePicker setSelectedDateRange={onDateRangeChange}/>
        </section>

        <section className="time-schedule-container planning-input-wrapper">

            <h2 className="section-heading-2">Time Schedule</h2>

            <div className="work-time-data">
                <div className="time-schedule">
                    <TableContainer component={Paper}>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">In Time</TableCell>
                                <TableCell align="right">{startTime}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Break time start:</TableCell>
                                <TableCell align="right">{startTimeBreak}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Break time end:</TableCell>
                                <TableCell align="right">{endTimeBreak}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Out Time</TableCell>
                                <TableCell align="right">{endTime}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Availability per day</TableCell>
                                <TableCell align="right">{availabilityPerDay}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    </TableContainer>
                </div>
                <div className="work-availability">
                    <TableContainer component={Paper}>
                        <Table sx={{ width: "100%" }} aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">Available Time:</TableCell>
                                    <TableCell align="right">
                                        <span
                                        style={{ color: `${workHoursAvailability <= 0 ? "red" : "inherit"}` }}
                                        className="val"
                                        >{workHoursAvailability ? workHoursAvailability : "Select available dates" }</span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Total Estimated Time:</TableCell>
                                    <TableCell align="right">
                                        <span 
                                        style={{ color: `${totalEstimatedTime <= 0 ? "red" : "inherit"}` }}
                                        className="val"
                                        >{totalEstimatedTime ? totalEstimatedTime : "Select notes with plan"}</span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>

                                    <TableCell
                                    sx={{ color: `${workHoursAvailability-totalEstimatedTime < 0 ? "red" : "inherit"}` }}
                                    align="left">Time Availability Left: </TableCell>

                                    <TableCell
                                    align="right"
                                    sx={{ color: `${workHoursAvailability-totalEstimatedTime < 0 ? "red" : "inherit"}` }}>
                                        <span className="val">{workHoursAvailability-totalEstimatedTime}</span>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </section>

        <ConfirmationModal 
        open = {confirmationOpen}
        setOpen = {setConfirmationOpen}
        callback = {() => {
            savePlan(selectedDateRange)
            setConfirmationOpen(false)
        }}
        confirmationText = {"Save plan?"}>
            If you are sure to save the plan, press save.
        </ConfirmationModal>

        <PopupNotifications
        open={popupOpen} 
        handleClose={handlePopupClose}
        severity={popupType}>
            {popupMessage}
        </PopupNotifications>
        
        <div className="planning-action-container">
            <Button variant="contained" onClick={generatePlan}>Generate plan</Button>
            <Button
                variant="contained"
                onClick={() => setConfirmationOpen(true)}
            >Save plan</Button>
            <Button
                variant="contained"
                onClick={() => setCalendarSchedule([])}
            >Clear</Button>
        </div>
    </div>
}

export default TaskEstimatesPlan;