import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

const TransactionItem = ({
  transaction
}) => {
  
	return <div className='TransactionItemContainer'
  style={{
    width: '100%',
    maxWidth: 450,
    border: "1px solid rgba(224, 224, 224, 1)"
  }}
  >
    <Table size="small" aria-label="a dense table">
      <TableBody>
      { transaction?.stripePaymentRef ?
        <TableRow>
          <TableCell align="left">Stripe Payment Ref:</TableCell>
          <TableCell align="right" style={{ fontWeight: 700, verticalAlign: "baseline" }}>{transaction.stripePaymentRef}</TableCell>
        </TableRow>: ""
      }
      <TableRow>
        <TableCell align="left">Amount:</TableCell>
        <TableCell align="right" style={{ fontWeight: 700 }}>SGD {transaction.amount}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left">Transaction Date:</TableCell>
        <TableCell align="right">{moment(transaction.createdAt).format('llll')}</TableCell>
      </TableRow>
      { transaction?.seminar ? <>
        <TableRow>
          <TableCell align="left">Seminar ID:</TableCell>
          <TableCell align="right">{transaction.seminar._id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Seminar:</TableCell>
          <TableCell align="right">{transaction.seminar.name}</TableCell>
        </TableRow>
        </> : ""
      }
      { transaction?.classroom ?
        <TableRow>
          <TableCell align="left">Classroom ID:</TableCell>
          <TableCell align="right">{transaction.classroom._id}</TableCell>
        </TableRow>: ""
      }
      { transaction?.group ?
        <TableRow>
          <TableCell align="left">Group ID:</TableCell>
          <TableCell align="right">{transaction.group._id}</TableCell>
        </TableRow>: ""
      }
      { transaction?.premiumAccount ?
        <TableRow>
          <TableCell align="left">Premium Account ID:</TableCell>
          <TableCell align="right">{transaction.premiumAccount._id}</TableCell>
        </TableRow>: ""
      }
      { transaction?.status ?
        <TableRow>
          <TableCell align="left">Status:</TableCell>
          <TableCell align="right">{transaction.status}</TableCell>
        </TableRow>: ""
      }
      <TableRow>
        <TableCell align="left">Customer ID:</TableCell>
        <TableCell align="right">{transaction.createdBy._id}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left">Customer name:</TableCell>
        <TableCell align="right">{transaction.createdBy.firstName} {transaction.createdBy.lastName}</TableCell>
      </TableRow>
      </TableBody>
    </Table>

	</div>
}

export default TransactionItem;