import React, { useEffect, memo } from 'react';
import './GlobalDashboard.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionGetLatestTodosOfUsers,
  // actionGetLatestPostsOfUsers
} from '../../app/Reducers/dashboard/dashboardSlice';
import UserDataSectionInfo from './UserDataSectionInfo';
import TodoSideNav from '../../components/Todo/TodoSideNav';
import UsersListWithEmergentIndex from './UsersListWithEmergentIndex';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import PostCards from '../../components/PostCards/PostCards';
import UsersPostsWithoutPoints from './UsersPostsWithoutPoints';

const GlobalDashboard = ({
}) => {
	const dispatch = useDispatch();
  const {
    usersDataInGlobalDashboard,
    // usersPostsWithoutPoints
  } = useSelector(state => state.dashboard);
  const [value, setValue] = React.useState(0);

  useEffect(()=>{
    dispatch(actionGetLatestTodosOfUsers())
  },[])
  
  const handleChange = (event, newValue) => setValue(newValue);

  return <div className={`GlobalDashboardContainer`}>
    <div className="pageContentWrapper">
      <div className="page-header-container">
        <h2 className="section-title-01">Global Dashboard</h2>
        {/* <p onClick={()=>dispatch(actionGetLatestPostsOfUsers())}>Testing</p> */}
      </div>
      <div className="contentWrapper">
        
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{
            borderBottom: "1px solid #ccc",
            marginBottom: 2
          }}
        >
          <Tab label="Todos without points" />
          <Tab label="Posts without points" />
          <Tab label="Users List EI" />
        </Tabs>
        
        {
        value == 0 ? 
          usersDataInGlobalDashboard.map(userDashboardData=>{
            return (
              <div key={userDashboardData.userData._id} className={`userDataInfo`}>
                <UserDataSectionInfo userDashboardData={userDashboardData} />
              </div>
            );
          })
        :
        value == 1 ?
          <UsersPostsWithoutPoints />
        :
        <UsersListWithEmergentIndex />
        }

      </div>
    </div>
    <TodoSideNav />
  </div>

}

export default memo(GlobalDashboard);