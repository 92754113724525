import React, {useState,useRef,memo, useEffect} from 'react';
import ReactDom from 'react-dom';
import { FileDrop } from 'react-file-drop';
import PostHeader from '../PostCards/PostHeader';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { emotions } from "../Hooks/Constants";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {
  actionToggleEditPostForm,
  actionSubmitEditPost
} from '../../app/Reducers/post/postSlice';
import PostInputs from './PostInputs';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import Stack from "@mui/material/Stack";

const style = {
  position: "absolute",
  top: "40px",
  left: "50%",
  transform: "translate(-50%, 0)",
  width: "calc(100% - 20px)",
  maxWidth: "480px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  height: "calc(100vh - 80px)",
  overflow: "auto",
};

const EditPosts = ({
  
}) => {

  const dispatch = useDispatch()

  const [imageToUpload, setImageToUpload] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [isCursorOnFrame, setIsCursorOnFrame] = useState(false)
  const fileInputRef = useRef()
  const [selectedEmotion, setSelectedEmotion] = useState(-1)
  const {
		userData
	} = useSelector(state => state.user)
  const {
		editPostFormOpen,
    postToEdit
	} = useSelector(state => state.post)
  const [showPostImage, setShowPostImage] = useState(postToEdit?.postImage ? true : false)
  

  const { setError, control, handleSubmit, reset, setValue, formState: {errors,isSubmitSuccessful} } = useForm({ 
    defaultValues: {
      postDescription: ""
    }
  });

  const onSubmit = async data => {
    
    let postData = {
      ...data,
      emotion: selectedEmotion,
      userId: userData._id,
      postId: postToEdit._id
    }
    // console.log({
    //   postData,
    //   imageToUpload
    // })
    
    dispatch(actionSubmitEditPost(
      postData,
      imageToUpload
    ))
    // setShowUploadForm(false)
    
  }

  const processUploadedFile = file => {
    if(
      file.type=="image/jpg" ||
      file.type=="image/jpeg" ||
      file.type=="image/png"
    ){
      // do something with the file object
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageToUpload(file)
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
    else{
      console.log("only image files are accepted")
    }
  }

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf('image') !== -1) {
        const file = item.getAsFile();
        const imageURL = URL.createObjectURL(file);
				console.log(imageURL)
        // You can now use imageURL to display the pasted image, e.g., set it as the background of a div
        // or render it using an <img> element.
        // For simplicity, we'll display it as a background image here.
				setImageToUpload(file)
				setImagePreview(imageURL);

      }
    }
  };

  const onFileInputChange = (event) => {
    const file = event.target.files[0];
    processUploadedFile(file)
  }
  
  const onFileDrop = (files, event) =>  {
    const file = files[0];
    processUploadedFile(file)
  }

  const emotion = selectedEmotion > -1 ? emotions[selectedEmotion-1]._id ? `Is Feeling ${emotions[selectedEmotion-1].name} ${emotions[selectedEmotion-1].emoji}` : null : null;

  const onClose = () => {
    dispatch(actionToggleEditPostForm(false));
    reset({
      name: "",
      description: "",
      groupImage: "",
    });
    setImageToUpload(null)
    setImagePreview(null)
  };
  
  useEffect(()=>{
    setValue("postDescription", postToEdit?.postDescription ? postToEdit.postDescription : "")
    setShowPostImage(postToEdit?.postImage ? true : false)
  },[postToEdit])

  return ReactDom.createPortal(
    <>
      <div className='EditPostsContainer'>
      <Modal
          keepMounted
          open={editPostFormOpen}
          onClose={() => onClose()}
          aria-labelledby="modal-Group"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: 10 }}
            >
              <Typography id="modal-Group" variant="h6" component="h2">
                Edit Post
              </Typography>

              <CloseIcon
                className="close-form-trigger"
                onClick={() => onClose()}
              />
            </Stack>
            <form
            className="post-form form-container"
            encType="multipart/form-data"
            onSubmit={handleSubmit(onSubmit)}>

              <div className="cardHeader">
                <PostHeader feeling={emotion} post={{createdBy: userData}}/>
                {/* <Tooltip title={"Close"}>
                  <IconButton
                  size="small"
                  onClick={()=>dispatch(actionToggleEditPostForm(false))}>
                    <CloseIcon/>
                  </IconButton>
                </Tooltip> */}
              </div>

              <PostInputs
                control={control}
                errors={errors}
                setSelectedEmotion={setSelectedEmotion}
                handlePaste={handlePaste}
              />

              <div
              className={`uploadWrapper ${isCursorOnFrame ? "cursorOnFrame" : ""}`}>
                {
                showPostImage || imageToUpload ? 
                <div className="uploadImageWrapper">
                  <Tooltip
                  className="uploadImageEditButton"
                  title={"Change image"}>
                    <IconButton
                    size="small"
                    onClick={()=>{
                      setShowPostImage(false)
                      setImageToUpload(null)
                    }}>
                      <EditIcon/>
                    </IconButton>
                  </Tooltip>
                  {
                    imagePreview ? 
                    <img src={imagePreview} alt="upload image here" loading="lazy"/>
                    :
                    <img src={postToEdit?.postImage} alt="" />
                  }
                </div> : 
                <FileDrop
                  className={`fileDropUpload`}
                  onFrameDragEnter={(event) => setIsCursorOnFrame(true)}
                  onFrameDragLeave={(event) => setIsCursorOnFrame(false)}
                  onFrameDrop={(event) => setIsCursorOnFrame(false)}
                  onDrop={onFileDrop}
                  accept="image/*"
                >
                  <p>Drop some files here!</p>
                  <Controller
                  name="postImage"
                  control={control}
                  defaultValue={""}
                  render={({field}) => 
                    <input
                      {...field}
                      onChange={onFileInputChange}
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      className="hidden"
                    />
                  }/>
                </FileDrop>
                }
              </div>

              <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                width: "100%",
                borderRadius: "8px",
                height: "48px",
                fontSize: "16px",
              }}>Post</Button>

            </form>
          </Box>
        </Modal>
      </div>
    </>,
    document.getElementById("edit-post-form-wrapper-portal")
  );
}

export default EditPosts;