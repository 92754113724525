// import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
import { getHeaders } from '../../helpers.js';

/* Just a test code... remove it afterwards */ 
const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;
/* Just a test code... remove it afterwards */

const initialState = {
    noteList: [],
    notesFor: "",
    isNoteListLoading: false,
    openChangeTodoCategoryForm: false,
    selectedNote: null,
    noteCategoryList: [],
    // popup messages: 
    popupType: "error",
    popupOpen: false,
    popupMessage: "Error",
    showChangeNoteTitleForm: false
};

export const noteSlice = createSlice({
    name: 'note',
    initialState,
    reducers: {
        createNote: (state,action) => {
            const {
                popupOpen,
                popupType,
                popupMessage,
            } = action.payload;

            state.noteList = [...state.noteList, action.payload]
            state.isNoteListLoading = false
            state.popupOpen = popupOpen ? popupOpen : false;
            if(popupType) state.popupType = popupType
            if(popupMessage) state.popupMessage = popupMessage
        },
        updateNote: (state,action) => {
            const updatedNote = action.payload
            state.openChangeTodoCategoryForm = false
            state.noteList = [...state.noteList.map(note=>{
                return note._id === updatedNote._id ? updatedNote : note
            })]
        },
        setNotes: (state, action) => {
            state.noteList = action.payload
        },
        setNotesFor: (state, action) => {
            state.notesFor = action.payload
        },
        deleteNote: (state, action) => {
            const {
                _id: noteId,
                popupOpen,
                popupType,
                popupMessage,
            } = action.payload

            state.noteList = [...state.noteList].filter(note => note._id !== noteId)

            state.popupOpen = popupOpen ? popupOpen : false;
            if(popupType) state.popupType = popupType
            if(popupMessage) state.popupMessage = popupMessage
        },
        setNotePopUpMessage: (state, action) => {
            const {
                popupOpen,
                popupType,
                popupMessage
            } = action.payload;

            state.popupOpen = popupOpen ? popupOpen : false;
            if(popupOpen){
                if(popupType) state.popupType = popupType
                if(popupMessage) state.popupMessage = popupMessage
            }
        },
        changeColor: (state, action) => {
            state.noteList = state.noteList.map(note => {
                    return action.payload.noteId === note._id ? {
                        ...note,
                        colorData: action.payload.colorData
                    } : note
            })
        },
        setNoteListLoading: (state, action) => {
			state.isNoteListLoading = action.payload
		},
        // addTodoCategory : (state, action) => {
        //     state.noteCategoryList = [action.payload,...state.noteCategoryList]
        // },
        setTodoCategoryList: (state, action) => {
            state.noteCategoryList = [...action.payload]
        },
        // deleteTodoCategory: (state, action) => {
        //     state.noteCategoryList = [...state.noteCategoryList].filter(noteCategory => noteCategory._id != action.payload._id)
        // },
        updateTodoCategory: (state, action) => {
            state.noteCategoryList = [...state.noteCategoryList].map(noteCategory => {
                return noteCategory._id == action.payload._id ? action.payload : noteCategory
            })
        },
        changeSelectedNote: (state, action) => {
            state.selectedNote = action.payload
        },
        openChangeTodoCategory: (state, action) => {
            state.openChangeTodoCategoryForm = action.payload
        },
        openChangeTodoCategoryAndAssignNote: (state, action) => {
            state.openChangeTodoCategoryForm = action.payload.open
            state.selectedNote = action.payload.note
        },
        toggleChangeNoteTitleForm: (state,action) => {
            state.showChangeNoteTitleForm = action.payload.open;
            state.selectedNote = action.payload.note;
        },
        setTodoInNotes: (state,action) => {
            state.noteList = state.noteList.map(note=>{
                if(note._id == action.payload.noteId){
                    return {
                        ...note,
                        isLoading: false,
                        todosInNotes: action.payload?.notePaginationData?.docs
                    }
                }
                return note
            })
        },
        setTodoInNotesLoading: (state,action) => {
            state.noteList = state.noteList.map(note=>{
                if(note._id == action.payload.noteId){
                    return {
                        ...note,
                        isLoading: true
                    }
                }
                return note
            })
        }
    }
});

export const {
    createNote,
    updateNote,
    setNotes,
    setNotesFor,
    deleteNote,
    setNotePopUpMessage,
    changeColor,
    setNoteListLoading,
    // addTodoCategory,
    setTodoCategoryList,
    // deleteTodoCategory,
    updateTodoCategory,
    changeSelectedNote,
    openChangeTodoCategory,
    openChangeTodoCategoryAndAssignNote,
    toggleChangeNoteTitleForm,
    setTodoInNotes,
    setTodoInNotesLoading
} = noteSlice.actions;

const showErrorType = (err,dispatch) => {
	if (err.response) {
		dispatch(setNotePopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: err.response.data
			}
		))
	} else if (err.request) {
		console.log(err.response)
		dispatch(setNotePopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: 'Server error. No response received, request may have timed out, been blocked or server is down.'
			}
		))
	} else {
		dispatch(setNoteListLoading(false))
		console.log('Error:', err.message);
	}
}

export const actionGetTodosInNotes = noteId => async dispatch => {
    const headers = getHeaders();
    if(headers === null) return

    dispatch(setTodoInNotesLoading({
        isLoading: true,
        noteId
    }))

    const res = await Axios.get(
        `${URI}/todos/getTodosInNotes/${noteId}`,
        headers
    )
    
    dispatch(setTodoInNotes({
        notePaginationData: res.data,
        noteId
    }))
}
export const actionToggleChangeNoteTitleForm = (open, note=null) => dispatch => {
    dispatch(toggleChangeNoteTitleForm({open,note}))
}

export const actionOpenChangeTodoCategory = (
    open,
    note = null
) => async dispatch => {
    if(note){
        dispatch(openChangeTodoCategoryAndAssignNote({
            open:true,
            note
        }))
    }
    else{
        dispatch(openChangeTodoCategory(open))
    }
}

export const actionChangeSelectedNote = note => async dispatch => {
    dispatch(changeSelectedNote(note))
}

export const actionAssignCategory = notesData => async dispatch => {
    const headers = getHeaders();
    if(headers === null) return

    const noteDataUpdate = {
        _id: notesData._id,
        category: notesData.categoryId
    }

    const res = await Axios.delete(
        `${URI}/notes/assignCategory`,
        noteDataUpdate,
        headers
    )
    
    dispatch(updateNote(res.data))
}

// export const actionDeleteTodoCategory = noteCategory => async dispatch => {
//     const headers = getHeaders();
//     if(headers === null) return

//     const res = await Axios.delete(
//         `${URI}/note-category/${noteCategory._id}`,
//         headers
//     )
    
//     dispatch(deleteTodoCategory(res.data))
// }

// export const actionEditTodoCategory = noteCategoryUpdate => async dispatch => {
//     const headers = getHeaders();
//     if(headers === null) return

//     const res = await Axios.put(
//         `${URI}/note-category/updateTodoCategory`,
//         noteCategoryUpdate,
//         headers
//     )

//     dispatch(updateTodoCategory(res.data))
// }

// export const actionCreateTodoCategory = noteCategoryData => async dispatch => {

//     const headers = getHeaders();
//     if(headers === null) return

//     const res = await Axios.post(
//         `${URI}/note-category/createTodoCategory`,
//         noteCategoryData,
//         headers
//     )

//     dispatch(addTodoCategory(res.data))
// }

export const actionGetTodoCategory = () => async (dispatch,getState) => {
    const headers = getHeaders();
    if(headers === null) return

    const {user} = getState();
    const userId = user.userData ? user.userData._id : null;

    const res = await Axios.get(
        `${URI}/note-category/getTodoCategoryList/${userId}`,
        headers
    )

    dispatch(setTodoCategoryList(res.data))
}

export const actionChangeColor = (noteId, colorData) => (dispatch) => {

    const headers = getHeaders();
    if(headers === null) return

    Axios.put(
        `${URI}/notes/change-color`,
        {
            noteId,
            colorData
        },
        headers
    ).then(res=>{
        dispatch(changeColor({noteId, colorData}))
    })

}

export const actionCreateNotes = createdNoteData => (dispatch,getState) => {

    const headers = getHeaders();
    if(headers === null) return

    const {user} = getState();
    const userId = user.userData ? user.userData._id : null;
    Axios.post(
        `${URI}/notes/create`,
        {
            ...createdNoteData,
            userId
        },
        headers
    ).then(res=>{
        console.log(res.data)
        dispatch(createNote({
            ...res.data,
            popupOpen: true,
            popupType: "success",
            popupMessage: `Note successfully created.`
        }))
    })

}

export const actionUpdateNotes = updatedNote => dispatch => {

    const headers = getHeaders();
    if(headers === null) return

    Axios.put(
        `${URI}/notes/update`,
        {...updatedNote},
        headers
    ).then(res => {
        dispatch(updateNote(res.data))
    })

}

export const actionGetNotes = () => dispatch => {

    const headers = getHeaders();
    if(headers === null) return

    Axios.get(
        `${URI}/notes`,
        headers
    ).then(res => {
        dispatch(setNotes(res.data))
    })

}

export const actionSetNotesFor = notesFor => dispatch => {
    dispatch(setNotesFor(notesFor))
}

export const actionGetUserNotes = () => (dispatch,getState) => {

    const headers = getHeaders();
    if(headers === null) return

    const {user} = getState();
    const userId = user.userData ? user.userData._id : null;

    Axios.get(
        `${URI}/notes/user-notes/${userId}`,
        headers
    ).then(res => {
        dispatch(setNotes(res.data))
    })

}

export const actionGetUserPlanningNotes = () => (dispatch,getState) => {

    const headers = getHeaders();
    if(headers === null) return

    const {user} = getState();
    const userId = user.userData ? user.userData._id : null;
    
    Axios.get(
        `${URI}/notes/user-planning-notes/${userId}`,
        headers
    ).then(res => {
        dispatch(setNotes(res.data))
    })

}

export const actionDeleteNote = (noteId) => async (dispatch) => {

    const headers = getHeaders();
    if(headers === null) return

    await Axios.delete(`${URI}/notes/${noteId}`,headers).then(res => {
        console.log("may response")
        dispatch(deleteNote({
            ...res.data,
            popupOpen: true,
            popupType: "success",
            popupMessage: `Note is successfully deleted`
        }))
    })

}

export const actionHideNotePopupMessage = () => dispatch => {
    dispatch(setNotePopUpMessage({popupOpen: false}))
}

export const noteList = state => state.note.noteList;
export const notesFor = state => state.note.notesFor;

export default noteSlice.reducer;