import React, { useState } from 'react';
import ReactDom from 'react-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import {
  actionToggleJoinSeminarFormOpen,
  actionCheckIfUserIsInSeminar
} from '../../app/Reducers/classroom/classroomSlice';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getDateAndTimeRange } from '../Hooks/Constants';
import Payment from '../../pages/Payment/Payment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

const schema = yup.object().shape({
  // name: yup.string().required("Provide valid name"),
  // description: yup.string().required("Provide valid description"),
  // seminarLink: yup.string().required("Provide valid seminarLink"),
  // speaker: yup.string().required("Provide valid speaker")
});


const JoinSeminarForm = ({

}) => {

  const dispatch = useDispatch()
  const {
    joinSeminarFormOpen,
    selectedSeminar,
    showPaymentForm,
  } = useSelector(state => state.classroom)

  const {
		userData
	} = useSelector(state => state.user);

  const isRegistered = () => {
    if(selectedSeminar?.attendees){
      const i = selectedSeminar?.attendees?.findIndex(attendeeId => attendeeId == userData._id)
      console.log(`${i} > -1 || ${userData._id} == ${selectedSeminar.createdBy._id}`)
      return i > -1 || userData._id == selectedSeminar.createdBy._id
    }
    return true
  }
  const [seminarAmount, setSeminarAmount] = useState(0)

  // form setup
  const { control, handleSubmit, reset, formState: { errors, isSubmitSuccessful } } = useForm({
    resolver: yupResolver(schema)
  });

  const onClose = () => {
    dispatch(actionToggleJoinSeminarFormOpen(false))
  }

  const onSubmit = ({}) => {
    // check first if the user is already registered in the seminar
    dispatch(actionCheckIfUserIsInSeminar(selectedSeminar._id))
    setSeminarAmount(selectedSeminar.price)
  }
  return ReactDom.createPortal(<>
    <div className='SeminarFormContainer'>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Modal
        keepMounted
        open={joinSeminarFormOpen}
        onClose={() => onClose()}
        aria-labelledby="modal-Seminar"
        aria-describedby="keep-mounted-modal-description"
      >
        
        <Box sx={style} spacing={2}>
          {
          selectedSeminar ? <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              style={{ marginBottom: 10 }}>
              <Typography id="modal-Seminar" variant="h6" component="h2">
                Join Seminar
              </Typography>

              <CloseIcon
                className="close-form-trigger"
                onClick={() => onClose()} />
            </Stack>
            {
              showPaymentForm ?
                seminarAmount ? <Payment amount={seminarAmount} /> : ""
              :
              <form 
              className="form-container"
              onSubmit={handleSubmit(onSubmit)}
              style={{
                textAlign: "center"
              }}>
                <h2 className='seminarTitle' style={{ fontSize: "24px" }}>{selectedSeminar.name}</h2>
                <p className='seminarJoiners' style={{ fontSize: "14px" }}>{selectedSeminar.description}</p>
                <p className='seminarSpeaker' style={{ fontSize: "14px" }}>Speaker: {selectedSeminar?.createdBy?.firstName} {selectedSeminar?.createdBy?.lastName}</p>
                <p className='seminarJoiners'>{selectedSeminar?.attendees?.length} joined</p>
                <p className='infoLabelClassroom'>Date and time:</p>
                {getDateAndTimeRange(selectedSeminar.start,selectedSeminar.end)}
                {
                selectedSeminar.price ?
                <p className='registrationFee'>Registration Fee: <span className="price">${selectedSeminar.price}</span></p> :
                <p className='registrationFee'>Free Registration</p>
                }
                <Stack
                spacing={1}
                direction="row"
                justifyContent="center"
                sx={{ padding: "16px 16px 8px 16px" }}>
                  { isRegistered() ? "" : <Button type="submit" variant="contained">Register</Button>}
                  <Button
                    onClick={()=>onClose()}
                    type="button"
                    variant="contained">Cancel</Button>
                </Stack>
              </form>
            }
          </> : ""
          }
        </Box>
      </Modal>
      </LocalizationProvider>
    </div>
  </>, document.getElementById("join-seminar-form-wrapper-portal"))
}

export default JoinSeminarForm;