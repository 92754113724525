//import CreateNote from '.components/CreateNote/CreateNote';//cut it and paste to the component
import React, {useState,useEffect,Fragment,useRef,memo} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import ColorSelection from '../ColorSelection/ColorSelection';
import {
    actionCreateNotes
} from '../../app/Reducers/note/noteSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

const schema = yup.object().shape({
    title: yup.string().required(),
    description: yup.string().default(""),
    content: yup.string().default("")
});


const CreateNote = ({
    createNotesOpen,
    setCreateNotesOpen
}) => {

    const dispatch = useDispatch();
    const { notesFor } = useSelector(state => state.note);
    const [colorData, setColorData] = useState(
        { name: "Default", color: "#f4f6f8", textColor: "#252423" }
    )

    // form setup
    const { control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
        defaultValues: {
            title: "",
            description: ""
        },
        resolver: yupResolver(schema)
    });

    // reset the form after successfull submit of form data
    useEffect(() => {
        if (isSubmitSuccessful) {
            setCreateNotesOpen(false)
            // reset({ title: "", description: "" });
        };
    }, [isSubmitSuccessful, reset]);

    // on form submit
    const onSubmit = ({title,description}) => {
        dispatch(actionCreateNotes({
            title,
            description,
            content: "",
            notesFor: notesFor,
            colorData
        }))
        setCreateNotesOpen(false)
    }
    
    const onChangeColor = colorData => {
        setColorData(colorData)
    }

    return <div className='CreateNoteContainer'>
        <Modal
            keepMounted
            open={createNotesOpen}
            onClose={()=>setCreateNotesOpen()}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style} spacing={2}>
                <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                style={{marginBottom:10}}>

                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        {`Create Note`}
                    </Typography>

                    <CloseIcon 
                    className="close-form-trigger"
                    onClick={()=>setCreateNotesOpen(false)}/>

                </Stack>
                <form className="Notes-create-form" onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>

                        <Controller
                        name="title"
                        control={control}
                        defaultValue={""}
                        render={({field}) => <TextField
                            {...field}
                            className="textArea"
                            label="Notes Title"
                            variant="outlined"
                            // inputRef={input => input && setTimeout(() =>{
                            //     input.focus()
                            // },100)}
                            placeholder={"Notes Title"}
                            error={!!errors.title}
                            type="text"
                            multiline
                            helperText={errors?.title ? errors?.title?.message : ''}
                        />
                        }/>
                        
                        <Controller
                            name="description"
                            control={control}
                            defaultValue={""}
                            render={({field}) => <TextField
                                {...field}
                                className="textArea"
                                label="Notes Description"
                                variant="outlined"
                                placeholder={"Notes Description"}
                                error={!!errors.description}
                                type="text"
                                multiline
                                helperText={errors?.description ? errors?.description?.message : ''}
                            />
                        }/>
                        <div className="color-container">
                            <p>Note color: </p>
                            <span
                                style={{
                                    backgroundColor: colorData.color,
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "20px",
                                    fontSize: "0",
                                    marginRight: "10px"
                                }}
                            >.</span>
                            {colorData.name}
                            <div className="selection-wrapper">
                                <ColorSelection onChangeColor={onChangeColor} />
                            </div>
                        </div>

                        <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end">
                            <Button type="submit" variant="outlined">Save</Button>
                            <Button onClick={()=>setCreateNotesOpen(false)} type="button" variant="outlined">Cancel</Button>
                        </Stack>

                    </Stack>
                </form>
            </Box>
        </Modal>
    </div>
}

export default CreateNote;