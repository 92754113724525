//import RegistrationForm from '.components/RegistrationForm/RegistrationForm';//cut it and paste to the component
import React from 'react';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {
	actionRegister
} from '../../app/Reducers/user/userSlice';
import Stack from '@mui/material/Stack';
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
	firstName:
		yup
		.string()
		.required("Provide last name"),
	lastName:
		yup
		.string()
		.required("Provide last name"),
	teamsId:
		yup
		.string(),
	email:
		yup
		.string()
		.required("Provide valid email"),
	role:
		yup
		.string(),
	password: 
		yup
		.string()
		.required('No password provided.') 
		.min(8, 'Password is too short - should be 8 chars minimum.'),
	confirmPassword:
		yup
		.string()
		.required("Confirm password required")
		.oneOf([yup.ref('password')], 'Passwords does not match'),
});

const RegistrationForm = ({
	
}) => {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const {
		popupMessage
	} = useSelector(state=>state.user)

	// form setup
	const { control, handleSubmit, reset, formState: {errors,isSubmitSuccessful} } = useForm({ 
		defaultValues: {
			firstName: "",
			lastName: ""
		},
		resolver: yupResolver(schema)
	});

	const onSubmit = RegistrationFormDetails => {
		dispatch(actionRegister(RegistrationFormDetails))
	}

	return <div className='RegistrationFormContainer'>
		<form className="moment-create-form form-container" onSubmit={handleSubmit(onSubmit)}>
			<h1 className="title">Registration Form</h1>
			<Stack spacing={2}>
				<Stack 
				direction="row"
				alignItems="center"
				spacing={2}>
					<RHFTextField
						control={control}
						name="firstName"
						label="First Name"
						placeholder="First Name"
						error={!!errors.firstName}
						helperText={errors?.firstName ? errors?.firstName?.message : ''}
					/>
					<RHFTextField
						control={control}
						name="lastName"
						label="Last Name"
						placeholder="Last Name"
						error={!!errors.lastName}
						helperText={errors?.lastName ? errors?.lastName?.message : ''}
					/>
				</Stack>
				<Stack 
				direction="column"
				alignItems="center"
				spacing={2}>
					<RHFTextField
						control={control}
						name="email"
						label="Email"
						placeholder="Email"
						error={!!errors.email}
						helperText={errors?.email ? errors?.email?.message : ''}
						type="email"
					/>
					<RHFTextField
						control={control}
						name="password"
						label="Password"
						placeholder="Password"
						error={!!errors.password}
						helperText={errors?.password ? errors?.password?.message : ''}
						type="password"
					/>
					<RHFTextField
            control={control}
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Confirm Password"
            error={!!errors.confirmPassword}
            helperText={errors?.confirmPassword ? errors?.confirmPassword?.message : ''}
            type="password"
          />
				</Stack>
				<Stack
				spacing={1}
				direction="row"
				justifyContent="center">
					<Button type="submit" variant="contained">Register</Button>
					<Button onClick={()=>navigate("/")} type="button" variant="contained">Cancel</Button>
				</Stack>
			</Stack>
		</form>
	</div>
}

export default RegistrationForm;