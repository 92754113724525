import { createSlice } from '@reduxjs/toolkit';

/* Just a test code... remove it afterwards */ 
const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;
/* Just a test code... remove it afterwards */

const initialState = {
    isNavigationOpen: false,
    minimizeMenu: false,
    showTodoList: false,
    showNotes: false,
    showNoteFilter: false,
	taskAndPostDashboardGridView: true,
	postGridView: false,
    showUserPostsWithPointsData: false
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
		setTaskAndPostDashboardView: (state, action) => {
			state.taskAndPostDashboardGridView = action.payload
		},
		setPostGridView: (state, action) => {
			state.postGridView = action.payload
		},
        openNavigation: (state,action) => {
            state.isNavigationOpen = action.payload.navigationOpen
        },
        toggleMinimizedMenu: (state,action) => {
            state.minimizeMenu = action.payload;
        },
        toggleShowTodoList: (state,action) => {
            state.showTodoList = action.payload;
        },
        toggleNotes: (state,action) => {
            state.showNotes = action.payload;
        },
        toggleNotesFilter: (state,action) => {
            state.showNoteFilter = action.payload
        },
    }
});

export const {
    openNavigation,
    toggleMinimizedMenu,
    toggleShowTodoList,
    toggleNotes,
    toggleNotesFilter,
    setTaskAndPostDashboardView,
    setPostGridView,
} = layoutSlice.actions;

export const actionSetTaskAndPostDashboardView = view => dispatch => {
	dispatch(setTaskAndPostDashboardView(view))
}

export const actionSetPostGridView = view => dispatch => {
	dispatch(setPostGridView(view))
}

export const actionToggleNotesFilter = open => dispatch => {
    dispatch(toggleNotesFilter(open))
}

export const actionToggleNavigation = open => dispatch => {
    dispatch(openNavigation({
        navigationOpen: open
    }))
}

export const actionToggleMinimizedMenu = open => dispatch => {
    dispatch(toggleMinimizedMenu(open))
}

export const actionToggleShowTodoList = open => dispatch => {
    dispatch(toggleShowTodoList(open))
}

export const actionToggleNotes = open => dispatch => {
    dispatch(toggleNotes(open))
}
export default layoutSlice.reducer;