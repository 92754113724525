import React, { useState, useMemo, useRef, useCallback } from 'react';
import './PostInfiniteScroll.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  actionSetPostGridView
} from '../../app/Reducers/layout/layoutSlice';
import PostCards from '../../components/PostCards/PostCards';
import PostUpload from '../../components/PostUpload/PostUpload';
import { useSearchParams } from 'react-router-dom';
import GridViewIcon from '@mui/icons-material/GridView';
import ReorderIcon from '@mui/icons-material/Reorder';
import IconButton from '@mui/material/IconButton';
import UserDisabledOrNotExist from '../../components/404/UserDisabledOrNotExist';

const PostInfiniteScroll = ({
  posts,
  page,
  nextPage,
  hasNextPage,
  loading,
  enablePostLayoutChange = false,
  loadMoreCallback,
  group = null,
  classroom = null,
  enableCreatePost = true
}) => {

	const [showUploadForm, setShowUploadForm] = useState(false)
	const [params, setParams] = useSearchParams({ user: "" })
	const dispatch = useDispatch()
	const id = params.get("user")

	const {
		userData,
		isPostInfiniteScrollLoading,
		userDoesNotExist
	} = useSelector(state => state.user)

	const {
    postGridView
  } = useSelector(state => state.layout)

	const observer = useRef()

  const lastPostRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
		// this function will listen if the last element is intersected
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasNextPage) {
        loadMoreCallback(page+1)
					// dispatch(actionGetPosts(id ? id : userData._id,page+1))
      }
    })
    if (node) observer.current.observe(node)
  }, [nextPage])

	const allPosts = useMemo(() => {
		return posts ? posts.map(( post, i )=>{
			if(i===0){
				console.log(post.postDescription)
			}
			if (posts.length === i + 1) {
				return	<div
								key={post._id}
								className="cardItemWrapper"
								ref={lastPostRef}>
									<PostCards postData={post} minified={postGridView}/>
								</div>
			} else {
				return	<div
								key={post._id}
								className="cardItemWrapper">
									<PostCards postData={post} minified={postGridView}/>
								</div>
			}
		}) : ""
	},[posts,postGridView])

	return <div className='PostInfiniteScrollContainer'>
		{
		!userDoesNotExist ? <>
      {
      isPostInfiniteScrollLoading ? <>
        <div className="loading-wrapper">
          <div className="loader"></div>
        </div>
      </> : ""
      }
      {
        enableCreatePost ? 
          showUploadForm ? 
            <PostUpload
              setShowUploadForm={setShowUploadForm}
              group={group}
              classroom={classroom} /> :
            <div className='PostUploadTrigger'>
              <div
              className="cardHeader"
              style={{
                marginBottom: 0,
                width: "auto"
              }}>
                <div className="userPostImage">
                  <img src={userData?.profilePicture ? userData.profilePicture : '/Classroomtemp.jpg'} loading="lazy"/>
                </div>
              </div>
              <div className="postTriggerText" onClick={()=>setShowUploadForm(true)}>Want to share something?</div>
            </div>
          : ""
      }
      {
      enablePostLayoutChange ? 
      <div className="viewOptionWrapper">
        <IconButton onClick={()=>dispatch(actionSetPostGridView(!postGridView))}>
          {postGridView ? <GridViewIcon /> : <ReorderIcon />}
        </IconButton>
      </div>:""
      }
      <div className="postList">
        <div className={`postListContainer ${postGridView ? "gridView" : ""}`}>
          {allPosts}
        </div>
        {loading ? <p>Loading...</p> : ""}
      </div>
		</> : <UserDisabledOrNotExist/>
		}
	</div>
}

export default PostInfiniteScroll;