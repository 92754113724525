import { createSlice } from '@reduxjs/toolkit';
import Axios from "axios";
// import * as microsoftTeams from "@microsoft/teams-js";
import { getHeaders } from '../../helpers.js';
import CustomHTMLParser from '../../../components/Hooks/CustomHTMLParser'

/* Just a test code... remove it afterwards */ 
const URI = "https://wingenio.world";//process.env.DEV_MONGO_URL;
/* Just a test code... remove it afterwards */
// const token = localStorage.getItem("wstoken");
// const headers = {
//   headers: {
//	 "auth-token": token
//   }
// }

const initialState = {

	isProfileLoading: false,
	isUserRequestLoading: false,
	thirdPartyUserData: null,
	showAccountRegistration: false,
	userDoesNotExist: false,
	userData: null,
	accountExist: true,
	teamMembers: [],
	selectedUserData: null,
	idea:"",
	purpose:"",
	goals:"",
	tempUserData: null,
	verifyEmail: false,
	userGoalNoteList: [],
	invalidVerificationCode: false,
	showForgotPassword: false,
	showRegistration: false,
	redirect: false,
	defaultSelectedGroup: "",
	postsCount: 0,
	postsTotalPoints: 0,
	postsWithPointsCount: 0,
	todosCount: 0,
	todosTotalPoints: 0,
	todosWithPointsCount: 0,
	userTransactions: [],

	// Users with emergent index
	usersWithEmergentIndex: [],

	// Search user
	searchedUsersList: [],
	showRoleCandidates: false,
	activeRoleType: 0,

	// Roles
	createRoleFormOpen: false,
	roleList: [],

	// Follower, Following, Suggestions, Disabled Accounts
	suggestedUsers: [],
	followers: [],
	following: [],
	superAdmins: [],
	admins: [],
	moderators: [],
	disabledAccounts: [],

	// notifications
	notifications: [],

	// popup messages: 
	popupType: "error",
	popupOpen: false,
	popupMessage: "",
};

const getGoalsArray = parsedHtml => {
	const goalsStr = CustomHTMLParser(parsedHtml)
	if(goalsStr.length){
		const goalsJson = JSON.parse(goalsStr)
		if(goalsJson?.children?.length){
			const goalsInNotes = goalsJson.children
								.filter(({children}) => {
									const text = children[0]?.textContent ? children[0].textContent.trim() : ""
									return text.length
								})
								.map(({children})=>{
									return {
										name: children[0].textContent,
										status: 0
									}
								});
			return goalsInNotes
		}
	}
	return []
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setNotifications: (state, action) => {
			state.notifications = action?.payload?.docs || []
		},
		setShowRegistrationForm: (state, action) => {
			state.showRegistration = action.payload
			state.isUserRequestLoading = false
		},
		setShowForgotPasswordForm: (state, action) => {
			state.showForgotPassword = action.payload
			state.isUserRequestLoading = false
		},
		setVerificationCodeInvalid: (state, action) => {
			state.invalidVerificationCode = true
			state.isUserRequestLoading = false
		},
		setProfileLoading: (state, action) => {
			state.isProfileLoading = true
		},
		setThirdPartyUserData: (state, action) => {
			state.thirdPartyUserData = action.payload
		},
		setUserData: (state, action) => {
			state.userData = action.payload
			
			state.showAccountRegistration = false
			state.accountExist = true
			state.selectedUserData = action.payload
			state.userGoalNoteList = getGoalsArray(action.payload?.goals ? action.payload.goals : "")
		},
		updateUserFollowing: (state, action) => {
			state.userData = {
				...state.userData,
				following: action.payload.following
			}
		},
		removeFollower: (state, action) => {
			state.userData = {
				...state.userData,
				following: [...state.userData.following].filter(userId=>userId != action.payload.unfollowedUser)
			}
		},
		updateUserSystemRole: (state, action) => {
			state.selectedUserData = {
				...state.selectedUserData,
				systemRole: action.payload.systemRole,
				systemRoleAssignedBy: action.payload.systemRoleAssignedBy,
			}
			// remove the previous role data of the selected user
			const superAdmins = [...state.superAdmins].filter(user => user._id != action.payload._id)
			const admins = 			[...state.admins].filter(user => user._id != action.payload._id)
			const moderators = 	[...state.moderators].filter(user => user._id != action.payload._id)

			if(action.payload.systemRole == 10){
				state.superAdmins = [...superAdmins, action.payload]
				state.admins = admins
				state.moderators = moderators
			}
			else if(action.payload.systemRole = 1){
				state.superAdmins = superAdmins
				state.admins = [...admins, action.payload]
				state.moderators = moderators
			}
			else if(action.payload.systemRole = 2){
				state.superAdmins = superAdmins
				state.admins = admins
				state.moderators = [...moderators, action.payload]
			}

		},
		updateUserFollowersAndFollowings: (state, action) => {
			console.log(action.payload)
			state.followers = action.payload.followers
			state.following = action.payload.following
		},
		disableUser: (state, action) => {
			state.followers = state.followers.filter(user => user._id != action.payload._id)
			state.following = state.following.filter(user => user._id != action.payload._id)
			state.suggestedUsers = state.suggestedUsers.filter(user => user._id != action.payload._id)
		},
		enableDisabledUser: (state, action) => {
			state.disabledAccounts = state.disabledAccounts.filter(user => user._id != action.payload._id)
		},
		setDefaultSelectedGroup: (state, action) => {
			const defaultSelectedGroup = action.payload?.defaultSelectedGroup ? action.payload.defaultSelectedGroup : null;
			if(defaultSelectedGroup){
				state.userData = {
					...state.userData,
					defaultSelectedGroup		
				}
			} else {
				const tempUserData = {...state.userData}
				delete tempUserData.defaultSelectedGroup
				state.userData = tempUserData;
			}
		},
		setAccountExist: (state, action) => {
			state.accountExist = action.payload.userExist
			state.userData = action.payload.userExist ? action.payload.userData : null
		},
		setShowRegistration: (state, action) => {
			state.showAccountRegistration = action.payload
		},
		setShowPopUpMessage: (state, action) => {
			state.popupMessage = action.payload
		},
		setTeamMembers: (state, action) => {
			state.teamMembers = action.payload.sort((a, b) => {
				const firstNameA = a.firstName.toLowerCase();
				const firstNameB = b.firstName.toLowerCase();
				if (firstNameA < firstNameB) {
						return -1;
				}
				if (firstNameA > firstNameB) {
						return 1;
				}
				return 0;
		});
		},
		setSelectedUserData: (state,action) => {
			state.selectedUserData = action.payload
			state.isProfileLoading = false
			state.userDoesNotExist = false
		},
		setGoals: (state,action) => {
			console.log(action.payload)
			state.userData = {
				...state.userData,
				goals: action.payload.goals
			}
			state.selectedUserData={
				...state.selectedUserData,
				goals: action.payload.goals
			}
		},
		setProfilePicture: (state,action) => {
			state.userData = {
				...state.userData,
				profilePicture: action.payload.profilePicture
			}
			state.selectedUserData = {
				...state.selectedUserData,
				profilePicture: action.payload.profilePicture
			}
			state.isProfileLoading = false
		},
		setTimelinePicture: (state,action) => {
			state.userData = {
				...state.userData,
				timelinePicture: action.payload.timelinePicture
			}
			state.selectedUserData = {
				...state.selectedUserData,
				timelinePicture: action.payload.timelinePicture
			}
			state.isProfileLoading = false
		},
		setShowVerifyEmail: (state,action) => {
			state.verifyEmail = action.payload
		},
		setTempUserData: (state,action) => {
			state.tempUserData = action.payload
			state.verifyEmail = true
			state.isUserRequestLoading = false
		},
		setUserPopUpMessage: (state, action) => {
			const {
					popupOpen,
					popupType,
					popupMessage
			} = action.payload;

			state.popupOpen = popupOpen ? popupOpen : false;
			if(popupOpen){
					if(popupType) state.popupType = popupType
					if(popupMessage) state.popupMessage = popupMessage
			}
			state.isUserRequestLoading = false
		},
		redirectToHome: (state, action) => {
			state.redirect = action.payload;
		},
		setUserRequestLoading: (state, action) => {
			state.isUserRequestLoading = action.payload
		},
		toggleCreateRoleForm: (state, action) => {
			state.createRoleFormOpen = action.payload
		},
		setPeopleYouMayKnow: (state, action) => {
			state.suggestedUsers = action.payload
		},
		setRoleList: (state, action) => {
			state.roleList = action.payload
		},
		appendInRoleList: (state, action) => {
			state.roleList = [...state.roleList, action.payload]
			state.createRoleFormOpen = false
		},
		setSearchedUsersList: (state, action) => {
			state.searchedUsersList = action.payload
		},
		toggleRoleCandidates: (state, action) => {
			state.showRoleCandidates = action.payload.showCandidates
			state.activeRoleType = action.payload.roleType
		},
		setSystemRoleAssignee: (state, action) => {
			state.superAdmins = action.payload.superAdmins
			state.admins = action.payload.admins
			state.moderators = action.payload.moderators
		},
		setEmergentIndex: (state, action) => {
			const {
				userPostsPointsData,
				userTodoPointsData
			} = action.payload;
			console.log(action.payload)
			state.postsCount =						userPostsPointsData[0] ? userPostsPointsData[0].postsCount : 0
			state.postsTotalPoints =			userPostsPointsData[0] ? userPostsPointsData[0].postsTotalPoints : 0
			state.postsWithPointsCount =	userPostsPointsData[0] ? userPostsPointsData[0].postsWithPointsCount : 0
			state.todosCount =						userTodoPointsData[0] ? userTodoPointsData[0].todosCount : 0
			state.todosTotalPoints =			userTodoPointsData[0] ? userTodoPointsData[0].todosTotalPoints : 0
			state.todosWithPointsCount =	userTodoPointsData[0] ? userTodoPointsData[0].todosWithPointsCount : 0
		},
		setUsersWithEmergentIndex: (state, action) => {
			state.usersWithEmergentIndex = action.payload.map(user=>{
				const todosTotalPoints = user?.todosPoints?.todosTotalPoints || 0
				const todosWithPointsCount = user?.todosPoints?.todosWithPointsCount || 0
				const postsTotalPoints = user?.postsPoints?.postsTotalPoints || 0
				const postsWithPointsCount = user?.postsPoints?.postsWithPointsCount || 0
				return {
					...user,
					todosPoints: {
						...user.todosPoints,
						emergentIndex: ((todosTotalPoints+postsTotalPoints)/(todosWithPointsCount+postsWithPointsCount))
					}
				}
			}).sort((a, b) => (b?.todosPoints?.emergentIndex || 0) - (a?.todosPoints?.emergentIndex || 0));
		},
		setUserDoesNotExist: (state,action) => {
			state.userDoesNotExist = true
			state.isProfileLoading = false
		},
		setDisabledAccounts: (state,action) => {
			state.disabledAccounts = action.payload
		},
		setUserTransactions: (state,action) => {
			state.userTransactions = action.payload
		},
	}
});

export const {
	setThirdPartyUserData,
	setUserData,
	setAccountExist,
	setShowRegistration,
	setShowPopUpMessage,
	setTeamMembers,
	setSelectedUserData,
	setGoals,
	setProfilePicture,
	setTimelinePicture,
	setProfileLoading,
	setTempUserData,
	setUserPopUpMessage,
	setVerificationCodeInvalid,
	setShowForgotPasswordForm,
	setShowRegistrationForm,
	redirectToHome,
	setUserRequestLoading,
	setShowVerifyEmail,
	setDefaultSelectedGroup,
	toggleCreateRoleForm,
	appendInRoleList,
	setPeopleYouMayKnow,
	updateUserFollowing,
	updateUserFollowersAndFollowings,
	setRoleList,
	setSearchedUsersList,
	toggleRoleCandidates,
	updateUserSystemRole,
	setSystemRoleAssignee,
	removeFollower,
	setEmergentIndex,
	setUsersWithEmergentIndex,
	setNotifications,
	disableUser,
	enableDisabledUser,
	setUserDoesNotExist,
	setDisabledAccounts,
	setUserTransactions,
} = userSlice.actions;

const showErrorType = (err,dispatch) => {
	if (err.response) {
		dispatch(setUserPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: err.response.data
			}
		))
	} else if (err.request) {
		console.log(err.response)
		dispatch(setUserPopUpMessage(
			{
				popupOpen: true,
				popupType: "error",
				popupMessage: 'Server error. No response received, request may have timed out, been blocked or server is down.'
			}
		))
	} else {
		dispatch(setUserRequestLoading(false))
		console.log('Error:', err.message);
	}
}

export const actionGetDisabledAccounts = ({
	page = 1
}) => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	const params = `?page=${page}`
	try{
		const res = await Axios.get(
			`${URI}/users/get-disabled-users${params}`,
			headers
		)
		console.log(res.data)
		dispatch(setDisabledAccounts(res.data))
	} catch (e) {
		console.log(e)
	}
}

export const actionGetNotifications = page => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	const params = `?page=${page}`
	try{
		const res = await Axios.get(
			`${URI}/notification/getNotificationsList${params}`,
			headers
		)
		console.log(res.data)
		dispatch(setNotifications(res.data))
	} catch (e) {
		console.log(e)
	}
}


export const actionCreateRole = data => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return
		
	try{
		const res = await Axios.post(
			`${URI}/role/create-role`,
			data,
			headers
		)
		dispatch(appendInRoleList(res.data))
	} catch (e) {
		console.log(e)
	}
}

export const actionsShowUserSelection = (
	show = false,
	roleType = 0
) => async dispatch => {
	dispatch(toggleRoleCandidates({
		showCandidates: show,
		roleType
	}))
}

export const actionToggleCreateRoleForm = (show = true) => dispatch => {
	dispatch(toggleCreateRoleForm(show))
}

export const toggleVerifyEmail = show => dispatch => {
	dispatch(setShowVerifyEmail(show))
}

export const actionToggleRegistrationForm = show => dispatch => {
	dispatch(setShowRegistrationForm(show))
}

export const actionHideForgotPassword = () => dispatch => {
	dispatch(setShowForgotPasswordForm(false))
}

export const actionRequestForgotPassword = email => async (dispatch,getState) => {
	try{
		dispatch(setUserRequestLoading(true))
		const res = await Axios.put(
			`${URI}/auth/request-forgot-password`,
			{email},
		)
		dispatch(setShowForgotPasswordForm(true))
	} catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionSendVerificationEmailRequest = () => async (dispatch,getState) => {

	const {user} = getState();
	const email = user?.tempUserData?.email ? user.tempUserData.email : null;

	if(email){
		try{
			dispatch(setUserRequestLoading(true))
			const res = await Axios.post(
				`${URI}/auth/send-verification-email`,
				{email},
			)
			
			dispatch(setUserPopUpMessage(
				{
					popupOpen: true,
					popupType: "success",
					popupMessage: res.data
				}
			))
		} catch(err) {
			showErrorType(err)
			dispatch(setVerificationCodeInvalid())
		}
	}
}

export const actionSetGoals = goals => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return
		
	console.log(goals)

	try{
		const res = await Axios.post(
			`${URI}/users/set-goals/`,
			{content: goals},
			headers
		)
		dispatch(setGoals(res.data))
	} catch (e) {
		console.log(e)
	}
}

export const actionGetUserProfile = userId => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return
	dispatch(setProfileLoading())
	try{
		const res = await Axios.get(
			`${URI}/users/get-user-profile/${userId}`,
			headers
		)

		console.log(res.data)
		dispatch(setSelectedUserData(res.data))
	} catch (e) {
		dispatch(setUserDoesNotExist(true))
		console.log(e)
	}

}

export const actionVerifyEmail = verificationCode => async (dispatch,getState) => {
	const {user} = getState();
	const email = user?.tempUserData?.email ? user.tempUserData.email : null;

	try{
		dispatch(setUserRequestLoading(true))
		const res = await Axios.post(
			`${URI}/auth/verify-email`,
			{
				email,
				verificationCode
			}
		)
		localStorage.setItem("wstoken", res.data.token)
		dispatch(setUserData(res.data.userData))
		return "success"
	} catch(err) {
		dispatch(setVerificationCodeInvalid())
		console.log(err)
		return "failed"
	}
}

export const actionRegister = thirdPartyUserData => async dispatch => {
	try{
		const res = await Axios.post(
						`${URI}/auth/registration`,
						thirdPartyUserData
					)
		dispatch(setTempUserData(res.data))
	}catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionChangePassword = ({
	email,
	verificationCode,
	password
}) => async (dispatch) => {
	try{
		const res = await Axios.put(
			`${URI}/auth/change-password`,
			{
				email,
				verificationCode,
				password
			}
		)
		localStorage.setItem("wstoken", res.data.token)
		dispatch(redirectToHome(true))
		setTimeout(()=>{
			dispatch(redirectToHome(false))
			dispatch(setUserData(res.data.userData))
		},200)
	} catch (err) {
		dispatch(setVerificationCodeInvalid())
	}
}

export const actionLogin = credentials => async dispatch => {
	try{
		
		dispatch(setUserRequestLoading(true))
		const res = await Axios.post(
			`${URI}/auth/login`,
			credentials
		)
		if(res?.data?.userData?.verified){
			localStorage.setItem("wstoken", res.data.token)
			dispatch(setUserData(res.data.userData))
		}
		else if(res?.data?.userData){
			dispatch(setTempUserData(res.data.userData))
		}
	} catch(err){
		showErrorType(err, dispatch)
	}
}

export const actionChangeProfilePicture = (data,file) => async (dispatch,getState) => {
		
	let headers = getHeaders();
	if(headers === null) return

	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	// console.log(file)

	try{
		let formData = new FormData();
		for (const [key, value] of Object.entries(data)) {
			formData.append(key, value);
		}

		if(file){ formData.append('image', file); }

		const res = await Axios.put(
			`${URI}/users/change-profile-picture`,
			formData,
			headers
		)

		dispatch(setProfileLoading())

		setTimeout(() => {
			dispatch(setProfilePicture(res.data))   
		}, 1000);
	} catch(err){
		showErrorType(err, dispatch)
	}
}		

export const actionChangeProfileTimelinePicture = (data,file) => async (dispatch,getState) => {
		
	let headers = getHeaders();
	if(headers === null) return

	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	try{
		let formData = new FormData();
		for (const [key, value] of Object.entries(data)) {
			formData.append(key, value);
		}
		if(file){ formData.append('image', file); }

		dispatch(setProfileLoading())

		const res = await Axios.put(
			`${URI}/users/change-timeline-picture`,
			formData,
			headers
		)
		
		setTimeout(() => {
			dispatch(setTimelinePicture(res.data))  
		}, 1000);

	} catch(err){ 
		showErrorType(err, dispatch)
	}
}

export const actionLogout = () => dispatch => {
	localStorage.clear()
	window.location.href = "/";
}

export const actionAuthenticateToken = () => async dispatch => {
	const headers = getHeaders();
	if(headers === null) { 
		alert("Please login your account")
		return 0
	}

	Axios.get(
		`${URI}/auth/authenticate-token`,
		headers
	).then( res => {
		if (res.statusText != 200) {
			console.log("network error here")
		}
		dispatch(setUserData(res.data))
	})
}

export const actionShowAccountRegistration = show => dispatch => {
	dispatch(setShowRegistration(show));
}

export const actionGetTeamMembers = () => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	// console.log("nandito sa actionGetTeamMembers")
	const res = await Axios.get(`${URI}/users/team-members`,headers)
	// console.log(res.data)
	dispatch(setTeamMembers(res.data))
}

export const actionSetSelectedUserData = selectedUserDataId => async dispatch => {
	
	const headers = getHeaders();
	if(headers === null) return

	dispatch(setProfileLoading())
	try{
		const res = await Axios.get(
			`${URI}/users/get-user-profile/${selectedUserDataId}`,
			headers
		)
		dispatch(setSelectedUserData(res.data))
	} catch (e) {
		console.log(e)
	}

}

export const actionSetDefaultSelectedGroup = groupId => async dispatch => {
	
	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.put(
			`${URI}/users/set-default-selected-group/${groupId}`,
			{},
			headers
		)
		dispatch(setDefaultSelectedGroup(res.data))
	} catch (e) {
		console.log(e)
	}

}

export const actionRemoveDefaultSelectedGroup = () => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.put(
			`${URI}/users/remove-default-selected-group`,
			{},
			headers
		)
		dispatch(setDefaultSelectedGroup(res.data))
	} catch (e) {
		console.log(e)
	}
}

export const actionHideUserPopupMessage = () => dispatch => {
	dispatch(setUserPopUpMessage({popupOpen: false}))
}

export const actionSearchUsers = (textSearch, options) => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	try{
		let params = "";
		if(options){
			params = `?page=${options.page}`;
		}

		const res = await Axios.get(
			`${URI}/users/search-users/${textSearch}${params}`,
			headers
		)
		dispatch(setSearchedUsersList(res.data))

	} catch (e) {
		console.log(e)
	}
}

export const actionGetPeaopleYouMayKnow = () =>async dispatch => {
	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.get(`${URI}/users/people-you-may-know`,headers)
		dispatch(setPeopleYouMayKnow(res.data))
	} catch (e) {
		console.log(e)
	}

}

export const actionFollowUser = userToFollow => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return
	try{
		
		const res = await Axios.put(
			`${URI}/users/follow-user`,
			{ userToFollow },
			headers
		)
		console.log(res.data)
		dispatch(updateUserFollowing(res.data))

	} catch (e) {
		console.log(e)
	}
}

export const actionUnfollowUser = userToUnfollow => async dispatch => {
	const headers = getHeaders();
	if(headers === null) return
	try{
		
		const res = await Axios.put(
			`${URI}/users/unfollow-user`,
			{ userToUnfollow },
		headers
		)
		
		dispatch(removeFollower(res.data))

	} catch (e) {
		console.log(e)
	}
}

export const actionGetFollowersAndFollowing = () => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	try{
		
		const res = await Axios.get(
			`${URI}/users/get-followers-and-following`,
			headers
		)
		dispatch(updateUserFollowersAndFollowings(res.data))

	} catch (e) {
		console.log(e)
	}

}

export const actionGetSystemRoleAssigneeList = () => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	console.log("dumaan here")
	try{
		const res = await Axios.get( `${URI}/users/get-system-role-assignee-list`, headers )
		console.log(res.data)
		dispatch(setSystemRoleAssignee(res.data))
	} catch (e) {
		console.log(e)
	}
	
}

export const actionSetUserSystemRole = (
	selectedUserId,
	systemRoleType
) => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.put(
			`${URI}/users/set-user-system-role`,
			{
				selectedUserId,
				systemRoleType
			},
			headers
		)
		console.log(res.data)
		dispatch(updateUserSystemRole(res.data))
	} catch (e) {
		console.log(e)
	}
	
}

export const actionGetUserEmergentIndex = (
	userId
) => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.get(
			`${URI}/users/get-user-emergent-index/${userId}`,
			headers
		)
		
		dispatch(setEmergentIndex(res.data))
	} catch (e) {
		console.log(e)
	}
	
}

export const actionGetUsersWithEmergentIndex = (
	
) => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.get(
			`${URI}/users/get-users-with-emergent-index`,
			headers
		)
		dispatch(setUsersWithEmergentIndex(res.data))
	} catch (e) {
		console.log(e)
	}
	
}

export const actionGetUserTransactions = (
	userId
) => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.get(
			`${URI}/transaction/get-transactions/${userId}`,
			headers
		)
		dispatch(setUserTransactions(res.data))
	} catch (e) {
		console.log(e)
	}
	
}

export const actionDisableUser = (userId) => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.put(
			`${URI}/users/disable-user/${userId}`,
			{},
			headers
		)
		console.log(res.data)
		// remove the disabled user in the list
		dispatch(disableUser(res.data))
	} catch (e) {
		console.log(e)
	}
	
}

export const actionEnableDisabledUser = (userId) => async dispatch => {

	const headers = getHeaders();
	if(headers === null) return

	try{
		const res = await Axios.put(
			`${URI}/users/enable-disabled-user/${userId}`,
			{},
			headers
		)
		console.log(res.data)
		// remove the disabled user in the list
		dispatch(enableDisabledUser(res.data))
	} catch (e) {
		console.log(e)
	}
	
}

export const uploadTradeHistoryFile = (file) => async dispatch => {

	let headers = getHeaders();
	if(headers === null) return

	headers = {
		...headers,
		'Content-Type': 'multipart/form-data'
	}

	try{
		let formData = new FormData();
		if(file){ formData.append('tradehistoryfile', file); }

		console.log(formData)

		const res = await Axios.put(
			`${URI}/users/upload-trade-file`,
			formData,
			headers
		)

		console.log(res.data)


	} catch (e) {
		console.log(e)
	}
	
}

export const thirdPartyUserData = state => state.user.thirdPartyUserData;
export const calendarSchedules = state => state.user.thirdPartyUserData;

export default userSlice.reducer;