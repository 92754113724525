import axios from "axios";

const KEY = "AIzaSyAG3_7gb4c6fSQLG124h0bo4Kzrv4yG6yY";

export default axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3/',
    params: {
        part: "snippet",
        maxResults: 3,
        key: KEY
    }
})