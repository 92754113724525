//import BigBroSuggestion from '.components/BigBroSuggestion/BigBroSuggestion';//cut it and paste to the component
import React, {useState} from 'react';
import './BigBroSuggestion.scss';
import RioChatBox from "../../components/ChatBox/RioChatBox"
import youtube from "../../apis/youtube";
import ConfirmationDialogRaw from '../../components/ConfirmationDialogRaw/ConfirmationDialogRaw'
import { useSelector, useDispatch } from 'react-redux';
import {
	actionSendMessage
} from '../../app/Reducers/chats/chatsSlice';
import {
	// actionAddTodoInList,
	actionAddTodoAndStart
  } from '../../app/Reducers/todo/todoSlice';
  import {v1 as uuid} from "uuid";


const BigBroSuggestion = ({
	
}) => {
    const conversationURL = "https://wingenio.world/bigbro";
    const [suggestions,setSuggestions] = useState([]);
	const { chatList, subjects, links } = useSelector(state => state.chats);
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const [youtubeData, setyoutubeData] = useState(null)
	const [linkData, setlinkData] = useState(null)
	const [dialogTitle, setDialogTitle] = useState("")
	const [dialogDescription, setDialogDescription] = useState("")
	const [dialogFor, setDialogFor] = useState("video")
	const { botData } = useSelector(state => state.chats);

	const createTodoOnWatch = () => {
		console.log(youtubeData)
		setDialogFor("video")

		dispatch(actionAddTodoAndStart({
            name: `Watch ${youtubeData.snippet.title}`,
            description: `
			${youtubeData.snippet.description}
			Link: https://www.youtube.com/watch?v=${youtubeData.id.videoId}
			`
        }))

		window.open(`https://www.youtube.com/watch?v=${youtubeData.id.videoId}`)
	}

	const createTodoOnRead = () => {
		dispatch(actionAddTodoAndStart({
            name: `Reading this link ${linkData}`,
            description: `${linkData}`
        }))
		window.open(`${linkData}`)
	}

	const openWatchDialogBox = (suggestion) => {
		setOpen(true)
		setyoutubeData(suggestion)
		setDialogTitle("Create todo for watching this video?")
		setDialogDescription("After watching this, make sure you end your todo in calendar and start your previous task.")
	}

	const openReadDialogBox = link => {
		setDialogFor("link")
		setlinkData(link)
		setOpen(true)
		setDialogTitle("Create todo for reading this link?")
		setDialogDescription("After reading this, make sure you end your todo in calendar and start your previous task.")
	}

	const getLinkSuggestion = link => {
		return	<div key={uuid()} className="message">
					<p>Link: {link}</p>
					<button
					className="watch"
					type="button"
					onClick={()=>openReadDialogBox(link)}>
						Read the link
					</button>
				</div>
	}

    const getYoutubeSuggestion = async () => {

		if(subjects.length === 0)
			return 0

		const query = subjects[0].at(-1)
		console.log("-----------------------")
		console.log(subjects)
		console.log(query)

		/**/
		const response = await youtube.get('/search',{
			params: {
				q: query
			}
		})

		console.log(response.data)
		let suggestions = "";
		if(response.data.items){
			suggestions = response.data.items.map(suggestion=>(
				<div key={suggestion.id.videoId} className="suggestion-container">
					<img className="yt-suggestion-image"
					src={suggestion.snippet.thumbnails.medium.url} alt={suggestion.snippet.title} />
					<p className="yt-suggestion-title">{suggestion.snippet.title}</p>
					<p className="yt-suggestion-description">{suggestion.snippet.description}</p>
					{/* <a href={`https://www.youtube.com/watch?v=${suggestion.id.videoId}`} className="">{`https://www.youtube.com/watch?v=${suggestion.id.videoId}`}</a> */}
					<button
					className="watch"
					type="button"
					onClick={()=>openWatchDialogBox(suggestion)}>
						Watch
					</button>
				</div>
			))
		}

		dispatch(actionSendMessage([{
			chat_id: "00000000000",
			name: "Jemuel",
			message: suggestions,
			isSender: false
		}]))

		setSuggestions(response.data.items);
		/**/
	}

    return <div className='BigBroSuggestionContainer'>
		<ConfirmationDialogRaw 
			onClose={()=>setOpen(false)}
			open={open}
			callBack={dialogFor === "video" ? createTodoOnWatch : createTodoOnRead}
			dialog={dialogTitle}
			description={dialogDescription}
		/>
        
        <div className="chatbox-container">
			<RioChatBox chatData = {botData} />
        </div>

		<div className="suggestion-list-container">
			<h2 onClick={()=>dispatch(actionSendMessage([{
				chat_id: "00000000000",
				name: "Jemuel",
				message: "Hi",
				isSender: true
			}]))}>Suggested Resources</h2>

		</div>
        
    </div>
}

export default BigBroSuggestion;