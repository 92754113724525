import React,{useEffect} from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RHFTextField from "../ReactHookFormElements/RHFTextField";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { actionEditTodoCategory } from "../../app/Reducers/todo/todoSlice";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 2,
};

const schema = yup.object().shape({
	name: yup.string().required("Provide valid name"),
	description: yup.string().required("Provide valid description"),
});

const EditTodoCategoryForm = ({
	selectedTodoCategory,
	setSelectedTodoCategory,
	showEditCategoryForm = false,
	setShowEditCategoryForm
}) => {
	const dispatch = useDispatch();

	// form setup
	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isSubmitSuccessful },
	} = useForm({
		defaultValues: {
			name: selectedTodoCategory ? selectedTodoCategory.name : "",
			description: selectedTodoCategory ? selectedTodoCategory.description : ""
		},
		resolver: yupResolver(schema),
	});

	const onSubmit = ({ name, description }) => {		
		dispatch(actionEditTodoCategory({
			_id: selectedTodoCategory._id,
			name,
			description
		}));
	};

	const resetValues = () => {
		setShowEditCategoryForm(false)
		setSelectedTodoCategory(null)
		reset({
			name: "",
			description: ""
		})
	}

	useEffect(() => {
		if (isSubmitSuccessful) {
			resetValues()
		};
	}, [isSubmitSuccessful, reset]);

	useEffect(() => {
		if(selectedTodoCategory){
			setValue("name", selectedTodoCategory.name)
			setValue("description", selectedTodoCategory.description)
		}
	}, [selectedTodoCategory]);

	return (
		<div className="EditTodoCategoryFormContainer form-wrapper">
			<Modal
				keepMounted
				open={showEditCategoryForm}
				onClose={()=>setShowEditCategoryForm(false)}
				aria-labelledby="keep-mounted-modal-title"
				aria-describedby="keep-mounted-modal-description"
			>
        <Box sx={style} spacing={2}>
					<form className="form-container" onSubmit={handleSubmit(onSubmit)}>

						<h2 className="title">Edit Note Category: "{selectedTodoCategory?.name}"</h2>

						<Stack direction="column" alignItems="center" spacing={2}>
							<RHFTextField
								control={control}
								name="name"
								label="name"
								placeholder="name"
								error={!!errors.name}
								helperText={errors?.name ? errors?.name?.message : ""}
							/>

							<RHFTextField
								control={control}
								name="description"
								label="description"
								placeholder="description"
								error={!!errors.description}
								helperText={errors?.description ? errors?.description?.message : ""}
							/>

							<Stack spacing={1} direction="row" justifyContent="center">
								<Button type="submit" variant="contained">
									Submit
								</Button>
								<Button type="button" variant="contained" onClick={()=>resetValues()}>
									Cancel
								</Button>
							</Stack>
						</Stack>
					</form>
				</Box>
			</Modal>
		</div>
	);
};

export default EditTodoCategoryForm;
