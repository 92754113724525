import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionGetLatestPostsOfUsers
} from '../../app/Reducers/dashboard/dashboardSlice';
import PostCards from '../../components/PostCards/PostCards';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const UsersPostsWithoutPoints = ({
}) => {
	const dispatch = useDispatch();
  const {
    usersPostsWithoutPoints
  } = useSelector(state => state.dashboard);
  
  useEffect(()=>{
    dispatch(actionGetLatestPostsOfUsers())
  },[])


  return <div className={`UsersPostsWithoutPointsContainer`}>
    <Tooltip title={"Re-fetch Posts without points"}>
      <IconButton
      className="refreshButton"
      aria-haspopup="true"
      onClick={()=>dispatch(actionGetLatestPostsOfUsers())}>
        <RefreshIcon fontSize="large" />
      </IconButton>
    </Tooltip>
    <div className={`postListContainer gridView`}>
      {usersPostsWithoutPoints.map( userPost => {
        return  <PostCards
                  key={userPost._id}
                  postData={userPost}
                  minified={true}/>
      })}
    </div>
      
  </div>

}

export default memo(UsersPostsWithoutPoints);