//import ConfirmationModal from '.components/ConfirmationModal/ConfirmationModal';//cut it and paste to the component
import React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

const ConfirmationModal = ({
    open,
    setOpen,
    callback,
    confirmationText,
    children,
}) => {
    return <>
        <Modal
            keepMounted
            open={open}
            onClose={()=>setOpen()}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style} spacing={2}>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {confirmationText}
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
                    {children}
                </Typography>

                <Stack
                spacing={1}
                direction="row"
                justifyContent="flex-end">
                    <Button
                    onClick={()=>callback()}
                    button="submit"
                    variant="outlined">Save</Button>
                    <Button 
                    onClick={()=>setOpen(false)}
                    type="button"
                    variant="outlined">Cancel</Button>

                </Stack>
            </Box>
        </Modal> 
    </>
}

export default ConfirmationModal;