//import Auth from '.components/Auth/Auth';//cut it and paste to the component
import React, {useState} from 'react';
import './Auth.scss';
// import Registration from '../../components/Registration/Registration';
// import Login from '../../components/Login/Login';
import { ReactComponent as Logo } from '../../Assets/logo.svg';
import { NavLink, Outlet } from "react-router-dom";
import { ReactComponent as IC_Logo } from '../../Assets/ic_logo.svg';
// import PopupNotifications from '../../components/PopupNotifications/PopupNotifications';
import { useSelector, useDispatch } from 'react-redux';
import {
	actionHideUserPopupMessage
} from '../../app/Reducers/user/userSlice'

const Auth = ({
	children
}) => {
	const dispatch = useDispatch()
	// const handlePopupClose = () => {
	// 	dispatch(actionHideUserPopupMessage());
	// }

	return <div className='AuthContainer'>
		<div
		className="auth-form-wrapper-container"
		style={{
			
		}}>
			<div className="logo-container">
				<Logo/>
			</div>
			{children ? children : ""}
			<Outlet/>
		</div>
		<div className="login-page-info-wrapper">
			<div className="login-page-info-container">
				<div className="info-section-1">
					<div className="logo-wrapper">
						<IC_Logo/>
					</div>
					<p className="app-name">Hungry2Learn</p>
				</div>
				<div className="info-section-2">
					<ul className="features-info-list">
						<li>Work...</li>
						<li>Learn...</li>
						<li>Reflect...</li>
					</ul>
					<p className='info-text'>New interactive tool to help the team to grow and reach their goals while learning.</p>
					<NavLink className="nav-link" to="/privacy-policy">Privacy Policy</NavLink>
					<NavLink className="nav-link" to="/terms-of-service">Terms of service</NavLink>
				</div>
			</div>
		</div>
		{/* <PopupNotifications
		open={popupOpen} 
		handleClose={handlePopupClose}
		severity={popupType}>
				{popupMessage}
		</PopupNotifications> */}
	</div>
}

export default Auth;