import React, {memo} from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionToggleJoinSeminarFormOpen,
  actionToggleUpdateSeminarFormOpen,
  actionToggleSeminarAttendeesOpen
} from '../../app/Reducers/classroom/classroomSlice';
import { getDateAndTimeRange } from '../Hooks/Constants';

const SeminarItem = ({
	seminar,
  enableEdit=false
}) => {

  const {
    name,
    start,
    end,
    attendees
  } = seminar

  const {
		userData
	} = useSelector(state => state.user);

  const dispatch = useDispatch();

  const isRegistered = () => {
    const i = attendees.findIndex(attendeeId => attendeeId == userData._id)
    return i > -1 || userData._id == seminar.createdBy._id
  }

	return (
    <div
    className='seminarItem'
    onClick={()=>console.log("Show the join form here")}>
      <div className="wrapHeading">
        <p
        className='seminarTitle'
        onClick={()=>dispatch(actionToggleJoinSeminarFormOpen(true, seminar))}
        style={{
          cursor: "pointer"
        }}
        >{name}</p>
        {
        enableEdit && 
        <Tooltip title={"Update"}>
          <IconButton onClick={()=>dispatch(actionToggleUpdateSeminarFormOpen(true, seminar))}>
            <EditIcon sx={{ fontSize: "14px", color:"#00AB55"}}/>
          </IconButton>
        </Tooltip>
        }
      </div>
      <p
      className='seminarJoiners'
      onClick={()=>dispatch(actionToggleSeminarAttendeesOpen(true, seminar._id))}>{attendees.length} joined</p>
      <p className='infoLabelClassroom'>Date and time:</p>
      {getDateAndTimeRange(start,end)}
      {
      seminar.archived ? 
        <Button
        size="small"
        variant="contained"
        disabled
        sx={{marginTop: "8px", width: "100%"}}			
        >Archived</Button>
        :
        isRegistered() ? 
          <Button
          size="small"
          variant="contained"
          disabled
          sx={{marginTop: "8px", width: "100%"}}			
          >Registered</Button>
          :
          <Button
          size="small"
          variant="contained"
          onClick={()=>dispatch(actionToggleJoinSeminarFormOpen(true, seminar))}
          sx={{marginTop: "8px", width: "100%"}}			
          >Register</Button>
      }
    </div>
	)
}

export default memo(SeminarItem);