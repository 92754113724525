import React, { useState, useEffect } from 'react'
import './Comment.scss';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import { formatDateInfo, formatText } from '../Hooks/Constants';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import Axios from 'axios';
import { getHeaders } from '../../app/helpers';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import EditCommentForm from '../CommentForm/EditCommentForm';
import Tooltip from '@mui/material/Tooltip';

const URI = "https://wingenio.world";
const voteButtonStyle = {width:'1.25rem', heigth:'1.25rem', padding: 0}
const schema = yup.object().shape({
  comment: yup.string().required("Write your comment before submitting")
});

export const Comment = ({
  commentData,
  deleteComment
}) => {

  const [comment, setComment] = useState(commentData)
  const [showEditCommentForm, setShowEditCommentForm] = useState(false)
  const {
    userData
  } = useSelector(state=>state.user)

  const { control, handleSubmit, reset, setValue, formState: {errors,isSubmitSuccessful} } = useForm({ 
    defaultValues: {
      comment: ""
    },
    resolver: yupResolver(schema)
  });

  const upVote = async () => {
    const headers = getHeaders();
    if(headers === null) return
    try{
      const res = await Axios.put(`${URI}/comments/up-vote`,{ commentId: comment._id }, headers)
      setComment({
        ...comment,
        upVotes: res.data.upVotes,
	      downVotes: res.data.downVotes,
      })
    }
    catch(err){
      console.log(err)
    }
  }

  const downVote = async () => {
    const headers = getHeaders();
    if(headers === null) return
    try{
      const res = await Axios.put(`${URI}/comments/down-vote`,{ commentId: comment._id }, headers)
      setComment({
        ...comment,
        upVotes: res.data.upVotes,
	      downVotes: res.data.downVotes,
      })
    }
    catch(err){
      console.log(err)
    }
  }

  const removeVote = async () => {
    const headers = getHeaders();
    if(headers === null) return
    try{
      const res = await Axios.put(`${URI}/comments/remove-vote`,{ commentId: comment._id }, headers)
      setComment({
        ...comment,
        upVotes: res.data.upVotes,
	      downVotes: res.data.downVotes,
      })
    }
    catch(err){
      console.log(err)
    }
  }

  const getVotes = () => {
    const upVotes = comment?.upVotes ? comment.upVotes.length : 0
    const downVotes = comment?.downVotes ? comment.downVotes.length : 0
    const totalVotes = upVotes - downVotes;

    let color = "#212B36";
    if(totalVotes > 0)
      color = "#00AB55"
    else if(totalVotes < 0)
      color = "#d32f2f"

    return <p className='votesInfo' >Votes: <span style={{color}}>{totalVotes}</span></p>
  }

  const hasVotedUp = () => {
    if(comment?.upVotes)
      return comment.upVotes.findIndex(userId => userId == userData._id) > -1
    return false
  }

  const hasVotedDown = () => {
    if(comment?.downVotes)
      return comment.downVotes.findIndex(userId => userId == userData._id) > -1
    return false
  }

  const sendEdittedComment = async data => {
    /**/
    const headers = getHeaders();
    if(headers === null) return
  
    try{
      const res = await Axios.put(
          `${URI}/comments/edit-comment`,
          {
            ...data,
            commentId: comment._id
          },
          headers
      )
      setComment({
        ...comment,
        comment: res.data.comment
      })
      setShowEditCommentForm(false)
    }
    catch(err){
      console.log(err)
    }
  }

  const onSubmit = async ({comment}) => {
    sendEdittedComment({
      comment
    })
  }

  useEffect(() => {
		if (isSubmitSuccessful) {
			reset({ comment: "" });
		};
	}, [isSubmitSuccessful, reset]);
  

  return (
    <div className='CommentContainer'>
      <div className={`userCommentImage ${comment?.owner?.profilePicture ? "" : "noPicture"}`}>
        <NavLink to={`/profile?user=${comment?.owner._id}`}>
          <img src={comment?.owner.profilePicture ? comment.owner.profilePicture : '/profiletemp.jpg'} loading="lazy" alt={`${comment?.owner.firstName} ${comment?.owner.lastName}`}/>
        </NavLink>
      </div>
      <div className="commentInfoContainer">
        <div className="commentMessageWrapper">
          <div className="commentNameAndDateContainer">
            <p className="commenterName">
              <NavLink to={`/profile?user=${comment?.owner._id}`}>
                {comment?.owner.firstName} {comment?.owner.lastName}
              </NavLink>
            </p>
            <p className="commentDate">{formatDateInfo(comment.updatedAt)}</p>
          </div>
          {
          showEditCommentForm ? 
            <EditCommentForm
            control = {control}
            onSubmit = {onSubmit}
            errors = {errors}
            handleSubmit = {handleSubmit}
            setValue = {setValue}
            defaultValue = {comment.comment}/> :
            <p className="commentContent">{formatText(comment.comment)}</p>
          }
          
        </div>
        <div className="commentOptions">
          {/* <p className="commentOptionStyle" onClick={()=>console.log("Edit")}>Like</p>
          <p className="commentOptionStyle" onClick={()=>console.log("Edit")}>Edit</p> */}
          
          {userData._id === comment.owner._id ? <>
            <p className="commentOptionStyle" onClick={()=>deleteComment(comment._id)}>Delete</p>
            {
            showEditCommentForm ?
              <p className="commentOptionStyle" onClick={()=>setShowEditCommentForm(false)}>Cancel</p>:
              <p className="commentOptionStyle" onClick={()=>setShowEditCommentForm(true)}>Edit</p>
            }
          </> : ""
          }
          <div className="commentVotes">
            {getVotes()}

            {
            comment.owner._id != userData._id ?
            <>
              {
              hasVotedUp() ?
              <Tooltip title={"Remove Vote"}>
                <IconButton onClick={removeVote} sx={voteButtonStyle}>
                  <ArrowDropUpIcon sx={{color:"#00AB55"}}/>
                </IconButton>
              </Tooltip> :
              <Tooltip title={"Up Vote"}>
                <IconButton onClick={upVote} sx={voteButtonStyle}>
                  <ArrowDropUpIcon sx={{color:"#637381"}}/>
                </IconButton>
              </Tooltip>
              }
              {
              hasVotedDown() ?
              <Tooltip title={"Remove Vote"}>
                <IconButton onClick={removeVote} sx={voteButtonStyle}>
                  <ArrowDropDownIcon sx={{color:"#d32f2f"}}/>
                </IconButton>
              </Tooltip> :
              <Tooltip title={"Down Vote"}>
                <IconButton onClick={downVote} sx={voteButtonStyle}>
                  <ArrowDropDownIcon sx={{color:"#637381"}}/>
                </IconButton>
              </Tooltip>
              }
            </> : ""
            }
          </div>
        </div>
      </div>
    
    </div>
  )
}

export default Comment;