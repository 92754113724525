//import Login from '.components/Login/Login';//cut it and paste to the component
import React from 'react';
import './LoginForm.scss';
import { useSelector, useDispatch } from 'react-redux';
import VerifyEmail from '../VerifyEmail/VerifyEmail';
import LoginForm from './LoginForm';
import PopupNotifications from '../PopupNotifications/PopupNotifications';
import {
	actionHideUserPopupMessage
} from '../../app/Reducers/user/userSlice'
const Login = ({
	setView
}) => {
	const {
		popupType,
		popupOpen,
		popupMessage,
		isUserRequestLoading,
		verifyEmail
	} = useSelector(state => state.user);
	
	const dispatch = useDispatch();

	const handlePopupClose = () => {
		dispatch(actionHideUserPopupMessage());
	}

	return <div className='LoginContainer form-wrapper'>
		{
			verifyEmail ?
			<VerifyEmail/> :
			<LoginForm setView={setView}/>
		}
		{
		  isUserRequestLoading ? 
		  <div className="loading-wrapper">
			<div className="loader"></div>
		  </div> : ""
		}
		<PopupNotifications
		open={popupOpen} 
		handleClose={handlePopupClose}
		severity={popupType}>
			{popupMessage}
		</PopupNotifications>
	</div>
}

export default Login;