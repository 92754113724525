import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  // actionToggleCreateMotivationMessageFormOpen,
  actionDeleteMotivationMessage,
  actionToggleEditMotivationMessageFormOpen
} from '../../app/Reducers/motivations/motivationSlice';
import { useSelector, useDispatch } from 'react-redux';

const Qoute = ({
  motivationMessage
}) => {

	const dispatch = useDispatch();
  
  return <>
    {motivationMessage.message}
    <span className="qouteActions">
      <Tooltip title="edit">
        <IconButton
        sx={{ marginLeft: "10", padding: "5px"}}
        aria-label="edit"
        onClick={()=>dispatch(actionToggleEditMotivationMessageFormOpen(true,motivationMessage))}>
          <EditIcon sx={{fontSize: 18}}/>
        </IconButton>
      </Tooltip>
      <Tooltip title="delete">
        <IconButton
        sx={{ marginLeft: "10", padding: "5px"}}
        aria-label="delete"
        onClick={()=>dispatch(actionDeleteMotivationMessage(motivationMessage))}>
          <DeleteIcon sx={{fontSize: 18}}/>
        </IconButton>
      </Tooltip>
    </span>
  </>
}

export default Qoute;