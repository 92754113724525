import React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange } from '@mui/material/colors';

const AvatarWithName = ({
    name,
    imgSrc=""
}) => {
    return <Stack
            direction="row"
            spacing={1}
            sx={{marginBottom: 1.5}}>
                <Avatar
                    sx={{ 
                        bgcolor: deepOrange[500],
                        width: 24,
                        height: 24,
                        fontSize: 14
                    }}
                    alt="Remy Sharp"
                    src={imgSrc}
                >
                    {name.at(0)}
                </Avatar>
                <p className="name">{name}</p>
            </Stack>
}

export default AvatarWithName;