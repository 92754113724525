import React from "react";
import "./GlobalStyles/GlobalStyles.scss";
import MobileHeader from "./Navigation/MobileHeader";
import Navigation from "./Navigation/Navigation";
import { Outlet } from "react-router-dom";

export default function Tab() {
  return (
    <div className="app-wrapper">
      <MobileHeader />
      <Navigation />
      <div className="route-wrapper">
        <Outlet/>
      </div>
    </div>
  )
}
