//import RioResponse from '.components/RioResponse/RioResponse';//cut it and paste to the component
import React, {useState,useEffect,useCallback,useRef,memo} from 'react';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import {
	actionRemoveOptionsInChatbox,
	actionOptionConfirm
} from '../../app/Reducers/chats/chatsSlice';

const RioResponse = ({
    dialog,
    // getYoutubeMessage,
    setDialogFor,
    setlinkData,
    setOpen,
    setDialogTitle,
    setDialogDescription,
    setSelectedDialogData,
    setBotFeedBackRatingsOpen,
    setyoutubeData
}) => {
    /*******************************************
    Data structure to be saved in ratings:
    {
        userQuery: String,
        rating: Number,
        ratingComment: String,
        ratedBy: UserId
        date: String,
        updatedDate: String
    }
    *******************************************/
    const { _id, name, message, isSender, options, isLinkReference, customMessageYT, ratingsEnabled }  = dialog;
    
    const dispatch = useDispatch()

    const getOptions = (searchQuery) => {
        return(
            <ul className="dialog-options">
                <li>
                    <Button
                    variant="outlined"
                    size="small"
                    type="button"
                    onClick={()=>{
                        const botMsgData = {
                            chat_id: "00000000000",
                            name: "Jemuel",
                            message: "Yes",
                            isSender: false
                        }
                        dispatch(actionOptionConfirm(dialog))
                    }}>Yes</Button>
                </li>
                <li>
                    <Button
                    variant="outlined"
                    size="small"
                    type="button"
                    onClick={()=>{
                        dispatch(actionRemoveOptionsInChatbox())
                    }}>No</Button>
                </li>
            </ul>
        )
    }

    const openWatchDialogBox = (suggestion) => {
        setDialogFor("video")
		setyoutubeData(suggestion)
		setOpen(true)
		setDialogTitle("Create todo for watching this video?")
		setDialogDescription("After watching this, make sure you end your todo in calendar and start your previous task.")
        setSelectedDialogData(dialog)
	}

    const openReadDialogBox = link => {
        setDialogFor("link")
        setlinkData(link)
        setOpen(true)
        setDialogTitle("Create todo for reading this link?")
        setDialogDescription("After reading this, make sure you end your todo in calendar and start your previous task.")
        setSelectedDialogData(dialog)
    }

    const getYoutubeMessage = customMessageYT => {
		return <div key={customMessageYT.id.videoId} className="suggestion-container">
			<img className="yt-suggestion-image"
			src={customMessageYT.snippet.thumbnails.medium.url} alt={customMessageYT.snippet.title} />
			<p className="yt-suggestion-title">{customMessageYT.snippet.title}</p>
			<p className="yt-suggestion-description">{customMessageYT.snippet.description}</p>
			{/* <a href={`https://www.youtube.com/watch?v=${customMessageYT.id.videoId}`} className="">{`https://www.youtube.com/watch?v=${suggestion.id.videoId}`}</a> */}
			<Button
			className="watch"
			variant="contained"
			type="button"
			onClick={()=>openWatchDialogBox(customMessageYT)}>
				Watch
			</Button>
		</div>
	}

    const getLinkSuggestion = link => {
        return	<div className="message">
                    <p>Link: {link}</p>
                    <Button
                    className="watch"
                    variant="contained"
                    type="button"
                    onClick={()=>openReadDialogBox(link)}>
                        Read the link
                    </Button>
                </div>
    }

    return <>
        {
            customMessageYT ?
                <div className="message">{getYoutubeMessage(customMessageYT)}</div> :
            isLinkReference ? 
                getLinkSuggestion(message) :
                typeof message === "string" ? 
                    <p className="message">{message}</p>
                    :
                    <div className="message">{message}</div>
        }
        {dialog.withSelection ? getOptions() : ""}

        {ratingsEnabled ? <>
            <span
            className="rio-rate-button"
            onClick={()=>{
                setBotFeedBackRatingsOpen(true)
                setSelectedDialogData(dialog)
            }}>Rate Response</span> 
        </> : ""}
    </>
}

export default RioResponse;