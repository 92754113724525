//import PopupNotifications from '.components/PopupNotifications/PopupNotifications';//cut it and paste to the component
import React, {useEffect} from 'react';
import ReactDom from 'react-dom';
import Alert from '@mui/material/Alert';
import {
    useTransition,
    animated
} from 'react-spring'

const PopupNotifications = ({
    open = false,
    children,
    handleClose,
    severity
}) => {

    const transition = useTransition(open,{
        from: {x: 0, y: 150, opacity:0},
        enter: {x: 0, y: 20, opacity:1},
        leave: {x: 0, y: 150, opacity:0}
    })

    // if(!open) return ""

    useEffect(() => {
        if (open) {
          const toRef = setTimeout(() => {
            handleClose()
            clearTimeout(toRef);
          }, 4000);
        }
    }, [open]);

    return ReactDom.createPortal(<>
        {
        transition((style,item) =>
            item ? 
            <animated.div style={style}>
                <div className='dialog-message-container'>
                    <Alert variant="filled" severity={severity} onClose={handleClose}>
                        {children}
                    </Alert>
                </div>
            </animated.div> : ""
        )
        }
    </>,document.getElementById("popup-notifications"))
}

export default PopupNotifications;